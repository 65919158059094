import { Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { Component, createRef } from 'react'
import mainLogo from '../../assets/images/main-logo.png';
import aboutUsBg  from "../../assets/images/about-us-image.jpg";
import faceBookIcon  from "../../assets/images/facebook.png";
import twitterIcon  from "../../assets/images/twitter.png";
import instagramIcon  from "../../assets/images/instagram.png";
import { Link } from 'react-router-dom';
import NavBackWidget from "./back_navigator";


export default class AboutUs extends Component {
  grp_btn_style = {
    border: "1px solid grey",
    backgroundColor: "#39483a",
    padding:'10px',
    width:'inherit',
    color:'#fff',
    borderRadius:'10px',
    marginTop:'5px'
    
  };
  constructor(props) {
    super(props);
    this.state = {
      contactFormRef: createRef(),
    };
  }
  render() {
    return (
      <div>
        <NavBackWidget />

        <Box
          sx={{
            margin: "0 auto",
            width: "90%",
            padding: 10,
            boxShadow: "1px 10px 4px 3px grey",
            marginTop: "20px",
          }}
        >
          <Box
            style={{
              backgroundImage: `url(${aboutUsBg})`,
              borderRadius: 10,
              textAlign: "center",
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img src={mainLogo}></img>
          </Box>

          <Box
            style={{
              backgroundColor: "light",
              marginTop: "10px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                backgroundColor: "",
                width: { xs: "100%", md: "30%" },
              }}
            >
              <div
                className="left-info"
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  margin: "10px",
                }}
              >
                <h1> Useful Links:</h1>
                <Box>
                  <div className="grp-btn" style={this.grp_btn_style}>
                    <Link style={{ color: "#fff" }} to="/terms&c">
                      Terms of use
                    </Link>
                  </div>

                  <div className="grp-btn" style={this.grp_btn_style}>
                    <Link style={{ color: "#fff" }} to="/privacy">
                      Privacy and policy
                    </Link>
                  </div>

                  <div className="grp-btn" style={this.grp_btn_style}>
                    <Link style={{ color: "#fff" }} to="/seller-agreement">
                      Seller Agreement
                    </Link>
                  </div>

                  <div className="grp-btn" style={this.grp_btn_style}>
                    <Link style={{ color: "#fff" }} to="/contact-us">
                      Contact Us
                    </Link>
                  </div>
                </Box>
              </div>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "70%" },
                display: "inline-block",
                backgroundColor: "",
              }}
            >
              <div className="bottom-info" style={{ display: "inline-block" }}>
                <h1>About Us</h1>
                <p>
                  AccountGig was founded in 2020. Since its inception, it has
                  strategically placed itself as a reliable provider of bulk
                  social media marketing services, to businesses establishments.
                  AccountGig has been thriving heavily in the media market and
                  one of the best media products (in general) dealer because of
                  its flexibility and adherence to stipulated policies.
                </p>
                <Box sx={{ textAlign: "center" }}>
                  <b>
                    {" "}
                    <small>Contact us on:</small>{" "}
                  </b>{" "}
                  <br />
                  <IconButton
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/profile.php?id=100089552083147&mibextid=LQQJ4d"
                      )
                    }
                  >
                    <img width="20" src={faceBookIcon}></img>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      window.open("https://twitter.com/accountgig")
                    }
                  >
                    <img width="20" src={twitterIcon}></img>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      window.open("https://instagram.com/accsgig")
                    }
                  >
                    <img width="20" src={instagramIcon}></img>
                  </IconButton>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}
