import React, { Component } from "react";
import NavBackWidget from "./back_navigator";
import {Box} from '@mui/material';
import { Divider, List } from "antd";

export default class SellerAgreement extends Component {
  render() {
    return (
      <div>
        <Box
          sx={{ width: { md: "60%", xs: "100%" } }}
          style={{ margin: "0 auto" }}
        >
          <NavBackWidget />
          <Box className="textContent" style={{ padding: "20px" }}>
            <h1>Seller Agreement</h1>
            <b>What does the store undertake?</b>
            <List>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Automatic sale of your accounts at your price.
                  <br />
                  <b>* 10% is for us , and 90% is for you the seller</b>
                </li>
              </List.Item>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Comprehensive advertising of the store and your products in
                  search engines, contextual advertising, forums, etc. * We
                  invest millions of Naira in advertising monthly.{" "}
                </li>
              </List.Item>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Communication with customers, problem-solving. * There is no
                  payment for refunded account , i.e a faulty account
                </li>
              </List.Item>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Acceptance of payments. * We accept payment from and payment
                  to all Nigerian banks.
                </li>
              </List.Item>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Withdrawals/payouts. * We charge 5% for withdrawal
                </li>
              </List.Item>
            </List>
            <b>What is required of you?</b>
            <List>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Periodically upload accounts to the store.{" "}
                </li>
              </List.Item>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Monitoring the performance of uploaded accounts.
                </li>
              </List.Item>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Click the "withdraw" button.
                </li>
              </List.Item>
            </List>
            <b>How to start working with us?</b>
            <h3>Very Eassyy!</h3>
            <List>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Register as a user, switch on seller mode on the side menu,
                  Fill out the application form and start uploading accounts.
                </li>
              </List.Item>
              <List.Item>
                <li style={{ listStyleType: "disc" }}>
                  Upload accounts to the store and wait for approval of your
                  request. If the request is rejected, you will see the reason.
                  If your request is approved, the account will be uploaded on
                  the site{" "}
                </li>
              </List.Item>
            </List>
            <h3>Note:</h3>
            <p>
              Account gig immediately blocks the identified accounts and access
              to them if its support detects that the Users, selling the
              accounts on the website, have more than one account. And all the
              funds of the User balance are transferred to Account gig as
              penalties and are not refundable.
            </p>{" "}
            <br />
            <p>
              Login details of the accounts should be secured until it’s bought
              , incorrect login details or charged logins details can lead to a
              request of a change or no payment if there is no identical account
              .{" "}
            </p>
            <br />
            <p>
              If complain keeps coming from buyers about accounts sold by you ,
              we may be forced to stop you from selling .
            </p>
            <Divider style={{ width: "70%" }}>
              <p>Last update: January 30th, 2023.</p>
            </Divider>
          </Box>
        </Box>
      </div>
    );
  }
}
