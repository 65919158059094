import {
  ArrowBackIos,
  Delete,
  DeleteOutline,
  Edit,
  Payment,
  Pin,
  TimerSharp,
} from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Card,
  Container,
  RadioGroup,
  IconButton,
  Input as MuiInput,
  ListItem,
  FormGroup,
  ButtonGroup,
} from '@mui/material'
import { Card as AntCard, List, Modal, Select } from 'antd'
import {
  Divider,
  Image,
  message,
  Rate,
  Tag,
  Avatar as Antavatar,
  Radio,
  Form,
  Input,
  Checkbox,
  Spin,
} from 'antd'
import { Header } from 'antd/lib/layout/layout'
import React, { Component, createRef, useState } from 'react'
import axios from 'axios'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3'
import flutter from '../../assets/images/flutterWave.png'
import { useNavigate } from 'react-router-dom'
import md5 from 'md5'
import WalletButton from './pay_with_wallet.js'
import APP_ENV from '../../env'
const BASE_URL = APP_ENV.SERVER_ADDR
const reqHeaders = {
  'Content-Type': 'application/json',
}

if (localStorage.getItem('AccgToken') !== null) {
  reqHeaders['Authorization'] = `Token ${localStorage.getItem('AccgToken')}`
}

const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,
})
const authClient = axios.create({
  baseURL: BASE_URL,
})
const VisitHistory = props => {
  const navigate = useNavigate()
  React.useEffect(() => {
    if (props.clicked == true) {
      if (props.path === '//') {
        return navigate(-1)
      }

      navigate(props.path)
    }
  }, [props.path])
  return <></>
}

export default class CheckOut extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cartListData: [],
      itemClicked: false,
      itemPath: '',
      productsRef: props.prodctsRef,
      badNStatus: false,

      selectedShippingAddress: null,
      selectedBillingAddress: null,
      order: {},
      UserAddresses: [],
      orderBilingAd: {},
      orderShippingAd: {},
      loading: true,
      billingAddresses: [],
      disableAll: false,
      shippingAddresses: [],
      sameAsShipping: false,
      createFormInitials: {
        full_name: '',
        email: '',
        type: null,
        default: '',
        whatsappNumber: '',
      },
    }
  }
  radioRef = createRef(null)
  updateAddresses() {
    client
      .get('/social/api/shipping-address')
      .then(resp => {
        this.setState({ UserAddresses: resp.data })
        this.setState({ billingAddresses: [] })
        this.setState({ shippingAddresses: [] })

        for (let i = 0; i < resp.data.length; i++) {
          const userAddress = resp.data[i]
          if (userAddress.type === 'S') {
            // this.state.shippingAddresses.filter(item => {
            //     if (item.email !== userAddress.email) {
            //         this.state.shippingAddresses.pop(item)

            //     }
            // })
            this.setState({
              shippingAddresses: [...this.state.shippingAddresses, userAddress],
            })
          }

          if (userAddress.type === 'B') {
            // this.state.billingAddresses.filter(item => {
            //     if (item.email !== userAddress.email) {

            //         this.state.billingAddresses.pop(item)
            //     }
            // })
            this.setState({
              billingAddresses: [...this.state.billingAddresses, userAddress],
            })
          }
          // console.log('shiping', this.state.shippingAddresses)
          // console.log('billing', this.state.billingAddresses)
          for (let i = 0; i < resp.data.length; i++) {
            if (
              this.state.selectedShippingAddress === null &&
              resp.data[i].default === true &&
              resp.data[i].type === 'S'
            ) {
              this.setState({
                selectedShippingAddress: resp.data[i].id,
              })
            }

            if (
              this.state.selectedBillingAddress === null &&
              resp.data[i].default === true &&
              resp.data[i].type === 'B'
            ) {
              this.setState({
                selectedBillingAddress: resp.data[i].id,
              })
            }
          }
        }
        this.setState({ loading: false })
      })
      .catch(err => {
        message.error(err)
        this.setState({ loading: false })
      })
  }
  updateOrder() {
    this.setState({ loading: true })

    setTimeout(() => {
      client
        .get('/user/api/order/checkout')
        .then(resp => {
          this.setState({
            cartListData: resp.data.cart,
            order: resp.data.order,
          })

          if (resp.data.order.shippingAddress) {
            this.setState({
              selectedShippingAddress: resp.data.order.shippingAddress,
            })
            this.setState({
              orderShippingAd: resp.data.address.shippingAddress,
            })
          }
          if (resp.data.order.billingAddress) {
            this.setState({
              selectedBillingAddress: resp.data.order.billingAddress,
            })
            this.setState({ orderBilingAd: resp.data.address.billingAddress })
          }
          this.updateAddresses()

          if (
            resp.data.order.billingAddress ===
              resp.data.order.shippingAddress &&
            resp.data.order.billingAddress !== null
          ) {
            this.setState({ sameAsShipping: true })
          }
          this.setState({ loading: false })
        })
        .catch(err => {
          message.error(err)
          this.setState({ loading: false })
        })

      // client.get('social/api/shipping-address').then(resp => {
      //     this.setState({ UserAddresses: resp.data })

      //     // this.setState({ cartListData: resp.data.cart })

      // }).catch(err => message.error(err))
    }, 100)
  }
  async getData() {
    do {
      console.log('getting')
      this.setState({loading:true})
      
    try {
      const resp = await client.get('/user/api/order/checkout')

      if (resp.data.cart) {
        console.log('no cart')
        if (!resp.data.cart.length >= 1) {
          this.setState({ itemPath: '/', itemClicked: true })
        }

        this.setState({
          cartListData: resp.data.cart,
          order: resp.data.order,
        })
        if (this.state.order.shippingAddress) {
          this.setState({
            selectedShippingAddress: resp.data.order.shippingAddress,
          })

          this.setState({
            orderShippingAd: resp.data.address.shippingAddress,
          })
        }
        if (this.state.order.billingAddress) {
          this.setState({
            selectedBillingAddress: resp.data.order.billingAddress,
          })
          this.setState({ orderBilingAd: resp.data.address.billingAddress })
        }
      } else {
        message.error('No item in your cart')
        this.setState({ disableAll: true })
      }

      if (
        resp.data.order.billingAddress === resp.data.order.shippingAddress &&
        resp.data.order.billingAddress !== null
      ) {
        this.setState({ sameAsShipping: true })
      }

      this.setState({ loading: false })
    } catch (err) {
      message.error(err)
      this.setState({ badNStatus: true })
      this.setState({ loading: false })
    }} while (this.state.cartListData.length <= 0)
  }

  componentDidMount() {
    this.getData()

    // client.get('social/api/shipping-address').then(resp => {
    //     this.setState({ UserAddresses: resp.data })
    //     for (let i = 0; i < resp.data.length; i++) {

    //         if (this.state.selectedShippingAddress === null && resp.data[i].default === true && resp.data[i].type === 'S') {
    //             this.setState({
    //                 selectedShippingAddress: resp.data[i].id
    //             })
    //         }

    //         if (this.state.selectedBillingAddress === null && resp.data[i].default === true && resp.data[i].type === 'B') {
    //             this.setState({
    //                 selectedBillingAddress: resp.data[i].id
    //             })
    //         }
    //     }

    //     // this.setState({ cartListData: resp.data.cart })
    // }).catch(err => message.error(err))
    // this.updateWishList()
    // this.updateOrder()
    this.updateAddresses()
  }

  wishHeaderStyle = {
    fontSize: '10px',
    borderTop: '2px solid grey',
    display: 'inline-block',
    marginRight: '5px',
    color: 'whitesmoke',
    backgroundColor: 'darkolivegreen',
  }
  btmBtnsStyle = {
    color: 'darkgreen',
    width: '100%',
    fontSize: '10px',
    fontWeight: 'bold',
  }
  FormStyle = {
    width: 300,
    padding: '20px',
    margin: '0',
  }
  pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ]
  handleEditForm(id) {
    const el = document.getElementById(`editAddress${id}`)
    if (el.style.height === '700px') {
      el.style.height = '200px'
    } else {
      el.style.height = '700px'
    }
  }
  handleCloseEditForm(id) {
    const el = document.getElementById(`editAddress${id}`)
    el.style.height = '200px'
  }
  handleAddressEdit(val) {
    this.setState({ loading: true })

    // const form = document.querySelector('#editFormID');
    val.default = document.getElementById(`${val.id}defaultCheckbtn`).checked
    val.whatsappNumber = document.getElementById(
      `${val.id}whtsCheckbtn`,
    ).checked

    client
      .put(`/social/api/shipping-address/${val.id}`, val)
      .then(resp => {
        this.setState({ UserAddresses: resp.data })
        // console.log(val)

        for (let i = 0; i < resp.data.length; i++) {
          if (
            this.state.selectedShippingAddress === null &&
            resp.data[i].default === true &&
            resp.data[i].type === 'S'
          ) {
            this.setState({
              selectedShippingAddress: resp.data[i].id,
            })
          }

          if (
            this.state.selectedBillingAddress === null &&
            resp.data[i].default === true &&
            resp.data[i].type === 'B'
          ) {
            this.setState({
              selectedBillingAddress: resp.data[i].id,
            })
          }
        }
        this.updateOrder()
        this.updateAddresses()

        message.info('Edit Applied')
        this.setState({ loading: false })

        // this.updateElements()
      })
      .catch(err => {
        message.error(err)
        this.setState({ loading: false })
      })
  }
  handleAddressCreate(val) {
    this.setState({ loading: true })

    // const form = document.querySelector('#editFormID');
    val.default = document.getElementById('newdefaultCheckbtn').checked
    val.whatsappNumber = document.getElementById('newwhtsCheckbtn').checked

    client
      .post('/social/api/shipping-address/', val)
      .then(resp => {
        if (resp.data.error) {
          message.error(resp.data.error)
        } else {
          this.setState({ UserAddresses: resp.data })
          // console.log(val)

          // this.updateElements()
          for (let i = 0; i < resp.data.length; i++) {
            if (
              this.state.selectedShippingAddress === null &&
              resp.data[i].default === true &&
              resp.data[i].type === 'S'
            ) {
              this.setState({
                selectedShippingAddress: resp.data[i].id,
              })
            }

            if (
              this.state.selectedBillingAddress === null &&
              resp.data[i].default === true &&
              resp.data[i].type === 'B'
            ) {
              this.setState({
                selectedBillingAddress: resp.data[i].id,
              })
            }
          }
          this.updateOrder()
          message.success('Address Created')
        }
        this.updateAddresses()
        this.setState({ loading: false })
      })
      .catch(err => {
        message.error(err)
        this.setState({ loading: false })
      })
  }
  handleAddressDelete(id) {
    this.setState({ loading: true })

    client
      .delete(`/social/api/shipping-address/${id}`)
      .then(resp => {
        this.setState({ UserAddresses: resp.data })
        if (this.state.selectedShippingAddress === id) {
          this.setState({ selectedShippingAddress: null })
        }
        if (this.state.selectedBillingAddress === id) {
          this.setState({ selectedBillingAddress: null })
        }

        for (let i = 0; i < resp.data.length; i++) {
          if (
            this.state.selectedShippingAddress === null &&
            resp.data[i].default === true &&
            resp.data[i].type === 'S'
          ) {
            this.setState({
              selectedShippingAddress: resp.data[i].id,
            })
          }

          if (
            this.state.selectedBillingAddress === null &&
            resp.data[i].default === true &&
            resp.data[i].type === 'B'
          ) {
            this.setState({
              selectedBillingAddress: resp.data[i].id,
            })
          }
        }
        this.updateOrder()
        this.updateAddresses()

        message.success('Address Deleted!')
        this.setState({ loading: false })

        // this.updateElements()
      })
      .catch(err => {
        message.error(err)
        this.setState({ loading: false })
      })
    this.setState({ UserAddresses: [] })

    this.updateOrder()
  }
  handleChangeAddress(id, type, sameAsB = false) {
    this.setState({ loading: true })

    if (type === 'S') {
      this.setState({ selectedShippingAddress: id })
    }
    if (type === 'B') {
      this.setState({ selectedBillingAddress: id })
    }

    // if (!(this.state.selectedBillingAddress === null && this.state.selectedShippingAddress === null)) {

    client
      .put('/user/api/order/checkout', {
        action: 'add-address',
        order_id: this.state.order.id,
        address_id: id,
        same:
          this.state.selectedBillingAddress === null &&
          this.state.billingAddresses.length <= 0
            ? true
            : sameAsB,
      })
      .then(resp => {
        this.setState({
          selectedShippingAddress: resp.data.order.shippingAddress,
        })

        this.setState({
          selectedBillingAddress: resp.data.order.billingAddress,
        })
        this.updateOrder()
        this.setState({ loading: false })
        message.success('Address Selected')
      })
      .catch(err => {
        message.error(err)
        this.setState({ loading: false })
      })
    // setTimeout(() => {

    //     console.log(this.state.selectedBillingAddress, this.state.selectedShippingAddress)

    // }, 100)
    this.updateOrder()
  }

  render() {
    const { UserAddresses, cartListData } = this.state
    // console.log(this.state.orderBilingAd, this.state.shippingAddresses, this.state.order)
    return (
      <div>
        <Spin spinning={this.state.loading} tip="getting checkout ready...">
          <Header
            children={
              <>
                <div style={{ fontSize: '30px', margin: '0' }}>
                  Order Checkout
                  <div style={{ float: 'right' }}>
                    {/* <Button style={this.wishHeaderStyle} onClick={() => { this.state.productsRef.current.add_allToCart(); this.updateWishList() }}>Add all to cart</Button> */}
                    <Button
                      style={this.wishHeaderStyle}
                      onClick={() => {
                        this.state.productsRef.current.cancel_Order(
                          this.state.order.id,
                        )
                        this.setState({
                          itemPath: '/cart',
                          itemClicked: true,
                        })
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>{' '}
              </>
            }
            style={{ backgroundColor: 'transparent', fontFamily: 'serif' }}
          ></Header>
          <ArrowBackIos
            style={{
              position: 'absolute',
              top: '10px',
              fontSize: '20px',
              cursor: 'pointer',
              marginLeft: '10px',
              marginTop: '10px',
            }}
            onClick={() => this.setState({ itemPath: '//', itemClicked: true })}
          />
          <VisitHistory
            path={this.state.itemPath}
            clicked={this.state.itemClicked}
          ></VisitHistory>
          <Divider />

          <Box
            sx={{
              width: { md: '30%', xs: '100%' },
              fontFamily: 'serif',
              display: { md: 'inline-flex' },
            }}
          >
            <AntCard
              unselectable="true"
              title={
                <>
                  Order Summary <br />
                  <small>#{this.state.order.code}</small>
                </>
              }
              style={{ margin: '0 auto', width: '90%' }}
              cover={
                <>
                  <IconButton
                    onClick={() =>
                      this.setState({
                        itemPath: '/cart',
                        itemClicked: true,
                      })
                    }
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: 'forestgreen',
                      width: 40,
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <AntCard>
                    <AntCard.Grid style={{ width: '50%', padding: '10px' }}>
                      <h4>Subtotal :</h4>
                      <h4>Shipping :</h4>
                      <h4>Disount :</h4>
                      <h4>Network Fee:</h4>
                      <h4>Total :</h4>
                    </AntCard.Grid>
                    <AntCard.Grid style={{ width: '50%', padding: '10px' }}>
                      <h4>{this.state.order.subtotal}</h4>
                      <h4>{this.state.order.shippingFee}</h4>
                      <h4>{this.state.order.discount_save}</h4>
                      <h4>{this.state.order.networkFee}</h4>
                      <h4> {this.state.order.total}</h4>
                    </AntCard.Grid>
                  </AntCard>
                </>
              }
            >
              <AntCard.Grid title="Item(s)" style={{ padding: 2 }}>
                <p>Item(s)</p>
              </AntCard.Grid>
              <AntCard.Grid title="Qty" style={{ padding: 2 }}>
                <p>Qty</p>
              </AntCard.Grid>
              <AntCard.Grid title="Price" style={{ padding: 2 }}>
                <p>Price</p>
              </AntCard.Grid>
              {cartListData.map(item => {
                return (
                  <>
                    <AntCard.Grid style={{ padding: 2, paddingLeft: 6 }}>
                      <p
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          lineHeight: '1.5em',
                          height: '1.5em',
                        }}
                      >
                        {item.account_title}
                      </p>
                    </AntCard.Grid>
                    <AntCard.Grid style={{ padding: 2, paddingLeft: 6 }}>
                      <p>{item.quantity}</p>
                    </AntCard.Grid>
                    <AntCard.Grid style={{ padding: 2, paddingLeft: 6 }}>
                      <p>{item.product_sum}</p>
                    </AntCard.Grid>
                  </>
                )
              })}
            </AntCard>
          </Box>
          <Box
            sx={{
              width: { md: '40%', xs: '100%' },
              fontFamily: 'serif',
              display: { md: 'inline-flex' },
            }}
          >
            <Box style={{ margin: '0 auto' }}>
              {this.state.shippingAddresses.length === 0 ? (
                <h2 style={{ textAlign: 'center' }}>Create Shipping Address</h2>
              ) : (
                <h2 style={{ textAlign: 'center' }}>Select Shipping Address</h2>
              )}
              {UserAddresses.length >= 1 ? (
                <>
                  {' '}
                  {UserAddresses.map(address => {
                    return (
                      <>
                        {address.type === 'S' ? (
                          <Card
                            title={address.full_name}
                            sx={{
                              width: { md: '100%', xs: '80%' },
                              padding: { md: '20px', xs: '5px' },
                            }}
                            style={{
                              margin: '0 auto',
                              transition: 'linear 0.2s ',
                              overflow: 'hidden',
                              height: '200px',
                            }}
                            id={`editAddress${address.id}`}
                          >
                            <Radio.Group
                              disabled={this.state.disableAll}
                              value={
                                address.default === true &&
                                this.state.selectedShippingAddress === null
                                  ? address.id
                                  : this.state.selectedShippingAddress
                              }
                            >
                              <Radio
                                className="address-radio"
                                id={`addressRadio${address.id}`}
                                onChange={() =>
                                  this.handleChangeAddress(
                                    address.id,
                                    address.type,
                                  )
                                }
                                value={address.id}
                                defaultChecked={
                                  address.id ===
                                    this.state.order.shippingAddress ||
                                  (address.default === true &&
                                    this.state.selectedShippingAddress === null)
                                    ? true
                                    : false
                                }
                              ></Radio>
                            </Radio.Group>
                            <div>
                              <Container>
                                <h4>Full Name : {address.full_name} </h4>
                              </Container>

                              <Container>
                                <h4>Email :{address.email}</h4>
                              </Container>
                              <Container>
                                <h4>Phone : {address.phone}</h4>
                              </Container>
                            </div>
                            <IconButton
                              style={{
                                float: 'left',
                                cursor: 'pointer',
                                color: 'red',
                              }}
                              onClick={() => {
                                this.handleAddressDelete(address.id)
                              }}
                            >
                              {<DeleteOutline />}
                            </IconButton>

                            <h2
                              style={{
                                float: 'right',
                                cursor: 'pointer',
                                marginRight: '10px',
                                color: 'Highlight',
                              }}
                              onClick={() => this.handleEditForm(address.id)}
                            >
                              {this.pencil}
                            </h2>
                            <Divider />
                            <Box style={{ textAlign: 'center' }}>
                              <FormControl required name={`edit${address.id}`}>
                                <Form
                                  component="form"
                                  onFinish={val => {
                                    this.handleAddressEdit(val)
                                  }}
                                  initialValues={{
                                    id: address.id,
                                    full_name: address.full_name,
                                    email: address.email,
                                    phone: address.phone,
                                    whatsappNumber: address.whatsappNumber,
                                    default: address.default,
                                    type: address.type,
                                  }}
                                >
                                  <Form.Item
                                    name="id"
                                    style={this.FormStyle}
                                    type="number"
                                    hidden
                                  >
                                    <Input name="id" type="number" />
                                  </Form.Item>

                                  <Form.Item
                                    name="type"
                                    style={this.FormStyle}
                                    type="text"
                                    hidden
                                  >
                                    <Input name="type" type="text" />
                                  </Form.Item>
                                  <Form.Item
                                    name="full_name"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Enter Full Name',
                                      },
                                    ]}
                                    style={this.FormStyle}
                                  >
                                    <Input
                                      name="full_name"
                                      placeholder="Full Name"
                                      type="text"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name="email"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Enter Email',
                                      },
                                    ]}
                                    style={this.FormStyle}
                                  >
                                    <Input
                                      required
                                      placeholder="Email"
                                      name="email"
                                      type="email"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Enter Phone Number',
                                      },
                                    ]}
                                    maxLength={11}
                                    style={this.FormStyle}
                                    name="phone"
                                  >
                                    <Input
                                      placeholder="Phone"
                                      name="phone"
                                      maxLength={11}
                                      type="tel"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name="whatsappNumber"
                                    title="tick if you want product delivered on whatsapp"
                                    initialValue={address.whatsappNumber}
                                  >
                                    <Input
                                      style={{
                                        width: '25px',
                                        height: '20px',
                                      }}
                                      type=""
                                      id={`${address.id}whtsCheckbtn`}
                                      defaultValue={address.whatsappNumber}
                                      defaultChecked={address.whatsappNumber}
                                      name="whatsappNumber"
                                      placeholder="Whatsapp line"
                                    />{' '}
                                    <FormLabel>
                                      This is my whatsapp number
                                    </FormLabel>
                                  </Form.Item>

                                  <Form.Item
                                    name="default"
                                    row={15}
                                    title="tick if you want to set as your default address"
                                    initialValue={address.default}
                                  >
                                    <Input
                                      style={{
                                        width: '25px',
                                        height: '20px',
                                      }}
                                      type="checkbox"
                                      defaultChecked={address.default}
                                      defaultValue={address.default}
                                      id={`${address.id}defaultCheckbtn`}
                                      value={true}
                                      name="default"
                                      placeholder="Default"
                                    />{' '}
                                    <FormLabel>Default</FormLabel>
                                  </Form.Item>
                                  <Button
                                    type="submit"
                                    style={{
                                      backgroundColor: 'ButtonHighlight',
                                    }}
                                  >
                                    Save Address
                                  </Button>
                                </Form>
                              </FormControl>
                            </Box>
                          </Card>
                        ) : (
                          ''
                        )}
                        <br></br>
                      </>
                    )
                  })}
                  <div
                    style={{
                      margin: '20px',
                      display:
                        this.state.shippingAddresses.length >= 1
                          ? 'block'
                          : 'none',
                    }}
                    onClick={() =>
                      !this.state.selectedShippingAddress
                        ? message.info('Please select shipping address', 1)
                        : ''
                    }
                  >
                    <Checkbox
                      disabled={
                        this.state.selectedShippingAddress ? false : true
                      }
                      checked={
                        this.state.selectedShippingAddress === null
                          ? false
                          : this.state.sameAsShipping === true &&
                            this.state.selectedBillingAddress ===
                              this.state.selectedShippingAddress
                          ? true
                          : false
                      }
                      onChange={e => {
                        this.setState({ sameAsShipping: e.target.checked })
                        if (e.target.checked === true) {
                          this.handleChangeAddress(
                            this.state.selectedShippingAddress,
                            'B',
                            true,
                          )
                        } else {
                          this.setState({ selectedBillingAddress: null })
                        }
                      }}
                    >
                      Same As Billing Address
                    </Checkbox>
                  </div>
                </>
              ) : (
                ''
              )}
              {this.state.shippingAddresses.length === 0 ? (
                <center>Add a Shipping Address </center>
              ) : (
                ''
              )}

              {this.state.sameAsShipping === false ? (
                <>
                  <Divider />
                  {!(this.state.UserAddresses.length === 0) ? (
                    this.state.billingAddresses.length === 0 ? (
                      <h2 style={{ textAlign: 'center' }}>
                        Create Billing Address
                      </h2>
                    ) : (
                      <h2 style={{ textAlign: 'center' }}>
                        Select Billing Address
                      </h2>
                    )
                  ) : (
                    ''
                  )}

                  {UserAddresses.length >= 1
                    ? UserAddresses.map(address => {
                        return (
                          <>
                            {address.type === 'B' ? (
                              <Card
                                title={address.full_name}
                                sx={{
                                  width: { md: '100%', xs: '80%' },
                                  padding: { md: '20px', xs: '5px' },
                                }}
                                style={{
                                  margin: '0 auto',
                                  transition: 'linear 0.2s ',
                                  overflow: 'hidden',
                                  height: '200px',
                                }}
                                id={`editAddress${address.id}`}
                              >
                                <Radio.Group
                                  disabled={this.state.disableAll}
                                  value={
                                    address.default === true &&
                                    this.state.selectedBillingAddress ===
                                      null &&
                                    this.state.sameAsShipping !== false
                                      ? address.id
                                      : this.state.selectedBillingAddress
                                  }
                                >
                                  <Radio
                                    className="address-radio"
                                    id={`addressRadio${address.id}`}
                                    onChange={() =>
                                      this.handleChangeAddress(
                                        address.id,
                                        address.type,
                                      )
                                    }
                                    value={address.id}
                                    defaultChecked={
                                      address.id ===
                                        this.state.order.billingAddress ||
                                      (address.default === true &&
                                        this.state.selectedBillingAddress ===
                                          null)
                                        ? true
                                        : false
                                    }
                                  ></Radio>
                                </Radio.Group>
                                <div>
                                  <Container>
                                    <h4>Full Name : {address.full_name} </h4>
                                  </Container>

                                  <Container>
                                    <h4>Email :{address.email}</h4>
                                  </Container>
                                  <Container>
                                    <h4>Phone : {address.phone}</h4>
                                  </Container>
                                </div>
                                <IconButton
                                  style={{
                                    float: 'left',
                                    cursor: 'pointer',
                                    color: 'red',
                                  }}
                                  onClick={() => {
                                    this.handleAddressDelete(address.id)
                                  }}
                                >
                                  {<DeleteOutline />}
                                </IconButton>

                                <h2
                                  style={{
                                    float: 'right',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    color: 'Highlight',
                                  }}
                                  onClick={() =>
                                    this.handleEditForm(address.id)
                                  }
                                >
                                  {this.pencil}
                                </h2>
                                <Divider />
                                <Box style={{ textAlign: 'center' }}>
                                  <FormControl
                                    required
                                    name={`edit${address.id}`}
                                  >
                                    <Form
                                      component="form"
                                      onFinish={val => {
                                        this.handleAddressEdit(val)
                                      }}
                                      initialValues={{
                                        id: address.id,
                                        full_name: address.full_name,
                                        email: address.email,
                                        phone: address.phone,
                                        whatsappNumber: address.whatsappNumber,
                                        default: address.default,
                                        type: address.type,
                                      }}
                                    >
                                      <Form.Item
                                        name="id"
                                        style={this.FormStyle}
                                        type="number"
                                        hidden
                                      >
                                        <Input name="id" type="number" />
                                      </Form.Item>
                                      <Form.Item
                                        name="type"
                                        style={this.FormStyle}
                                        type="text"
                                        hidden
                                      >
                                        <Input name="type" type="text" />
                                      </Form.Item>

                                      <Form.Item
                                        name="full_name"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please Enter Full Name',
                                          },
                                        ]}
                                        style={this.FormStyle}
                                      >
                                        <Input
                                          name="full_name"
                                          placeholder="Full Name"
                                          type="text"
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        name="email"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please Enter Email',
                                          },
                                        ]}
                                        style={this.FormStyle}
                                      >
                                        <Input
                                          required
                                          placeholder="Email"
                                          name="email"
                                          type="email"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              'Please Enter Phone Number',
                                          },
                                        ]}
                                        maxLength={11}
                                        style={this.FormStyle}
                                        name="phone"
                                      >
                                        <Input
                                          placeholder="Phone"
                                          name="phone"
                                          maxLength={11}
                                          type="tel"
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        name="whatsappNumber"
                                        title="tick if you want product delivered on whatsapp"
                                        initialValue={address.whatsappNumber}
                                      >
                                        <Input
                                          style={{
                                            width: '25px',
                                            height: '20px',
                                          }}
                                          type="checkbox"
                                          id={`${address.id}whtsCheckbtn`}
                                          defaultValue={address.whatsappNumber}
                                          defaultChecked={
                                            address.whatsappNumber
                                          }
                                          name="whatsappNumber"
                                          placeholder="Whatsapp line"
                                        />{' '}
                                        <FormLabel>
                                          This is my whatsapp number
                                        </FormLabel>
                                      </Form.Item>

                                      <Form.Item
                                        name="default"
                                        row={15}
                                        title="tick if you want to set as your default address"
                                        initialValue={address.default}
                                      >
                                        <Input
                                          style={{
                                            width: '25px',
                                            height: '20px',
                                          }}
                                          type="checkbox"
                                          defaultChecked={address.default}
                                          defaultValue={address.default}
                                          id={`${address.id}defaultCheckbtn`}
                                          value={true}
                                          name="default"
                                          placeholder="Default"
                                        />{' '}
                                        <FormLabel>Default</FormLabel>
                                      </Form.Item>
                                      <Button
                                        type="submit"
                                        style={{
                                          backgroundColor: 'ButtonHighlight',
                                        }}
                                      >
                                        Save Address
                                      </Button>
                                    </Form>
                                  </FormControl>
                                </Box>
                              </Card>
                            ) : (
                              ''
                            )}

                            <br></br>
                          </>
                        )
                      })
                    : ''}

                  {this.state.billingAddresses.length === 0 ? (
                    <center>Add a Billing Address </center>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ' '
              )}
              <div
                style={{
                  margin: '0 auto',
                  textAlign: 'center',
                  fontFamily: 'serif',
                }}
              >
                {!(
                  this.state.billingAddresses.length === 1 &&
                  this.state.shippingAddresses.length === 1
                ) ? (
                  <Box style={{ textAlign: 'center' }}>
                    <FormControl>
                      <Form
                        component="form"
                        form={this.props.form}
                        initialValues={this.state.createFormInitials}
                        onFinish={val => {
                          this.handleAddressCreate(val)
                          this.props.form.resetFields()
                        }}
                      >
                        <Form.Item
                          name="full_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Full Name',
                            },
                          ]}
                          style={this.FormStyle}
                        >
                          <Input
                            name="full_name"
                            placeholder="Full Name"
                            type="text"
                          />
                        </Form.Item>

                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Email',
                            },
                          ]}
                          style={this.FormStyle}
                        >
                          <Input
                            required
                            placeholder="Email"
                            name="email"
                            type="email"
                          />
                        </Form.Item>
                        <Form.Item
                          name="type"
                          rules={[
                            {
                              required: true,
                              message: 'Please Choose Address Type',
                            },
                          ]}
                          style={this.FormStyle}
                        >
                          <Select required>
                            <Select.OptGroup>
                              <Select.Option value={null}>
                                Address Type
                              </Select.Option>

                              <Select.Option
                                disabled={
                                  this.state.shippingAddresses.length >= 1
                                    ? true
                                    : false
                                }
                                value="S"
                              >
                                Shipping Address
                              </Select.Option>
                              <Select.Option
                                disabled={
                                  this.state.billingAddresses.length >= 1
                                    ? true
                                    : false
                                }
                                value="B"
                              >
                                Billing Address
                              </Select.Option>
                            </Select.OptGroup>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Phone Number',
                            },
                          ]}
                          maxLength={11}
                          style={this.FormStyle}
                          name="phone"
                        >
                          <Input
                            placeholder="Phone"
                            maxLength={11}
                            name="phone"
                            type="tel"
                          />
                        </Form.Item>

                        <Form.Item
                          name="whatsappNumber"
                          title="tick if you want product delivered on whatsapp"
                        >
                          <Input
                            style={{
                              width: '25px',
                              float: 'right',
                              height: '20px',
                            }}
                            type="checkbox"
                            id={'newwhtsCheckbtn'}
                            name="whatsappNumber"
                            placeholder="Whatsapp line"
                          />{' '}
                          <FormLabel
                            style={{
                              float: 'right',
                            }}
                          >
                            This is my whatsapp number
                          </FormLabel>
                        </Form.Item>

                        <Form.Item
                          name="default"
                          row={15}
                          title="tick if you want to set as your default address"
                        >
                          <Input
                            style={{
                              width: '25px',
                              float: 'right',
                              height: '20px',
                            }}
                            type="checkbox"
                            id={'newdefaultCheckbtn'}
                            name="default"
                            placeholder="Default"
                          />{' '}
                          <FormLabel
                            style={{
                              float: 'right',
                            }}
                          >
                            Default
                          </FormLabel>
                        </Form.Item>
                        <Button
                          type="submit"
                          style={{
                            width: '100%',
                            backgroundColor: 'rgba(40,50,30,0.7)',
                            color: 'whitesmoke',
                          }}
                        >
                          Add New Address
                        </Button>
                      </Form>
                    </FormControl>
                  </Box>
                ) : (
                  ''
                )}
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: '30%', xs: '100%' },
              fontFamily: 'serif',
              display: { md: 'inline-flex', xs: 'block' },
            }}
          >
            <List style={{ margin: '0 auto', textAlign: 'center' }}>
              <ListItem
                disabled={
                  this.state.selectedShippingAddress === null ||
                  this.state.selectedBillingAddress === null
                    ? this.state.selectedShippingAddress !== null &&
                      this.state.sameAsShipping === true
                      ? false
                      : true
                    : false
                }
              >
                <FlutterWavePaymentApp
                  headerRefP={this.props.headerUpdater}
                  badNetworkStatus={this.state.badNStatus}
                  order={this.state.order ? this.state.order : {}}
                  billingAddress={
                    this.state.orderBilingAd ? this.state.orderBilingAd : {}
                  }
                  disable={
                    this.state.selectedShippingAddress === null ||
                    this.state.selectedBillingAddress === null
                      ? this.state.selectedShippingAddress !== null &&
                        this.state.sameAsShipping === true
                        ? false
                        : true
                      : false
                  }
                />
              </ListItem>
              {this.state.order ? (
                <ListItem
                  sx={{ textAlign: 'center', margin: '0 auto' }}
                  disabled={
                    this.state.selectedShippingAddress === null ||
                    this.state.selectedBillingAddress === null
                      ? this.state.selectedShippingAddress !== null &&
                        this.state.sameAsShipping === true
                        ? false
                        : true
                      : false
                  }
                >
                  <WalletButton
                    updateParentOrder={() => this.updateOrder()}
                    pOrder={this.state.order}
                    disable={
                      this.state.selectedShippingAddress === null ||
                      this.state.selectedBillingAddress === null
                        ? this.state.selectedShippingAddress !== null &&
                          this.state.sameAsShipping === true
                          ? false
                          : true
                        : false
                    }
                    headerRef={this.props.headerUpdater}
                  />
                </ListItem>
              ) : (
                ''
              )}
            </List>
          </Box>
        </Spin>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    )
  }
}

function FlutterWavePaymentApp(props) {
  const navigate = useNavigate()
  const config = {
    public_key: APP_ENV.FLUTTERWAVE_PUB_KEY,
    tx_ref: Date.now(),
    amount: props.order.total_amount,
    currency: props.order.currency,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: props.billingAddress.email,
      phonenumber: props.billingAddress.phone,
      name: props.billingAddress.full_name,
    },
    customizations: {
      title: 'AccountGig',
      description: 'Payment for items in cart',
      logo: '/static/media/logo192.png',
    },
  }
  const fwConfig = {
    ...config,
    text: <h4> Pay with Flutterwave!</h4>,
    callback: response => {
      const nonce = Math.floor(Math.random() * 9999) + 1
      const timestamp = Date.now().toString().replace('.', '')
      const user_id = props.order.user.id
      const user_username = props.order.user.username
      const comb = String(
        String(nonce) +
          String(timestamp) +
          String(user_username) +
          String(user_id),
      ).toLowerCase()
      const sign = md5(comb).toString()
      const order_id = props.order.id
      const callbackJSON = {}
      callbackJSON['nonce'] = nonce
      callbackJSON['api_key'] = APP_ENV.FLUTTERWAVE_API_KEY
      callbackJSON['timestamp'] = timestamp
      callbackJSON['sign'] = sign
      callbackJSON['user_id'] = user_id
      callbackJSON['user_username'] = user_username
      callbackJSON['order_id'] = order_id
      callbackJSON['payment_type'] = 'P'
      callbackJSON['trx_type'] = 'debit'
      callbackJSON['paymentInfo'] = response

      client
        .post('/user/api/create-payment', callbackJSON)
        .then(resp => {
          // console.log(resp)
          props.headerRefP.current.updateAll()
          window.location.href = `/order/status/${props.order.code}?payment_status=${resp.data.status}`
        })
        .catch(err => message.error(err))
      // closePaymentModal() // this will close the modal programmatically
    },
    onClose: () => {
      message.error('Payment Closed')
    },
  }

  return (
    <div
      className="App"
      style={{ textAlign: 'center', margin: '0 auto', objectFit: 'contain' }}
    >
      <h1>Proceed to Payment</h1>
      {/* <img src={flutter} alt="flutter wave" />

            {props.badNetworkStatus === false ? <FlutterWaveButton className='flutterPayButton' disabled={props.disable} {...fwConfig} /> : 'Cannot get flutter wave ready! (Network error)'} */}
    </div>
  )
}

// flutter wave key
// 1Qg5qs36.hNSZlQ2en9vUaH5ELQTa1CEq8OqyXPS2
