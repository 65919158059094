import * as React from "react";
import TextField from "@mui/material/TextField";
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useState, useRef } from "react";
import { useAutocomplete } from "@mui/material";
import { Avatar, Divider, IconButton, Input, List, ListItem } from "@mui/material";
import { style, Box, spacing, shadows } from "@mui/system";
import { client } from "../app_context";
import { Tag, Form } from "antd";
import { useNavigate } from "react-router-dom";



export default function SearchHighlights(props) {
  const [query, setQuery] = useState('')
  const [fetchedData, setfetchedData] = useState([])
  const navigate = useNavigate()
  const formRef = useRef()
  const getAutoComplete = ()=> setTimeout(async() => {

    // if (fetchedData.length <= 0){
   const response= await client.get('/user/api/search-platform?autocomplete=' + query)
   if (response.data.result){
    setfetchedData(response.data.result)
   }
  // }
  }, 1000)

  const StyledInputBase = styled('input')(({ theme }) => ({

    color: "inherit",

    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    zIndex: '100',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    top: '0',
    left: 0,
    position: "absolute",
    pointerEvents: "none",
    color: 'white',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  }));

  const Listbox = styled('ul')(({ theme }) => ({
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',


    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li.Mui-focused': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  }));
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: fetchedData,
    getOptionLabel: (option) => option.title,
  });


  return (
    <div style={{ width: "70%", margin: "0 auto", justifyContent: "center", alignContent: 'center' }}>
      <div {...getRootProps()} style={{ width: "100%" }}>
        {/* <Label {...getInputLabelProps()}>useAutocomplete</Label> */}
        {/* <Input {...getInputProps()} /> */}

        <Box
          sx={{
            // display: { xs:props.xs === true?'block': "none", sm: "block" },
            backgroundColor: alpha("rgb(132,170,99)", 0.25),
            "&:hover": {
              backgroundColor: alpha("rgb(132,190,99)", 0.25),
            },

            padding: { xs: "0px", md: "20px" },
            // width:{xs :"inherit", md: "500px"},
            height: "50px",
            position: "relative",
            paddingBottom: "20px",
            borderRadius: "13px",
            color: "inherit",
            overflow: "hidden",
          }}
        >
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          {/* <StyledInputBase
          placeholder="Search…"
          {...getInputProps()}
          inputProps={getInputProps()}
        /> */}
          <form
            method="get"
            ref={formRef}
            style={{ color: "inherit" }}

            action={"/query-result"}
          >
            <InputBase
              name="query"
              onKeyUp={(e) => {
                setQuery(e.target.value);
                getAutoComplete();
              }}
              placeholder="Search..."
              style={{
                width: "80%",

                "& .MuiInputBase-input": {
                  padding: spacing(1, 1, 1, 0),
                  paddingLeft: `calc(1em + ${spacing(4)})`,
                },
                overflow: "hidden",
                marginRight: spacing(2),
                position: "absolute",
                top: 0,
                bottom: 0,
                color: "ActiveBorder",

                //   padding: spacing(1, 1, 1, 0),
                paddingLeft: "40px",
              }}
              {...getInputProps()}
            />{" "}
          </form>
        </Box>
      </div>

      {groupedOptions.length > 0 ? (
        <List
          sx={{
            color: "black",
            width: { xs: "80%", md: "100%" },
            position: "absolute",
            borderRadius: "10px",
            margin: { md: "0 auto" },
            left: 0,
            height: "50vh",
            overflow: "hidden",
            scrollBehavior: "smooth", scrollbarWidth: "thin",
            // overflowY:"scroll",
            right: 0,
            backgroundColor: "#42524b",
            zIndex: "100",
          }}
          {...getListboxProps()}
        >
          {groupedOptions.map((option, index) => (
            <>
              <div
                onClick={() => {
                  setQuery(option.title);
                  navigate(option.url)



                }}
              >
                <ListItem
                  style={{
                    cursor: "pointer",
                    textOverflow: "ellipsis",
                    width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    minWidth: "300px",
                    padding: "10px",
                  }}
                  title={option.title}
                  {...getOptionProps({ option, index })}
                >
                  {" "}
                  <p style={{}}>{option.title} </p>
                  {option.is_seller === true ? (
                    <Tag
                      style={{
                        position: "absolute",
                        right: "0",
                        borderRadius: "30px",
                        marginRight: "20px",
                        marginBottom: "-20px",
                        padding: "3px",
                        fontSize: "8px",
                        color: "white",
                        // backgroundColor: "lightgreen",
                        zIndex: 100,
                      }}
                    >
                      Seller
                    </Tag>
                  ) : (
                    ""
                  )}
                  {option.primary_img ? (
                    <div
                      style={{ position: "absolute", zIndex: 50, right: "0" }}
                    >
                      <Avatar round src={option.primary_img} sizes="100" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: "10px",
                      left: 0,
                      // word
                      top: -5,
                      color: "rgba(260,184,50,0.94)",
                      textOverflow: "ellipsis",
                      // wordWrap:''
                    }}
                  >
                    {/* <p> */}
                    {option.is_seller === true && option.type === "profile" ? (
                      <small>{option.category}</small>
                    ) : (
                      <small>
                        {option.type === "product" || option.type === "smm" ? option.category : ""}
                      </small>
                    )}
                    {/* </p> */}
                  </div>
                </ListItem>
              </div>
              <Divider />
            </>
          ))}
        </List>
      ) : null}
    </div>
  );
}



