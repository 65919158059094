import { InfoOutlined, Cancel, Clear, Remove, Done, Pin } from '@mui/icons-material'
import { Box, Container, Divider, IconButton, alpha, } from '@mui/material'
import { Button, Avatar, Space, message, Spin, Modal, Form, Input, Tooltip, AutoComplete, notification } from 'antd'

import React, { useContext, useRef, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import PinInput from 'react-pin-input';
import AppContext from '../../app_context';
import APP_ENV from '../../../env';

export default function Transfer(props) {
    const [tf_Form] = Form.useForm()
    const { TextArea } = Input
    const [autoComOptions, setautoCoOptions] = useState([])
    const [selectedUSer, setSelectedUSer] = useState(null)
    const { Option } = AutoComplete
    const [loading, setloading] = useState(true)
    // console.log(props.users)
    const [formValues, setFormValues] = useState({})
    const [setFinalConfirmModal, setsetFinalConfirmModal] = useState(false)
    const formRef = useRef(null)
    const navigate = useNavigate()
    const [selfDisplay, setSelfDisplay] = useState('block')
    const { client, BASE_URL, authClient } = React.useContext(AppContext)

    React.useEffect(() => {
        setSelfDisplay('block')
        setloading(false)
    }, [])
    const [sBG, setSBG] = useState('warning')
    const setAutoComplete = (val) => {
        const items_match = props.users.filter(item => item.value.includes(val))
        setautoCoOptions(items_match)
        setSelectedUSer(val)
        if (items_match.length <= 0) {
            setSBG('error')
        }
    }
    const handleSelect = (val) => {

        setSelectedUSer(val)
        setSBG('success')
        message.info(val + '  selected')

    }
    const handleTransfer = (val, proceed = false) => {

        Modal.destroyAll()
        setFormValues(val)
        setloading(false)
        var mainfrm = document.getElementById('mainTfForm')
        mainfrm.style.marginTop = '-450px'
        if (proceed === true) {


            const nonce = Math.floor(Math.random() * 9999) + 1
            const timestamp = Date.now().toString().replace('.', '')
            const user_id = props.user ? props.user.id : null
            const currency = props.currency
            const user_username = props.user ? props.user.username : null
            const comb = String(String(nonce) + String(timestamp) + String(user_username) + String(user_id)).toLowerCase()
            const sign = md5(comb).toString()
            const callbackJSON = {}
            callbackJSON['nonce'] = nonce
            callbackJSON['api_key'] = APP_ENV.PLATFORM_WALLET_API_KEY
            callbackJSON['timestamp'] = timestamp
            callbackJSON['currency'] = currency
            callbackJSON['sign'] = sign
            callbackJSON['user_id'] = user_id
            callbackJSON['user_username'] = user_username
            callbackJSON['payment_type'] = 'T'
            callbackJSON['transferDetails'] = formValues
            callbackJSON['trx_type'] = 'transfer'
            const paymentConfirmPinContent = (<>
                <Box style={{ textAlign: 'center', fontFamily: 'sans' }}>
                    <h4>Enter Pin </h4>

                    <PinInput autoSelect={true} onComplete={(val) => {


                        callbackJSON['pin'] = val
                        setloading(true)





                        client.post('/user/api/create-payment', callbackJSON).then(resp => {

                            const data = resp.data


                            if (resp.data.status === 'error') {
                                if (data.msg) {

                                    notification.error({message:data.msg})
                                    setloading(false)
                                }
                            }



                            if (resp.data.status == 'successful') {
                                setloading(false)

                                notification.success({message:'Pin Correct!'})
                                Modal.destroyAll()
                                Modal.success({

                                    style: { foltFamily: 'serif' },
                                    onCancel: () => setloading(false),
                                    onOk: () => { setloading(true); navigate(`/user/wallet?trx=true&flw_ref=` + resp.data.ref); setloading(false); props.updateParent() },
                                    title: <p style={{ foltFamily: 'serif' }}>Transfer Done</p>,
                                    centered: true,

                                    content: (
                                        <>
                                            <Box style={{ padding: '20px', fontFamily: 'serif' }}>
                                                <Done style={{ margin: '5px', color: 'springgreen', fontSize: '40px' }} />
                                                <h2 state={
                                                    { color: 'silver', margin: '5px', textTransform: 'uppercase', fontFamily: 'verdana' }
                                                }
                                                >Transfer successful</h2>

                                                {formValues.amount}  {props.currency} sent to {formValues.receipient_username}

                                            </Box>

                                        </>
                                    )


                                })
                            }

                        }).catch(err => { message.error(err); setloading(false) })
                        setloading(false)
                    }} secret style={{ marginTop: '10px', borderRadius: '5px', borderColor: 'green' }} type='numeric' inputMode='numeric' focus length={4} /></Box></>
            )


            Modal.confirm({
                style: { foltFamily: 'serif' },
                title: 'Authorize Transfer',
                icon: <Pin />,
                okButtonProps: { style: { display: 'none' } },
                onCancel: () => { Modal.destroyAll(); setloading(false) },
                content: paymentConfirmPinContent

            })
        }

    }
    const handleConfirmUser = (val) => {
        setloading(true)
        if (props.users) {
            const user = props.users.filter(item => item.value === val.receipient_username)
            if (user.length >= 1) {
                if (val.amount <= 999) {
                    setloading(false)
                    return message.error('Please increase amount, (atleast #1000)', 3)
                }
                // message.success('user found')
                return Modal.confirm({
                    title: 'Confirm Receipient',
                    style: { foltFamily: 'serif', padding: '20px', },

                    okText: 'Confirm',
                    okButtonProps: { color: 'green', color: 'white' },
                    onOk: () => handleTransfer(val),
                    onCancel: () => { Modal.destroyAll(); setloading(false) },
                    centered: true,
                    content: (<>
                        <Box style={{ textAlign: 'left', margin: '0 auto', fontFamily: 'serif' }}>
                            <h4>Details</h4>
                            <Avatar src={user[0].dp} size={70}>{String(user[0].username)[0]}</Avatar>
                            <br />
                            <br />
                            <Divider style={{ color: 'yellowgreen', color: 'yellowgreen' }} />
                            <p>Full name :</p>
                            {user[0].full_name !== '' ? <>    <b>{user[0].full_name}</b> </> : ''}
                            <br />
                            <p>Username :</p>  <b>{user[0].value} </b>

                            <br />
                            <br />
                            <small style={{ float: 'right' }}>Click Confirm to proceed</small>

                        </Box>
                    </>)
                })
            }
            else {
                if (val.receipient_username === props.user.username) {
                    setloading(false)
                    return message.error("You can't transfer to yourself")
                }
                message.error('User Does not exist')
                setloading(false)
            }
        }

    }
    return (

        <>
            <Spin tip='fetching data..' style={{ zIndex: '20' }} spinning={loading}>
                <Container style={{ boxShadow: '0.2px 1px 8px 0.3px gray', fontFamily: 'serif', display: selfDisplay, position: 'fixed', top: '0', left: '0', right: '0', justifySelf: 'center', backgroundColor:alpha('#476156', 0.8), color: 'whitesmoke', height: '100%', zIndex: '10', width: '100%', textAlign: 'center' }}>
                    <IconButton onClick={() => { setloading(true); navigate("/user/wallet"); setloading(false); props.updateParent(); }} style={{ position: 'absolute', top: 0, left: 0 }}> <Cancel />
                    </IconButton>
                    <Box id='mainTfForm' sx={{ width: { md: '50%', xs: '70%' } }} style={{ transition: 'linear 1s', justifyItems: 'center', content: 'contents', margin: '0 auto', marginTop: '200px', justifyContent: 'center' }}>
                        <h1 style={{ 'color': 'graytext', border: '1px solid lavender', color: 'wheat', fontSize: '20px' }}>Inter-Wallet Transfer</h1>

                        <Form ref={formRef} form={tf_Form} onFinish={(val) => handleConfirmUser(val)}>

                            <Form.Item name='receipient_username' rules={[{ required: true, }]}>
                                <AutoComplete style={{ borderColor: selectedUSer ? '1px solid green' : '1px solid red' }} status={sBG} onSelect={(val) => handleSelect(val)} onChange={(val) => { setAutoComplete(val) }}
                                    clearIcon={<Clear />} removeIcon={<Remove />}
                                    suffix={<Tooltip title='Username of the person you want to send your funds. Pick correctly and confirm' >
                                        <InfoOutlined style={{ color: 'rgba (0,0,0,.45' }} />
                                    </Tooltip>}
                                    placeholder='Enter receipient username'
                                >

                                    {autoComOptions.length >= 1 ? autoComOptions.map((item) => (
                                        <Option key={item.value} value={item.value}>
                                            <Avatar src={ item.dp} > {String(item.value)[0]}</Avatar>  <Space /> <small><i>@</i></small>{item.value}
                                        </Option>
                                    )

                                    ) : 'no such user'}

                                </AutoComplete>
                            </Form.Item>
                            <br></br>
                            <br></br>
                            <Form.Item name='amount' rules={[{ required: true, }]}>
                                <Input name='amount' placeholder='Enter amount ' prefix={<i class="fa fa-money" aria-hidden="true"></i>} type='number' suffix={props.currency} >
                                </Input>
                            </Form.Item>


                            <Form.Item name='description'>
                                <TextArea name='description' placeholder='Transaction description' showCount maxLength={100} style={{ height: '120px' }} />




                            </Form.Item>
                            <Button onClick={() => formRef.current.submit()} type='submit' style={{ color: 'lavender', width: '100%', textTransform: 'uppercase', color: 'goldenrod' }} >Proceed</Button>
                        </Form>
                    </Box>
                    <Box sx={{ width: { md: '50%' } }} style={{ margin: '0 auto', paddingTop: '300px', fontFamily: 'serif' }}>
                        <h1>Almost Done! </h1>

                        <p style={{ fontSize: '24px', color: 'sienna' }}>  Send <b style={{ color: 'saddlebrown' }}>{formValues.amount}{props.currency}</b>  to {selectedUSer}?</p>
                        <Button onClick={() => handleTransfer(formValues, true)} style={{ width: '100%', height: '40px', color: 'goldenrod', border: '1px solid green', borderRadius: '0.2px', color: 'whitesmoke' }}
                        >Send</Button>
                    </Box>
                </Container>
            </Spin >
        </>
    )
}
