import { combineReducers } from 'redux';
import adGigReducer from './gig/reducer';
import influencerReducer from './influencer/reducer';
import orderReducer from './order/reducer';
import packageReducer from './package/reducer';

const inHubReducer = combineReducers({
    adGig: adGigReducer,
    influencer: influencerReducer,
    order: orderReducer,
    package: packageReducer


    // Add reducers for other slices as needed...
});

export default inHubReducer;
