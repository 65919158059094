import {
  AttachFile,
  Clear,
  FilePresent,
  Send,
  SendOutlined,
  Telegram,
} from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Container,
  IconButton,
  Modal,
} from "@mui/material";
import {
  Avatar,
  Divider,
  Button as AntButton,
  Spin,
  message,
  notification,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useContext, useRef, useReducer } from "react";
import AppContext, {
  BASE_SOCKET_URL,
  BASE_URL,
  client,
  randomString,
} from "../../app_context";
import ChatMessage from "./ChatMessage";
import ChatNotificaton from "./ChatNotificaton";
import InputEmoji from "react-input-emoji";

import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import FileWidget from "./fileWidget";
import ReactPlayer from "react-player";

import { Link } from "react-router-dom";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { ReadyState } from "react-use-websocket";
import md5 from "md5";
import { number } from "yup";
import { CircularProgress, LinearProgress } from "@mui/material";
import { memo } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { set } from "nprogress";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "rgb(37, 44, 48)",
  // border: "2px solid #000",
  color: "whitesmoke",
  boxShadow: 24,
  p: 4,
};

const GetMessageBox = memo(
  ({
    message,
    index,
    usr,
    selectMsgState,
    handleSelectMsgFunc,

    messagesList,
  }) => {
    const user = useMemo(() => usr, []);

    const getArrayedMessage = (messageBody) => {
      return String(messageBody).replace(user.username, "You");
    };
    const flN = getArrayedMessage(message.body);
    const messages = useMemo(() => messagesList, [messagesList]);

    switch (message.type) {
      case "Chat_Message":
        return (
          <>
            <span
              style={{
                display:
                  messages[index - 1] &&
                  messages[index - 1].date == message.date
                    ? "none"
                    : "block",
              }}
            >
              <Divider>
                <ChatNotificaton type={"date"} value={message.date} />
              </Divider>
            </span>
            <ChatMessage
              className={`msg-b msg-box-date-${
                message.receipts
                  ? message.receipts.filter(
                      (itm) => itm.chat_member.username !== user.username
                    )[0]?.seen
                    ? "seen"
                    : message.receipts.filter(
                        (itm) => itm.chat_member.username !== user.username
                      )[0]?.read
                    ? "read"
                    : "unread"
                  : "sending-chat"
              }`}
              msg={message}
              usr={user}
              selectOn={selectMsgState}
              child={
                messages[index - 1]
                  ? messages[index - 1].sender.username ==
                    message.sender.username
                  : false
              }
              key={index}
              handleSelect={() => handleSelectMsgFunc(message)}
            ></ChatMessage>
          </>
        );
      case "Chat_Notification":
        return (
          <Box>
            <ChatNotificaton type={"notification"} value={flN} />;
          </Box>
        );
      case "Chat_Request":
        return (
          <>
            <ChatNotificaton type={"message"} value={flN} />
          </>
        );
    }
  }
);

function PrivateChatMessage({
  // setCurrentChat,
  user,
  root_chat,
  selectMsg,
  selectedMessages,
  setselectedMessages,...others
}) {
  const [messages, setMessages] = useState([]);
  const [chat, setChat] = useState(root_chat);

  const [text, setText] = useState("");
  const [attachFile, setAttachFile] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [newMessageReceipt, setnewMessageReceipt] = useState(true);
  const textInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [chatSenderBlocked, setchatSenderBlocked] = useState(
    chat.members.filter((mm) => mm.username !== user.username)[0]?.blocked ===
      true
  );
  const [accepted, setaccepted] = useState(
    chat.members?.filter((mm) => mm.username === user.username)[0]?.accepted
  );
  const [userToken, setUserToken] = useState(localStorage.getItem("AccgToken"));

  const [socketUrl, setSocketUrl] = useState(
    BASE_SOCKET_URL + `/ws/api/${chat.uid}/messages/`
  );
  const { lastJsonMessage, lastMessage, sendJsonMessage, readyState } =
    useWebSocket(socketUrl, {
      queryParams: { token: userToken },
      shouldReconnect: (closeEvent) => {
        return true;
      },
      reconnectAttempts: 10,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    });
  const attachmentRef = useRef(null);

  const getArrayedMessage = (messageBody) => {
    return String(messageBody).replace(user.username, "You");
  };

  React.useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage instanceof Array) {
        getMessages();
      } else {
        switch (lastJsonMessage.type) {
          case "Read_Receipts":
          if (JSON.stringify(lastJsonMessage["messages"]) === JSON.stringify(messages)) {
          } else {
            setMessages(lastJsonMessage["messages"]);
          }
            // console.log(lastJsonMessage["messages"]);
            break;

          case "Chat_Notification":
            notification.info({
              message: getArrayedMessage(lastJsonMessage.msg),
            });
            break;

          default:
            const latest_msg = messages.filter(
              (msg) => msg.signature === lastJsonMessage.signature
            );
            if (latest_msg.length >= 1) {
              messages.pop(latest_msg[0]);
            }
            setMessages((prevList) => prevList.concat(lastJsonMessage));
            setLoading(false);
            setTimeout(() => {
              const all_m = document.getElementById("messages-body-box-cont");
              all_m.scroll(0, all_m.scrollHeight);
              
            }, 300);

        }
      }
      setLoading(false);
    }
  }, [lastJsonMessage]);

  async function updateReadReceipts() {
  console.log('updating receipts...')
    const seen_el = document.getElementsByClassName("msg-box-date-seen");
    const read_el = document.getElementsByClassName("msg-box-date-read");
    const el_m = document.getElementsByClassName("msg-b");
    const msg_screen = document.querySelector(".center-msg");
    const chat_header = document.querySelector(".chat-header");
    const chat_bottom = document.querySelector(".bottom-input");
    const chat_bottomY = chat_bottom
      ? chat_bottom?.getBoundingClientRect()?.y
      : 0;
    const all_msg_ids = [];
    for (let i = 0; i < el_m.length; i++) {
      const element = el_m[i];
      const msg_id = element.getElementsByClassName("msg-id")[0].id;
      const msg_txt = element.getElementsByClassName("msg-id")[0].textContent;
      // alert(element.getBoundingClientRect().y); sometmes 442
      // chatbottomY <= 662
      // alert(chat_header.getBoundingClientRect().y); == 92
      if (
        (element.getBoundingClientRect().y <= chat_bottomY &&
          !(
            element.getBoundingClientRect().y <
            chat_header.getBoundingClientRect().y
          )) ||
        element.getBoundingClientRect().y <= -200
      ) {
        try {
          const int_id = parseInt(msg_id);

          if (int_id && msg_txt !== "Read") {
            all_msg_ids.push(int_id);
          }
        } catch (err) {
          message.error(JSON.stringify(err));
        }
      }
    }
    // alert(all_msg_ids)

    if (all_msg_ids.length >= 1) {
      return sendJsonMessage({
        type: "Read_Receipt",
        multiple: true,
        message_ids: all_msg_ids,
        read: true,
        seen: true,
        chat_uid: chat.uid,
      });
    }
    // alert(all_msg_ids.length);
  }

  const getMessages = () => {
    if (lastJsonMessage) {

      if (newMessageReceipt) {
        setMessages(lastJsonMessage);
        updateReadReceipts();
        setnewMessageReceipt(false);
        setLoading(false);
      } else {
        updateReadReceipts();

        setMessages(lastJsonMessage);
      }

      const all_m = document.getElementById("messages-body-box-cont");

      setTimeout(() => all_m.scroll(0, all_m.scrollHeight), 50);
      all_m.addEventListener("scroll", (e) => {
        if (!newMessageReceipt) {
          updateReadReceipts();
        }
      });
    }
  };
  const handleSelectMsg = (msg) => {
    if (selectMsg) {
      if (selectedMessages.filter((id) => id === msg.id).length >= 1) {
        setselectedMessages(selectedMessages.filter((id) => id !== msg.id));
      } else {
        setselectedMessages((prevList) => [...prevList, msg.id]);
      }
    }
  };

  const handleAttachment = () => {
    setAttachFile(true);
    attachmentRef.current.click();
  };

  function getAsByteArray(file) {
    var reader = new FileReader();
    var fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt) {
      if (evt.target.readyState == FileReader.DONE) {
        var arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        for (var i = 0; i < array.length; i++) {
          fileByteArray.push(array[i]);
        }
      }
    };

    return fileByteArray;
  }

  const handleBlockMember = () => {
    client
      .post(`/chat/api/${chat.uid}/actions`, {
        action: "Block_Member",
        private: true,
      })
      .then((resp) => {
        notification.success({ message: resp.data.msg });
        setchatSenderBlocked(true);
      });
  };

  const handleUnBlockMember = () => {
    client
      .post(`/chat/api/${chat.uid}/actions`, {
        action: "Unblock_Member",
        private: true,
      })
      .then((resp) => {
        notification.success({ message: resp.data.msg });
        setchatSenderBlocked(false);
      });
  };

  const sendMessage = ({
    type = "Chat_Message",
    files = fileList,
    messageBody = text,
  }) => {
    updateReadReceipts();

    setAttachFile(false);
    if (messageBody.trim().length <= 0 && fileList.length <= 0) {
      return message.info("message empty");
    }

    // const newFileList = [];
    const filesByteArrays = [];
    var acceptedFiles = [];
    const trFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      var obj = {};
      obj["type"] = file.type;
      obj["name"] = file.name;
      obj["url"] = URL.createObjectURL(file);
      trFiles.push(obj);
      if (file.size > 10000000) {
        notification.error({
          message: `${file.name} size is greater than the upload limit (100mb).`,
        });
        message.error(
          `${file.name} size is greater than the upload limit (100mb).`
        );

        trFiles.pop(file);
        setAttachFile(true);

        return;
        // if (fileList.length <=0){
        // alert('otilo')
        // }
        //  message.error("sending failed");
      }
      acceptedFiles.push(file);
    }

    const jsonChat = {
      body: messageBody,
      nonce: randomString(10),
      sender: {
        user: user,
        username: user.username,
      },
      date: new Date().getUTCDate(),
      attachments: trFiles,
      fileList: filesByteArrays,
      time: `${new Date().getHours()}:${new Date().getMinutes()}`,
      type: type,
      chat: chat.id,
      chat_uid: chat.uid,
    };

    const signature = md5(JSON.stringify(jsonChat));

    jsonChat["signature"] = signature;

    if (messageBody.trim().length >= 1 || acceptedFiles.length >= 1) {
      setMessages((prevList) => prevList.concat(jsonChat));
    }
    if (fileList.length >= 1 && acceptedFiles.length >= 1) {
      client
        .post(`/chat/api/${chat.uid}/messages`, jsonChat)
        .then((resp) => {})
        .catch((err) => notification.error({ message: "file upload failed" }));

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (evt) {
          if (evt.target.readyState == FileReader.DONE) {
            var fileByteArray = [];

            var arrayBuffer = evt.target.result;
            const array = new Uint8Array(arrayBuffer);
            for (var i = 0; i < array.length; i++) {
              fileByteArray.push(array[i]);
            }
            obj["array_bytes"] = fileByteArray;
            filesByteArrays.push(obj);
            notification.info({
              message: `Sending ${file.name} ...`,
              icon: <Spin spinning={true} />,
            });
            client
              .post(`/chat/api/${chat.uid}/messages`, {
                sender: {
                  user: user,
                  username: user.username,
                },
                file: obj,
                chat: chat.id,
                chat_uid: chat.uid,
                signature: signature,
              })

              .then((resp) => {
                notification.success({ message: `${file.name} Sent.` });
                setFileList([]);
              })
              .catch((err) =>
                notification.error({ message: "file upload failed" })
              );
          }
        };
      }
    } else {
      sendJsonMessage(jsonChat);
    }
    if (type === "Chat_Request" && messageBody === "Chat Accepted") {
      setaccepted(true);
    }
    setText("");
    setTimeout(() => {
      const all_m = document.getElementById("messages-body-box-cont");
      // all_m.scrollIntoView();
    }, 100);

    setnewMessageReceipt(true);
    setTimeout(() => {
      const all_m = document.getElementById("messages-body-box-cont");
      all_m.scroll(0, all_m.scrollHeight);
    }, 300);
  };

  const BottomLayout = () => {
    return (
      <>
        {accepted === true ? (
          <Container className="bottom-input" style={{ marginTop: "10px" }}>
            <IconButton
              style={{ display: "flex", float: "left" }}
              onClick={() => handleAttachment()}
            >
              <AttachFile />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                float: "left",
                maxHeight: "50px",
                width: { xs: "350px", md: "390px" },
              }}
            >
              <InputEmoji
                id="msg-box-input"
                value={text}
                // onChange={(val) => setText(val)}
                ref={textInputRef}
                onEnter={(val) => {
                  setText(val);
                  sendMessage({ messageBody: val });
                }}
                placeholder="Type a message"
                cleanOnEnter
              />
            </Box>
            <Box sx={{ display: "flex", margin: "0" }}>
              <IconButton
                title="send"
                onClick={() => {
                  const txt = document.getElementsByClassName(
                    "react-input-emoji--input"
                  )[0].innerText;
                  setText(txt);
                  sendMessage({ messageBody: txt });
                }}
                style={{ color: "rgb(37, 44, 48)", margin: "0" }}
              >
                <Send />
              </IconButton>
            </Box>
          </Container>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <AntButton
              onClick={() =>
                sendMessage({
                  type: "Chat_Request",
                  messageBody: "Chat Accepted",
                })
              }
              style={{ display: "inline-flex", margin: "10px" }}
              type="primary"
            >
              Accept
            </AntButton>
            {chatSenderBlocked ? (
              <AntButton
                onClick={() => {
                  handleUnBlockMember();
                }}
                type="ghost"
                style={{ display: "inline-flex", margin: "10px" }}
              >
                UnBlock
              </AntButton>
            ) : (
              <AntButton
                onClick={() => {
                  handleBlockMember();
                }}
                type="ghost"
                style={{ display: "inline-flex", margin: "10px" }}
              >
                Block
              </AntButton>
            )}
            <AntButton
              type="danger"
              onClick={() =>
                sendMessage({
                  type: "Chat_Request",
                  messageBody: "Chat Rejected",
                })
              }
              style={{ display: "inline-flex", margin: "10px" }}
            >
              Reject
            </AntButton>
          </Box>
        )}
      </>
    );
  };
  function AttatchFileModal({ open = false, setOpen, receiver }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [fileText, setFileText] = useState(text);
    const [urlFileList, setUrlFileList] = useState([]);

    React.useEffect(() => {
      const trFiles = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        var obj = {};
        obj["type"] = file.type;
        obj["name"] = file.name;
        obj["url"] = URL.createObjectURL(file);
        trFiles.push(obj);
      }
      setUrlFileList(trFiles);
    }, []);
    return (
      <div>
        <Modal
          style={{ overflowY: "scroll" }}
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                id="spring-modal-title"
                variant="h6"
                style={{ color: "whitesmoke" }}
                component="h2"
              >
                Send Files to {receiver}
              </Typography>
              <Typography
                id="spring-modal-description"
                sx={{
                  mt: 2,
                  overflow: "auto",
                  height: fileList.length >= 3 ? "50vh" : "100%",
                  textAlign: "center",
                }}
              >
                {urlFileList.length >= 1
                  ? urlFileList.map((item, index) => (
                      <Box
                        style={{
                          width: fileList.length >= 3 ? "150px" : "100%",
                          height: fileList.length >= 3 ? "150px" : "100%",
                          overflow: "hidden",
                          padding: "10px",
                          display: "inline-block",
                        }}
                      >
                        <Box>
                          <IconButton
                            title="remove"
                            style={{
                              color: "grey",
                              position: "static",
                              zIndex: "999",
                            }}
                            onClick={() => {
                              const prevList = [];
                              for (let i = 0; i < fileList.length; i++) {
                                const fl = fileList[i];
                                prevList.push(fl);
                              }

                              const newList = prevList.filter(
                                (fItem) => fItem.name !== item.name
                              );

                              setFileList(newList);
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </Box>
                        <a target="_blank" href={item.url}>
                          {" "}
                          <div>
                            {item.type.includes("audio") ? (
                              <audio
                                src={item.url}
                                style={{ width: "100%" }}
                                controls
                                title={item.name}
                              ></audio>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {item.type.includes("video") ? (
                              <ReactPlayer
                                width={"100%"}
                                height={"100%"}
                                controls
                                url={item.url}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {item.type.includes("image") ? (
                              <img
                                src={item.url}
                                style={{
                                  width: "100%",
                                  height:
                                    fileList.length >= 3 ? "200px" : "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {item.type.includes("application") ? (
                              <div>
                                <FilePresent />
                                {item.name}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </a>
                      </Box>
                    ))
                  : ""}
              </Typography>
              <Container
                className="bottom-input"
                style={{
                  marginTop: "10px",
                  display: "grid",
                  justifyContent: "center",
                }}
              >
                <div>
                  <IconButton
                    title="change"
                    style={{ display: "flex", float: "left" }}
                    onClick={() => handleAttachment()}
                  >
                    <AttachFile style={{ color: "whitesmoke" }} />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      float: "left",
                      maxHeight: "50px",
                      width: { xs: "350px", md: "390px" },
                    }}
                  >
                    <InputEmoji
                      value={fileText}
                      onChange={(val) => setFileText(val)}
                      onEnter={() =>
                        sendMessage({ messageBody: fileText, files: fileList })
                      }
                      placeholder="Type a message"
                      cleanOnEnter
                    />
                  </Box>
                  <Box sx={{ display: "flex", margin: "0" }}>
                    <IconButton
                      title="send"
                      onClick={() =>
                        sendMessage({ messageBody: fileText, files: fileList })
                      }
                      style={{ color: "whitesmoke", margin: "0" }}
                    >
                      <Send />
                    </IconButton>
                  </Box>
                </div>
              </Container>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }

  if (user.username !== null && user.username !== undefined) {
    return (
      <Box className="messages-container">
        <Spin spinning={loading} indicator={<CircularProgress />}>
          <Container className="messages-inner-cont">
            <Box
              sx={{
                height: { md: "450px", xs:'550px' },
                marginBottom:{md:'0px', xs:'30px'},                
                overflow: "hidden",
                overflowY: "auto",
                width: "100%",
                scrollbarWidth: "thin",
              }}
              className="messages-body-box"
              id="messages-body-box-cont"
            >
              <ChatNotificaton type={"first"} />

              {messages.map((messageObj, index) => (
                <>
                  <GetMessageBox
                    message={messageObj}
                    handleSelectMsgFunc={handleSelectMsg}
                    messagesList={messages}
                    index={index}
                    usr={user}
                    selectMsgState={selectMsg}
                    key={`${messageObj.id}-${messageObj.signature}`}
                  />
                </>
              ))}
            </Box>

            <input
              ref={attachmentRef}
              accept="image/*, audio/*, video/*, application/pdf, application/docs,application/xls"
              onChange={(e) => setFileList(e.target.files)}
              id="raised-button-file"
              hidden
              multiple
              type="file"
            />
            {chat.members.filter((mm) => mm.username === user.username)[0]
              ?.blocked === false ? (
              <BottomLayout />
            ) : (
              ""
            )}
          </Container>

          {/* upload attachment */}
          <AttatchFileModal
            fileList={fileList}
            receiver={
              chat.type === "Private" ? (
                <>
                  {" "}
                  {
                    chat.members.filter(
                      (item) => item.username !== user.username
                    )[0]?.username
                  }{" "}
                  {/* <Badge style={{ top: "-3px" }} status="success"></Badge> */}
                </>
              ) : (
                chat.name
              )
            }
            setOpen={setAttachFile}
            open={attachFile}
            chat={chat}
          />
        </Spin>
      </Box>
    );
  } else {
    return "Login Plz";
  }
}

export default PrivateChatMessage;
