import { ArrowBackIos, Delete, ShoppingBag } from '@mui/icons-material';
import { Box, IconButton, Button, Container, ButtonGroup } from '@mui/material';
import { Card, Divider, Image, message, Rate, Tag, Avatar as Antavatar, Form, Select, Spin } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { Component, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import APP_ENV from '../../env';
const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json",

    "Authorization": `Token ${localStorage.getItem('AccgToken')}`


};

if (localStorage.getItem('AccgToken') !== null) {
    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


})
const VisitHistory = (props) => {
  // const navigate = useNavigate()
    const navigate = useNavigate()
    React.useEffect(() => {
        if (props.clicked == true) {
            if (props.path === '//') {
                return navigate(-1)
            }

            navigate(props.path)
        }

    }, [props.path])
    return (
        <></>
    )
}

function SelectProduct  ({ main_prod, refresh }) {
  const [selectProductForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const {Option} = Select;
  const [subProducts, setsubProducts] = useState(main_prod.sub_products);
  const [selectedCartProducts, setSelectedCartProducts] = useState([]);

  React.useEffect(() => {
    selectProductForm.setFields([
      { name: "sub_products", value: main_prod.cartedSubProducts },
    ]);
    setLoading(false);
  }, [main_prod.cartedSubProducts]);
  const handleSelectProducts = (val) => {
      setLoading(true);

    client
      .get("/auth/users/me/")
      .then((response) => {
        if (response.data) {
          if (response.data.username) {
            client
              .post(`/user/api/select-cart-product`, {
                sub_product_ids: val,
                product_id: main_prod.account_id,
              })
              .then((resp) => {
                setSelectedCartProducts(resp.data.sub_product_ids);
                message.success("Products updated");
                refresh();
                    setLoading(false);

              });
          }
        }
      })
      .catch((err) => {
        message.error("please login!");    setLoading(false);

      });
  };
  return (
    <Box >
      <Form form={selectProductForm}>
        <Form.Item
          name={"sub_products"}
          initialValue={main_prod.cartedSubProducts}
        >
          <Select
            multiple
            // defaultValue={1}
            mode="multiple"
            placeholder="Select products for cart"
            style={{
              width: "100%",
            }}
            allowClear={false}
            loading={loading}
            onChange={(val) => {
              setSelectedCartProducts(val);
              handleSelectProducts(val);
            }}
          >
            {subProducts.map((item) => (
              <Option style={{ color: "green" }} value={item.product_id}>
                @<b>{item.username}</b>
                <small style={{ float: "right" }}>ID : {item.product_id}</small>
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Box>
  );
  
};

export default class Cart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cartListData: [],
            itemClicked: false,
            itemPath: '',
            productsRef: props.productFuncsRef,
            sumOfCartsPrice: 0,
            sumOfCartsActualPrice: 0,
            networkFee: 0,
            Total: 0,
            subTotal: 0,
            savedAmount: 0,
            currentCurrency: '',
            updateHeader:props.headerUpdater,
            loading:true,
        }

    }
    updateCartList() {
    this.setState({loading:true})
        setTimeout(() => {
            this.updateElements()
        }, 100)
    }

    componentDidMount() {

        client.get('/user/api/cart').then(resp => {
            this.setState({ cartListData: resp.data })
            this.updateElements()
        }).catch(err => {message.error(err);    this.setState({loading:false})}
)
        // this.updateWishList()

    }
    updateElements() {
        this.setState({ loading: true });

    this.state.updateHeader.current.updateAll();
        client.get('/user/api/cart').then(resp => {
            this.setState({ cartListData: resp.data })
            this.setState({
                Total: 0,
                networkFee: 0, savedAmount: 0, subTotal: 0, sumOfCartsPrice
                    : 0, sumOfCartsActualPrice: 0
            })
            if (resp.data) {
                resp.data.forEach(item => {

                    if (!(item.account_available_Qty <= 0 || item.account_sold === true)) {
                        this.setState({
                            currentCurrency: item.products_currency
                        })
                        this.setState({ sumOfCartsActualPrice: this.state.sumOfCartsActualPrice + parseInt(Math.round(item.product_actual_sum_amount)) });
                        this.setState({ sumOfCartsPrice: this.state.sumOfCartsPrice + parseInt(Math.round(item.product_sum_amount)) });
                        this.setState({ subTotal: this.state.sumOfCartsPrice });
                        this.setState({ savedAmount: this.state.sumOfCartsActualPrice - this.state.sumOfCartsPrice });
                        this.setState({ networkFee: Math.round(2 / 100 * this.state.sumOfCartsPrice) });
                        this.setState({ Total: this.state.networkFee + this.state.subTotal });
                    }
                })

            }
                this.setState({ loading: false});

        }).catch(err => {message.error(err);     this.setState({ loading: false });
})


    }
    wishHeaderStyle = {
        fontSize: '10px',
        borderTop: '2px solid grey',
        display: 'inline-block',
        marginRight: '5px',
        color: 'whitesmoke',
        backgroundColor: 'darkolivegreen'

    }
    btmBtnsStyle = {
        color: 'darkgreen',
        width: '100%',
        fontSize: '10px',
        fontWeight: 'bold'
    }
    orderBtnStyle = {
        width: '90%',
        padding: '15px',
        fontFamily: 'ariel',

        backgroundColor: 'darkolivegreen',
        display: 'inline-block',
        margin: '0 auto',
        color: 'white',
        fontWeight: '10px',
        boxShadow: '2px 1px 1px 1px ButtonShadow',

    }
    callBtnStyle = {
        width: '90%',
        fontFamily: 'fantasy', padding: '10px',
        top: '10px',
        backgroundColor: 'inherit',
        color: 'black',
        fontWeight: '10px',
        display: 'inline-block',
        margin: '0 auto',
        borderRadius: '0',
        boxShadow: '2px 1px 2px 1px black',

    }
    render() {
        return (
        <Spin tip='getting carts...' spinning={this.state.loading}>
          <div>
            <Header
              children={
                <>
                  <div style={{ fontSize: "30px", margin: "0" }}>
                    Cart
                    <div style={{ float: "right" }}>
                      {/* <Button style={this.wishHeaderStyle} onClick={() => { this.state.productsRef.current.add_allToCart(); this.updateWishList() }}>Add all to cart</Button> */}
                      <Button
                        style={this.wishHeaderStyle}
                        onClick={() => {
                          this.state.productsRef.current.remove_allFromCart();
                          this.updateCartList();
                        }}
                      >
                        clear all
                      </Button>
                    </div>
                  </div>{" "}
                </>
              }
              style={{ backgroundColor: "transparent", fontFamily: "serif" }}
            ></Header>
            <ArrowBackIos
              style={{
                position: "absolute",
                top: "20px",
                fontSize: "20px",
                cursor: "pointer",
                marginLeft: "10px",
                // marginTop: "100px",
              }}
              onClick={() =>
                this.setState({ itemPath: "//", itemClicked: true })
              }
            />
            <VisitHistory
              path={this.state.itemPath}
              clicked={this.state.itemClicked}
            ></VisitHistory>
            <Divider />
            {this.state.cartListData.length >= 1 ? (
              <>
                <Box sx={{ width: { md: "70%", xs: "100%" } }}>
                  {this.state.cartListData.map((item, index) => {
                    return (
                      <Box
                        key={String(index) + item.account_title}
                        sx={
                          item.account_available_Qty <= 0 ||
                          item.account_sold === true
                            ? { opacity: "0.5" }
                            : { opacity: 1 }
                        }
                      >
                        <Card
                          style={{
                            width: "100%",
                            height: "20vh",
                            backgroundColor: "inherit",
                            borderBottom: "none",
                            margin: "0 auto",
                          }}
                        >
                          {/* last float 'prices' */}
                          <Box
                            sx={{
                              display: {
                                width: "30%",
                                top: "0",
                                float: "right",
                                borderRight: "2px solid whitesmoke",
                              },
                            }}
                          >
                            {item.account_available_Qty <= 0 ||
                            item.account_sold === true ? (
                              <small
                                style={{
                                  float: "right",
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "4px",
                                }}
                              >
                                Sold out
                              </small>
                            ) : (
                              <small
                                style={{
                                  float: "right",
                                  backgroundColor: "lightgreen",
                                  color: "whitesmoke",
                                  padding: "4px",
                                }}
                              >
                                on Sale
                              </small>
                            )}
                            {!item.account_perc_off ? (
                              <small
                                style={{
                                  color: "whitesmoke",
                                  padding: "3px",
                                  float: "left",
                                  fontSize: "10px",
                                  borderRadius: "3px",
                                }}
                              >
                                {item.account_perc_off}% off
                              </small>
                            ) : (
                              <small
                                style={{
                                  color: "green",
                                  fontSize: "10px",
                                  padding: "3px",
                                  fontFamily: "cursive",
                                  float: "left",
                                }}
                              >
                                {item.account_perc_off}% off
                              </small>
                            )}{" "}
                            <br />
                            <h2
                              style={{
                                fontFamily: "serif",
                                margin: "0",
                                marginTop: "4px",
                                fontWeight: "bolder",
                                color: "teal",
                                fontSize: "15px",
                              }}
                            >
                              {item.product_sum}
                            </h2>
                            {item.account_saving_amount != 0 ? (
                              <>
                                <small
                                  style={{
                                    fontFamily: "serif",
                                    margin: "0",
                                    marginTop: "4px",
                                    fontWeight: "bolder",
                                    float: "left",
                                    color: "tan",
                                    fontSize: "10px",
                                  }}
                                >
                                  <strike> {item.product_actual_sum}</strike>
                                </small>
                                <h4
                                  style={{
                                    fontFamily: "serif",
                                    margin: "0",
                                    float: "right",
                                    borderBottom: "2px dotted darkseagreen",
                                    fontSize: "10px",
                                    backgroundColor: "aliceblue",
                                    color: "chocolate",
                                  }}
                                >
                                  Save {item.product_saving_sum}
                                </h4>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                          {/* second float 'title' */}
                          <Box
                            sx={{
                              display: {
                                cursor: "pointer",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "40%",
                                paddingLeft: "20px",
                                backgroundColor: "rgba(160,200,59,0.2)",
                                float: "right",
                                borderRight: "2px solid whitesmoke",
                                marginBottom: "10px",
                              },
                            }}
                          >
                            <small
                              style={{
                                backgroundColor: "goldenrod",
                                fontStyle: "oblique",
                                color: "whitesmoke",
                                padding: "3px",
                                fontSize: "10px",
                                borderRadius: "3px",
                              }}
                            >
                              {item.account_category}
                            </small>
                            <br></br>
                            <p
                              style={{
                                fontFamily: "Ariel",
                                color: "GrayText",
                                marginBottom: "1px",
                                float: "left",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                lineHeight: "1.5em",
                                height: "3em",
                              }}
                              onClick={() =>
                                this.setState({
                                  itemPath: `/p/${item.account_slug}/${item.account_id}`,
                                  itemClicked: true,
                                })
                              }
                            >
                              {item.account_title}
                            </p>{" "}
                            <br></br>
                            <div
                              style={{
                                width: "100%",
                                paddingRight: "20px",
                                display: "grid",
                                justifyContent: "left",
                                marginTop: "20px",
                              }}
                            >
                              <Tag
                                style={{
                                  width: "67px",
                                  // flo: "right",
                                  display: "inline-block",

                                  marginBottom: "3px",
                                }}
                              >
                                {item.quantity}Qty{" "}
                              </Tag>

                              <div
                                style={{
                                  width: "80px",
                                  float: "right",
                                  display: "inline-block",
                                  marginBottom: "5px",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    this.state.productsRef.current.add_QtyToCart(
                                      item.id
                                    );
                                    this.updateCartList();
                                  }}
                                  dataId={item.id}
                                  aria-label="Add"
                                  disabled={
                                    item.quantity >= item.account_available_Qty
                                      ? true
                                      : false
                                  }
                                  style={{
                                    padding: "0",
                                    marginRight: "2px",
                                    width: "30px",
                                    height: "22px",
                                    border: "1px solid black",
                                    borderRadius: "2px",
                                  }}
                                >
                                  +
                                </IconButton>
                                <div style={{ float: "right" }}>
                                  <IconButton
                                    onClick={() => {
                                      this.state.productsRef.current.subtract_FromCart(
                                        item.id
                                      );
                                      this.updateCartList();
                                    }}
                                    dataId={item.id}
                                    disabled={item.quantity <= 1 ? true : false}
                                    aria-label="Add"
                                    style={{
                                      width: "30px",
                                      padding: "0",
                                      marginLeft: "6px",
                                      height: "22px",
                                      border: "1px solid black",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    -
                                  </IconButton>
                                </div>
                              </div>
                              <SelectProduct
                                key={String(index) + item.account_title +'-p'}
                                main_prod={item}
                                refresh={() => this.updateCartList()}
                              />
                            </div>
                          </Box>

                          {/* first float 'avatar' */}
                          <Box
                            sx={{
                              display: {
                                xs: "block",
                                cursor: "pointer",
                                md: "none",
                                margin: "0",
                                width: "30%",
                                borderRight: "2px solid whitesmoke",
                              },
                            }}
                          >
                            <Antavatar
                              onClick={() =>
                                this.setState({
                                  itemPath: `/p/${item.account_slug}/${item.account_id}`,
                                  itemClicked: true,
                                })
                              }
                              shape="square"
                              src={item.account_image}
                              size={70}
                            ></Antavatar>
                            <Button
                              variant="text"
                              style={{
                                fontSize: "10px",
                                marginBottom: "-20px",
                              }}
                              type="error"
                              color="error"
                              onClick={() => {
                                this.state.productsRef.current.delete_ItemFromCart(
                                  item.id
                                );
                                this.updateCartList();
                              }}
                            >
                              <Delete style={{ fontSize: "15px" }} />
                              Remove
                            </Button>
                          </Box>

                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                md: "block",
                                width: "30%",
                                borderRight: "2px solid whitesmoke",
                              },
                            }}
                          >
                            <Antavatar
                              shape="square"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.setState({
                                  itemPath: `/p/${item.account_slug}/${item.account_id}`,
                                  itemClicked: true,
                                });
                              }}
                              src={item.account_image}
                              size={100}
                            ></Antavatar>
                            <br></br>
                            <Button
                              variant="text"
                              style={{ fontSize: "10px" }}
                              type="error"
                              color="error"
                            >
                              <Delete style={{ fontSize: "15px" }} />
                              Remove
                            </Button>
                          </Box>
                        </Card>

                        <Divider />
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    right: { md: "0", xs: "0" },
                    width: { md: "28%", xs: "100%" },
                    top: { md: "200px", xs: "0" },
                    position: { md: "absolute", sx: "relative" },
                  }}
                >
                  <div
                    style={{
                      margin: "0 auto ",
                      textAlign: "right",
                      fontFamily: "monospace",
                      color: "GrayText",
                    }}
                  >
                    <Divider />
                    <Container style={{ marginRight: "10px" }}>
                      <h4 style={{ color: "coral" }}>Amount Saved: </h4>{" "}
                      {this.state.currentCurrency} {this.state.savedAmount}
                    </Container>
                    <Divider />{" "}
                    <Container style={{ marginRight: "10px" }}>
                      <h4>Subtotal:</h4>
                      {this.state.currentCurrency} {this.state.subTotal}
                    </Container>
                    <Divider />
                    <Container style={{ marginRight: "10px" }}>
                      <h4 style={{ color: "teal" }}>Network fee(2%):</h4>{" "}
                      {this.state.currentCurrency} {this.state.networkFee}
                    </Container>
                    <Divider />
                    <Container
                      style={{ fontSize: "20px", marginRight: "10px" }}
                    >
                      <h4 style={{ color: "forestgreen" }}>Total: </h4>{" "}
                      {this.state.currentCurrency} {this.state.Total}
                    </Container>
                    <Divider />
                    <Container style={{ width: "100%" }}>
                      <Button
                        disabled={this.state.Total <= 0 ? true : false}
                        onClick={() =>
                          this.setState({
                            itemPath: "/order/checkout",
                            itemClicked: true,
                          })
                        }
                        style={this.orderBtnStyle}
                      >
                        Buy Now
                      </Button>
                      <Button
                        style={this.callBtnStyle}
                        onClick={() => {
                          window.location.href = "tel:+2348136095341";
                        }}
                      >
                        Easy Order (Call Now)
                      </Button>
                    </Container>
                    <br></br>
                  </div>
                </Box>
                <br></br>
                <br></br>
              </>
            ) : (
              <Box
                style={{
                  fontFamily: "serif",
                  fontSize: "20px",
                  widht: "100%",
                  textAlign: "center",
                  margin: "0 auto",
                  padding: "100px",
                }}
              >
                <ShoppingBag />
                <h2> No item</h2>
                <small>
                  {" "}
                  <Link to="/">Continue Shopping.</Link>
                </small>
              </Box>
            )}
          </div></Spin>
        );
    }
}
