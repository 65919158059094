import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { claimInfluencerPage, createInfluencerAdPackages, deleteInfluencerAddPackage, fetchAllInfluencers, fetchBookmarkedInfluencers, fetchInfluencerAdPackages, fetchPlatformMediums, fetchinfluencer, influencersPageGet, interactInfluencerPage, postApplicationForm, updateInfluencerAdPackage, updateInfluencerMediums } from "./thunks";

const initialState = {
  platformMediums: [],
  message:null,
  status: "idle",
  influencers: [],
  error: null,
  influencerOnView:null,
  selectedInfluencerAdPackages :{
    all:[],
    active:null,

  },
  pagination: {
    pageNext: null,
    pagePrev: null,
    next: null,
    prev: null,
    currentPage: 0
    , totalInfluencers: 0
    ,
  }
};

const influencerReducer = createSlice({
  name: "influencer",
  initialState: initialState,
  reducers: {
    changeStatus(state, action) {
      state.status = action.payload;
    },
    resetInfluencerErrors(state, action) {

      state.error = null
    },
    resetInfluencerStatus(state, action) {

      state.status = 'idle'
    }, 
    resetInfluencerMessage(state, action) {

      state.message=null
    }, 
    setInfluencerMessage(state, action) {

      state.message= action.payload
    }, 
    setActiveInfluencerAdPAckage(state, action){
      state.selectedInfluencerAdPackages.active = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatformMediums.fulfilled, (state, action) => {
        state.platformMediums = action.payload;
        state.status = "loaded";
      })
      .addCase(postApplicationForm.fulfilled, (state, action) => {
        state.status = "uploaded";
      })
      .addCase(fetchinfluencer.fulfilled, (state, action) => {
        state.influencerOnView = action.payload 
                state.status = "loaded";

      }) .addCase(interactInfluencerPage.fulfilled, (state, action) => {
        state.influencerOnView = action.payload 
        state.influencers= state.influencers.map(inf=>inf.id === action.payload.id?action.payload:inf)
                state.status = "success";

      }) .addCase(updateInfluencerMediums.fulfilled, (state, action) => {
        state.influencerOnView.mediums = action.payload 
                state.status = "success";

      }) .addCase(fetchInfluencerAdPackages.fulfilled, (state, action) => {
        state.selectedInfluencerAdPackages.all = action.payload 
                state.status = "loaded";

      }) .addCase(createInfluencerAdPackages.fulfilled, (state, action) => {     

        state.selectedInfluencerAdPackages.all.unshift(action.payload)
                state.status = "success";

      }).addCase(updateInfluencerAdPackage.fulfilled, (state, action) => {
        state.selectedInfluencerAdPackages.active = action.payload 
        state.selectedInfluencerAdPackages.all= state.selectedInfluencerAdPackages.all.map(pkg=> pkg.id === action.payload.id ? action.payload :pkg)
                state.status = "success";

      }).addCase(deleteInfluencerAddPackage.fulfilled, (state, action) => {
        state.selectedInfluencerAdPackages.active =null
        state.selectedInfluencerAdPackages.all =      state.selectedInfluencerAdPackages.all.filter(pkg=>pkg.id !== action.payload)
                state.status = "success";

      }).addCase(claimInfluencerPage.fulfilled, (state, action) => {
        state.message ={type:"notification", message:action.payload.msg, title:"Claim Request Received"}

      }).addMatcher(isAnyOf(fetchAllInfluencers.fulfilled, fetchBookmarkedInfluencers.fulfilled, influencersPageGet.fulfilled), (state, action) => {
        
        state.influencers = action.payload.results;
        state.pagination.pageNext = action.payload.next
        state.pagination.pagePrev = action.payload.previous
        state.pagination.totalInfluencers = action.payload.count;
        state.status = "loaded";
      })
      .addMatcher(
        isAnyOf(fetchPlatformMediums.pending, claimInfluencerPage.pending, interactInfluencerPage.pending, fetchBookmarkedInfluencers.pending, createInfluencerAdPackages.pending, fetchinfluencer.pending,updateInfluencerMediums.pending, deleteInfluencerAddPackage.pending, updateInfluencerAdPackage.pending, fetchInfluencerAdPackages.pending, influencersPageGet.pending, fetchAllInfluencers.pending, postApplicationForm.pending),
        (state, action) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isAnyOf(fetchPlatformMediums.rejected, interactInfluencerPage.rejected, claimInfluencerPage.rejected, fetchBookmarkedInfluencers.rejected, createInfluencerAdPackages.rejected, fetchinfluencer.rejected, updateInfluencerMediums.rejected, deleteInfluencerAddPackage.rejected, fetchInfluencerAdPackages.rejected, updateInfluencerAdPackage.rejected, fetchAllInfluencers.rejected, influencersPageGet.rejected, postApplicationForm.rejected),
        (state, action) => {
          state.status = "failed";
          const errorMsg = JSON.parse(action.error.message);
          if (errorMsg instanceof Array) {
            state.error = { detail: errorMsg };
          } else {
            state.error = errorMsg;
          }
        }
      );
  },
});
export const { changeStatus, resetInfluencerErrors, resetInfluencerStatus, setActiveInfluencerAdPAckage, resetInfluencerMessage, setInfluencerMessage } = influencerReducer.actions;
export default influencerReducer.reducer;
