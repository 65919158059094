import { styled, alpha } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useNavigate } from  'react-router-dom';
import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

export default function ApplyButton() {
  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    animatedButton: {
      animation: "$colorChangeAnimation 3s infinite",
    },
    "@keyframes colorChangeAnimation": {
      "0%": {
        backgroundColor: alpha(theme.palette.success.main, 0.5),
      },
      "25%": {
        backgroundColor: alpha(theme.palette.success.light, 0.5),
      },
      "50%": {
        backgroundColor: alpha(theme.palette.success.dark, 0.5),
      },
      "75%": {
        backgroundColor: alpha(theme.palette.success.main, 0.5),
      },
      "100%": {
        backgroundColor: alpha(theme.palette.success.light, 0.5),
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box
      onClick={() => navigate("/influence-hub/influencers/apply")}
      variant="text"
      className={classes.animatedButton}
      sx={{
        width: { sm: "100%", md: "50%" },
        borderRadius: { md: "14px" },
        borderBottomRightRadius: "14px",
        borderBottomLeftRadius: "14px",
        cursor: "pointer",
        color: "#fff",
        margin: "0 auto",
        textAlign: "center",
        marginTop: { md: "20px" },
      }}
    >
      <h2 style={{ margin: "0", padding: 0, opacity: "0.7" }}>Apply Today</h2>
      <p style={{ margin: "0", padding: 0, opacity: "0.7" }}>
        Become An AccountGig Influencer
      </p>
    </Box>
  );
}
