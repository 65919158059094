import React from "react";

import servicesJson from "./services";
import fields from "./fields";
import {
  Box,
  Container,
  Paper,
  Typography,
  ClickAwayListener,
  Checkbox,
} from "@mui/material";
import {
  Button,
  Form,
  Input,
  List,
  Select,
  Spin,
  message,
  Modal,
  notification,
  Divider,
  Switch,
} from "antd";
import { authClient, client } from "../app_context";
import { FlashAutoOutlined, Pin, WindowSharp } from "@mui/icons-material";
import SMMHeader from "./header";
import { useNavigate } from "react-router-dom";
import WalletButton from "../authpages/pay_with_wallet";
import { motion } from "framer-motion";
import { uid } from "react-uid";
import { useAuthTabs } from "../middlewares/authTabs";

export default function SMMIndex(props) {
  const [apiServices, setApiServices] = React.useState([]);
  const authTabs = useAuthTabs({active:1})
  const [categories, setCategories] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [serviceFieldsList, setServiceFieldsList] = React.useState([]);

  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const [selectedService, setSelectedService] = React.useState(null);
  const [selectedServiceId, setSelectedServiceId] = React.useState(null);
  const [selectedFullDetail, setSelectedFullDetail] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [boosting, setBoosting] = React.useState(false);
  const [activeServiceID, setActiveServiceID] = React.useState(undefined);
  const [currentOrder, setCurrentOrder] = React.useState(null);

  const { TextArea } = Input;
  const pinRef = React.useRef(null);
  const navigate = useNavigate();
  const inputFields = React.useRef([]);
  const [formEl] = Form.useForm();
  const formRef = React.useRef(null);
  const [priceTotal, setPriceTotal] = React.useState();
  const { Option } = Select;

  const getData = async () => {
    setLoading(true);
    try {
      const resp = await client.get("/smm/api/services/");
      const data = resp.data;
      setApiServices(data);
      const cats = new Set();
      for (let i = 0; i < data.length; i++) {
        const category = data[i]["category"];
        cats.add(category);
      }
      setCategories((prev) => [...cats]);

      setLoading(false);
    } catch (err) {
      message.error("Unable to get services ");
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (localStorage) {
      localStorage.setItem("currentPage", "smm");
    }

    getData(); 
     
  }, []);



  const handleSetService = ({val, serviceID=null}) => {
    setSelectedCategory(val);


    const servicesL = [];
if(serviceID){
    for (let i = 0; i < apiServices.length; i++) {
      const itm = apiServices[i];
      if (itm.category === val) {
        servicesL.push(itm);
      }
    }
    setServices((pre) => [...servicesL]);
    const serviceToSet =servicesL.find(itm=>itm.id === serviceID)
    if (serviceToSet){
    setSelectedService(serviceToSet);
    handleAddFields({ service_: serviceToSet.service, category_: val });
    
    }else{
    if (servicesL[0]) { 
           setSelectedService(servicesL[0]);

      handleAddFields({ service_: servicesL[0].service, category_: val });
    }
  }
  }else{
    for (let i = 0; i < apiServices.length; i++) {
      const itm = apiServices[i];
      if (itm.category === val) {
        servicesL.push(itm);
      }
    }
    setServices((pre) => [...servicesL]);
    if (servicesL[0]) {
          setSelectedService(servicesL[0]);

      handleAddFields({ service_: servicesL[0].service, category_: val });
    }}
  };
  const updateQuantityField = () => {

    if (apiServices.find((itm) => itm.service === activeServiceID)) {
      const priceTotalV = apiServices.find((itm) => itm.service === activeServiceID)
        .price_currency +
        " " +
        String(
          (
            apiServices.find((itm) => itm.service === activeServiceID)
              ?.price_rate * +(formEl.getFieldValue("quantity") || 1)
          ).toFixed(5)
        );

      setPriceTotal(priceTotalV)
    }
    else {
      setPriceTotal(apiServices.find((itm) => itm.service === activeServiceID)?.price_currency || "" + " 0.0")
    }
  }
  React.useEffect(()=>{
    const serviceParam = new URLSearchParams(window.location.search).get("service")
    const serviceToSet= apiServices.find(itm=>itm.id === +serviceParam)
      
    if (serviceToSet){
    

      const defaultCat = serviceToSet.category;

      handleSetService({val:defaultCat, serviceID: serviceToSet.id});

      updateQuantityField()

    }
  }, [apiServices]);

  React.useEffect(() => {

    
    if (!selectedCategory) {
      if (apiServices.length >= 1) {
        const defaultCat = apiServices[0].category;
        handleSetService({val:defaultCat});
        updateQuantityField()

      }
    }



  }, [categories, services]);

  const handleAddFields = ({ category_, service_ }) => {
    formEl.resetFields();
    const selectedDetail = apiServices.find(
      (itm) => itm.service === service_
    );
    setActiveServiceID(selectedDetail.service);
    if (selectedDetail) {
      const selectedFullDetail = servicesJson[selectedDetail.service];
      if (selectedFullDetail) {
        setSelectedFullDetail(selectedFullDetail);
        // alert(String(selectedFullDetail["type"]))
        const serviceFields = fields[String(selectedFullDetail["type"])];
        if (serviceFields === undefined) {
          setServiceFieldsList([]);
          return message.error("Service Fields Not Available");
        }
        setServiceFieldsList(serviceFields);
        setSelectedService(selectedDetail);
        setSelectedServiceId(selectedDetail.service);
        setPriceTotal(" 0.0")

      } else {
        message.error("Service Info Not Available");
      }
    }
  };

  const fieldStyle = {
    width: "inherit",
    fontWeight: "bolder",
    fontSize: "20px",
    padding: "10px",
  };

  // const createSMMOrderAPI = (values) => {
  //     setBoosting(true)
  //     client.post("/smm/api/order/", values).then(resp => {
  //         if (resp.data.orderId) {
  //             navigate("/smm/orders");

  //         }
  //         else {
  //             if (resp.data.msg) {
  //                 Modal.error({ title: "Order Not Successful", content: <b>{resp.data.msg}</b> })

  //             }
  //         } setBoosting(false)

  //     }).catch(err => {
  //         notification.error({ message: "Order Failed. Please contact us if you got debited or try again." });
  //         setBoosting(false)
  //     })

  // }
  const handleBoost = (values) => {
    setBoosting(true);

    const mainVal = { fields: values };
    mainVal["action"] = "add";
    mainVal["service"] = activeServiceID;
    mainVal["price_total"] = priceTotal;
    mainVal["quantity"] = values.quantity || undefined;
    mainVal["currency"] = apiServices.find(
      (itm) => itm.service === activeServiceID
    ).price_currency;
    mainVal["price_rate"] = apiServices.find(
      (itm) => itm.service === activeServiceID
    ).price_rate;
    setCurrentOrder(mainVal);
  };
  return (
    <>
      <Spin spinning={loading}>
        <Box sx={{ padding: "10px", borderRadius: "50px" }}>
          <Paper
            sx={{
              width: { sm: "100%", md: "50%" },
              overflow: "hidden",
              marginTop: { sx: "10px", md: "30px" },
              margin: "0 auto",
              height: "100%",
              minHeight: "50vh",
            }}
          >
            <SMMHeader current="boosting" />

            <Container style={{ padding: "20px" }}>
              <h1 style={{ fontSize: "36px", margin: 0, fontWeight: "bold" }}>
                Welcome to AG-Boostify! <FlashAutoOutlined />
              </h1>
              <p style={{ fontSize: "18px", margin: 0 }}>
                Your Ultimate Social Media Marketing Solution
              </p>
              <Form
                form={formEl}
                ref={formRef}
                onFinish={handleBoost}
                layout="vertical"
                onChange={updateQuantityField}
              >
                <Form.Item style={fieldStyle} label="Category">
                  <Select
                    showSearch
                    size="large"
                    value={selectedCategory}
                    onChange={(value)=> handleSetService({val:value})}
                    options={categories.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  ></Select>
                </Form.Item>
                <Form.Item style={fieldStyle} label="Service">
                  <Select
                  size="large"
                    showSearch
                    value={selectedService?.service}
                    onChange={(val) =>
                      handleAddFields({
                        service_: val,
                        category_: selectedCategory,
                      })
                    }


                  // dropdownRender={(item, id)=><Option  key={uid}>{item.}</Option>}
                  >

                    {services.map((item) => (

                      <Option
                        style={{ color: "green" }}
                        value={item.service}

                        key={uid(item)}

                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {selectedFullDetail?.description && (
                  <Form.Item style={fieldStyle} label={"Description"}>
                    <TextArea
                      value={selectedFullDetail.description}
                      rows={7}
                      disabled={true}
                    ></TextArea>
                  </Form.Item>
                )}
                {serviceFieldsList.map((field, index) => (
                  <>
                    <Form.Item
                      style={fieldStyle}
                      help={
                        field.name === "quantity" &&
                        `Min: ${apiServices.find(
                          (fld) => fld.service === selectedServiceId
                        )?.min
                        } - Max: ${apiServices.find(
                          (fld) => fld.service === selectedServiceId
                        )?.max
                        }`
                      }
                      name={field.name}
                      label={field.label}
                      rules={[
                        {
                          required: field.required,
                          message: `Please Enter ${field.label}`,
                        },
                      ]}
                      key={String(field.name) + index}
                    >
                      {field.field === "text" ? (
                        <Input
                          name={field.name}
                          defaultValue={
                            selectedFullDetail[field.name]
                              ? selectedFullDetail[field.name]
                              : undefined
                          }
                          style={{
                            borderStyle: "groove",
                            borderColor: "inherit",
                            borderWidth: "2px",
                          }}
                          min={
                            field.name === "quantity"
                              ? apiServices.find(
                                (fld) => fld.service === selectedServiceId
                              )?.min
                              : undefined
                          }
                          max={
                            field.name === "quantity"
                              ? apiServices.find(
                                (fld) => fld.service === selectedServiceId
                              )?.max
                              : undefined
                          }
                          bordered
                          type={field.type === "integer" ? "number" : "text"}
                          disabled={field.disabled}
                        />
                      ) : field.field === "textarea" ? (
                        <TextArea
                          name={field.name}
                          defaultValue={
                            selectedFullDetail[field.name]
                              ? selectedFullDetail[field.name]
                              : undefined
                          }
                          style={{
                            borderStyle: "groove",
                            borderColor: "inherit",
                            borderWidth: "2px",
                          }}
                          rows={7}
                          bordered
                          disabled={field.disabled}
                        />
                      ) : field.field === "checkbox" ? (
                        <Switch
                          size="default"
                          name={field.name}
                          defaultChecked={
                            selectedFullDetail[field.name]
                              ? selectedFullDetail[field.name]
                              : undefined
                          }
                          style={{
                            borderStyle: "groove",
                            borderColor: "inherit",
                            borderWidth: "2px",
                          }}
                          disabled={field.disabled}
                        />
                      ) : (
                        <Input
                          name={field.name}
                          defaultValue={
                            selectedFullDetail[field.name]
                              ? selectedFullDetail[field.name]
                              : undefined
                          }
                          style={{
                            borderStyle: "groove",
                            borderColor: "inherit",
                            borderWidth: "2px",
                          }}
                          bordered
                          type={field.type}
                          disabled={field.disabled}
                        />
                      )}
                    </Form.Item>
                  </>
                ))}
                {apiServices.find((itm) => itm.service === activeServiceID)
                  ?.price && (
                    <Form.Item style={fieldStyle}>
                      <Typography>
                        <List
                          renderItem={(item) => (
                            <List.Item>
                              <List.Item.Meta
                                title={
                                  <div
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item.label}
                                  </div>
                                }
                              />
                              <div
                                style={{ fontWeight: "bolder", fontSize: "17px" }}
                              >
                                {item.value}
                              </div>
                            </List.Item>
                          )}
                          dataSource={[
                            {
                              label: "Rate",
                              value:
                                apiServices.find(
                                  (itm) => itm.service === activeServiceID
                                )?.price_currency + " " +
                                String(
                                  apiServices.find(
                                    (itm) => itm.service === activeServiceID
                                  )?.price_rate
                                ),
                            }, {
                              label: "Total",
                              value: priceTotal
                            },
                          ]}
                        ></List>
                      </Typography>
                    </Form.Item>
                  )}
                <Form.Item>
                  <Button
                    title="Boost"
                    style={{ width: "100%", fontSize: "18px" }}
                    type="primary"
                    shape="round"
                    loading={boosting}
                    block
                    htmlType="submit"
                  >
                    BOOST
                  </Button>
                </Form.Item>
              </Form>{" "}
            </Container>
          </Paper>
        </Box>
      </Spin>
      <Modal
        title={"Order Summary"}
        onCancel={() => {
          setBoosting(false);
          setCurrentOrder(null);
        }}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={boosting}
        closable
        centered
      >
        <Typography
          style={{
            minHeight: "30vh",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
            justifySelf: "center",
            alignSelf: "center",
            padding: "10px",
          }}
        >
          <List
            dataSource={
              currentOrder
                ? [...new Map(Object.entries(currentOrder?.fields)).keys()]
                : []
            }
            renderItem={(item) => (
              <List.Item key={item}>
                {" "}
                <List.Item.Meta
                  title={
                    serviceFieldsList.find((itm) => itm.name === item)?.label
                  }
                />
                <div>{currentOrder?.fields[item] || "Not Set"} </div>
                {/* <hr/> */}
              </List.Item>
            )}
          ></List>
          <hr />

          {currentOrder && (
            <Box
              // border={"2px solid InfoBackground"}
              sx={{
                justifySelf: "center",
                textAlign: "center",
                width: { xs: "100%", md: "100%" },
                marginTop: "10px",
                marginBottom: "10px",
                margin: "0px auto",
                display: "block",
              }}
            >
              <Typography
                sx={{
                  marginBottom: "10px",
                  width: { xs: "100%", md: "60%" },
                  float: "right",
                }}
              >
                <List
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <div
                            style={{ fontWeight: "bolder", fontSize: "18px" }}
                          >
                            {item.label}
                          </div>
                        }
                      />
                      <div style={{ fontWeight: "bolder", fontSize: "18px" }}>
                        {item.value}
                      </div>
                    </List.Item>
                  )}
                  dataSource={[
                    {
                      label: "Rate",
                      value:
                        apiServices.find(
                          (itm) => itm.service === activeServiceID
                        ).price_currency + " " +
                        String(
                          apiServices.find(
                            (itm) => itm.service === activeServiceID
                          ).price_rate
                        ),
                    },
                    { label: "Total", value: priceTotal },
                  ]}
                ></List>
              </Typography>

              <hr style={{ width: "70%", float: "right", marginTop: "0px" }} />

              <WalletButton
                type="smm"
                smmOrderData={currentOrder}
                quantity={currentOrder?.quantity}
                currency={currentOrder?.currency}
                rate={currentOrder?.price_rate}
              />
            </Box>
          )}
        </Typography>
      </Modal>
    </>
  );
}
