import {
  BoltOutlined,
  HomeOutlined,
  ShoppingBagOutlined,
  WalletOutlined,
} from '@mui/icons-material'
import { switchTabs } from '../../store/core/reducer'
import store from '../../store/store'
import { useNavigate } from 'react-router-dom'
import { SelectCurrentTabName } from '../../store/core/selectors'

export const useAuthTabs = props => {
  const navigate = useNavigate()

  const AuthTabs = [
    {
      action: () => navigate('/'),
      title: 'Home',
      icon: <HomeOutlined />,
    },
    {
      action: () => navigate('/smm/boosting'),
      title: 'Boostify',
      icon: <BoltOutlined />,
    },
    {
      action: () => navigate('/orders'),
      title: 'Orders',
      icon: <ShoppingBagOutlined />,
    },
    {
      title: 'Wallet',
      action: () => navigate('/user/wallet'),
      icon: <WalletOutlined />,
    },
  ]
  const currentTabName = SelectCurrentTabName()
    if(!props?.terminate){
    store.dispatch(
      switchTabs({
        name: 'main',
        tabs: AuthTabs,
        active: props?.active || 0,
      }),
    )
  }
  return
}
