import logo from './logo.svg'
import './App.css'
import Header from './components/layouts/header'
import Footer from './components/layouts/footer'
import AccountList from './components/accountsList'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import React, { createContext, useContext, useEffect, useRef } from 'react'
import Wishlist from './components/authpages/wishlist'
import DashBoard from './components/authpages/dashboard'
import Orders from './components/authpages/orders'
import ReferEarn from './components/authpages/refer&earn'
import JoinourCommunity from './components/basic/community'
import BuyUsCoffee from './components/basic/buyCoffee'
import ContactUs from './components/basic/contactUs'
import AboutUs from './components/basic/aboutUs'
import Privacy from './components/basic/privacy'
import SellerAgreement from './components/basic/sellerAgreement.js'
import Cart from './components/authpages/cart'
import DashBoardHome from './components/dashboard/pages/Home'
import Profile from './components/dashboard/pages/Profile'
import Tables from './components/dashboard/pages/Tables'
import SignIn from './components/dashboard/pages/SignIn'
import SignUp from './components/dashboard/pages/SignUp'
import Rtl from './components/dashboard/pages/Rtl'
import Billing from './components/dashboard/pages/Billing'
// import Profile from './components/authpages/profile';
import TermsAndCondictions from './components/basic/terms&C'
import CookiesUsage from './components/basic/cookiesUsage'
import CheckOut from './components/authpages/checkout'
import Sell from './components/authpages/sell'
import SellerProductsPage from './components/authpages/seller_products_page'
import ProductEditPage from './components/authpages/productEditPage'
import ProductsFunctions from './components/ProductsFunctions'
import ProductDetail from './components/productDetail'
import { ConfigProvider, Form, message, theme as aTheme } from 'antd'
import OrderStatus from './components/authpages/order_status'
import UserWallet from './components/authpages/user_wallet'
import ChatApp from './components/authpages/chat/ChatApp'
import NotFound from './components/NotFound'
import AppContext from './components/app_context.js'

import axios from 'axios'
import SearchPage from './components/searchPage'
import Signout from './components/authpages/signout'
import ResetPasswordForm from './components/authpages/reset-password/ResetPasswordForm'
import PasswordResetConfirm from './components/authpages/password-reset-confrim.js/PasswordResetConfirm'
import LandingPage from './components/Home'
import { Box } from '@mui/system'
import { blue, green, grey } from '@mui/material/colors'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import ProductCategory from './components/productCategory'

import {
  Slider,
  SliderThumb,
  BottomNavigation,
  Button,
  BottomNavigationAction,
  Bottom,
} from '@mui/material'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Alert from '@mui/material/Alert'
import SMMIndex from './components/smm/index.js'
import SMMOrders from './components/smm/orders.js'
import APP_ENV from './env'
import { Provider } from 'react-redux'
import store from './store/store.js'
import InfluenceHub from './components/influence-hub/index.js'
import InfluencerApplication from './components/influence-hub//influencer/apply.js'
import { fetchUser } from './store/auth/thunks.js'
import Influencer from './components/influence-hub/influencer/influencer.js'
import InHubOrderList from './components/influence-hub/order/orderList.js'
import InHubOrderDetail from './components/influence-hub/order/order.js'

const BASE_URL = APP_ENV.SERVER_ADDR
const reqHeaders = {
  'Content-Type': 'application/json',
}

if (localStorage.getItem('AccgToken') !== null) {
  reqHeaders['Authorization'] = `Token ${localStorage.getItem('AccgToken')}`
}

const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,
})
const authClient = axios.create({
  baseURL: BASE_URL,
})

function App() {
  const productFunctionsRef = useRef(null)
  const handleHeaderUpdate = useRef(null)
  const [antForm] = Form.useForm()

  const [mode, setMode] = React.useState(
    localStorage.getItem('mode') || 'light',
  )

  const [displayErrorOpen, setDisplayErrorOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [alertColor, setAlertColor] = React.useState('success')
  const [alertMessage, setAlertMessage] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState('home')
  const location = useLocation()

  const navigate = useNavigate()

  const swipEContainer = {
    backgroundColor: 'rgb(30, 48, 40)',
    textAlign: 'center',
    borderBottom: '3px solid grey',
    padding: '0px',
    margin: '0px',
    boxSizing: 'border-box',
    top: '0px',
  }
  const navLi = {
    display: 'inline-flex',
    marginLeft: '20px',
    padding: '10px',
    fontSize: '20px',
    cursor: 'pointer',
    borderTop: '2px solid green',
    userSelect: 'none',
  }

  const displayErrorClose = () => {
    setDisplayErrorOpen(false)
  }

  const displayErrorDialogue = (
    <div>
      <Dialog open={displayErrorOpen} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          We are sorry!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={displayErrorClose}>
            Ok
          </Button>

          {/* <Button onClick={removeFromCart} style={{ backgroundColor: 'red', fontSize: '6x', color: 'white' }}>Remove From Cart</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )

  // console.log(SocialAccounts)

  const showAlert = (color = null, message = null) => {
    var cartEl = document.querySelector('#cartSuccessAlert')
    if (color) {
      setAlertColor(color)
      cartEl.children.item(1).innerHTML = message
    }
    cartEl.style.left = 0
    setTimeout(() => {
      cartEl.style.left = '-800px'
    }, 1300)
  }

  const addToWish = id => {
    // alert(e.target.getAttribute('dataId'))
    client
      .get('/auth/users/me/')
      .then(response => {
        if (response.data) {
          if (response.data.username) {
            client
              .get(
                `/social/api/add-to-wishlist/${response.data.username}/${id}`,
              )
              .then(resp => {
                // console.log(resp)
              })
          }
        }
        handleHeaderUpdate.current.updateAll()
        showAlert('success', 'Wishlist Updated')
      })
      .catch(err => {
        // showAlert('error', 'Please Login')
      })
  }

  const addToCart = id => {
    // alert(e.target.getAttribute('dataId'))
    client
      .get('/auth/users/me/')
      .then(response => {
        if (response.data) {
          if (response.data.username) {
            client
              .get(`/social/api/add-to-cart/${response.data.username}/${id}`)
              .then(resp => {
                if (resp.data.msg) {
                  setErrorMessage(resp.data.msg)
                  setDisplayErrorOpen(true)
                }

                handleHeaderUpdate.current.updateAll()
                showAlert('success', 'Cart Updated!')
                // props.refreshPage();
              })
          }
        }
      })
      .catch(err => {
        showAlert('error', 'Please Login!')
      })
  }

  React.useEffect(() => {
    store.dispatch(fetchUser())
    if (localStorage) {
      localStorage.setItem('mode', mode)
    }
  }, [])
  React.useEffect(() => {
    setTitle()
  }, [location])
  const setTitle = () => {
    const title =
      window.location.pathname
        .replaceAll('/', ' ')
        .replaceAll('-', ' ')
        .split('?').length >= 1
        ? ' | ' +
          window.location.pathname
            .replaceAll('/', ' ')
            .replaceAll('-', ' ')
            .split('?')[0]
        : ''

    document.title = 'AccountGig ' + title.toUpperCase()
  }
  const getDesignTokens = mode => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: { main: '#4f7363' },
            divider: green[700],
            text: {
              primary: green[800],
              secondary: green[900],
            },
            background: {
              default: green[300],
              paper: green[100],
            },
          }
        : {
            // palette values for dark mode
            primary: { main: green[900] },
            divider: green[300],
            background: {
              default: green[900],
              paper: '#2a4039',
            },
            text: {
              primary: green['A100'],
              secondary: green[100],
            },
          }),
    },
  })
  const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    [],
  )

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <ConfigProvider
            theme={{
              token:
                mode === 'light'
                  ? {
                      // Seed Token
                      colorPrimaryBg: green[300],
                      colorPrimary: '#4f7363',
                      borderRadius: 2,
                      colorText: green[800],
                      // Alias Token
                      colorBgContainer: green[100],
                      colorBorder: '#546b65',
                    }
                  : {
                      colorPrimaryBg: green[900],
                      colorPrimary: green['A100'],
                      borderRadius: 2,
                      colorText: green['A100'],

                      colorBorder: '#254039',

                      // Alias Token
                      colorBgContainer: '#2a4039',
                    },
              algorithm:
                mode === 'dark'
                  ? aTheme.darkAlgorithm
                  : aTheme.compactAlgorithm,
            }}
          >
            <AppContext.Provider value={{ client, BASE_URL, authClient }}>
              <Alert
                severity={alertColor}
                color={alertColor}
                style={{
                  width: '400px',
                  transition: 'left 1s ease',
                  margin: '10px auto',
                  left: '-800px',
                  justifyContent: 'center',
                  zIndex: 99999,
                  position: 'absolute',
                }}
                draggable
                id="cartSuccessAlert"
              >
                <small>{alertMessage}</small>
              </Alert>
              <Box
                sx={{
                  minHeight: '100vh',
                  zIndex: 10,
                  overflowX: 'hidden',
                  height: 'auto',
                  backgroundColor: mode === 'dark' ? '#27302d' : '#d3f2e8',
                }}
              >
                <Header
                  mode={mode}
                  colorMode={colorMode}
                  ref={handleHeaderUpdate}
                />

                <ProductsFunctions
                  ref={productFunctionsRef}
                  headerUpdater={handleHeaderUpdate}
                />

                <Routes>
                  <Route
                    path="/products/category/:name"
                    element={
                      <ProductCategory
                        addToCart={addToCart}
                        addToWish={addToWish}
                      />
                    }
                  ></Route>
                  <Route path="/about-us" element={<AboutUs />}></Route>
                  <Route path="/contact-us" element={<ContactUs />}></Route>
                  <Route
                    path="/buy-us-coffee"
                    element={<BuyUsCoffee />}
                  ></Route>
                  <Route
                    path="/join-our-community"
                    element={<JoinourCommunity />}
                  ></Route>
                  <Route path="/privacy" element={<Privacy />}></Route>
                  <Route
                    path="/seller-agreement"
                    element={<SellerAgreement />}
                  ></Route>
                  <Route
                    path="/cookies-usage"
                    element={<CookiesUsage />}
                  ></Route>
                  <Route
                    path="/cart"
                    element={
                      <Cart
                        productFuncsRef={productFunctionsRef}
                        headerUpdater={handleHeaderUpdate}
                      />
                    }
                  ></Route>
                  <Route
                    path="/smm/boosting"
                    element={
                      <SMMIndex
                        productFuncsRef={productFunctionsRef}
                        headerUpdater={handleHeaderUpdate}
                      />
                    }
                  ></Route>
                  <Route
                    path="/smm/orders"
                    element={
                      <SMMOrders
                        productFuncsRef={productFunctionsRef}
                        headerUpdater={handleHeaderUpdate}
                      />
                    }
                  ></Route>
                  {/* <Route path='/profile' >
            <Profile />
          </Route> */}
                  <Route
                    exact
                    path="/"
                    element={
                      <LandingPage
                        displayMode={mode}
                        headerUpdater={handleHeaderUpdate}
                      />
                    }
                  ></Route>
                  <Route
                    exact
                    path="/products"
                    element={
                      <AccountList
                        addToCart={addToCart}
                        addToWish={addToWish}
                        headerUpdater={handleHeaderUpdate}
                      />
                    }
                  ></Route>
                  <Route
                    path="/wishlist"
                    element={<Wishlist productFuncs={productFunctionsRef} />}
                  ></Route>
                  <Route
                    path="/p/:slug/:id"
                    element={
                      <ProductDetail
                        productFuncs={productFunctionsRef}
                        headerUpdater={handleHeaderUpdate}
                      />
                    }
                  ></Route>
                  <Route
                    path="/query-result"
                    element={
                      <SearchPage
                        productFuncs={productFunctionsRef}
                        headerUpdater={handleHeaderUpdate}
                      />
                    }
                  ></Route>
                  <Route path="/orders" element={<Orders />}></Route>
                  <Route path="/dashboard" element={<DashBoard />}></Route>
                  <Route path="/refer-and-earn" element={<ReferEarn />}></Route>
                  <Route
                    path="/sell"
                    element={<Sell headerUpdater={handleHeaderUpdate} />}
                  ></Route>
                  <Route
                    path="/order/checkout"
                    element={
                      <CheckOut
                        form={antForm}
                        headerUpdater={handleHeaderUpdate}
                        prodctsRef={productFunctionsRef}
                      />
                    }
                  ></Route>
                  <Route
                    path="/seller/products"
                    element={<SellerProductsPage />}
                  ></Route>
                  <Route
                    path="/terms&C"
                    element={<TermsAndCondictions />}
                  ></Route>
                  <Route
                    path="/seller/product/:slug"
                    element={<ProductEditPage />}
                  ></Route>
                  <Route path="/user/wallet" element={<UserWallet />}></Route>
                  <Route path="/order/status/:code" element={<OrderStatus />} />
                  <Route path="/user/chats" element={<ChatApp />} />
                  <Route path="/dashboard" element={<DashBoardHome />} />
                  <Route path="/tables" element={<Tables />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/sign-out" element={<Signout />} />
                  <Route
                    path="/sign-in"
                    element={<SignIn headerUpdater={handleHeaderUpdate} />}
                  />
                  <Route
                    path="/password/reset"
                    element={<ResetPasswordForm />}
                  />
                  <Route
                    path="/password/reset/confirm/:uid/:token"
                    element={<PasswordResetConfirm />}
                  />
                  <Route path="/rtl" element={<Rtl />} />
                  <Route path="/profile/:username" element={<Profile />} />
                  {/* InfluenceHub  */}
                  <Route
                    path="/influence-hub"
                    element={<InfluenceHub header={handleHeaderUpdate} />}
                  />
                  <Route
                    path="/influence-hub/orders"
                    element={
                      <InfluenceHub header={handleHeaderUpdate} active={1}>
                      </InfluenceHub>
                    }
                  />
                  <Route
                    path="/influence-hub/orders/:code"
                    element={
                      <InfluenceHub header={handleHeaderUpdate} active={1}>
                        <InHubOrderDetail header={handleHeaderUpdate} />
                      </InfluenceHub>
                    }
                  />
                  <Route
                    path="/influence-hub/gigs"
                    element={
                      <InfluenceHub header={handleHeaderUpdate} active={1} />
                    }
                  />
                  <Route
                    path="/influence-hub/proposals"
                    element={
                      <InfluenceHub header={handleHeaderUpdate} active={3} />
                    }
                  />
                  {/* influencers  */}
                  <Route
                    path="/influence-hub/influencers/apply"
                    element={
                      <InfluencerApplication header={handleHeaderUpdate} />
                    }
                  />
                  <Route
                    path="/influencers/:display_name"
                    element={
                      <InfluenceHub header={handleHeaderUpdate} active={0}>
                        <Influencer active={0} header={handleHeaderUpdate} />
                      </InfluenceHub>
                    }
                  />{' '}
                  <Route
                    path="/influencers/:display_name/:segment"
                    element={
                      <InfluenceHub header={handleHeaderUpdate} active={0}>
                        <Influencer header={handleHeaderUpdate} />
                      </InfluenceHub>
                    }
                  />
                  {/* Not found  */}
                  <Route path="*" element={<NotFound />} />
                </Routes>

                {displayErrorDialogue}
              </Box>
              <Footer />
            </AppContext.Provider>
          </ConfigProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  )
}

export default App
