import * as React from 'react'
import axios from 'axios'
import mainLogo from '../../assets/images/main-logo.png'
import Switch from '@mui/material/Switch'
import { useNavigate } from 'react-router-dom'
import { Avatar as Antavatar } from 'antd'
import { styled, alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import LoginRounded from '@mui/icons-material/LoginRounded'
import LogoutRounded from '@mui/icons-material/LogoutRounded'
import ShoppingCartCheckout from '@mui/icons-material/ShoppingCartCheckout'
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined'
import MoreIcon from '@mui/icons-material/MoreVert'
import Container from '@mui/material/Container'
import Drawer from '@mui/material/Drawer'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ClickAwayListener, ListItemButton, Tab } from '@mui/material'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import { createTheme, height } from '@mui/system'
import ListItemText from '@mui/material/ListItemText'
import {
  Modal,
  FormControl,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormLabel,
  FormHelperText,
  TextField,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Popover,
  popoverClasses,
  Alert,
  Select,
  Icon,
} from '@mui/material'
import { textAlign, width } from '@mui/system'
import Cookies from 'js-cookie'
import UserModel from '../models'
import NotificationList from '../notifications'
import { SwipeableDrawer, Grid, Paper, Tabs, Slider } from '@mui/material'
import {
  Add,
  AppRegistration,
  CheckOutlined,
  Delete,
  HdrPlusOutlined,
  Info,
  LinkSharp,
  LoginOutlined,
  LoginSharp,
  Logout,
  ShoppingCartCheckoutSharp,
  Home,
  ShoppingCartOutlined,
  AccountBalanceWalletOutlined,
  Sell,
  ChatBubble,
  Chat,
  ShoppingBagOutlined,
  FavoriteBorderOutlined,
  ShoppingCartCheckoutOutlined,
  PaymentOutlined,
  AttachMoneyOutlined,
  ChatOutlined,
  DashboardOutlined,
  SellOutlined,
  PeopleOutline,
  CoffeeOutlined,
  InfoOutlined,
  ContactPageOutlined,
  HomeOutlined,
  BoltOutlined,
  LightModeOutlined,
  DarkModeOutlined,
  WalletOutlined,
  MenuOutlined,
} from '@mui/icons-material'
import { Input, InputLabel, NativeSelect } from '@mui/material'
import Divider from '@mui/material/Divider'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { message } from 'antd'
import { Link } from 'react-router-dom'
import AppContext from '../app_context'
import SearchHighlights from './sarchInput'
import { motion } from 'framer-motion'
import APP_ENV from '../../env'
import { useDispatch } from 'react-redux'
import { UserSelector } from '../../store/auth/selectors'
import { gotoTab, switchTabs } from '../../store/core/reducer'
import {
  SelectCurrentTabActive,
  SelectCurrentTabName,
  SelectCurrentTabTabs,
} from '../../store/core/selectors'

const urlRefCodeParams = new URLSearchParams(window.location.search).get('ref')
if (urlRefCodeParams) {
  localStorage.setItem('__refCode__', urlRefCodeParams)
  window.location = '/sign-up'
}
const defaultTheme = createTheme({
  palette: {
    primary: 'red',
    secondary: 'black',
  },
})

var loginFormModalStyle = {
  // width: '50%'
  backgroundColor: '#fff',
  marginTop: '40px',
  textAlign: 'center',
  boxShadow: '2px 3px 4px 5px grey',
  height: '100%',
  border: '0.1px solid green',
}
const loginFormInputStyle = {
  padding: '10px',
  // width: '400px'
}
const sideBarListItem = {
  width: 'inherit',
  margin: '0',
  color: 'white',
  border: '0.2px solid rgb(21, 24, 26)',
}

const sellButtonStyle = {
  height: 'inherit',
  color: 'inherit',
  borderRadius: '2px',
  fontSize: '15px',
  width: '100px',
  paddingBottom: '6px',
  paddingTop: '6px',
  border: '0.2px solid black',
}
const sideBarListStyle = {
  cursor: 'pointer',
  userSelect: 'none',
  marginTop: '10px',
  padding: '0',
}
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha(theme.palette.common.white, 0.15),
  // '&:hover': {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  // },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))
const csrftoken = Cookies.get('csrftoken') // Cookies from Django Domain

// function getCookie(name) {
//     let cookieValue = null;
//     if (document.cookie && document.cookie !== '') {
//         const cookies = document.cookie.split(';');
//         for (let i = 0; i < cookies.length; i++) {
//             const cookie = cookies[i].trim()

//             //if this cookie string begin with the name we want
//             if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//                 break;
//             }
//         }

//     }
//     return cookieValue

// }

const userInst = new UserModel(null, null)

export default React.forwardRef(function Header(props, ref) {
  const dispatch = useDispatch()
  React.useImperativeHandle(ref, () => ({
    updateAll() {
      updateUserInfo()
    },
  }))
  const navigate = useNavigate()

  const [User, setUser] = React.useState({})

  const [UserProfile, setUSerProfile] = React.useState({})
  const [UserNotifications, setUSerNotifications] = React.useState([])
  const [UserCart, setUSerCart] = React.useState([])
  const [loginModalHandler, setloginModalHandler] = React.useState(false)
  const [signupModalHandler, setSignupModalHandler] = React.useState(false)
  const [sideBarDrawer, setSideBarDrawer] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorNonUserEl, setAnchorNonUserEl] = React.useState(null)
  const [anchorElPopOverError, setanchorElPopOverError] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [loginError, setLoginError] = React.useState(false)
  const [forgotPassword, setForgotPassword] = React.useState(false)
  const [signUpErrors, setSignUpErrors] = React.useState(null)
  const [signUpWelcome, setSignUpWelcome] = React.useState(false)
  const [loginWelcome, setLoginWelcome] = React.useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const notificationAnchorRef = React.useRef(null)
  const isMenuNonUserOpen = Boolean(anchorNonUserEl)
  const [openHeaderNotification, setOpenHeaderNotification] =
    React.useState(false)
  const [openCart, setOpenCart] = React.useState(false)
  const [displayErrorOpen, setDisplayErrorOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [seller, setSeller] = React.useState(false)
  const [errorMessageTitle, setErrorMessageTitle] =
    React.useState('We are Sorry!')
  const { client, BASE_URL, authClient } = React.useContext(AppContext)
  const [resetPEmail, setResetPEmail] = React.useState('')
  const tabRef = React.useRef(null)

  const currentPage = SelectCurrentTabActive()
  // setCurrentPage] = React.useState('home')
  const [isSticky, setIsSticky] = React.useState(false)
  const loggedInUser = UserSelector()
  const currentTabName = SelectCurrentTabName()
  const currentTabItems = SelectCurrentTabTabs()

  // const isMobileNonUserMenuOpen = Boolean(mobileMoreAnchorEl)

  const updateUserInfo = () => {
    if (localStorage.getItem('AccgToken') !== null) {
      client
        .get('/auth/users/me/')
        .then(response => {
          client
            .get('/user/api/profile/' + response.data.username)
            .then(response => {
              if (response.data) {
                setUSerProfile(response.data)
                setSeller(response.data.seller)
              }
            })
            .catch(err => {
              message.info(err)
            })
          if (response.data) {
            // console.log(response.data)
            setUser(response.data)
          }
        })
        .catch(error => {
          // localStorage.removeItem("AccgToken");
          // message.error('Please signup/login');
          // navigate('/sign-in');
          // alert(error)
        })

      client
        .get('/user/api/notifications')
        .then(response => {
          if (response.data) {
            setUSerNotifications(response.data)
          }
        })
        .catch(err => {
          console.log(err)
        })

      client
        .get('/user/api/cart')
        .then(response => {
          if (response.data) {
            // console.log(response.data)
            setUSerCart(response.data)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  React.useEffect(
    function () {

      if (localStorage.getItem('AccgToken') !== null) {
        updateUserInfo()
      } else {
        setUSerCart([])
      }

      return
    },
    [currentPage],
  )

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting)
      },
      { threshold: 0.5 },
    )

    if (tabRef.current) {
      observer.observe(tabRef.current)
    }

    return () => {
      if (tabRef.current) {
        observer.unobserve(tabRef.current)
      }
    }
  }, [isSticky, tabRef.current, currentPage])

  const user = () => {
    if (User.username === null && User.email === null) {
      return null
    } else {
      return User.username
    }
  }
  const handleLoginErrorClose = () => {
    setanchorElPopOverError(null)
  }
  const handleLoginWelcomeClose = () => {
    setLoginWelcome(false)
    handleLoginClose()
  }
  const handleLoginWelcomeOpen = () => {
    setLoginWelcome(true)
    handleLoginClose()
  }
  const handleSignUpWelcomeOpen = () => {
    setSignUpWelcome(true)
    handleSignupClose()
  }
  const handleSignUpWelcomeClose = () => {
    setSignUpWelcome(false)
    handleSignupClose()
  }
  const handleSignupModal = () => {
    setSignupModalHandler(true)
    handleNonUserMenuClose()
  }
  const handleSignupClose = () => {
    setSignupModalHandler(false)
    handleNonUserMenuClose()
  }
  const handleLoginModal = () => {
    setloginModalHandler(true)
    handleNonUserMenuClose()
  }
  const handleLoginClose = () => {
    setloginModalHandler(false)
    handleNonUserMenuClose()
  }
  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleProfileNonUserMenuOpen = event => {
    setAnchorNonUserEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)

    handleMobileMenuClose()
  }

  const handleNonUserMenuClose = () => {
    setAnchorNonUserEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }
  const handleMainMenuOpen = event => {
    setSideBarDrawer(!sideBarDrawer)
  }
  const openForgotPassword = event => {
    setForgotPassword(true)
  }
  const handleForgotPasswordClose = () => {
    setForgotPassword(false)
  }
  const handleLogout = () => {
    setUser(null, null, false)
    localStorage.removeItem('AccgToken')
    window.location.reload()
    setTimeout(() => {
      message.success('logged-out')
    }, 2000)
  }
  const handleopenHeaderNotification = event => {
    setOpenHeaderNotification(!openHeaderNotification)
    handleMobileMenuClose()
  }
  const handleHeaderNotificationClose = () => {
    setOpenHeaderNotification(false)
  }

  const handleOpenCart = () => {
    handleMobileMenuClose()

    setOpenCart(true)
  }
  const handleCartCloseFunc = () => {
    setOpenCart(false)
  }

  const displayErrorClose = () => {
    setDisplayErrorOpen(false)
  }
  const turnOffSell = () => {
    client
      .post('/user/api/profile/' + User.username, { seller: false })
      .then(response => {
        if (response.data) {
          setUSerProfile(response.data)
          setSeller(response.data.seller)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  const handleSell = () => {
    if (seller) {
      navigate('/sell')
    } else {
      setErrorMessage(
        'Please Turn-On SELLER-MODE Switch On The Side Menu or Dashboard',
      )
      setErrorMessageTitle('Not Yet A Seller!')
      setDisplayErrorOpen(true)
      setSideBarDrawer(true)
    }
  }

  const deleteItemFromCart = id => {
    if (localStorage.getItem('AccgToken') !== null) {
      client
        .put('/user/api/cart', { data: { item_id: id, method: 'delete' } })
        .then(response => {
          if (response.data) {
            setUSerCart(response.data.cart)
            updateUserInfo()
            if (response.data.msg) {
              setErrorMessageTitle('Sorry!')

              setErrorMessage(response.data.msg)
              setDisplayErrorOpen(true)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setUSerCart([])
    }
    return
  }

  const subtractFromCart = id => {
    if (localStorage.getItem('AccgToken') !== null) {
      client
        .put('/user/api/cart', { data: { item_id: id, method: 'subtract' } })
        .then(response => {
          if (response.data) {
            setUSerCart(response.data.cart)
            updateUserInfo()
            if (response.data.msg) {
              setErrorMessageTitle('Sorry!')
              setErrorMessage(response.data.msg)
              setDisplayErrorOpen(true)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setUSerCart([])
    }
    return
  }

  const addToCart = id => {
    if (localStorage.getItem('AccgToken') !== null) {
      client
        .put('/user/api/cart', { data: { item_id: id, method: 'add' } })
        .then(response => {
          if (response.data) {
            setUSerCart(response.data.cart)
            updateUserInfo()
            if (response.data.msg) {
              setErrorMessageTitle('Sorry!')
              setErrorMessage(response.data.msg)
              setDisplayErrorOpen(true)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setUSerCart([])
    }
    return
  }

  const mountUser = (username, password) => {
    if (localStorage.getItem('AccgToken') !== null) {
      client
        .get('/auth/users/me/', {
          auth: {
            username: username,
            password: password,
          },
        })
        .then(response => {
          if (response.data) {
            const newUSer = new UserModel(
              response.data.username,
              response.data.email,
              true,
            )
            setUser(newUSer)
          }
        })
        .catch(error => {
          message.error('Please signup/login')
        })
    }
  }

  function handleLoginApi(event) {
    event.preventDefault()
    var loginFormGet = document.getElementById('LoginForm')
    var loginForm = new FormData(loginFormGet)
    var emptyField = false

    loginForm.forEach(inputs => {
      if (inputs === '') {
        emptyField = true
      }
    })

    if (emptyField) {
      setLoginError('Please fill in the blank fields !')
    } else {
      authClient
        .post('/auth/token/login/', loginForm)
        .then(response => {
          if (response.data.auth_token) {
            localStorage.setItem('AccgToken', response.data.auth_token)

            mountUser(loginForm.get('username'), loginForm.get('password'))

            handleLoginWelcomeOpen()
          } else {
            document.getElementById('LoginContainer').style.border =
              '0.5px solid red'
            setLoginError('invalid credentials!')
          }
        })
        .catch(err => {
          document.getElementById('LoginContainer').style.border =
            '0.5px solid red'
          if (err.response.data.detail) {
            setLoginError(err.response.data.detail)
          }
          if (err.response.data.non_field_errors) {
            setLoginError(err.response.data.non_field_errors)
          }
        })
    }
  }
  function handleForgotPasswordApi() {
    const email = document.getElementById('resetPasswordEmail')
    if (email.value) {
      authClient
        .post('/auth/users/reset_password/', { email: email.value })
        .then(resp => {
          message.success('Password Reset Done')

          setForgotPassword(false)
        })
        .catch(err => {
          message.error(err.data.detail)
        })
    } else {
      message.info('Enter Email Please')
    }
  }
  function handleReferrer(val) {
    val['api_key'] = APP_ENV.PLATFORM_WALLET_API_KEY
    message.success('Account created.Login to continue')
    authClient
      .post('/user/api/referrals-handle', val)
      .then(resp => {
        message.success('referred by : ' + resp.data.referrer_username)
        // this.setState({ loading: false })
        window.location.href = 'sign-in'
      })
      .catch(err => {
        message.error(err)
      })
  }
  function signUpUser(event) {
    // alert('i am here')
    event.preventDefault()
    var signUpFormGet = document.getElementById('SignUpForm')
    var inputs = signUpFormGet.getElementsByTagName('input')
    var holdReq = false
    // var errorCount = 0
    for (let i = 0; i < inputs.length; i++) {
      if (
        (inputs[i].type === 'checkbox' && inputs[i].checked === false) ||
        (inputs[i].type !== 'checkbox' && inputs[i].value === '')
      ) {
        if (inputs[i].required === true) {
          inputs[i].style.border = '0.4px solid red'
          var smallErro = inputs[i].parentElement.getElementsByTagName('small')
          if (smallErro.length === 0) {
            var errorEl = inputs[i].parentElement.getElementsByTagName('small')

            var smallError = document.createElement('small')
            smallError.innerText = '*Field Required'

            inputs[i].parentElement.append(smallError)
          }

          holdReq = true
          break
        } else {
          holdReq = false
        }
      }
    }
    var SignUPForm = new FormData(signUpFormGet)
    const resetVal = () => {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = SignUPForm.get(inputs[i].name)

        // alert(SignUPForm.values)
      }
    }
    if (SignUPForm.get('password') != SignUPForm.get('password1')) {
      setSignUpErrors('Passwords do not match.')
      resetVal()

      holdReq = true
    }
    if (holdReq === false) {
      // alert('sending req')
      authClient
        .post('/auth/users/', SignUPForm)
        .then(response => {
          handleSignUpWelcomeOpen()
          setloginModalHandler(true)
          if (response.status == 201 || response.status == 200) {
            handleReferrer(SignUPForm)
          }
        })
        .catch(err => {
          if (err.response.data.username) {
            resetVal()

            setSignUpErrors(err.response.data.username[0])
          }
          if (err.response.data.email) {
            resetVal()
            setSignUpErrors(err.response.data.email[0])
          }
          if (err.response.data.detail) {
            resetVal()

            setSignUpErrors(err.response.data.detail[0])
          }
          if (err.response.data.password) {
            resetVal()

            setSignUpErrors(err.response.data.password[0])
          }
        })
    }
  }
  const SignupModal = () => {
    const formModalSignup = (
      <Modal
        aria-labelledby="sign-up"
        aria-describedby="simple-modal-description"
        open={signupModalHandler}
        onClose={handleSignupModal}
      >
        <div>
          <button
            type="button"
            style={{ fontSize: '20px' }}
            className="close"
            aria-label="Close"
            onClick={handleSignupClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Container maxWidth="sm">
            <Card style={loginFormModalStyle}>
              <h1>Create Account</h1>
              <div>
                <small style={{ color: 'red' }}>{signUpErrors}</small>
              </div>

              <FormControl component="fieldset">
                <FormLabel component="legend"></FormLabel>
                <form id="SignUpForm" method="post">
                  <FormGroup>
                    <TextField
                      id="user_username"
                      label="Username"
                      variant="outlined"
                      type="text"
                      name="username"
                      required={true}
                      style={loginFormInputStyle}
                    />
                  </FormGroup>

                  <FormLabel component="legend"></FormLabel>
                  <FormGroup>
                    <TextField
                      id="user_email"
                      label="Email"
                      variant="outlined"
                      type="email"
                      name="email"
                      required={true}
                      style={loginFormInputStyle}
                    />

                    <FormLabel component="legend"></FormLabel>
                  </FormGroup>
                  {/* <FormGroup>
                                        <TextField
                                            id="user_firstName"
                                            label="First Name"
                                            variant="outlined"
                                            type='text'
                                            name='first_name'
                                            required={true}

                                            style={loginFormInputStyle}

                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <TextField
                                            id="user_lastName"
                                            label="Last Name"
                                            variant="outlined"
                                            type='text'
                                            name='last_name'
                                            required={true}

                                            style={loginFormInputStyle}

                                        />
                                    </FormGroup> */}

                  <FormLabel component="legend"></FormLabel>
                  <FormGroup>
                    <TextField
                      id="user_password"
                      label="Password"
                      variant="outlined"
                      type="password"
                      name="password"
                      required={true}
                      style={loginFormInputStyle}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      id="user_password2"
                      label="Confirm Password"
                      variant="outlined"
                      type="password"
                      name="password1"
                      required={true}
                      style={loginFormInputStyle}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      id="user_refcode"
                      label="Referral Code (Optional)"
                      variant="outlined"
                      type="text"
                      name="referral_code"
                      defaultValue={localStorage.getItem('__refCode__')}
                      style={loginFormInputStyle}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      label="I Agree to AccountGig Terms and Conditions."
                      control={
                        <Checkbox
                          value="I agree"
                          // onChange={ }
                          color="primary"
                          required={true}
                        />
                      }
                    />

                    <FormHelperText>
                      {' '}
                      <Link to="/terms&C" style={{ cursor: 'pointer' }}>
                        {' '}
                        Read Terms and conditions{' '}
                      </Link>
                    </FormHelperText>
                  </FormGroup>
                  <br></br>
                </form>
                <FormGroup style={{ paddingBottom: '10px' }}>
                  <Button
                    onClick={signUpUser}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    SignUp
                  </Button>
                </FormGroup>

                {/* <FormHelperText></FormHelperText> */}
              </FormControl>
              {/* <form action='/login' method='post'>


                            </form> */}
            </Card>
          </Container>
        </div>
      </Modal>
    )
    return <>{formModalSignup}</>
  }

  const LoginModal = () => {
    const formModal = (
      <Modal
        aria-labelledby="sign-up"
        aria-describedby="simple-modal-description"
        open={loginModalHandler}
        onClose={handleLoginClose}
      >
        <div>
          <button
            type="button"
            style={{ fontSize: '20px' }}
            className="close"
            aria-label="Close"
            onClick={handleLoginClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Container maxWidth="sm">
            <Card style={loginFormModalStyle} id="LoginContainer">
              <h1>User Login</h1>
              <form id="LoginForm" method="post">
                <p style={{ color: 'red' }}>{loginError}</p>
                <FormControl component="fieldset">
                  <FormGroup aria-required="true">
                    <TextField
                      id="user_username"
                      label="username"
                      variant="outlined"
                      type="text"
                      name="username"
                      aria-required="true"
                      style={loginFormInputStyle}
                    />
                  </FormGroup>

                  <FormGroup aria-required="true">
                    <TextField
                      id="user_password"
                      label="password"
                      variant="outlined"
                      type="password"
                      name="password"
                      aria-required="true"
                      style={loginFormInputStyle}
                    />

                    <FormControlLabel
                      label="Remember me?"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          name="remember"
                          //   onChange={}
                          color="secondary"
                        />
                      }
                    />
                    <br></br>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLoginApi}
                    >
                      Login
                    </Button>

                    <Link
                      onClick={openForgotPassword}
                      style={{ float: 'right' }}
                      to="/user/forgot/password"
                    >
                      Forgot password!
                    </Link>
                  </FormGroup>

                  <br></br>
                  <br></br>
                  <br></br>
                  {/* <FormHelperText></FormHelperText> */}
                </FormControl>
              </form>
              {/* <form action='/login' method='post'>


                            </form> */}
            </Card>
          </Container>
        </div>
      </Modal>
    )
    return <>{formModal}</>
  }
  const ForgotPasswordModal = () => {
    return (
      <>
        <Dialog
          open={forgotPassword}
          onClose={handleForgotPasswordClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Retrieve Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We are so sorry! <br></br>
              Please enter your email and a recovery link will be sent to you
              for your password reset in a couple of minutes.
              <br /> Note : Enter Email you used in opening the account.
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="resetPasswordEmail"
              label="Email Address"
              type="email"
              name="email"
              defaultValue={resetPEmail}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleForgotPasswordClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleForgotPasswordApi} color="primary">
              Reset Password
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const SignUpWelcome = () => {
    return (
      <>
        <Dialog
          open={signUpWelcome}
          onClose={handleSignUpWelcomeClose}
          aria-labelledby="NewSignup"
        >
          <DialogTitle id="welcom-new-user"></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>Welcome!</p>
              We are here to serve you to our best effort. Don't hesitate to
              take advantage of all our services and contact us using the
              contact page information anytime you wish to have our help. Thank
              you.
              <br />
              <b>Please login to continue..</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSignUpWelcomeClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const LoginWelcome = () => {
    return (
      <>
        <Dialog
          open={loginWelcome}
          onClose={handleLoginWelcomeClose}
          aria-labelledby="NewSignup"
        >
          <DialogTitle id="welcom-new-user"></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>It's good to have you back, {User.username}.</p>
              <p>
                We believe you are enjoying our services.Have a good time with
                us!
              </p>
              Best Regards !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLoginWelcomeClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const displayErrorDialogue = (
    <div>
      <Dialog open={displayErrorOpen} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {errorMessageTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={displayErrorClose}>
            Ok
          </Button>

          {/* <Button onClick={removeFromCart} style={{ backgroundColor: 'red', fontSize: '6x', color: 'white' }}>Remove From Cart</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
  const getNewNotifications = () => {
    const newNotification = Array()
    if (UserNotifications.length >= 1) {
      for (let i = 0; i < UserNotifications.length; i++) {
        if (UserNotifications[i].seen === false) {
          newNotification.push(UserNotifications[i])
        }
      }
    }

    return newNotification
  }
  const menuId = 'primary-search-account-menu'
  const drawerWidth = 350

  const renderSideMenu = (
    <>
      <Drawer
        variant="persistent"
        anchor='bottom'

        sx={{
          display: { xs: 'block', sm: 'block' },

          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
        open={sideBarDrawer}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            left: '20px',
            fontSize: '10px',
            zIndex: '10',
            padding: '3px',
            // minWidth: "40px",
            width: '50px',
            borderRadius: '10px',
            // backgroundColor: "teal",
            color: 'whitesmoke',
            // position: "fixed",
            top: '124px',
          }}
          onClick={handleMainMenuOpen}
        >
          <b>close</b>
        </Button>
        <Container maxWidth="lg">
          <CardHeader
            avatar={
              <Avatar src={UserProfile.display_picture} aria-label=""></Avatar>
            }
            title={user() ? User.username : 'User'}
            subheader={user() ? User.email : 'Anonymous'}
            style={{
              width: '300px',
              border: '0.1px solid rgb(2, 24, 26)',
              marginTop: '5px',
              borderRadius: '1px',
            }}
          />
          <div>
            <Toolbar />

            <Divider />

            {user() ? (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => {
                          seller ? turnOffSell() : navigate('/sell')
                          setSideBarDrawer(false)
                        }}
                        checked={seller}
                      />
                    }
                    label="Seller"
                  />
                </FormGroup>
                <List>
                  {[
                    {
                      name: ' Chats',
                      icon: (
                        <Badge
                          color="success"
                          badgeContent={UserProfile.unread_messages}
                        >
                          {' '}
                          <ChatOutlined />
                        </Badge>
                      ),
                      url: '/user/chats',
                    },
                    {
                      name: 'DashBoard',
                      icon: <DashboardOutlined />,
                      url: '/dashboard',
                    },
                    {
                      name: 'My Products',
                      icon: <SellOutlined />,
                      url: '/seller/products',
                    },
                    {
                      name: 'SMM Boostify',
                      icon: <BoltOutlined />,
                      url: '/smm/boosting',
                    },
                    {
                      name: 'WishList',
                      icon: <FavoriteBorderOutlined />,
                      url: '/wishlist',
                    },
                    {
                      name: 'Cart',
                      icon: <ShoppingBagOutlined />,
                      url: '/cart',
                    },
                    {
                      name: 'Wallet',
                      icon: <AccountBalanceWalletOutlined />,
                      url: '/user/wallet',
                    },

                    {
                      name: 'My Orders',
                      icon: <ShoppingCartCheckoutOutlined />,
                      url: '/orders',
                    },
                    {
                      name: 'Billing',
                      icon: <PaymentOutlined />,
                      url: '/billing',
                    },
                    {
                      name: 'Refer & Earn',
                      icon: <AttachMoneyOutlined />,
                      url: '/refer-and-earn',
                    },
                  ].map((link, index) => (
                    <ListItem
                      key={index}
                      style={
                        (link.name === 'DashBoard' ||
                          link.name === 'My Products') &&
                          seller === false
                          ? { display: 'none' }
                          : { display: 'block' }
                      }
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          navigate(link.url)
                          setSideBarDrawer(false)
                        }}
                      >
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText primary={link.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <></>
            )}
            <Divider />

            <List>
              {[
                { name: 'Home', icon: <HomeOutlined />, url: '/' },
                {
                  name: 'Join our Community',
                  icon: <PeopleOutline />,
                  url: '/join-our-community',
                },
                {
                  name: 'Buy Us Coffee',
                  icon: <CoffeeOutlined />,
                  url: '/buy-us-coffee',
                },
                {
                  name: 'Platform Informations',
                  icon: <InfoOutlined />,
                  url: '/about-us',
                },
                !user()
                  ? {
                    name: 'Contact Us',
                    icon: <ContactPageOutlined />,
                    url: '/contact-us',
                  }
                  : '',
                !user()
                  ? {
                    name: 'Sign-up',
                    icon: <AccountCircleOutlined />,
                    url: '/sign-up',
                  }
                  : '',
                !user()
                  ? {
                    name: 'Login',
                    icon: <LoginOutlined />,
                    url: '/sign-in',
                  }
                  : ' ',
                user()
                  ? { name: 'Logout', icon: <Logout />, url: '/sign-out' }
                  : ' ',
              ].map((link, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(link.url)
                      setSideBarDrawer(false)
                    }}
                  >
                    <ListItemIcon>{link.icon}</ListItemIcon>
                    <ListItemText primary={link.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        </Container>
      </Drawer>
    </>
  )
  var cartSum = 0
  const cartItemsList = (
    <>
      <List sx={{ width: 400, bgcolor: 'background.paper' }}>
        {UserCart.length != 0 ? (
          UserCart.map(item => {
            cartSum += parseInt(item.product_sum_amount)
            if (item.account_sold === true || item.account_available_Qty <= 0) {
              cartSum -= item.product_sum_amount
            }
            return (
              <>
                <ListItem
                  style={{
                    // backgroundColor: "rgba(150,200,122,0.1)",
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  alignItems="flex-start"
                  disabled={
                    item.account_sold === true ||
                      !item.account_available_Qty >= 1
                      ? true
                      : false
                  }
                >
                  <ListItemAvatar
                    onClick={() =>
                      navigate(`/p/${item.account_slug}/${item.account_id}`)
                    }
                    style={{ padding: '4px', cursor: 'pointer' }}
                  >
                    {item.images.length != 0 ? (
                      <Antavatar
                        shape="square"
                        size={77}
                        alt={item.account_title}
                        src={item.images[0].url}
                      />
                    ) : (
                      <Antavatar
                        shape="square"
                        size={77}
                        alt={item.account_title}
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    style={{ width: '100%', cursor: 'pointer' }}
                    onClick={() =>
                      navigate(`/p/${item.account_slug}/${item.account_id}`)
                    }
                    primary={
                      <p
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          lineHeight: '1.5em',
                          height: '3em',
                        }}
                      >
                        {item.account_title}
                      </p>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <small> {item.account_category}</small>
                        </Typography>
                        <br></br>
                        {!item.account_available_Qty >= 1 ||
                          item.account_sold == true ? (
                          <small
                            style={{
                              float: 'left',
                              backgroundColor: 'red',
                              color: 'white',
                              padding: '4px',
                            }}
                          >
                            Sold out
                          </small>
                        ) : (
                          <small
                            style={{
                              float: 'left',
                              backgroundColor: 'lightgreen',
                              color: 'white',
                              padding: '4px',
                            }}
                          >
                            on Sale
                          </small>
                        )}
                      </React.Fragment>
                    }
                  />

                  <div
                    style={{
                      right: '0',
                      top: '0',
                      zIndex: '10',
                      position: 'absolute',
                      margin: '3px',
                    }}
                  >
                    <IconButton
                      onClick={() => deleteItemFromCart(item.id)}
                      style={{
                        width: '30px',
                        padding: '0',
                        borderRadius: '0',
                        color: 'darkred',
                      }}
                      aria-label="remove"
                      dataId={item.id}
                    >
                      <Delete style={{ width: '20px' }}></Delete>
                    </IconButton>
                  </div>
                  <div
                    style={{
                      margin: '10px',
                      padding: '10px',
                      borderRadius: '4px',
                      textAlign: 'right',
                    }}
                  >
                    {/* <small style={{ float: 'left', display: 'flex', top: '0' }}>quantity</small> */}

                    <div
                      style={{
                        width: '60px',
                        display: 'inline-flex',
                        marginBottom: '5px',
                      }}
                    >
                      <IconButton
                        onClick={() => addToCart(item.id)}
                        dataId={item.id}
                        aria-label="Add"
                        disabled={
                          item.quantity >= item.account_available_Qty
                            ? true
                            : false
                        }
                        style={{
                          padding: '0',
                          marginRight: '2px',
                          width: '20px',
                          height: '22px',
                          border: '1px solid black',
                          borderRadius: '2px',
                        }}
                      >
                        +
                      </IconButton>
                      <div style={{ float: 'right' }}>
                        <b>{item.quantity}</b>
                        <IconButton
                          onClick={() => subtractFromCart(item.id)}
                          dataId={item.id}
                          disabled={item.quantity <= 1 ? true : false}
                          aria-label="Add"
                          style={{
                            width: '20px',
                            padding: '0',
                            marginLeft: '6px',
                            height: '22px',
                            border: '1px solid black',
                            borderRadius: '2px',
                          }}
                        >
                          -
                        </IconButton>
                      </div>
                    </div>

                    <Typography variant="button" style={{ float: 'right' }}>
                      <Paper
                        elevation={1}
                        style={{
                          // backgroundColor: "darkgreen",
                          color: 'white',
                          padding: '5px',
                        }}
                      >
                        <Typography
                          style={{ color: 'white' }}
                          variant="headline"
                          component="h3"
                        >
                          {item.product_sum}
                        </Typography>
                      </Paper>
                    </Typography>
                  </div>
                </ListItem>

                <Divider variant="inset" component="li" />
              </>
            )
          })
        ) : (
          <>
            <div
              style={{
                with: '100%',
                textAlign: 'center',
                padding: '40px',
                marginTop: '100px',
              }}
            >
              <h4>No item in your Cart.</h4> <br></br>
              <ShoppingCartOutlined
                style={{ width: '200px', fontSize: '50px' }}
              ></ShoppingCartOutlined>
            </div>
          </>
        )}
      </List>
    </>
  )
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate('/profile/' + User.username)
          return handleMenuClose()
        }}
      >
        <IconButton aria-label="profile">
          <Avatar src={UserProfile.display_picture}></Avatar>
        </IconButton>
        <p> Profile </p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate('/Orders')
          return handleMenuClose()
        }}
      >
        <IconButton aria-label="view orders | checkout">
          <ShoppingCartCheckoutOutlined />{' '}
        </IconButton>{' '}
        <p> My Orders</p>
      </MenuItem>
    </Menu>
  )
  const renderNonUserMenu = (
    <Menu
      anchorEl={anchorNonUserEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuNonUserOpen}
      onClose={handleNonUserMenuClose}
    >
      <MenuItem onClick={() => navigate('/sign-in')}>
        {' '}
        <IconButton>
          <LoginOutlined />
        </IconButton>{' '}
        <p> Login</p>
      </MenuItem>
      <MenuItem onClick={() => navigate('/sign-up')}>
        <IconButton>
          <AccountCircleOutlined />{' '}
        </IconButton>
        <p> Create Account</p>
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = user() ? (
    <>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleOpenCart}>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={UserCart.length} color="error">
              <ShoppingCartOutlined />
            </Badge>
          </IconButton>
          <p>Cart</p>
        </MenuItem>

        <MenuItem onClick={handleopenHeaderNotification}>
          <IconButton
            size="large"
            // ref={notificationAnchorRef}

            aria-label={`show ${getNewNotifications().length
              } new notifications`}
            color="inherit"
          >
            <Badge badgeContent={getNewNotifications().length} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <p>Notifications</p>
        </MenuItem>
        <NotificationList notification={UserNotifications}></NotificationList>

        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={() => navigate('/profile/' + User.username)}
          >
            <Avatar src={UserProfile.display_picture} />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <IconButton
            onClick={() => navigate('/sign-out')}
            size="large"
            aria-label="logout user"
            // aria-haspopup="true"
            color="inherit"
          >
            <LogoutRounded />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleOpenCart}>
          <IconButton
            size="large"
            aria-label="cart of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="false"
            color="inherit"
          >
            <Badge badgeContent={UserCart.length} color="error">
              <ShoppingCartOutlined />
            </Badge>
          </IconButton>
          <p>Cart</p>
        </MenuItem>
        <MenuItem onClick={() => navigate('/sign-in')}>
          <IconButton
            size="large"
            aria-label="login to your account"
            color="inherit"
          >
            <LoginOutlined />
          </IconButton>
          <p>Login</p>
        </MenuItem>

        <MenuItem onClick={() => navigate('/sign-up')}>
          <IconButton size="large" aria-label="sign-up" color="inherit">
            <AccountCircleOutlined />
          </IconButton>
          <p>Create Account</p>
        </MenuItem>
      </Menu>
    </>
  )
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2, zIndex: 300 }}
              onClick={handleMainMenuOpen}
            >
              <MenuIcon
                sx={{ pointer: 'cursor' }}
                onClick={handleMainMenuOpen}
              />{' '}
            </IconButton>

            <Box sx={{ flexGrow: 0.7, position: 'relative' }} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="container"
              alignContent="center"
              alignItems="center"
              justify="center"
              style={{ textAlign: 'center', margin: 'auto' }}
              sx={{ position: { md: 'absolute' }, left: 0, right: 0 }}
            >
              <IconButton
                onClick={() => {
                  navigate('/')
                  message.success('😁AccountGig Got You!😉')
                }}
                aria-label="accountgig"
              >
                <img width="100px" src={mainLogo}></img>
              </IconButton>
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'flex', md: 'block' } }}>
              <IconButton
                size="large"
                aria-label="Sell"
                aria-controls={mobileMenuId}
                aria-haspopup="false"
                style={{
                  ...sellButtonStyle,
                  fontFamily: 'monospace',
                  borderRadius: '13px',
                  backgroundColor: alpha('rgb(43, 61, 45)', 0.52),
                  border: 'none',
                  fontSize: '14px',
                }}
                onClick={handleSell}
                color="info"
              >
                Sell
              </IconButton>
            </Box>

            {user() ? (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={handleOpenCart}
                >
                  <Badge badgeContent={UserCart.length} color="error">
                    <ShoppingCartOutlined />
                  </Badge>
                </IconButton>
                <IconButton
                  ref={notificationAnchorRef}
                  aria-haspopup="true"
                  aria-controls={
                    openHeaderNotification ? 'composition-menu' : undefined
                  }
                  aria-expanded={openHeaderNotification ? 'true' : undefined}
                  onClick={handleopenHeaderNotification}
                  aria-label={`show ${
                    getNewNotifications().length
                  } new notifications`}
                  color="inherit"
                >
                  <Badge
                    badgeContent={getNewNotifications().length}
                    color="error"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>

                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircleOutlined />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={handleOpenCart}
                >
                  <Badge badgeContent={UserCart.length} color="error">
                    <ShoppingCartOutlined />
                  </Badge>
                </IconButton>

                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileNonUserMenuOpen}
                  color="inherit"
                >
                  <AccountCircleOutlined />
                </IconButton>
              </Box>
            )}

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                style={{ height: 'inherit' }}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'block',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
            margin: '0 auto',
            paddingTop: { xs: user() ? '0px' : '10px', md: '10px' },
            backgroundColor:
              props.mode === 'dark'
                ? alpha('#42524b', 0.45)
                : alpha('#8fb3a3', 0.45),
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            position: 'relative',
            width: { xs: '100%', md: '80vh' },
          }}
        >
          {loggedInUser.isAuthenticated && (
            <div ref={tabRef}>
              <>
                <Tabs
                  centered
                  sx={{
                    position: isSticky ? 'fixed' : 'relative',
                    top: 0,
                    zIndex: 20,
                    backgroundColor: isSticky
                      ? props.mode === 'dark'
                        ? '#2a3830'
                        : '#8fb3a3'
                      : '',
                    left: 0,
                    right: 0,
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    alignContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: "inherit",
                  }}
                  showLabels
                  value={currentPage}
                  onChange={(e, val) => {
                    dispatch(gotoTab(val))
                  }}
                >
                  {currentTabItems.map((tab, index) => (
                    <Tab
                      sx={{ fontSize: '10px' }}
                      value={index}
                      onClick={tab.action}
                      label={tab.title}
                      icon={
                        <Badge
                          color="primary"
                          badgeContent={tab.notif_count || 0}
                        >
                          {tab.icon}{' '}
                        </Badge>
                      }
                    />
                  ))}
                </Tabs>
              </>
            </div>
          )}
          <Box sx={{ display: 'inline-block', width: '80%' }}>
            <SearchHighlights />
          </Box>
          <Box sx={{ display: 'inline-block', width: '20%' }}>
            <IconButton
              sx={{
                position: { md: 'absolute' },
                backgroundColor: alpha('rgb(132,170,99)', 0.25),
                '&:hover': { backgroundColor: alpha('rgb(132,190,99)', 0.25) },
                top: { md: 15, xs: '-20px' },
              }}
              onClick={props.colorMode.toggleColorMode}
            >
              {props.mode === 'light' ? (
                <LightModeOutlined />
              ) : (
                <DarkModeOutlined color="grey" />
              )}
            </IconButton>
          </Box>
        </Box>
        {renderMobileMenu}
        {renderMenu}
        {renderNonUserMenu}
      </Box>

      {renderSideMenu}
      <NotificationList
        openNotification={openHeaderNotification}
        handleNotificationClose={handleHeaderNotificationClose}
        notification={UserNotifications}
      ></NotificationList>
      <SwipeableDrawer
        open={openCart}
        onClose={handleCartCloseFunc}
        variant="temporary"
        anchor="right"
        onTransitionEnd={updateUserInfo}
      >
        <CardHeader
          disableTypography
          avatar={
            <Avatar
              aria-label=""
              // style={{ backgroundColor: "rgb(37, 90, 48)" }}
            >
              <ShoppingCartOutlined></ShoppingCartOutlined>
            </Avatar>
          }
          action={<IconButton aria-label=""></IconButton>}
          title="Quick Cart"
          subheader=""
        />{' '}
        <Button
          variant="contained"
          color="primary"
          style={{
            fontSize: '10px',
            zIndex: '10',
            padding: '3px',
            // minWidth: "40px",
            float: 'right',
            width: '100px',
            borderRadius: '10px',
            color: 'whitesmoke',
            position: 'absolute',
            right: '10px',
            top: '15px',
            margin: '10px',
            // position: "fixed",
          }}
          onClick={() => {
            setOpenCart(false)
            navigate('/cart')
          }}
        >
          Open Full Cart
        </Button>
        <Divider inset />
        {cartItemsList}
        {UserCart.length != 0 ? (
          <>
            <div style={{ textAlign: 'right', marginRight: '30px' }}>
              <h4>Total : {cartSum}</h4>
            </div>

            <Divider variant="light" component="div" />

            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              style={{ marginTop: '10px' }}
            >
              <Button
                variant="contained"
                disabled={cartSum <= 0 ? true : false}
                size="large"
                style={{
                  // backgroundColor: "#39483a",
                  color: 'whitesmoke',
                }}
                onClick={() => {
                  navigate('/order/checkout')
                  setOpenCart(false)
                }}
                endIcon={<ShoppingCartCheckoutOutlined />}
              >
                Checkout
              </Button>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
          </>
        ) : (
          ''
        )}
      </SwipeableDrawer>
      <SignupModal></SignupModal>

      <LoginModal></LoginModal>
      <ForgotPasswordModal></ForgotPasswordModal>
      <SignUpWelcome></SignUpWelcome>
      <LoginWelcome></LoginWelcome>
      {displayErrorDialogue}
    </>
  )
})
