/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import React from 'react';
function LineChart(props) {
  const { Title, Paragraph } = Typography;
  const { user } = props
  const [orderData, setOrderData] = React.useState([])
  const [orderMonthData, setOrderMonthData] = React.useState([])
  const [productviewsMonthData, setProductviewsMonthData] = React.useState([])
  const [productViewsData, setProductViewsData] = React.useState([])




  const months = {

    1: 'Jan',
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  }

  React.useEffect(() => {
    const orderDatafromLoop = []
    const orderMonthfromLoop = []
    if (user.monthly_product_order) {

      const data = user.monthly_product_order
      for (let i = 0; i < data.length; ++i) {
        var item = data[i]
        orderDatafromLoop.push(item.sum)
        orderMonthfromLoop.push(months[item.month])
      }

    }

    setOrderData(orderDatafromLoop)
    setOrderMonthData(orderMonthfromLoop)
    const productviewsDatafromLoop = []
    const productviewsMonthfromLoop = []
    if (user.monthly_product_views) {

      const data = user.monthly_product_views
      for (let i = 0; i < data.length; ++i) {
        var item = data[i]
        productviewsDatafromLoop.push(item.sum)
        productviewsMonthfromLoop.push(months[item.month])
      }

    }

    setProductViewsData(productviewsDatafromLoop)
    setProductviewsMonthData(productviewsMonthfromLoop)

  }, [user])




  const lineChart = {
    series: [
      {
        name: "Views",
        data: productViewsData,
        offsetY: 0,
      },
      {
        name: "Orders",
        data: orderData,
        offsetY: 0,
      },
    ],

    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
      },

      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: [
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
            ],
          },
        },
        categories: orderMonthData,
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };


  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Product Views & Orders</Title>
          <Paragraph className="lastweek">
            {/* than last week <span className="bnb2">+30%</span> */}
          </Paragraph>
        </div>
        <div className="sales">
          <ul>
            <li>{<MinusOutlined />} Traffic</li>
            <li>{<MinusOutlined />} Sales</li>
          </ul>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
