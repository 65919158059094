
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListSubHeader from '@mui/material/ListSubheader';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { NotificationsActive } from '@mui/icons-material';
import axios from 'axios';
import { Box } from '@mui/material';
import APP_ENV from '../env';

const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json"


};

if (localStorage.getItem('AccgToken') !== null) {
    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


}

)
export default function NotificationList(props) {



    const LatestNotification = []
    const OldNotification = []
    const newNotIDs = []
    if (props.notification.length !== 0) {
        for (let i = 0; i < props.notification.length; i++) {
            let notEl = props.notification[i]
            if (notEl.seen === false) {
                let latestElements = (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      style={{ backgroundColor: "rgba(150,200,122,0.1)" }}
                    >
                      <ListItemAvatar>
                        <Avatar alt={notEl.title} src={notEl.image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={notEl.title}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              NEW
                            </Typography>
                            {`-- ${notEl.comment}`}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                );

                LatestNotification.push(latestElements)
                newNotIDs.push(notEl.id)

            }
            else {
                let oldElements = (
                    <>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt={notEl.title} src={notEl.image} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={notEl.title}

                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {notEl.dateCreated}
                                        </Typography>
                                        {`-- ${notEl.comment}`}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>
                )
                OldNotification.push(oldElements)

            }

        }

    }

    const applyReadChange = () => {
        client.put('/user/api/notifications', { data: { 'notification_id': newNotIDs } }).then(resp => {
            // console.log(resp)
        })

    }
    return (


        <Popper open={props.openNotification}
            role={undefined}
            // ref={props.notificationAnchorRefVar}
            disablePortal={false}
            onTransitionEnd={applyReadChange}
            placement="top-start"
            transition
            overf


            style={{ zIndex: "10", bottom: 'unset', right: '0', top: '67px', left: 'unset' }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start' ? 'right top' : 'left bottom',
                    }}
                >
                    <Paper>

                        <ClickAwayListener onClickAway={props.handleNotificationClose}>
<Box style={{overflowX:'scroll', overflowAnchor:'revert', overflowClipBox:'content-box', width:'400px', height:'600px'}}>
                            <List sx={{ width: '150%', padding: '20px', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListSubHeader component="div">

                                    <h4>Notifications <NotificationsActive style={{ float: 'right' }} />
                                    </h4>
                                    <Divider sx={{ my: 0.5 }} />

                                </ListSubHeader>

                                {LatestNotification ? LatestNotification :
                                    ''



                                }
                                {OldNotification.length !== 0 || LatestNotification.length !== 0 ?
                                    OldNotification : (

                                        <ListItem style={{ padding: '120px', textTransform: 'capitalize', opacity: '0.5', textAlign: 'center' }} alignItems="flex-start">

                                            <ListItemText
                                                primary="0 notification"
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    )
                                }
                                {/* 
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Oui Oui"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        Sandra Adams
                                                    </Typography>
                                                    {' — Do you have Paris recommendations? Have you ever…'}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem> */}
                            </List>
                            </Box>
                        </ClickAwayListener>
                       
                    </Paper>
                </Grow>)}
        </Popper>

    );
}
