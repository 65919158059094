import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { fetchActiveInHubOrder, fetchOrders } from './thunks'

const initialState = {
  status: 'idle',
  orders: [],
  activeOrder: null,
}

const orderReducer = createSlice({
  name: 'influenceHubOrder',
  initialState: initialState,
  reducers: {
    changeStatus(state, action) {
      state.status = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchActiveInHubOrder.fulfilled, (state, action) => {
        state.status = 'idle'
        state.activeOrder = action.payload;
      }) .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = 'idle'
        state.orders = action.payload;
      })
      .addMatcher(isAnyOf(fetchOrders.pending, fetchActiveInHubOrder.pending), (state, action) => {
        state.status = 'loading'
      })
      .addMatcher(isAnyOf(fetchOrders.rejected, fetchActiveInHubOrder.rejected), (state, action) => {
        state.status = 'failed'
      })
  },
})
export const { changeStatus } = orderReducer.actions
export default orderReducer.reducer
