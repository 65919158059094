import React, { Component, forwardRef } from "react";
import { Card,Space, DatePicker, Divider, Spin } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Switch, Modal, message, Upload, Input } from "antd";
import { useState,useImperativeHandle } from "react";
import { Box } from "@mui/system";
import { client, BASE_URL } from "./app_context";
import { AddCircle, Remove } from "@mui/icons-material";
import {Card as muiCard } from '@mui/material';
const Field = ({
  dictkey,
  value,
  index,
  submitProductFormFunc,
  handleDeleteField,
  disable_key = false,
}) => {
  const [formatedvalue, setValue] = useState(value);
  const [formatedkey, setKey] = useState(dictkey);

  return (
    <Box key={index}>
      <Form.Item
        name={"field-" + index}
        label={"Field name"}
        initialValue={formatedkey}
        key={index}
        rules={[
          {
            required: true,
            message:
              "Please Input Field name (it appears exactly this way when live)",
          },
        ]}
      >
        <Input
          onKeyUp={() => submitProductFormFunc()}
          disabled={disable_key === true ? true : false}
          placeholder={"Field name"}
          defaultValue={formatedkey.replace("_", " ")}
          onChange={(e) => setKey(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name={formatedkey.replace(" ", "_")}
        key={index}
        label={
          <>
            Enter {formatedkey}
            <br />
          </>
        }
        initialValue={formatedvalue}
        rules={[
          {
            required: true,
            message: "Please Input " + formatedkey,
          },
        ]}
      >
        <Input
          onKeyUp={() => submitProductFormFunc()}
          onChange={(e) => setValue(e.target.value)}
          placeholder={formatedkey.replace("_", " ")}
          defaultValue={formatedvalue}
        />
      </Form.Item>
      {disable_key === false ? (
        <Button
          onClick={() => handleDeleteField(index)}
          icon={<Remove />}
          style={{ color: "red" }}
        >
          {" "}
          Remove Field
        </Button>
      ) : (
        <small style={{ color: "tomato", fontWeight: "bolder" }}>
          Required field
        </small>
      )}
      <Divider />
    </Box>
  );
};

const SubProductForm = forwardRef( ( props, ref) => {
  
  
  
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageList, setImageList] = React.useState([]);
  const [draftedProduct, setDraftedProduct] = useState({});
  const [fields, setfields] = useState([]);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(true);
  const [privateProduct, setprivateProduct] = useState(false);
  const [productIndex, setproductIndex] = useState(props.index);
  
  React.useEffect(() => {
   
    if (props.type) {
    refreshPageData();}
  }, [productIndex]);
  
  
  
  const refreshPageData = () => {
    client
      .post(
        "/social/api/seller/products/" + props.productId + "/" + props.index,
        { product_type: props.type }
      )
      .then((resp) => {
        setproductIndex(resp.data.index);
        setprivateProduct(resp.data.private);
        setDraftedProduct(resp.data);

        if (resp.data.images) {
          setImageList(resp.data.images);
        }

        if (resp.data.fields) {
          setfields([]);
          for (let i = 0; i < resp.data.fields.length; i++) {
            const field = resp.data.fields[i];

            addField(
              field.key,
              field.value,
              field.required === true ? true : false,
              i
            );
          }
        }
        if (productIndex) {
          setloading(false);
        }
      })
      .catch((err) => setloading(false));
  };

  
  const handleCancel = () => setPreviewVisible(false);

  const addField = (key, value, disable_keyField, index) => {
    setloading(false);
    setTimeout(() => {
      if (fields.length >= 10) {
        message.info("Fields limit reached!");
      } else
        setfields((prevField) => [
          ...prevField,
          <Field
            disable_key={disable_keyField}
            submitProductFormFunc={submitFormFunc}
            value={value}
            dictkey={key}
            index={index}
            handleDeleteField={removeField}
          />,
        ]);
      setloading(false);
    }, 100);
  };

  const removeField = (index) => {
    client
      .put(
        "/social/api/seller/products/" + props.productId + "/" + productIndex,
        { "field-index": index, action: "delete-field" }
      )
      .then((resp) => {
        message.info(resp.data.msg);
        refreshPageData();
      })
      .catch((err) => message.error("An error occured"));
    // }
    var formPar = document.getElementById("product-" + productIndex);
    formPar.scrollIntoView();
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const handleImageRemove = (file) => {
    client
      .delete(
        "/social/api/UserFiles?type=images&name=" +
          file.name +
          "&slug=" +
          file.slug
      )
      .then((resp) => message.info("Deleted"))
      .catch((err) => message.error("something went wrong"));
  };

  const normFile = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const handleChange = ({ fileList: newFileList }) => {
    setImageList(newFileList);
    // console.log(newFileList)
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const submitFormFunc = async () =>{ 
    var formData = form.getFieldsValue();
    if(props.editMode !== true){
          // if(productIndex !== null){
          formData["product_type"] = props.type;
      
         try{
           const  response = await  client
            .post(
              "/social/api/seller/products/" + props.productId + "/" + productIndex,
              formData
            )
            
        
            
            
          } catch(err){ console.log('retype last word after two seconds to save update!')};
          
          // }
  }}


  const handleSubmit =  (val) =>{ 
      val["product_type"] = props.type;
      var existingEl = document.getElementById("product-" + productIndex + "-validator")
      if (existingEl){

      document.body.removeChild(existingEl);

      }
      
        const valid_holder = document.createElement('input')
     
            valid_holder.id = "product-" + productIndex + "-validator";
            
            valid_holder.hidden = true
            valid_holder.setAttribute('value', false) 

        document.body.append(valid_holder)
      try{
      form.validateFields().then(async (values)=>{
      
       const  response = await  client
        .post(
          "/social/api/seller/products/" + props.productId + "/" + productIndex,
          val
        )
        if (response.data.error === true){
        throw new Error(response.data.msg)
        }
        else{
        message.success(response.data.msg)

        valid_holder.setAttribute('value', true);

        }

      }
      ).catch((err)=>{
      document.getElementById('product-'+productIndex).scrollIntoView();
                  message.info('Please Enter required Field(s)/image(s)');});
      }catch(err){
      document.getElementById('product-'+productIndex).scrollIntoView();
                  message.info(err.toString());    
      }
          // }
    
  }





  return (
    <Spin
      spinning={loading}
      tip={
        props.submitForm === true ? "submitting product..." : "fetching product"
      }
    >
      {draftedProduct.sold === true ? (
        <div
          id="soldHolder"
          style={{
            transition: "all 1s ease-in-out",
            height: "300px",
            marginBottom: "20px",
            overflow: "hidden",
          }}
          onClick={(e) => {
            document.querySelector("#soldHolder").style.height =
              document.querySelector("#soldHolder").style.height === "300px"
                ? "100%"
                : "300px";
          }}
        >
          <Box
            sx={{
              borderColr: "green",
              width: { md: "50%", xs: "100%" },
              margin: "0px auto",
            }}
          >
            <Card
              type="inner"
              title={<>Product {productIndex ? productIndex : ""}</>}
              extra={
                <Button onClick={() => props.deleteProduct(productIndex)}>
                  <p style={{ color: "red" }}>Delete Product</p>
                </Button>
              }
            >
              <h2
                style={{
                  backgroundColor: "green",
                  // position: "absolute",
                  margin: "0px auto",
                  textAlign: "center",
                  padding: "10px",
                  // float: "right",
                  borderRadius: "10px",
                  color: "whitesmoke",
                  width: "100%",
                }}
              >
                Sold
              </h2>
              <small>
                Delete and create new product to re-sell or leave and upload a
                new product to reactivate(New changes made would not renew its
                state/reactivate it)
              </small>

              <Form.Item name={"product-index"} hidden>
                <Input value={productIndex} hidden />
              </Form.Item>

              {fields.length >= 1 ? (
                <>
                  {fields.map((field, index) => (
                    <Box key={index} style={{ display: "block", margin: "30px" }}>
                      {field}
                    </Box>
                  ))}
                </>
              ) : (
                <>
                  <br />
                </>
              )}

              {/* <Form.Item
              name="password"
              label="Account Password"
              rules={[
                {
                  required: true,
                  message: "Please input account password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item> */}
              {draftedProduct.date_created === undefined ? (
                ""
              ) : (
                <Input
                  type="date"
                  name="created_date"
                  disabled
                  defaultValue={draftedProduct.date_created}
                />
              )}
              {/* {alert(draftedProduct.private)} */}
              {draftedProduct.private === null ||
              draftedProduct.private === undefined ? (
                ""
              ) : (
                <Switch
                  checkedChildren="Private"
                  unCheckedChildren="Public"
                  defaultChecked={privateProduct}
                />
              )}

              <Upload
                onRemove={handleImageRemove}
                maxCount={10}
                headers={{
                  Authorization: `Token ${localStorage.getItem("AccgToken")}`,
                }}
                data={{ product_type: props.type }}
                action={
                  BASE_URL +
                  "social/api/seller/products/" +
                  props.productId +
                  "/" +
                  productIndex
                }
                name="productImages"
                onPreview={handlePreview}
                fileList={imageList.length >= 1 ? imageList : []}
                onChange={handleChange}
                listType="picture"
              ></Upload>

              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Card>
          </Box>
        </div>
      ) : (
        <div
          style={
            draftedProduct.unapprovable === true
              ? { backgroundColor: "red" }
              : {}
          }
        >
          <Box sx={{ width: { md: "100%", xs: "100%" }, margin: "0 auto" }}>
            <Card
              type="inner"
              title={<>Product {productIndex ? productIndex : ""}</>}
              extra={
                <Button onClick={() => props.deleteProduct(productIndex)}>
                  <p style={{ color: "red" }}>Delete Product</p>
                </Button>
              }
            >
              <Form
                form={form}
                id={"product-" + productIndex}
                className={"subProduct"}
                onFinish={(val) => handleSubmit(val)}
              >
                <Form.Item name={"product-index"} hidden>
                  <Input value={productIndex} hidden />
                </Form.Item>

                {fields.length >= 1 ? (
                  <>
                    <div style={{ margin: "20px auto" }}>
                      <small>
                        <b>Instructions:</b>
                        <li style={{ listStyleType: "disc" }}>
                          Product variant images should exude the detail of that
                          variant e.g a screenshot of the account.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          Do not use Special Characters on field names (e.g
                          .,/';[]-=@!#$%^&*()_+|{}') .
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          These credentials are hidden until the product is paid
                          for so make sure they are correct and vital.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          See the input arrangements as basically field name =
                          field value.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          leaving a required field empty might lead to the
                          disapproval of the whole product.
                        </li>
                      </small>
                    </div>
                    {fields.map((field, index) => (
                      <Box style={{ display: "block", margin: "30px" }}>
                        {field}
                      </Box>
                    ))}
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <small>
                      Fields includes product access credentials e.g username,
                      password and confirmation email credentials
                    </small>
                  </>
                )}
                <Button
                  style={{ margin: "20px" }}
                  onClick={() => addField("", "", false, fields.length - 1 + 1)}
                  icon={<AddCircle />}
                >
                  Add Field
                </Button>

                {/* <Form.Item
              name="password"
              label="Account Password"
              rules={[
                {
                  required: true,
                  message: "Please input account password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item> */}
                {draftedProduct.date_created === undefined ? (
                  ""
                ) : (
                  <Form.Item
                    name="created_date"
                    label="Creation date"
                    initialValue={draftedProduct.date_created}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please Enter when account/product was created",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      type="date"
                      name="created_date"
                      defaultValue={draftedProduct.date_created}
                      onChange={(e) => {
                        submitFormFunc();
                      }}
                    />
                  </Form.Item>
                )}
                {/* {alert(draftedProduct.private)} */}
                {draftedProduct.private === null ||
                draftedProduct.private === undefined ? (
                  ""
                ) : (
                  <Form.Item
                    name="privacyStatus"
                    label="Product/Account Status"
                    // valuePropName="checked"
                    initialValue={privateProduct}
                  >
                    <Switch
                      checkedChildren="Private"
                      unCheckedChildren="Public"
                      defaultChecked={privateProduct}
                      onChange={() => submitFormFunc()}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="productImages"
                  label="Upload Product Images"
                  // valuePropName="fileList"
                  getValueFromEvent={normFile}
                  required={true}
                >
                  <Upload
                    onRemove={handleImageRemove}
                    maxCount={10}
                    headers={{
                      Authorization: `Token ${localStorage.getItem(
                        "AccgToken"
                      )}`,
                    }}
                    data={{ product_type: props.type }}
                    action={
                      BASE_URL +
                      "social/api/seller/products/" +
                      props.productId +
                      "/" +
                      productIndex
                    }
                    name="productImages"
                    onPreview={handlePreview}
                    fileList={imageList.length >= 1 ? imageList : []}
                    onChange={handleChange}
                    listType="picture"
                  >
                    <Button aria-required={true} icon={<UploadOutlined />}>
                      Click to upload
                    </Button>
                  </Upload>

                  <Button
                    className="subProduct-submit-btn"
                    hidden
                    htmlType="submit"
                    onSubmit={(e) => {
                      e.preventDefault();
                      alert("im here 1");
                      handleSubmit(form.getFieldsValue());
                    }}
                  ></Button>
                </Form.Item>
                <Form.Item hidden>
                  <Button
                    id={"product-" + productIndex}
                    className="product-subm-btn"
                    type="secondary"
                    onClick={() => {
                      handleSubmit(form.getFieldsValue());
                    }}
                  >
                    Submit Product
                  </Button>
                </Form.Item>
              </Form>

              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Card>
          </Box>
        </div>
      )}
    </Spin>
  );
})

export default SubProductForm;
