import React from 'react'

import { Badge, Button, Col, Image, Space, Tag } from "antd";
import { addToCart, getSocialAvatar } from "./app_context";
import { Carousel,Card,mx  } from "antd";
import { Icon, IconButton,LinearProgress } from '@mui/material';
import {  ShoppingBag,FavoriteBorder,Favorite } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';



function ProductCard({account,wishFunc,cartFunc,refresh, loading}) {

const navigate = useNavigate()
  const quantity_inPerc = account.available_quantity * 100/account.total_quantity
  const get_Product=(slug, id)=>{
  navigate(`/p/${slug}/${id}`);
  }
  return (
    <Card
      hoverable={true}
      style={{width:"100%", borderRadius:"10px"}}
      size="small"
      bodyStyle={{marginTop:0, paddingTop:2}}
      title={
        <Box

          sx={{
            fontSize: { md: "16px", xs: "12px" },
            textOverflow: "ellipsis",
            padding: { xs: "0" },
          }}
        >
          <h2
            style={{
              fontFamily: "sans-serif",
              padding: "0",
              margin: "0",
              fontWeight: "bold",
            }}
          >
            { account.price instanceof  Object ?<>{account.price.currency} {account.price.amount} </>:account.price}
          </h2>
          {account.perc_discount ? (
            <p style={{ opacity: "0.5", padding: "0", margin: "0" }}>
              <strike> { account.actual_price instanceof  Object ? <>{account.actual_price.currency} {account.actual_price.amount} </>:account.actual_price}</strike>
            </p>
          ) : (
            ""
          )}{" "}
        </Box>
      }
      loading={loading}
      bordered={false}
    >
      <Box sx={{
          
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          lineHeight: 1.5,
          height:{md:'1.5em', xs:"20px"},
          width:"100%",
          fontSize: {md:'16px',  xs:'8px'},
          
        }}>
      <b
        
        onClick={() => {
          get_Product(account.slug, account.id);
        }}
      >
        {" "}
        {account.title}
      </b></Box>
      <div className="quantityBar">
        <small>
          {account.available_quantity} left of {account.total_quantity} Qty{" "}
        </small>

        <LinearProgress
          variant="determinate"
          title="Qty"
          value={quantity_inPerc}
        />
      </div>
      <>
        {account.image ? (
          <Box sx={{ padding: "10px", overflow: "hidden", textAlign:"center", width:"100%",  height:{md:"30vh", xs:"15vh"}}}>
            <div style={{ objectFit:"contain",  height:"100%", minHeight:"100%", }}>
            <img loading='lazy'
              onClick={() => {
                get_Product(account.slug, account.id);
              }}    
              style={{ objectFit: "cover", width:"inherit", height:"inherit" }}
              src={account.image.url}
              // height={window.screen.width >= 720? "":'100px'}
            ></img></div>
          </Box>
        ) : (
          ""
        )}
      </>
      {account.perc_discount ? (
        <Tag
          style={{
            position: "absolute",
            top: "4px",
            right: "0",
            paddingBottom: "0",
            color: "green",
            borderColor: "lightgreen",
          }}
        >
          {/* <Badge status="success"></Badge> */}
          {account.perc_discount}% off
        </Tag>
      ) : (
        <Tag
          style={{
            position: "absolute",
            paddingBottom: "0",
            top: "4px",
            right: "0",
            color: "tomato",
            borderColor: "tomato",
          }}
        >
          {account.perc_discount}% off
        </Tag>
      )}

      <div style={{ display: "inline-flex", marginTop: 5 }}>
        <Icon>{getSocialAvatar(account.socialMedia)}</Icon>
      </div>
      <div style={{ display: "inline-flex", float: "right" }}>
        <IconButton
          onClick={() => {
            wishFunc(account.id);
            refresh();
          }}
        >
          {!account.userFavourite == true ? <FavoriteBorder /> : <Favorite />}
        </IconButton>
      </div>
      <div style={{ display: "inline-flex", float: "right" }}>
        {account.userCartItem ? (
          <Box xs={{ margin: { md: "200px" } }}>
            <Badge count={account.qty_in_userCart} status="success">
              <Button
                onClick={() => {
                  cartFunc(account.id);
                  refresh();
                }}
                style={{ width: "", }}
                loading={loading}
              >
                Buy <ShoppingBag />
              </Button>
            </Badge>
          </Box>
        ) : (
          <Button
            onClick={() => {
              cartFunc(account.id);
            }}
            style={{ width: "" }}
            loading={loading}
          >
            Buy <ShoppingBag />
          </Button>
        )}
      </div>
    </Card>
  );
}

// ProductCard.propTypes = {}

export default ProductCard
