/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useRef, useState } from "react";
import { useNavigate,useMatch } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
  Modal,
  Form,
  Input,
  Image,
  Tag,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import BgProfile from "../../../assets/images/bg-profile.jpg";
import profilavatar from "../../../assets/images/face-1.jpg";
import convesionImg from "../../../assets/images/face-3.jpg";
import convesionImg2 from "../../../assets/images/face-4.jpg";
import convesionImg3 from "../../../assets/images/face-5.jpeg";
import convesionImg4 from "../../../assets/images/face-6.jpeg";
import convesionImg5 from "../../../assets/images/face-2.jpg";
import project1 from "../../../assets/images/home-decor-1.jpeg";
import project2 from "../../../assets/images/home-decor-2.jpeg";
import project3 from "../../../assets/images/home-decor-3.jpeg";
import "../../../assets/styles/main.css";
import "../../../assets/styles/responsive.css";
import axios from "axios";
import EditUserProfile from "../editProfile";
import { LocalActivityTwoTone } from "@mui/icons-material";
import APP_ENV from "../../../env";
const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {

  "Content-Type": "application/json",

};

if (localStorage.getItem('AccgToken') !== null) {

  reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}
const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,


})
const authClient = axios.create({
  baseURL: BASE_URL,


})

function Profile() {

  const [imageURL, setImageURL] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitReplyRef = useRef(null);
  const feedBack = useRef(null);
  const overView = useRef(null);
  const products = useRef(null);
  const saveEditRef = useRef(null);
  const avatarRef = useRef(null);

  const [userProfile, setUserProfile] = useState({});
  const [fileList, setFileList] = useState([]);
  const [previewAvatar, setpreviewAvatar] = useState(false);
  const [replyModalOpen, setReplyModalOpen] = useState(false);
  const [replyModalProp, setReplyModalProp] = useState({});
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const navigate = useNavigate();
  const url_username = useMatch('/profile/:username').params.username


  const updateProfile = () => {
    client.get('/user/api/profile/' + url_username).then(response => {
      if (response.data) {
        // console.log(response.data)
        setUserProfile(response.data)

      }
    }).catch(err => { message.error(err) })
  }
  React.useEffect(() => {
    updateProfile();
  }, [])

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = ({ fileList: newFileList }) => {

    setFileList(newFileList)
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const data = [
    {
      title: "Sophie B.",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      avatar: convesionImg2,
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      avatar: convesionImg3,
      description: "About files I can…",
    },
    {
      title: "Peterson",
      avatar: convesionImg4,
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      avatar: convesionImg5,
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];
  const submitReply = (values) => {
    // console.log(values)
    if (values.message === undefined || values.message === '') {
      message.error('cannot send empty reply')
    }
    else {
      client.post('/user/api/seller/feedback?reply=True', values).then(response => {
        if (response.data) {
          // console.log(response.data)
          message.success('reply sent')

        }
      }).catch(err => { message.error(err) })
      setReplyModalOpen(false)
    }
  }
  const ReplyModal = () => (

    <Modal okText='Send' onOk={() => { submitReplyRef.current.submit() }} onCancel={() => setReplyModalOpen(false)} visible={replyModalOpen}>

      <Avatar src={ replyModalProp.feedback_sender_pic}></Avatar>
      <br></br>

      {replyModalProp.feedback_sender}
      <br></br>
      <small style={{ float: 'right' }}><b>replying: </b> {replyModalProp.user_message} </small>
      <br></br>
      <Form ref={submitReplyRef} onFinish={(values) => submitReply(values)}>

        <Form.Item name='message' required>

          <Input name="message" required type='text' placeholder="Enter reply"></Input>

        </Form.Item>

        <Form.Item hidden name='sender' initialValue={userProfile.username} required>

          <Input name="sender" required type='text' ></Input>

        </Form.Item>

        <Form.Item hidden name='feedback_id' initialValue={replyModalProp.feedback_id} required>

          <Input name="feedback_id" required type='text' ></Input>

        </Form.Item>

      </Form>
    </Modal>
  )
  const EditProfile = () => (

    <Modal cancelText='Close' onOk={() => saveEditRef.current.submit()} visible={editProfileModalOpen} okText='Save' okButtonProps={{ color: 'green' }} onCancel={() => {
      setEditProfileModalOpen(false); updateProfile()
    }}>


      <EditUserProfile ref={saveEditRef} userProfile={userProfile} />
    </Modal>)

  return (
    <>
      <div
        ref={overView}
        className="profile-nav-bg"
        style={userProfile.cover_picture === null ? { backgroundImage: "url(" + BgProfile + ")" } : { backgroundImage: "url(" +  userProfile.cover_picture + ")" }}
      ></div>

      <Card
        id="feedbacks"
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">

              <Avatar.Group>
                <Avatar onClick={() => setpreviewAvatar(true)} size={74} shape="square" src={userProfile.display_picture ?  userProfile.display_picture : profilavatar}  >


                </Avatar>


                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{userProfile.full_name}</h4>
                  <p>{userProfile.occupation}</p>
                </div>

              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group defaultValue="a">
                <Radio.Button value="a" onClick={() => { overView.current.scrollIntoView() }}>OVERVIEW</Radio.Button>
                <Radio.Button value="b" onClick={() => { feedBack.current.scrollIntoView() }}>FEEDBACKS</Radio.Button>
                <Radio.Button value="c" onClick={() => { products.current.scrollIntoView() }}>PRODUCTS</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
      ></Card >
      <Row gutter={[24, 0]}>
        {userProfile.isAuth ?
          <Col span={24} md={8} className="mb-24 ">
            <Card
              bordered={false}
              className="header-solid h-full"
              title={<h6 className="font-semibold m-0">Platform Settings</h6>}
            >
              <ul className="list settings-list">
                <li>
                  <h6 className="list-header text-sm text-muted">ACCOUNT</h6>
                </li>
                {/* <li>
                <Switch defaultChecked />

                <span>Email me when someone follows me</span>
              </li> */}
                <li>

                  <Switch checked={userProfile.notify_on_conversations} />
                  <span>Notify me when someone answers me</span>
                </li>
                <li>
                  <Switch checked={userProfile.notify_on_order} />
                  <span>Email me when order is fulfiled</span>
                </li>
                <li>
                  <h6 className="list-header text-sm text-muted m-0">
                    APPLICATION
                  </h6>
                </li>
                {/* <li>
                <Switch defaultChecked />
                <span>New launches and projects</span>
              </li> */}
                <li>
                  <Switch checked={userProfile.notify_on_update} />
                  <span>Monthly product updates</span>
                </li>
                <li>
                  <Switch checked={userProfile.subscribed_to_NL} />
                  <span>Subscribe to newsletter</span>
                </li>
              </ul>
            </Card>
          </Col>

          : ''}
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"

            extra={userProfile.isAuth ? <Button type="link" onClick={() => { setEditProfileModalOpen(true) }}>{pencil}</Button> : ''}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            onClick={userProfile.isAuth ? () => { setEditProfileModalOpen(true) } : ''}
          >
            <p className="text-dark">
              {" "}
              {userProfile.bio}{" "}
            </p>
            <hr className="my-25" />
            <Descriptions title={userProfile.username}>
              <Descriptions.Item label="Full Name" span={3}>
                {userProfile.full_name}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                {userProfile.mobile}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {userProfile.email}
              </Descriptions.Item>
              <Descriptions.Item label="Location" span={3}>
                {userProfile.location}
              </Descriptions.Item>
              <Descriptions.Item label="website" span={3}>
                <a target='_blank' href={userProfile.website}>  {userProfile.website}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Social" span={3}>
                <a target='_blank' href={userProfile.tw_url} className="mx-5 px-5">
                  {<TwitterOutlined />}
                </a>
                <a target='_blank' href={userProfile.fb_url} className="mx-5 px-5">
                  {<FacebookOutlined style={{ color: "#344e86" }} />}
                </a>
                <a target='_blank' href={userProfile.in_url} className="mx-5 px-5">
                  {<InstagramOutlined style={{ color: "#e1306c" }} />}
                </a>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24" ref={feedBack}>
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Feedbacks</h6>}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={userProfile.feedbacks}
              split={false}
              className="conversations-list"
              renderItem={userProfile.seller === true || userProfile.feedbacks ?
                (item) => (



                  < List.Item actions={userProfile.isAuth ? [<Button type="link" onClick={() => { setReplyModalProp(item); setReplyModalOpen(true) }}>REPLY</Button>] : ''}>
                    <List.Item.Meta
                      avatar={
                        <Avatar shape="square" size={48} src={item.feedback_sender_pic ?  item.feedback_sender_pic : convesionImg} />
                      }
                      title={item.feedback_sender}
                      description={item.user_message}
                    />

                  </List.Item>
                ) : 'O FeedBacks'}
            />
          </Card>
        </Col>
      </Row>
      <Card
        ref={products}
        bordered={false}
        className="header-solid mb-24"
        title={
          <>
            <h6 className="font-semibold">Products</h6>
            <p>{userProfile.seller_category ? userProfile.seller_category.map(name => (


              <small>{name}, </small>
            )) : ''}</p>
          </>
        }
      >

        <Row gutter={[24, 24]} style={{ margin: '0 auto' }}>
          {userProfile.products ? userProfile.products.map((p, index) => (
            <Col span={24} md={12} xl={6} key={index} >
              <Card
                bordered={false}
                className="card-project"
                cover={<div style={{ display: 'inline-block', maxWidth: '300px', maxHeight: '200px', overflow: 'hidden', objectFit: 'contain' }}><img style={{ width: 'inherit' }} alt="example" src={ p.image_url} /></div>}
              >
                <div className="card-tag">{p.category}</div>
                <div ><h5>{p.title}</h5></div>
                <p style={{ height: 20, maxWidth: 100, textOverflow: 'ellipsis', overflow: 'hidden' }}>{p.description}</p>
                <Row gutter={[6, 0]} className="card-footer">

                  <Col span={12}>
                    <Button type="button" onClick={() => { navigate('/p/' + p.slug + '/' + p.id) }}>VIEW PRODUCT</Button>
                    <Col span={12} >
                      <Tag>{p.price}</Tag>
                    </Col>
                  </Col>
                  <Col span={12} className="text-right">
                    <Avatar.Group className="avatar-chips">
                      {/* <Avatar size="small" src={profilavatar} />
                      <Avatar size="small" src={convesionImg} />
                      <Avatar size="small" src={convesionImg2} />
                      <Avatar size="small" src={convesionImg3} /> */}
                    </Avatar.Group>
                  </Col>
                </Row>
              </Card>
            </Col>
          )) : <Col span={24} md={12} xl={6}  >0 products</Col>
          }
          {userProfile.isAuth ?
            <Col span={24} md={12} xl={6} >
              <Upload
                listType="picture-card"
                className="avatar-uploader product-uploader"
                fileList={fileList}
                showUploadList={true}
                headers={{
                  "Authorization": `Token ${localStorage.getItem('AccgToken')}`
                }}

                action={BASE_URL + 'social/api/UserFiles'}
                name="productImages"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageURL ? (
                  <img src={imageURL} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
              {
                fileList.length >= 1 ?
                  <Button onClick={() => navigate('/sell')}>Next Step</Button> : ''}

            </Col> : ''}
        </Row>

      </Card>
      <ReplyModal />
      <EditProfile />
    </>
  );
}

export default Profile;
