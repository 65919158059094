import React, { Component } from "react";
import NavBackWidget from "./back_navigator";
import { Box } from "@mui/material";
import { Divider } from "antd";
import TandCContentPDF from '../../assets/docs/terms_of_use.pdf';
export default class TermsAndConditions extends Component {
componentDidMount(){
 

}

  render() {
    return (
      <div>
        <Box
          sx={{ width: { md: "60%", xs: "100%" } }}
          style={{ margin: "0 auto" }}
        >
          <NavBackWidget />
          <Box
            className="textContent"
            style={{ padding: "20px", height: "100%" }}
          >
            <h1>Terms of Use</h1>
            <b>1.1. <a href='/'>Accountgig.com</a> service GUARANTEES:</b> <br />
            <p>
              - The full validity of the account (within 48 hours after the
              purchase), according to all the rules described in the Terms of
              Use{" "}
            </p>
            <p>- Sale of accounts in the hands;</p>
            <p>
              - Replacing accounts in case of invalidity because of 
              <a href='/'> accountgig.com</a> store fault. Namely: the account is blocked,
              deleted, incorrect credentials to login into the account, ACTIVE
              guarantee (ACTIVE guarantee = NOT canceled guarantee); - Refund if
              an invalid account can not be replaced. Refunds can be made only
              to the  Platform wallet{" "}
            </p>
            <b>
              1.2. The following use is FORBIDDEN for the accounts purchased on
              the <a href='/'>accountgig.com</a> website:
            </b>{" "}
            <br></br>
            <p>- Use your original (local, home) IP address;</p>
            <p>
              - Use VPN services (paid, free, built-in (opera-VPN), and others);
            </p>
            <p>- Use an anonymous TOR browser;</p>
            <p>
              - Use virtual machines, device emulators, and servers (VPS, VDS);
            </p>
            <p>- Use IPv6 - proxy, common proxy, public proxy;</p>
            <p>
              - Use proxies and devices obtained illegally (including, but not
              limited to - botnets, etc.);
            </p>
            <p>
              - Log in to 2 or more accounts using 1 device and 1 proxy server;
            </p>
            <p>
              - Use accounts to harm other people (including, but not limited to
              - bullying on social networks, comment spam, threats,etc.) and to
              commit other illegal actions (including, but not limited to -
              fraud, extortion, data theft, etc. d.).
            </p>
            <p>
              1 legal private IPv4 proxy server and 1 new device (new UserAgent,
              cookie, specialized program) MUST be used for 1 account.
            </p>{" "}
            <br></br>
            <b>
              1.3. <a href='/'>Accountgig.com</a> and its employees do not provide training and
              advice.
            </b>
            <br />
            <p>
              1.3.1 The <a href='/'>accountgig.com</a> service and its employees do not store
              accounts and the navigate of sold ones. The customer must take care
              of the preservation of the data himself.
            </p>
            <b>1.4. <a href='/'>Accountgig.com</a> and its employees have the right to do:</b>{" "}
            <br />
            <p>
              - Not to change the initially valid accounts. If you logged into
              your account and did something there, and your account was blocked
              after, the problem is most likely on your side;{" "}
            </p>
            <p>
              - Not to give a refund for purchased valid accounts. If you want
              to get a refund or a replacement of the account after your
              inattentive purchase, your request can be rejected.
            </p>
            <p>
              - Block the customer on <a href='/'>accountgig.com</a> and do not give a refund in
              the following cases:
            </p>
            <p>a). The client spams on tickets, email, or chat</p>
            <p>
              b). The client leaves negative reviews or comments about the
              service on third-party resources
            </p>
            <p>
              c). The client is rude to technical support or uses cuss words.
            </p>
            <b>
              1.5. If the client agrees to the rules of the store (at the time
              of purchase), the CLIENT CONFIRMS that:
            </b>{" "}
            <br></br>
            <p>
              - The client has read the detailed description of the accounts;
            </p>
            <p>
              - The client will change passwords for the accounts and will
              independently take care of their security in case of prolonged use
              of purchased accounts;
            </p>
            <p>
              - The responsibility shifts to the buyer after the purchase is
              made;
            </p>
            <p>
              - THE GUARANTEE IS BEING CANCELED in case of the violation of
              paragraph 1.2. and the client DOES NOT HAVE any claims to the
              service <a href='/'>accountgig.com</a>;
            </p>
            <p>- The client refers to all the points above of the agreement.</p>
            <b>1.6 Additional information for suppliers:</b> <br />
            <p>
              Account gig immediately blocks the identified accounts and access
              to them if its support detects that the Users, selling the
              accounts on the website, have more than one account. And all the
              funds of the User balance are transferred to Account gig as
              penalties and are not refundable.
            </p>
            <p>
              If you have any problems with the purchase, after the purchase of
              goods, or other difficulties associated with this, please, report
              order or contact us.
            </p>
            <br></br>
            <p>
              Disclaimer - The <a href='/'>accountgig.com</a> service is an intermediary between
              account sellers and buyers. The buyers are responsible themselves
              if use the accounts for any illegal activity. <a href='/'>Accountgig.com</a> does
              not approve spamming (i.e., commercial emailing without the prior
              consent of the recipients) and any fraudulent activity using the
              accounts.
            </p>
            <Divider style={{ width: "70%" }}>
              <p>Last update: January 30th, 2023.</p>
            </Divider>
          </Box>
        </Box>
      </div>
    );
  }
}
