import { Warning } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import React from 'react';

export default function ChatNotificaton({type, value=''}) {
    const notificationStyle = makeStyles((theme) => ({
      first: {
        color: "darkorange",
        border: "1px solid grey",
        padding: "10px",
        borderRadius: "10px",
        width: "400px",
        textAlign: "center",
      },
      normal: {
        color: "darkgreen",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "10px",
        width: "400px",
        textAlign: "center",
      },
      date: {
        border: "1px solid lightgrey",
        borderRadius: "5px",
        width: "100px",
        padding: "0px",
      },
      
      info: {
        border: "1px solid lightgrey",
        borderRadius: "5px",
        width: "80%",        
        padding: "0px",
      },
    }));
    const classes = notificationStyle();
    switch (type) {
      case "first":
        return (
          <div style={{ display: "grid", justifyContent: "center" }}>
            <div className={classes.first}>
              <Warning />
              <small>
                {" "}
                Never perform any payment transaction outside of accountgig. We
                will not be legible for such transactions and any after case.{" "}
              </small>
            </div>
          </div>
        );

      case "date":
        return (
          <div style={{ display: "grid", justifyContent: "center" }}>
            <div className={classes.date}>
              <small> {value} </small>
            </div>
          </div>
        );
      case "notification":
        return (
          <div style={{ display:'flex', marginTop:'10px', justifyContent:'center', color:'skyblue', width:'100%', maxWidth:'100%', justifySelf:'center', textAlign:'center' }}>
            <div className={classes.info}>
              <small> {value} </small>
            </div>
          </div>
        );
      default:
        return (
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              width: "100%",
              maxWidth: "100%",
              justifySelf: "center",
              textAlign: "center",
            }}
          >
            <div className={classes.normal}>
              <small>{value}</small>
            </div>
          </div>
        );
    }
 
}
