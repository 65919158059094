import {
  AudioFile,
  Error,
  FileOpenOutlined,
  Pause,
  PauseOutlined,
  PlayArrow,
  PlayArrowOutlined,
  VideoFile,
} from "@mui/icons-material";
import { Image, message, Modal } from "antd";
import React, { useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import VideoThumbnail from "react-video-thumbnail";
import ReactPlayer from "react-player";
import { shadows } from "@mui/system";
import { Box, Button, Icon, IconButton } from "@mui/material";
import WaveSurfer from "wavesurfer.js";
import { memo } from "react";
// import { WaveformContianer, Wave, PlayButton } from "./Waveform.styled";

 function FileWidget({
  name,
  type,
  url,
  id,
  urlList = [],
  large = false,
}) {
  const [visible, setVisible] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(url);
  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [waveSurfer, setWaveSurfer] = useState({});
  React.useEffect(() => {
    if (type == "music") {
      var wavesufer = WaveSurfer.create({
        container: `#waveform-${id}`,
        responsive: true,
        height: 50,
        waveColor: "rgb(39, 54, 50)",
        progressColor: "#f2fafa",
      });
      wavesufer.load(url);
      setWaveSurfer(wavesufer);
    }
  }, []);

  const handlePlay = () => {
    if (waveSurfer.isPlaying()) {
      waveSurfer.pause();
      setPlaying(false);
    } else {
      waveSurfer.play();
      setPlaying(true);
    }
  };

  if (type.includes("audio")) {
    return (
      <>
        {" "}
        <Box
          sx={{
            cursor: "pointer",
            backgroundColor: "rgba(100,140,100,0.2)",
            borderRadius: "5px",
            width: { md: "200px", xs: "100%" },
            boxShadow: "0px 0.4px 3px 0px",
            height: "auto",
            overflow: "hidden",

            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            marginBottom: "5px",
            padding: "0",
            margin: "0",
          }}
        >
          {" "}
          <small>{name}</small>
          <div id={`waveform-${id}`}></div>
          <IconButton style={{ margin: "1px" }} onClick={handlePlay}>
            {playing === true ? <PauseOutlined /> : <PlayArrowOutlined />}
          </IconButton>
        </Box>
      </>
    );
  } else if (type.includes("video")) {
    return (
      <>
        <Box
          style={{
            cursor: "pointer",
            backgroundColor: "rgba(100,140,100,0.2)",
            borderRadius: "5px",
            width: "100px",
            boxShadow: "0px 0.4px 3px 0px",
            overflow: "hidden",

            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            padding: "0",
            margin: "0",
          }}
          onClick={() => {
            setCurrentUrl(url);
            setVisible(true);
          }}
        >
          <ReactPlayer width={"100%"} height={"80px"} controls url={url} />
          <small>{name}</small>
        </Box>
      </>
    );
  } else if (type.includes("application")) {
    return (
      <>
        <Box
          style={{
            cursor: "pointer",
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,140,100,0.2)",
            borderRadius: "5px",
            width: large ? "100%" : "100px",
            height: "60px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "2",
            boxShadow: "0px 0.4px 3px 0px",
            padding: 2,
          }}
          onClick={() => {
            setCurrentUrl(url);
            setVisible(true);
            Modal.info({
              title: name,
              // bodyStyle:{height:'80vh'},
              okText: "Close",
              icon: <FileOpenOutlined color="blue" />,
              content: (
                <iframe
                  width={"100%"}
                  height="500px"
                  src={url}
                  title={name}
                ></iframe>
              ),
              onOk: () => Modal.destroyAll(),
            });
          }}
        >
          {" "}
          <FileOpenOutlined color="blue" />
          <small
            style={{
              position: large ? "relative" : "absolute",
              left: "0",
              top: large ? 0 : "30px",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {" "}
            {name}{" "}
          </small>
        </Box>
      </>
    );
  } else if (type.includes("image")) {
    return (
      <>
        <Box
          style={{
            cursor: "pointer",
            textAlign: "center",
            width: "100px",
            justifyContent: "center",
            alignContent: "center",
            overflow: "hidden",

            backgroundColor: "rgba(100,140,100,0.2)",
          }}
          onClick={() => {
            setCurrentUrl(url);
            setVisible(true);
          }}
        >
          <Image height={50} width={"inherit"} src={currentUrl} alt={name} />
          <br />
          <small>{name}</small>
        </Box>{" "}
      </>
    );
  } 
  else {
    return (
      <>
        <Box
          style={{
            cursor: "pointer",
            textAlign: "center",
            justifyContent: "center",
            overflow:'hidden',
            alignContent: "center",
            backgroundColor: "rgba(100,140,100,0.2)",
            borderRadius: "5px",
            width: "100px",

            boxShadow: "0px 0.4px 3px 0px",
          }}
          onClick={() => {
            setCurrentUrl(url);
            setVisible(true);
            message.info('File may not load')
          }}
        >
          <FileOpenOutlined />
          Format unavailable
          <small>{name}</small>
        </Box>
      </>
    );
  }
}
export default memo(FileWidget)
