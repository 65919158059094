/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Component } from "react";
import {
  Layout,
  Menu,
  Button,
  Typography,
  Card,
  Form,
  Input,
  Checkbox,
  message,
  Spin,
} from "antd";
import logo1 from "../../../assets/images/logos-facebook.svg";
import logo2 from "../../../assets/images/logo-apple.svg";
import logo3 from "../../../assets/images/Google__G__Logo.svg.png";

import "../../../assets/styles/main.css";
import "../../../assets/styles/responsive.css";
import { Link } from "react-router-dom";
import axios from "axios";
import APP_ENV from "../../../env";
const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
  "Content-Type": "application/json",

  "Authorization": `Token ${localStorage.getItem('AccgToken')}`


};

if (localStorage.getItem('AccgToken') !== null) {
  reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,


})
const authClient = axios.create({
  baseURL: BASE_URL,


})

const { Title } = Typography;
const { Header, Footer, Content } = Layout;
const template = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const profile = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signup = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signin = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      className="fill-muted"
      d="M12.25,14H1.75A1.752,1.752,0,0,1,0,12.25V3.5A1.752,1.752,0,0,1,1.75,1.75h.876V.875a.875.875,0,0,1,1.75,0V1.75h5.25V.875a.875.875,0,0,1,1.75,0V1.75h.875A1.752,1.752,0,0,1,14,3.5v8.75A1.752,1.752,0,0,1,12.25,14ZM3.5,4.375a.875.875,0,0,0,0,1.75h7a.875.875,0,0,0,0-1.75Z"
    />
  </svg>,
];
export default class SignUp extends Component {
  state = {
    loading: true,
    referralCode: localStorage.getItem('__refCode__')
  }
  componentDidMount() {


    this.setState({ loading: false })

  }
  handleReferrer(val) {
    val['api_key'] =APP_ENV.PLATFORM_WALLET_API_KEY
    message.success('Account created. Please Login to continue')
   
    authClient.post('/user/api/referrals-handle', val).then(resp => {

      message.success('referred by : ' + resp.data.referrer_username)
      this.setState({ loading: false })
                window.localStorage.removeItem("__refCode__");
      
      window.location.href = 'sign-in'
      

    }).catch(err => { this.setState({ loading: false });
    
    
    message.error('Error assigning refferals');
          window.location.href = "sign-in";
    })

  }
  render() {

    const onFinish = async (values) => {
      if (values.password !== values.password1) {

        message.error('passwords do not match')
      }
      else {
        this.setState({ loading: true })

        try {
          const response = await authClient.post('/auth/users/', values)
          if ((values.referral_code !== '' || values.referral_code !== null || values.referral_code !== undefined) && (response.status === 200 || response.status === 201)) {

           return this.handleReferrer(values)

          }

          else {
            this.setState({ loading: true })
            message.success('Account created. Please Login to continue')
                   window.location.href = "sign-in";



          }



        } catch (err) {
          this.setState({ loading: false })

          const response = err.response
          if (response.data.username) {

            message.error(response.data.username[0])

          }
          if (response.data.email) {
            message.error(response.data.email[0])


          }
          if (response.data.detail) {

            message.error(response.data.detail[0])

          }
          if (response.data.password) {

            message.error(response.data.password[0])

          }
        }





      }
    };

    const onFinishFailed = (errorInfo) => {
      message.error(errorInfo);
    };
    return (
      <>
        <Spin tip="loading" spinning={this.state.loading}>
          <div
            className="layout-default ant-layout layout-sign-up"
            style={{ overflow: "hidden", backgroundColor:"inherit" }}
          >
            <Header>
              <div className="header-col header-brand">
                <h5>AccountGig</h5>
              </div>
              <div className="header-col header-nav">
                <Menu mode="horizontal" defaultSelectedKeys={["3"]}>
                  {/* <Menu.Item key="1"> */}
                  {/* <Link to="/dashboard">
                    {template}
                    <span> Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/profile">
                    {profile}
                    <span>Profile</span>
                  </Link>
                </Menu.Item> */}
                  <Menu.Item key="3">
                    <Link to="/sign-up">
                      {signup}
                      <span> Sign Up</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <Link to="/sign-in">
                      {signin}
                      <span> Sign In</span>
                    </Link>
                  </Menu.Item>
                </Menu>
              </div>
              <div className="header-col header-btn">
                <Link to="/">
                  {" "}
                  <Button type="false">Continue Shopping</Button>
                </Link>
              </div>
            </Header>

            <Content style={{backgroundColor:"inherit"}} className="p-0">
              <div className="sign-up-header">
                <div className="content">
                  <Title>Sign Up</Title>
                  <p className="text-lg">
                    Varieties of Digital Products waiting for you. come-on and
                    hop in today!
                  </p>
                </div>
              </div>

              <Card
                className="card-signup header-solid h-full ant-card pt-0"
                title={<h5>Register With</h5>}
                bordered="false"
              >
                <div className="sign-up-gateways">
                  <Button disabled type="false">
                    <img src={logo1} alt="logo 1" />
                  </Button>
                  <Button disabled type="false">
                    <img src={logo2} alt="logo 2" />
                  </Button>
                  <Button disabled type="false">
                    <img src={logo3} alt="logo 3" />
                  </Button>
                </div>
                <p className="text-center my-25 font-semibold text-muted">Or</p>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="row-col"
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input placeholder="Username" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please input your email!" },
                    ]}
                  >
                    <Input placeholder="Email" type="email" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input placeholder="Password" type="password" />
                  </Form.Item>

                  <Form.Item
                    name="password1"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                    ]}
                  >
                    <Input placeholder="Confirm Password" type="password" />
                  </Form.Item>

                  <Form.Item
                    name="referral_code"
                    initialValue={this.state.referralCode}
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Referral Code (optional)",
                      },
                    ]}
                  >
                    <Input
                      defaultValue={this.state.referralCode}
                      placeholder="Referral Code (Optional)"
                      type="text"
                    />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    rules={[{ required: true }]}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      I agree to the{" "}
                      <a href="terms&C" className="font-bold text-dark">
                        Terms and Conditions
                      </a>
                    </Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                    >
                      SIGN UP
                    </Button>
                  </Form.Item>
                </Form>
                <p className="font-semibold text-muted text-center">
                  Already have an account?{" "}
                  <Link to="/sign-in" className="font-bold text-dark">
                    Sign In
                  </Link>
                </p>
              </Card>
            </Content>
          </div>
        </Spin>
      </>
    );
  }
}
