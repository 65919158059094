import React from 'react'
import Influencer from './influencer'
import { useDispatch } from 'react-redux'
import { SelectInfluencerOnView } from '../redux/influencer/selectors'
import { fetchinfluencer } from '../redux/influencer/thunks'

export default function MyPage(props) {
	const dispatch = useDispatch()
	const page = SelectInfluencerOnView()
	React.useEffect(()=>{

dispatch(fetchinfluencer({display_name:'me'}))
	}, [])
	return <>
		<Influencer myPage={true} />
		


	</>
}