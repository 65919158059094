import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  SelectInfluenceHubOrderStatus,
  SelectInfluenceHubOrders,
} from '../redux/order/selectors'
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Stack,
  Typography,
  alpha,
} from '@mui/material'
import { uid } from 'react-uid'
import { Empty, Spin, Tag } from 'antd'
import { fetchOrders } from '../redux/order/thunks'
import { useDispatch } from 'react-redux'
import {
  ContentCopyOutlined,
  PendingActionsOutlined,
  PhotoLibraryOutlined,
  TextFieldsOutlined,
  TextFormatOutlined,
} from '@mui/icons-material'
import { UserProfileSelector, UserSelector } from '../../../store/auth/selectors'
import { InfluencerAvatar } from '../influencer/widgets/avatar'

export default function InHubOrderList() {
  const orderList = SelectInfluenceHubOrders()
  const navigate = useNavigate()
  const stateStatus = SelectInfluenceHubOrderStatus()
  const dispatch = useDispatch()
  const User = UserSelector()
  const UserProfile=UserProfileSelector()
  React.useEffect(() => {
    dispatch(fetchOrders())
  }, [])
  return (
    <Container sx={{ margin: '0 auto' }}>
      <Spin spinning={stateStatus === 'loading'}>
        <div>
          {orderList.map(order => {
            const viewerIsCreator =order.viewer_is_creator
            return (
              <div key={uid(order)} style={{marginBottom:'10px'}}>
                <Card
                  sx={{ width: { md: '80%', xs: '100%', margin: '0 auto' } }}
                >
                  <CardHeader
                    sx={{
                      alignItems: 'right',
                      justifyContent: 'right',
                      padding: '6px',cursor:'pointer',
                      backgroundColor: alpha('#131514', 0.01),
                    }}
                    onClick={ ()=> viewerIsCreator && navigate(`/influencers/${order.influencer.slug}`)}
                    title={
                      <p>
                        <b>
                          {viewerIsCreator
                            ? order.influencer.display_name
                            : order.creator.username}
                        </b>
                        <br />
                        <span style={{ opacity: 0.7 }}>
                          Placed {order.created_at}
                        </span>
                      </p>
                    }
                    avatar={
                      <InfluencerAvatar
                        isVerified={order.influencer.is_verified}
                        src={
                          viewerIsCreator
                            ? order.influencer.display_image
                            : order.creator.display_picture
                        }
                      >
                        {viewerIsCreator
                          ? order.influencer.display_name[0]
                          : order.creator?.username[0]}{' '}
                      </InfluencerAvatar>
                    }
                  ></CardHeader>
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignContent: 'start',
                        width: '50%',
                        marginLeft: '15px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          paddingTop: '10px',
                          gap: 3,
                          fontSize: { sm: '20px', md: '30px' },
                        }}
                      >
                        {order.text_content.length >= 1 && (
                          <span title="Text Contents">
                            <TextFieldsOutlined />
                          </span>
                        )}
                        {order.media_contents.length >= 1 && (
                          <span title="Media Contents">
                            <Badge
                              color="info"
                              style={{ padding: '3px' }}
                              badgeContent={order.media_contents.length}
                            >
                              <PhotoLibraryOutlined />
                            </Badge>
                          </span>
                        )}
                        {(order.required_influencer_next_actions.length >= 1 &&
                          !viewerIsCreator) ||
                        (order.required_creator_next_actions.length >= 1 &&
                          viewerIsCreator) ? (
                          <Box>
                            {' '}
                            <Button
                              size="small"
                              onClick={()=>navigate(`/influence-hub/orders/${order.code}?update=true`)}
                              sx={{
                                borderRadius: '20px',
                                marginTop: '5px ',
                                fontSize: '10px',
                                padding: '4px',
                                opacity: 0.6,
                              }}
                              startIcon={<PendingActionsOutlined />}
                              variant="contained"
                              color="warning"
                            >
                              Action Required
                            </Button>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignContent: 'end',
                        width: '50%',
                        marginRight: '15px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <small>Order Code</small>
                        <Tag bordered={false}>
                          <Typography color="ButtonShadow">
                            {order.code}
                          </Typography>
                        </Tag>
                      </Box>
                    </Box>
                  </Box>
                  <CardContent>
                    <b>Package</b>
                    <br />
                    <Typography variant="button">
                      {order?.package.name}
                    </Typography>
                    <Divider sx={{ marginY: '10px', marginRight: '10px' }} />
                    <Chip
                      variant="outlined"
                      color={
                        ['CA', 'F'].includes(order.status)
                          ? 'error'
                          : ['P', 'A'].includes(order.status)
                          ? 'info'
                          : ['D', 'C'].includes(order.status)
                          ? 'warning'
                          : order.status === 'FU'
                          ? 'success'
                          : 'default'
                      }
                      label={order.status_display}
                    ></Chip>
                    <br />
                    <Box sx={{ opacity: 0.4, margin: '5px', float: 'right' }}>
                      {!viewerIsCreator
                        ? order.last_reminded && (
                            <small>
                              Last Reminder <br />
                              {order.last_reminded}
                            </small>
                          )
                        : order.last_seen_by_in && (
                            <small>
                              Last Seen <br /> {order.last_seen_by_in}
                            </small>
                          )}
                    </Box>
                  </CardContent>

                  <CardActionArea>
                    <Button variant="contained"                               onClick={()=>navigate(`/influence-hub/orders/${order.code}`)}
 fullWidth>
                      View Order
                    </Button>
                  </CardActionArea>
                </Card>
              </div>
            )
          })}
          <Container
            sx={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
          >
            {orderList.length <= 0 && (
              <div>
                <Empty description="No Order yet"></Empty>
                <Button
                  onClick={() => navigate('/influence-Hub')}
                  sx={{ margin: 10 }}
                  variant="contained"
                  size="medium"
                >
                  Find Influencer
                </Button>
              </div>
            )}
          </Container>
        </div>
      </Spin>
    </Container>
  )
}
