import React from 'react';
// import { useNavigate } from 'react-router-dom';
import {  Box, Divider, } from '@mui/material';
function Footer() {

  return (
    <>
      <Box sx={{ backgroundColor: "inherit", textAlign: "center" }}>
        <footer style={{ width: '80%', backgroundColor: "inherit", margin: '0 auto ' }}>
          <Divider style={{ borderStyle: 'dotted' }} textAlign='center'>

          </Divider>
          <p style={{ padding: '10px' }}>
            Copyright © 2023 Accountgig.
          </p>
        </footer>
      </Box>

        

    </>
  )
}

export default Footer;