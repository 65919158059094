import { useImperativeHandle } from 'react';

import axios from 'axios';
import * as React from 'react';
import AccountPosts from './accountsPosts';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SAccounts from '../apis/postApis';
import { Slider, SliderThumb, BottomNavigation, Button } from '@mui/material'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { message } from 'antd';
import APP_ENV from '../env';
export default React.forwardRef(function ProductsFunctions(props, ref) {
    useImperativeHandle(ref, () => ({
        addToCartFunc(id) {
            addToCart(id)

        },
        addToWishFunc(id) {
            addToWish(id)

        },
        add_allToCart() {
            addAllToCart()
        },
        remove_allFromWish() {
            removeAllFromWish()
        },
        remove_allFromCart() {
            removeAllFromCart()
        },
        subtract_FromCart(id) {
            subtractFromCart(id)
        },
        delete_ItemFromCart(id) {
            deleteItemFromCart(id)

        },
        add_QtyToCart(id) {
            addQtyToCart(id)

        },
        cancel_Order(id) {
            cancelOrder(id)
        }

    }))

    // const CardStyle = {

    //     backgroundColor: 'rgb(37, 44, 48, .5)',
    //     margin: '5px',
    //     display: 'inline-block'
    // }
    const [alertColor, setAlertColor] = React.useState('success')
    const [accountTypeIndisplay, setaccountTypeIndisplay] = React.useState('all')

    const [displayErrorOpen, setDisplayErrorOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [dialogTitleMsg, setDialogTitleMsg] = React.useState('sorry!');

    const swipEContainer = {
        backgroundColor: 'rgb(30, 48, 40)',
        textAlign: 'center',
        borderBottom: '3px solid grey',
        padding: '0px',
        margin: '0px',
        boxSizing: 'border-box',
        top: '0px'


    }
    const navLi = {
        display: 'inline-flex',
        marginLeft: '20px',
        padding: '10px',
        fontSize: '20px',
        cursor: 'pointer',
        borderTop: '2px solid green',
        userSelect: 'none'

    }

    const BASE_URL = APP_ENV.SERVER_ADDR;
    const reqHeaders = {
        "Content-Type": "application/json",

    };
    if (localStorage.getItem('AccgToken') !== 'null') {
        reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

    }
    const client = axios.create({
        baseURL: BASE_URL,
        headers: reqHeaders


    })
    const authClient = axios.create({
        baseURL: BASE_URL,


    }
    )

    const displayErrorClose = () => {
        setDisplayErrorOpen(false)
        setDialogTitleMsg('we are sorry')
    }



    const displayErrorDialogue = (
        <div>
            <Dialog
                open={displayErrorOpen}
                title='error'
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle color='red' style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {dialogTitleMsg}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={displayErrorClose} >
                        Ok
                    </Button>

                    {/* <Button onClick={removeFromCart} style={{ backgroundColor: 'red', fontSize: '6x', color: 'white' }}>Remove From Cart</Button> */}
                </DialogActions>
            </Dialog>

        </div>

    )

    const showAlert = (color = null, message = null) => {
        var cartEl = document.querySelector('#cartSuccessAlert')
        if (color) {

            setAlertColor(color)
            cartEl.children.item(1).innerHTML = message

        }
        cartEl.style.left = 0
        setTimeout(() => {
            cartEl.style.left = '-800px'

        }, 1300)
    }

    const addToWish = (id) => {

        // alert(e.target.getAttribute('dataId'))
        client.get('/auth/users/me/').then(response => {
            if (response.data) {
                if (response.data.username) {


                    client.get(`/social/api/add-to-wishlist/${response.data.username
                        }/${id}`).then(resp => {

                            // console.log(resp)

                        })
                }

            }
            props.headerUpdater.current.updateAll()
            showAlert('success', 'Wishlist Updated')


        }).catch(err => {

            showAlert('error', 'please login!')
        })


    }

    const addToCart = (id) => {

        // alert(e.target.getAttribute('dataId'))
        client.get('/auth/users/me/').then(response => {
            if (response.data) {
                if (response.data.username) {


                    client.get(`/social/api/add-to-cart/${response.data.username
                        }/${id}`).then(resp => {
                            if (resp.data.msg) {
                                setErrorMessage(resp.data.msg)
                                setDisplayErrorOpen(true)

                            }

                            props.headerUpdater.current.updateAll()
                            showAlert('success', 'Cart Updated!')

                        })


                }
            }
        }).catch(err => {
            showAlert('error', 'please login!')
        })


    }

    const removeAllFromCart = () => {

        // alert(e.target.getAttribute('dataId'))
        client.get('/auth/users/me/').then(response => {
            if (response.data) {
                if (response.data.username) {


                    client.put(`/user/api/cart`, { data: { 'method': 'clear-all' } }).then(resp => {
                        if (resp.data.msg) {
                            setErrorMessage(resp.data.msg)
                            setDisplayErrorOpen(true)

                        }

                        props.headerUpdater.current.updateAll()
                        showAlert('success', 'Cart Cleared!')

                    })


                }
            }
        }).catch(err => {
            showAlert('error', 'please login!')
        })


    }

    const cancelOrder = (id) => {

        // alert(e.target.getAttribute('dataId'))
        client.get('/auth/users/me/').then(response => {
            if (response.data) {
                if (response.data.username) {

                    client.put(`/user/api/order/checkout`, {
                        action: 'cancel', order_id: id
                    }).then(resp => {

                        props.headerUpdater.current.updateAll()
                        message.info(resp.data.msg)

                    })


                }
            }
        }).catch(err => {
            showAlert('error', 'please login!')
        })


    }
    const subtractFromCart = (id) => {

        if (localStorage.getItem('AccgToken') !== null) {


            client.put('/user/api/cart', { data: { 'item_id': id, 'method': 'subtract' } }).then(response => {
                if (response.data) {

                    props.headerUpdater.current.updateAll();
                    if (response.data.msg) {
                        setDialogTitleMsg('Sorry!')
                        setErrorMessage(response.data.msg)
                        setDisplayErrorOpen(true)
                    }
                }
            }).catch(err => { console.log(err) })


        }
        else {
            ;

        }
        return;
    }

    const deleteItemFromCart = (id) => {

        if (localStorage.getItem('AccgToken') !== null) {


            client.put('/user/api/cart', { data: { 'item_id': id, 'method': 'delete' } }).then(response => {
                if (response.data) {

                    props.headerUpdater.current.updateAll();

                    if (response.data.msg) {
                        setDialogTitleMsg('Sorry!')

                        setErrorMessage(response.data.msg)
                        setDisplayErrorOpen(true)
                    }
                }
            }).catch(err => { console.log(err) })


        }
        else {
            ;
        }
        return;
    }
    const addQtyToCart = (id) => {

        if (localStorage.getItem('AccgToken') !== null) {


            client.put('/user/api/cart', { data: { 'item_id': id, 'method': 'add' } }).then(response => {
                if (response.data) {
                    props.headerUpdater.current.updateAll();

                    if (response.data.msg) {
                        setDialogTitleMsg('Sorry!')
                        setErrorMessage(response.data.msg)
                        setDisplayErrorOpen(true)
                    }
                }
            }).catch(err => { console.log(err) })


        }
        else {

        }
        return;
    }


    function addAllToCart() {
        client.get('/auth/users/me/').then(response => {


            client.put('/user/api/wishlist', { action: 'add-all-to-cart' }).then(resp => {
                if (resp.data.errormsg) {
                    setDialogTitleMsg('invalid action')

                    setErrorMessage(resp.data.errormsg)
                    setDisplayErrorOpen(true)
                }
                else {
                    props.headerUpdater.current.updateAll()
                    showAlert('success', 'All Items Added to Cart !')
                }
            })



        }).catch(err => {
            showAlert('error', 'please login!')
        })


    }


    function removeAllFromWish() {
        client.get('/auth/users/me/').then(response => {


            client.put('/user/api/wishlist', { action: 'clear-all' }).then(resp => {
                if (resp.data.errormsg) {
                    setDialogTitleMsg('invalid action')

                    setErrorMessage(resp.data.errormsg)
                    setDisplayErrorOpen(true)
                }
                else {
                    props.headerUpdater.current.updateAll()
                    showAlert('success', 'All Items Removed from Wishlist!')
                }
            })



        }).catch(err => {
            showAlert('error', 'please login!')
        })


    }
    return (
        <>

            <Alert severity={alertColor} color={alertColor} style={{ width: '400px', transition: 'left 1s ease', margin: '10px auto', left: '-800px', justifyContent: 'center', zIndex: 1, position: 'fixed' }} draggable id='cartSuccessAlert'>
                <small>Cart Updated!</small>
            </Alert>
            <div style={{ textAlign: 'center' }}>

                {/* <BodyCont /> */}
                {displayErrorDialogue}

            </div>
        </>
    )
}

)