import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: 'idle',
  tabItems: [],
  tabName : 'main',
  activeTab: 0,
}

const coreReducers = createSlice({
  name: 'core',
  initialState,
  reducers: {
    switchTabs(state, action) {
      state.tabName = action.payload.name
      state.tabItems= action.payload.tabs
      state.activeTab = action.payload.active
    },
    changeStatus(state, action) {
      state.status = action.payload
    },
    gotoTab(state, action){
    state.activeTab = action.payload
    }
  },
  extraReducers: builder => {},
})



export const {switchTabs, changeStatus, gotoTab} = coreReducers.actions
export default coreReducers.reducer
