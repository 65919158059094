import axios from 'axios'
import React, { useState, createContext, useEffect, useRef } from 'react'
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
import tiktokPng from '../assets/images/tiktok-icon.png'
import { useNavigate } from 'react-router-dom'
import { message, Select, Input } from 'antd'
import { Option } from 'antd/lib/mentions'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'

import {
  Facebook,
  FavoriteBorderOutlined,
  Link,
  LinkSharp,
  LinkOutlined,
} from '@mui/icons-material'
import {
  Google,
  Instagram,
  LinkedIn,
  Map,
  Message,
  ShoppingCartOutlined,
  Twitter,
  YouTube,
} from '@mui/icons-material'
import APP_ENV from '../env'

export const addToCart = id => {
  // alert(e.target.getAttribute('dataId'))
  client
    .get('/auth/users/me/')
    .then(response => {
      if (response.data) {
        if (response.data.username) {
          client
            .get(`/social/api/add-to-cart/${response.data.username}/${id}`)
            .then(resp => {
              if (resp.data.msg) {
                message.info(resp.data.msg)
              }

              message.success('success', 'Cart Updated!')
              window.location.href = '/cart'
            })
        }
      }
    })
    .catch(err => {
      message.info('Please Login to buy')
    })
}
export const addToWish = id => {
  // alert(e.target.getAttribute('dataId'))
  client
    .get('/auth/users/me/')
    .then(response => {
      if (response.data) {
        if (response.data.username) {
          client
            .get(`/social/api/add-to-wishlist/${response.data.username}/${id}`)
            .then(resp => {
              // console.log(resp)
              message.info('Added to wishlist')
            })
        }
      }
    })
    .catch(err => {
      message.info('Please Login to add to wish')
      // showAlert('error', 'Please Login')
    })
}

export function copyFunc(e, elId) {
  var textArea = document.getElementById(elId)
  textArea.focus()
  textArea.select()
  try {
    var successful = document.execCommand('copy')
    var msg = successful
      ? (e.target.innerHTML = 'Copied')
      : message.error('unable to copy')
    message.success(msg)
  } catch (err) {
    message.error('Oops, unable to copy', err)
  }

  navigator.clipboard.writeText(textArea.value).then()
}

export const PriceInput = ({
  inputProps = {},
  value = {},
  currentProduct = null,
  onChange,
}) => {
  const [currency, setCurrency] = useState("NGN"||currentProduct?.price_currency )
  const [number, setNumber] = useState(
    parseInt(currentProduct?.price_amount ),
  )
  const triggerChange = changedValue => {
    onChange?.({
      number,
      currency,
      ...value,
      ...changedValue,
    })
  }

  React.useEffect(() => {
    if (currentProduct) {
      setCurrency(currentProduct?.price_currency)
      setNumber(currentProduct?.price_amount)
    }
  }, [currentProduct?.price_amount, currentProduct?.price_currency])
  const onNumberChange = e => {
    const newNumber = parseInt(e.target.value || '0', 10)
    if (Number.isNaN(newNumber)) {
      return
    }

    // if (!('number' in value)) {
      setNumber(newNumber)
    // }

    triggerChange({
      number: newNumber,
      currency: currency,
    })
  }

  const onCurrencyChange = newCurrency => {
    // if (!('currency' in value)) {
      setCurrency(newCurrency)
    // }

    triggerChange({
      currency: newCurrency,
      number: number,
    })
  }

  return (
    <span style={{ display: 'flex', flexDirection: 'row' }}>
      <Input
        type="text"
        value={number}
        {...inputProps}
        onChange={onNumberChange}
        style={{
          width: '100%',
          height: '40px',
          border: 'none',
          borderRadius: '0px',
          outline: 'none',
        }}
        required={true}
        placeholder='Enter Amount'
        size="large"
      />
      <Select
        value={currency}
        {...inputProps}
        style={{
          width: '100%',
          height: '40px',
          // margin: "0 8px",
          border: 'none',
          borderColor: 'inherit',
          outline: 'none',
        }}
        size="large"
        onChange={onCurrencyChange}
      >
        <Option value="NGN">NGN</Option>
        <Option value="USD" disabled>
          {' '}
          USD
        </Option>
      </Select>
    </span>
  )
}

export const randomString = length => {
  var text = ''
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export const VisitHistory = props => {
  const navigate = useNavigate()
  React.useEffect(() => {
    if (props.clicked == true) {
      if (props.path === '//') {
        return navigate(-1)
      }

      navigate(props.path)
    }
  }, [props.path])
  return <></>
}

export const BASE_URL = APP_ENV.SERVER_ADDR
export const BASE_SOCKET_URL = APP_ENV.SOCKET_URL
export const reqHeaders = {
  'Content-Type': 'application/json',
}

if (localStorage.getItem('AccgToken') !== null) {
  reqHeaders['Authorization'] = `Token ${localStorage.getItem('AccgToken')}`
}

export const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,
})
export const authClient = axios.create({
  baseURL: BASE_URL,
})
export const authToken = `Token ${localStorage.getItem('AccgToken')}`
export const LoadPDF = props => {
  const [pdfInfo, setPdfInfo] = useState([])
  const viewer = useRef(null)

  useEffect(() => {
    modifyPdf()
  }, [])

  const modifyPdf = async () => {
    const existingPdfBytes = await fetch(props.src).then(res =>
      res.arrayBuffer(),
    )

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()
    firstPage.drawText('This text was added with JavaScript!', {
      x: 5,
      y: height / 2 + 300,
      size: 50,
      font: helveticaFont,
      color: rgb(0.95, 0.1, 0.1),
      rotate: degrees(-45),
    })

    const pdfBytes = await pdfDoc.save()
    const docUrl = URL.createObjectURL(
      new Blob([pdfBytes], { type: 'application/pdf' }),
    )

    setPdfInfo(docUrl)
  }

  return (
    <>
      {
        <iframe
          title="test-frame"
          src={pdfInfo}
          ref={viewer}
          type="application/pdf"
        />
      }
    </>
  )
}

export const SocialMedia = [
  ['fb', 'Facebook'],
  ['tw', 'Twitter'],
  ['sn', 'Snapchat'],
  ['in', 'Instagram'],
  ['ln', 'LinkedIn'],
  ['tt', 'TikTok'],
  ['Yt', 'Youtube'],
  ['hg', 'Hangout'],
  ['gm', 'Gmail'],
  ['yh', 'Yahoo mail'],
]

export const getSocialAvatar = shortForm => {
  switch (shortForm) {
    case 'fb':
      return <Facebook></Facebook>
      break
    case 'in':
      return <Instagram></Instagram>

      break
    case 'tw':
      return <Twitter></Twitter>

      break
    case 'sn':
      return <i class="fa fa-snapchat" aria-hidden="true"></i>

      break
    case 'ln':
      return <LinkedIn></LinkedIn>

      break
    case 'tt':
      return <img src={tiktokPng} height={40} width={100} />

      break
    case 'Yt':
      return <YouTube></YouTube>

      break
    case 'hg':
      return <Message></Message>

      break
    case 'gm':
      return <Google></Google>

      break
    case 'yh':
      return <i class="fa fa-yahoo" aria-hidden="true"></i>
      break
    default:
      return 'S'
      break
  }
}
const getUser = async () => {
  try {
    const data = await client.get('/auth/users/me/')

    return data.data
  } catch (errr) {
    console.log('login')
    return null
  }
}

const AppContext = createContext({
  client,
  PriceInput,
  getSocialAvatar,
  BASE_URL,
  authClient,
  copyFunc,
  LoadPDF,
  getUser,
})

export default AppContext
