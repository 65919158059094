import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  alpha,
  Divider,
  IconButton,
  Button,
  Chip,
  Paper,
  Typography,
  TextField,
  TextareaAutosize,
  Input,
} from '@mui/material'
import React, { useRef } from 'react'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import { InfluencerAvatar } from '../influencer/widgets/avatar'
import { BASE_URL, authToken } from '../../app_context'
import { useNavigate, useParams } from 'react-router-dom'
import {
  SelectInfluenceHubActiveOrder,
  SelectInfluenceHubOrderStatus,
} from '../redux/order/selectors'
import { fetchActiveInHubOrder } from '../redux/order/thunks'
import { Avatar, Spin, Tag, Card as AntCard, notification } from 'antd'
import { useDispatch } from 'react-redux'
import {
  UserProfileSelector,
  UserSelector,
} from '../../../store/auth/selectors'
import {
  ChatBubbleOutline,
  ContentCopyOutlined,
  CopyAllOutlined,
  DoneOutlined,
  DownloadOutlined,
  DownloadingOutlined,
  ImageOutlined,
  OpenInFullOutlined,
  PhotoLibraryOutlined,
  PreviewOutlined,
  ReceiptOutlined,
  RingVolumeOutlined,
  TextFieldsOutlined,
  ToggleOnOutlined,
} from '@mui/icons-material'
import { FILE_TYPES, FULFILLMENT_TIMES_CHOICES } from '../platformVars'

registerPlugin(FilePondPluginFileValidateType)
registerPlugin(FilePondPluginImagePreview)

export default function InHubOrderDetail() {
  const order = SelectInfluenceHubActiveOrder()
  const params = useParams()
  const viewerIsCreator = order?.viewer_is_creator
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const stateStatus = SelectInfluenceHubOrderStatus()
  const User = UserSelector()
  const UserProfile = UserProfileSelector()

  const [mediaContents, setMediaContents] = React.useState([])
  const [currentFile, setCurrentFile] = React.useState({})
  const filePndRef = React.useRef(null)

  const [mediaFiles, setMediaFiles] = React.useState([])
  const [textCopied, setTextCopied] = React.useState(false)
  const [allContentDownloading, setAllContentDownloading] = React.useState(false)
  const copyRef = useRef(null)
  React.useEffect(() => {
    dispatch(fetchActiveInHubOrder({ code: params.code }))
  }, [])

  const TabStyle = {
    padding: '10px',
    textAlign: 'center',
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    overflow: 'hidden',
    background: alpha('#698979', 0.4),
    gap: 0.5,
    borderRadius: '5px',
  }

  const handleCopyText = () => {
    setTextCopied(true);
    navigator.clipboard.writeText(copyRef.current.value);

    setTimeout(() => {
      setTextCopied(false)
    }, 6000)
  }
  const handleRevert = file => {
    // Send a request to your backend endpoint to revert the file
    fetch(BASE_URL + `/in-hub/api/order-receipts/${file.id}/`, {
      method: 'DELETE', // Or appropriate HTTP method
    })
      .then(response => {
        if (response.ok) {
          // Optionally, you can refresh the file list or update UI here
          // load(); // Reload FilePond to reflect the changes
        } else {
          console.error('Failed to revert file')
        }
      })
      .catch(error => {
        console.error('Error occurred while reverting file:', error)
      })
  }

  const handleAllContentDownload =()=>{


  }
  return (
    <>
      <Container sx={{ width: { xs: '100%', md: '80%' }, margin: '0 auto' }}>
        <Spin spinning={stateStatus === 'loading'}>
          {order && (
            <Card>
              <CardHeader
                sx={{
                  alignItems: 'right',
                  justifyContent: 'right',
                  padding: '6px',
                  cursor: 'pointer',
                  backgroundColor: alpha('#363C39', 0.01),
                }}
                onClick={() =>
                  viewerIsCreator &&
                  navigate(`/influencers/${order.influencer.slug}`)
                }
                title={
                  <>
                    <p>
                      <b>
                        {viewerIsCreator
                          ? order.influencer.display_name
                          : order.creator?.username}
                      </b>
                      <small
                        style={{
                          fontSize: '10px',
                          color: 'teal',
                          display: 'block',
                        }}
                      >
                        {' '}
                        ({viewerIsCreator ? 'Influencer' : 'Creator'})
                      </small>

                      <span style={{ opacity: 0.7 }}>
                        Placed {order.created_at}
                      </span>
                    </p>
                  </>
                }
                avatar={
                  <InfluencerAvatar
                    sx={{ width: '80px', height: '80px' }}
                    isVerified={order.influencer.is_verified}
                    src={
                      viewerIsCreator
                        ? order.influencer.display_image
                        : order.display_picture
                    }
                  >
                    {viewerIsCreator
                      ? order.influencer.display_name[0]
                      : order.creator?.username[0]}{' '}
                  </InfluencerAvatar>
                }
              ></CardHeader>
              <Box
                sx={{
                  top: '10px',
                  position: 'absolute',
                  textAlign: 'center',
                  display: 'flex',
                  gap: 1,
                  flexDirection: 'column',
                  right: '10px',
                }}
              >
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <small>Order Code</small>
                  <Tag bordered={false}>
                    <Typography color="ButtonShadow">{order.code}</Typography>
                  </Tag>
                </span>
                {viewerIsCreator ? (
                  order.can_remind_influencer ? (
                    <Button
                      onClick={() =>
                        navigate('/user/chats?get=' + order.creator?.username)
                      }
                      variant="contained"
                      sx={{ borderRadius: '40px' }}
                      size="small"
                      startIcon={<RingVolumeOutlined />}
                    >
                      REMIND
                    </Button>
                  ) : (
                    ''
                  )
                ) : (
                  <Button
                    onClick={() =>
                      navigate('/user/chats?get=' + order.creator?.username)
                    }
                    variant="contained"
                    sx={{ borderRadius: '40px' }}
                    size="small"
                    startIcon={<ChatBubbleOutline />}
                  >
                    Chat
                  </Button>
                )}
              </Box>
              <Divider sx={{ opacity: '0.5' }} />

              <CardContent>
                <Box>
                  <Chip
                    variant="outlined"
                    color={
                      ['CA', 'F'].includes(order.status)
                        ? 'error'
                        : ['P', 'A'].includes(order.status)
                        ? 'info'
                        : ['D', 'C'].includes(order.status)
                        ? 'warning'
                        : order.status === 'FU'
                        ? 'success'
                        : 'default'
                    }
                    label={order.status_display}
                  ></Chip>
                </Box>
                {/* <Paper> */}
                <Divider sx={{ opacity: 0.4 }}>
                  {' '}
                  <Typography variant="subtitle1" fontSize={12}>
                    Order Package
                  </Typography>
                </Divider>
                <div>
                  <p>{order.package.name}</p>
                  <p>
                    <small style={{ opacity: 0.5 }}>
                      {order.package.description}
                    </small>
                  </p>
                </div>
                <Divider sx={{ opacity: '0.2' }}></Divider>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1.4,
                    width: '90%',
                    margin: '0 auto',
                    flexDirection: 'row',
                    paddingY: '10px',
                  }}
                >
                  <div style={TabStyle}>
                    <Typography variant="subtitle1" fontSize={10}>
                      ID
                    </Typography>
                    <p> {order.package.id}</p>
                  </div>
                  <div style={TabStyle}>
                    <Typography variant="subtitle1" fontSize={10}>
                      {' '}
                      FULFILLMENT{' '}
                    </Typography>
                    <p>
                      {
                        FULFILLMENT_TIMES_CHOICES.find(
                          ([key, val]) =>
                            key === order.package.fulfillment_time,
                        )[1]
                      }
                    </p>
                  </div>
                  <div style={TabStyle}>
                    <Typography variant="subtitle1" fontSize={10}>
                      MEDIUMS
                    </Typography>
                    {order.mediums.map(md => (
                      <p title={md.medium.name}>
                        <Avatar
                          size="small"
                          shape="square"
                          src={md.medium.logo}
                        >
                          {' '}
                        </Avatar>
                      </p>
                    ))}
                  </div>
                </Box>
                {/* </Paper> */}
                {/* Content Section */}
                <Divider sx={{ opacity: 0.5 }} textAlign="left">
                  Contents
                </Divider>
                {/* Order Contents  */}

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Paper
                    sx={{
                      margin: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '10px',
                    }}
                  >
                    <div>
                      <TextFieldsOutlined />{' '}
                    </div>

                    <div ><TextField variant='outlined' multiline  disabled rows={4} maxRows={8} fullWidth value={order.text_content}/></div>
                   <input  ref={copyRef} value={order.text_content} hidden></input>
                    <div style={{ textAlign: 'right', margin: '5px' }}>
                      {textCopied ? (<div style={{display:'flex', gap:0, flexDirection:'column', justifyContent:'end', alignContent:'end'}}>
                       <div> <DoneOutlined /></div>
                        <small>copied</small></div>
                      ) : (
                        <IconButton onClick={handleCopyText} title="Copy Text Content">
                          <CopyAllOutlined />

                        </IconButton>
                      )}
                    </div>
                  </Paper>

                  <Paper
                    sx={{
                      margin: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '10px',
                    }}
                  >
                    <div>
                      {' '}
                      <PhotoLibraryOutlined />{' '}
                      <Box sx={{display:'flex', flexDirection:'row'}}>
                        {order.media_contents.map(md=>{

                          return (
                            <AntCard 
                            // cover={
                            //   FILE_TYPES.

                            // } 
                            actions={[<IconButton><DownloadOutlined/></IconButton>, <IconButton><PreviewOutlined/></IconButton>]}>

                            </AntCard>
                          )
                        })}

                      </Box>
                    </div>
                    <div>
                      <ImageOutlined />
                      <div style={{ textAlign: 'right', margin: '5px' }}>
                      {allContentDownloading ? (<div style={{display:'flex', gap:0, flexDirection:'column', justifyContent:'end', alignContent:'end'}}>
                       <div> <DownloadingOutlined /></div>
                        <small>Download Started</small></div>
                      ) : (
                        <Button startIcon={<DownloadOutlined/>} onClick={handleAllContentDownload} variant='contained' >
                            Download All

                        </Button>
                      )}
                    </div> 
                    </div>
                  </Paper>
                </Box>

                {/* Action Section  */}
                <AntCard actions={['Click me', 'I am here']}>
                  <Box
                    className="first-section"
                    sx={{
                      margin: '10px',
                      justifyContent: 'right',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Paper
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right',
                        justifySelf: 'end',
                        padding: '10px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontSize={15}
                        style={{ opacity: 0.5 }}
                      >
                        Total Charge
                      </Typography>
                      {viewerIsCreator ? (
                        <Typography variant="h5" fontSize={20}>
                          {order.charge_currency}
                          {(+order.charge + +order.network_fee).toFixed(2)}
                        </Typography>
                      ) : (
                        <Typography variant="h5" fontSize={20}>
                          {order.charge_currency}
                          {(+order.charge).toFixed(2)}
                        </Typography>
                      )}
                    </Paper>
                    {order.declination_reason >= 1 &&
                    order.status === 'D' &&
                    viewerIsCreator === true ? (
                      <Paper
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right',
                          justifySelf: 'end',
                          padding: '10px',
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={15}
                          style={{ opacity: 0.5 }}
                        >
                          {' '}
                          <b>Declination Reason</b>
                        </Typography>
                        <p>{order.declination_reason}</p>
                      </Paper>
                    ) : (
                      ''
                    )}
                  </Box>

                  <Box>
                    {/* Order Receipts  */}
                    {!viewerIsCreator && order.status === 'C' ? (
                      <div
                        style={{
                          // display: 'flex',
                          flexDirection: 'row',
                          padding: '10px',
                        }}
                      >
                        <Box sx={{ display: 'block' }}>
                          {order?.receipts.map(rcp =>
                            rcp.file ? (
                              <Paper
                                sx={{
                                  padding: '10px',
                                  textAlign: 'center',
                                  overflow: 'hidden',
                                  background: alpha('#5B8384', 0.5),
                                }}
                              >
                                <Box sx={{ margin: '3px' }}>
                                  <ReceiptOutlined fontSize="20px" />
                                  <p>{rcp.uid}</p>
                                </Box>

                                <Button
                                  download={rcp.file}
                                  variant="outlined"
                                  startIcon={<DownloadOutlined />}
                                ></Button>
                              </Paper>
                            ) : (
                              rcp.text.length >= 1 && (
                                <Paper
                                  sx={{
                                    padding: '10px',
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    background: alpha('#5B8384', 0.5),
                                  }}
                                >
                                  <Typography variant="subtitle1" fontSize={14}>
                                    {rcp.text}
                                  </Typography>
                                </Paper>
                              )
                            ),
                          )}
                        </Box>
                        <div sx={{ display: 'block' }}>
                          <FilePond
                            ref={filePndRef}
                            allowImagePreview
                            allowMultiple
                            onupdatefiles={files => {
                              setMediaContents(files)
                            }}
                            labelIdle={
                              'Add Order Receipts (Screenshots or any receipt that could help in order confirmation)' +
                              '<br/>' +
                              '<small style="color:green; font-size:10px"> Drag and Drop Files Here </small>'
                            }
                            name="order_receipts"
                            onaddfile={(error, file) => {
                              setCurrentFile({
                                file: file.file,
                                fileName: file.filenameWithoutExtension,
                                fileType: file.fileType,
                              })
                            }}
                            server={{
                              process: {
                                url: BASE_URL + '/in-hub/api/order-receipts/',
                                method: 'POST',
                                withCredentials: false,

                                headers: { Authorization: authToken },

                                onload: response => {
                                  const resp = JSON.parse(response)
                                  // console.log(response);
                                  notification.success({
                                    message: 'Upload Successful',
                                  })
                                  return resp
                                },

                                ondata: formData => {
                                  formData.append('name', currentFile.fileName)
                                  formData.append('type', currentFile.fileType)
                                  formData.append('uploader', User.id)
                                  return formData
                                },
                              },
                              revert: file => handleRevert(file), // Use the serverId for revert
                            }}
                          ></FilePond>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Divider sx={{ opacity: 0.2 }}>Available Actions</Divider>
                </AntCard>
              </CardContent>
            </Card>
          )}
        </Spin>
      </Container>
    </>
  )
}
