import React, { Component } from "react";
import NavBackWidget from "./back_navigator";
import { Box, Button } from "@mui/material";
import { Divider, Input, Modal, Select,message, Form } from "antd";
import donateImg from '../../assets/images/donate.png'
import { Option } from "antd/lib/mentions";
import PaymentApp from "../authpages/wallet/recharge";
import { client } from "../app_context";
import invalidLinkImg from '../../assets/images/invalid-link.png';
import donationReceivedImg from '../../assets/images/team-happy.png';



export default class BuyUsCoffee extends Component {
constructor(props)
{
super(props)
this.state = {
    amount:0,
    currency:'NGN',
    full_name:'',
    email:'',
    phone:'',
    id:'',
    madeDonation:{},
    trx:false,
    invalidDonation:false,
    
  };
}
  componentDidMount(){
  const urlPar = new URLSearchParams(window.location.search)
    var status =  urlPar.get('status'),
     flw_ref =  urlPar.get('flw_ref'),
     trx = urlPar.get('trx')
     this.setState({'trx':trx})
     if(trx === true||trx === 'true'){
     
    
     client.get(`/user/api/donation?flw_ref=${flw_ref}&status=${status}`).then(resp => {
     this.setState({madeDonation:resp.data})
     
     }).catch(err => {this.setState({invalidDonation:true});message.error('Donation not found')});
      }
  
  }

 

  donate() {
     
   
    
    Modal.info({
      onOk: () => {
        Modal.destroyAll();
        message.error("Donation cancelled");
      },
      okText: "cancel",
      okButtonProps: { style: { backgroundColor: "red" } },
      onCancel: () => message.error("donation cancelled"),
      content: (
        <>
          <div style={{ textAlign: "center" }}>
            <small>
              These credentials are not compulsory but it would help us send our
              peaceful gratitude to you!
            </small>

            <PaymentApp
                trxType='donate'
              show_credentials_form={true}
              paymentType="Donate to accountgig"
              user={{ full_name: "", email: "", id: "", phone: "" }}
            />
          </div>
        </>
      ),
      icon: <b>&hearts;</b>,
      title: <p style={{ color: "green" }}>Donate to Accountgig</p>,
    });
  }

  
  render() {
    return (
      <div>
        <Box
          sx={{ width: { md: "60%", xs: "100%" } }}
          style={{ margin: "0 auto" }}
        >
          <NavBackWidget />

          <Box className="content-box" style={{ textAlign: "center" }}>
            {this.state.trx === false || this.state.trx === null ? (
              <>
                <div classname="img-box">
                  <img
                    width={200}
                    src={donateImg}
                    alt="donate to accountgig"
                  ></img>
                  <h2 style={{ color: "grey" }}>
                    {" "}
                    Donate and lets grow together{" "}
                  </h2>
                </div>
                <p>
                  Your donations goes directly to different sector of our
                  company that are financially incapacitated and this goes a
                  long way in helping us improve our services.
                  <br></br>
                  <b>
                    Any non-financial donations are also accepted and can be
                    done by contacting +2349039981921.
                  </b>
                  <br></br>
                  <Button
                    onClick={this.donate}
                    style={{
                      width: "50%",
                      margin: "10px",
                      borderRadius: "20px",
                      backgroundColor: "orange",
                      border: "0.1px solid darkorange",
                    }}
                  >
                    <h3 style={{ color: "lightgreen" }}>Donate &hearts; </h3>
                  </Button>
                </p>
              </>
            ) : (
              <>
                {this.state.invalidDonation === true ? (
                  <>
                    <div classname="img-box">
                      <img
                        width={400}
                        src={invalidLinkImg}
                        alt="invalid link"
                      ></img>
                      <h2 style={{ color: "red" }}>
                        {" "}
                        This link might be broken{" "}
                      </h2>
                      <p>No donation was found under this link </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div classname="img-box">
                      <img
                        width={400}
                        src={donationReceivedImg}
                        alt="donation received"
                      ></img>
                      <h2 style={{ color: "green" }}>
                        {" "}
                        <h4 style={{ color: "pale" }}>
                          wow! {this.state.madeDonation.donator}
                        </h4>
                        Your donation of <b>{this.state.madeDonation.amount}</b> was
                        received warm heartedly, the team will surely hear of
                        your good deed.
                        <br></br>
                        &#128515;{" "}
                      </h2>
                      <h1>Thank You!</h1>
                      <small>Have a blissful day!</small>
                    </div>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </div>
    );
  }
}

