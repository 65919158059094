import {
  List,
  Paper,
  Divider,
  Avatar,
  alpha,
  Chip,
  Box,
  IconButton,
  Input,
  FormControl,
} from "@mui/material";
import { Typography } from "antd";
import React from "react";
import { fetchPlatformMediums } from "../redux/influencer/thunks";
import { useDispatch } from "react-redux";
import { SelectPlatformMediums } from "../redux/influencer/selectors";
import { makeStyles } from "@mui/styles";
import { uid } from "react-uid";
import { LinkOutlined } from "@mui/icons-material";
import validator from "validator";
import axios from "axios";

const Connection = ({ medium, connected, handleConnectF }) => {
  return (
    <>
      <Box sx={{ top: 0, bottom: 0, right: 0, position: "absolute" }}>
        <IconButton
          style={{
            fontSize: "10px",
            color: connected ? "#68a2a8" : "inherit",
          }}
        // onClick={() => handleConnectF()}
        >
          {connected ? "Connected" : "Connect"} <LinkOutlined />
        </IconButton>
      </Box>
    </>
  );
};

const NotConnected = ({ medium, small, index, instance = null }) => {
  const [isValidURL, setIsValidURL] = React.useState(null);
  const { isURL } = validator;
  const [url, setURL] = React.useState(instance?.url_link  ||"");
  const [connected, setConnected] = React.useState(isURL(instance?.url_link ||'' )|| false);
  const { Text } = Typography;

  const handleURLChange = (e) => {
    if (connected === true) {
      setConnected(false);
    }
    setURL(e.target.value);
    // alert();
    setIsValidURL(isURL(e.target.value));

    if (isURL(e.target.value)) {
      setConnected(true);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
      >
        <Connection
          medium={medium}
          // handleConnectF={handleConnect}
          connected={connected}
        />

        <Avatar
          style={{
            width: small ? "10px" : "20px",
            height: small ? "10px" : "20px",
            borderRadius: 0,
          }}
          alt={medium.name}
          src={medium.logo}
        ></Avatar>
        <Box sx={{display:{md:"block", xs:'none'}}}>
        <Text style={{ fontSize: small ? "10px" : "16px" }}>
          {medium.display_name}
        </Text></Box>
        <div className="link-cont">
          {instance?.id && (
              <input
              name={index + "_id"}
              value={instance.id}
              hidden/>
          )}
          <input
            name={index + "_url_link"}
            defaultValue={instance?.url_link}
            id="app-field"
            style={{
              fontSize: "14px",

              backgroundColor: "inherit",
              border: "none",
              outline: "none",
              color: isValidURL === false ? "#AE989B" : "inherit",
              width: "200px",
            }}
            // status={isValidURL === false ? "error" : ""}
            // value={url}
            onChange={handleURLChange}
            placeholder="Enter your link"
          ></input>
        </div>
        <input hidden name={index + "_medium"} value={medium.id} />
      </div>
      {medium.connection_message && <small>{medium.connection_message}</small>}
    </>
  );
};

export const ConnectMediumComponent = React.forwardRef((props, ref) => {
  const [connectedMediums, setConnectedMediums] = React.useState([]);
  const PlatformMediums = props.platforms 
  const dispatch = useDispatch();

  const { Title, Text } = Typography;


  React.useImperativeHandle(
    ref,
    () => ({
      getConnectedMediums() {
        return connectedMediums;
      },
    }),
    []
  );
  const useStyles = makeStyles((theme) => ({
    mediumListItem: {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
      padding: "10px",
      borderRadius: "5px",
      display: "flex",
      position: "relative",
      flexDirection: "row",
    },
    notConnected: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
    },
    mediums: {
      marginTop: "10px",
      marginBottom: "10px",
      width: "100%",
      padding: "10px",
    },
    connection: {},
  }));
  const classes = useStyles();

  const Connected = ({ mediumAccount }) => {
    return (
      <>
        {mediumAccount?.connected && (
          <>
            <b>{mediumAccount.handle}</b>
            {[...new Map(Object.entries(mediumAccount.metrics))].map(
              ([key, value]) => (
                <Chip label={value + " " + key}></Chip>
              )
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Paper className={classes.mediums} variant="elevation">
      <Divider>
        <Title style={{ opacity: "0.5", fontSize: "10px" }}>
          Connect your mediums
        </Title>
      </Divider>
      <List>
        {PlatformMediums.map((medium, index) => (
          <div
            key={uid(medium)}
            className={classes.mediumListItem}
            title={medium.name}
          >
            <Connected
              mediumAccount={connectedMediums.find(
                (itm) => itm.medium === medium.id
              )}
            />

            <NotConnected
              medium={medium}
              index={index}
              instance={props.instances?.find(md => md.medium.id === medium.id)}
              small={
                connectedMediums.find((itm) => itm.id === medium.id)
                  ? true
                  : false
              }
            />
          </div>
        ))}
      </List>
    </Paper>
  );
});
