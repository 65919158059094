import { useSelector } from "react-redux";


export const SelectPlatformMediums =()=> useSelector(state=> state.influencer.platformMediums)
export const SelectInfluencerStatus =()=> useSelector(state=> state.influencer.status)
export const SelectInfluencerErrors =()=> useSelector(state=> state.influencer.error)
export const SelectInfluencers=()=> useSelector(state=> state.influencer.influencers)
export const SelectInfluencersNext=()=> useSelector(state=> state.influencer.pagination.pageNext)
export const SelectInfluencersPrevious=()=> useSelector(state=> state.influencer.pagination.pagePrev)
export const SelectInfluencersCurrentPage=()=> useSelector(state=> state.influencer.pagination.currentPage)
export const SelectInfluencersTotalCount=()=> useSelector(state=> state.influencer.pagination.totalInfluencers)
export const SelectInfluencerOnView=()=> useSelector(state=> state.influencer.influencerOnView)
export const SelectInfluencerOnViewAdPackages=()=> useSelector(state=> state.influencer.selectedInfluencerAdPackages.all)
export const SelectInfluencerOnViewActiveAdPAckage=()=> useSelector(state=> state.influencer.selectedInfluencerAdPackages.active)
export const SelectInfluencerMessage=()=> useSelector(state=> state.influencer.message)