import React, { Component } from "react";
import NavBackWidget from "./back_navigator";
import { Box } from "@mui/material";
import { Divider } from "antd";
import { Link } from "react-router-dom";

export default class Privacy extends Component {
  render() {
    return (
      <div>
        <Box
          sx={{ width: { md: "60%", xs: "100%" } }}
          style={{ margin: "0 auto" }}
        >
          <NavBackWidget />
          <Box className="textContent" style={{ padding: "20px" }}>
            <h1>Privacy and Policy</h1>
            <b>EFFECTIVE DATE 20/01/2023</b>
            <br></br>
            <p>
              Account gig is committed to protecting your privacy. We are
              committed to gaining and maintaining your trust by following a
              core set of Privacy Principles. Intro
            </p>
            <p>
              This Privacy Policy explains how Account gig uses and protects any
              information that you provide. We take your privacy seriously, so
              we would like to tell you what information we receive from you and
              how do we use it. We may ask you to provide specific information
              by which you can be identified when using this Site and our
              services. The information you provide will only be used in
              accordance with this Privacy Policy.
            </p>
            <b>What information do we collect?</b>
            <br />
            <p>
              We collect your personal information that you voluntarily provide
              to us, the information which personally identifies you, such as
              your name, e-mail address, and other information that you provide
              about yourself.
            </p>
            <p>
              Personal information also includes navigation information and
              payment information, where such information can directly or
              indirectly identify the person. Navigation information is
              information about your computer and your visits to this Site, for
              example, your IP address, geographical location, browser type,
              duration of visit, and viewed pages. We collect and process the
              payment information of Users when they carry out transactions on
              the Site.
            </p>
            <b>Access to third party information</b>
            <br />
            <p>
              Account gig uses various third-party service providers, such as
              payment systems, CRM systems, email services, and other necessary
              service providers, to help us provide you services. We may
              disclose some information about you to our thirdparty service
              providers with limited access to perform certain tasks and
              services on our behalf. We work only with trusted companies to
              ensure the security of your information and provide only the
              minimum amount of information necessary to provide our services.
            </p>
            <b>Cookie and analytical data</b>
            <br />
            <p>
              Cookies are very small text files that are stored on your computer
              when you visit some sites. We use cookies to identify your
              computer and tailor the User interface to your needs. You can
              disable any already stored cookies on your computer, but they may
              interfere with the proper operation of our Site and service. For a
              proper Account gig Site to work, you need to identify yourself as
              a User of the system and ensure that your session is safe.
            </p>
            <p>
              These instructions are not strictly required, but they are
              necessary to ensure the best user experience, and to tell us which
              pages you find most interesting (anonymously).{" "}
            </p>
            <b>How do we use personal information?</b> <br />
            <br />
            <p>
              We use the information that is collected only in accordance with
              this Privacy Policy in order to provide you with the best
              services, as well as understanding your needs. Sometimes we may
              contact you on behalf of other Users about a specific offer that
              may interest you. In this case, we will not transfer your personal
              information to third parties. We will never sell your personal
              information to third parties. We can use your personal information
              to improve your user experience by personalizing our Site, sending
              you personalized offers.
            </p>
            <b>Information disclosure</b>
            <br />
            <p>
              Account gig is committed to maintaining your trust, and we want
              you to understand when and with whom we can share information
              collected about you. We may share the information collected about
              you that was disclosed at the time of collection, as otherwise
              disclosed in this Privacy policy, as well as in the following
              circumstances: With your consent. Account gig may share your
              contact information with third-party advertising partners. You
              acknowledge this when you disclose personal information on the
              Site and allow it to be transferred to third parties who may
              contact you in various ways. Sometimes Account gig may give you
              the opportunity to receive further communications from one of our
              advertising partners. If you decide to refuse by explicitly
              agreeing to receive messages from a third-party advertiser, your
              personal information will be governed by this third party’s own
              privacy policy.
            </p>
            <b>Transfer of rights</b> <br />
            <p>
              Account gig may share your information in connection with the
              transfer of rights, such as the sale of the Site, mergers,
              acquisitions, sale of assets, or in the event of bankruptcy.
            </p>
            <b>Legal requirements</b> <br />
            <p>
              Account gig may disclose information about Users, including
              contact information, to respond to requests, court orders, court
              decisions, and other enforcement measures, as well as to fulfill
              other legal obligations. There may be circumstances where
              legislation requires Account gig to disclose information, or when
              disclosure is required for law enforcement purposes
            </p>
            <b>Protection of our Site and Users</b>
            <br />
            <p>
              We may disclose information to protect the legitimate rights,
              interests, and safety of the Site; protect the security of Site
              Users and the public; and other cases referred to in our Policy.
              Finally, we may also share aggregated or anonymous information
              with third parties to help us develop content that we hope will be
              of interest to you, or to help these third parties develop their
              service offerings.
            </p>
            <b>Data storage</b>
            <br />
            <p>
              We retain your Personal Data as long as we have a reasonable
              commercial need to store such data in order to provide you with
              services or products. E-mail addresses and other personal data are
              securely stored with limited access. Login information is
              encrypted and stored in a highly secure environment.
            </p>
            <b>Final provisions</b>
            <br />
            <p>
              The Privacy policy is subject to change at any time without prior
              notice and is the publicly available information on our Site. Any
              changes will be published on the Site immediately after they are
              made. Account gig users agree with this Privacy Policy.
            </p>
            <span>
              <p>
                If you have any questions about this privacy policy or our
                handling of your personal information, please <Link to="/contact-us">contact us</Link>.
              </p>
            </span>
            <Divider style={{ width: "70%" }}>
              <p>Last update: January 30th, 2023.</p>
            </Divider>
          </Box>
        </Box>
      </div>
    );
  }
}
