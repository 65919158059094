import { Avatar, List, Space,Badge } from "antd";
import React from "react";
import {
  CloseRounded,
  DoorFront,
  FavoriteOutlined,
  MessageOutlined,
  StarOutline } from "@mui/icons-material";
import { Box, Icon,  } from "@mui/material";

import './chat.css'

const TicketChat = ({ chat, user, setCurrentChat }) => {
  
  const IconText = ({ icon, text }) => (
    <Space
    
    
      style={{ color: "lightgrey",  borderColor: "0.1px solid rgb(39, 74, 60)" }}
    >     

      <Icon  > {icon} </Icon>
      <small style={{marginLeft:'-15px'}}>
 {text}</small>
    </Space>
  );
  
  return (
    <Box
      style={{
        borderBottom: "0.1px solid rgb(39, 74, 60)",
        overflow: "hidden",
      }}
    >
      <List.Item
        style={{
          borderBottom: "0.1px solid rgba(39, 74, 60, .3)",
          overflow: "hidden",
        }}
        onClick={() => {
          setCurrentChat({chat:chat});
        }}
        className="chat-box"

        // extra={<img width={272} alt="logo" src={chat.ticket.image} />}
      >
        <List.Item.Meta
          avatar={<Avatar size={50} src={chat.ticket.image} />}
          title={
            <p style={{ color: "#ccf", padding: "0", margin: "0" }}>
              {" "}
              {chat.name}
            </p>
          }
          description={
            <p
              style={{
                marginTop: "-10px",
                lineHeight: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "20px",
                color: "whitesmoke",
              }}
            >
              {chat.latest_message
                ? chat.latest_message.body
                : "Greetings...! 👋"}
            </p>
          }
        />
        <Box>
          <p style={{ margin: 0 }}>
            {" "}
            {
              <small style={{ color: "rgba(110, 200, 100, 0.7)" }}>
                {chat.latest_message.time}
              </small>
            }
          </p>
          <p style={{ float: "right", marginRight: "2px" }}>
            <Badge
              count={chat.unread_count}
              showZero={false}
              style={{
                backgroundColor: "whitesmoke",
                paddingRight: "4px",
                paddingLeft: "4px",
                color: "darkgreen",
              }}
            ></Badge>
          </p>
        </Box>
      </List.Item>
      <List.Item
        style={{ overflowX: "auto", overflow: "hidden" }}
        actions={[
          chat.ticket.closed === true ? (
            <IconText text="Closed" key="list-vertical-star-o" />
          ) : (
            <IconText text="Open" />
          ),
          chat.ticket.closed === true ? (
            <IconText text={`Closed on ${chat.ticket.natural_dateClosed}`} />
          ) : (
            <IconText text={`Opened on ${chat.ticket.natural_dateCreated}`} />
          ),
          <IconText text={`${chat.ticket.type}`} />,
        ]}
      ></List.Item>
    </Box>
  );
};
export default TicketChat;
