import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../components/app_context";

export const fetchUser = createAsyncThunk("auth/fetchUser", async () => {
  try {
    const response = await client.get("/auth/users/me/");
    return response.data;
  } catch (error) {
    return Promise.reject(JSON.stringify(error.response.data));
  }
});
export const fetchUserProfile = createAsyncThunk(
  "auth/fetchUserProfile",
  async () => {
    try {
      const response = await client.get("/user/api/profile/me");
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const fetchMyInfluencerPage = createAsyncThunk(
  'auth/fetchMyInfluencerPage',
  async () => {
    try {
      const response = await client.get(
        `/in-hub/api/influencers/me/`,
      )
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data))
    }
  },
)
