// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Create this file in the next step

const store = configureStore({
  reducer: rootReducer,

});

export default store;
