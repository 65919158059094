/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  List,
  Descriptions,
  Avatar,
  Empty,
  Spin,
  message,
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
} from "antd";

import { PlusOutlined, ExclamationOutlined } from "@ant-design/icons";
// import mastercard from '../../../assets/images/mastercard-logo.png'
import mastercard from "../../../assets/images/mastercard-logo.png";
import paypal from "../../../assets/images/paypal-logo-2.png";
import visa from "../../../assets/images/visa-logo.png";

import "../../../assets/styles/main.css";
import "../../../assets/styles/responsive.css";
import { Box } from "@mui/system";
import { IconButton, Paper } from "@mui/material";
import { client } from "../../app_context";
import React, { useState } from "react";
import { useRef } from "react";
import { Add, Edit, Home } from "@mui/icons-material";
import { Option } from "antd/lib/mentions";

 const AddrForm = (props) => {
 const [addr, setAddr] = useState(props.addr);

 const [whatsappNumField, setWhatsappNumField] = useState(addr.whatsappNumber)
 const [defaultField, setDefaultField] = useState(addr.default)
   const handleFormSubmit =(val)=>{
  
     val["default"]= defaultField;
     val['whatsappNumber']=whatsappNumField

 client
   .post("/user/api/billing-info", val)
   .then((resp) => {
     message.info(resp.data.msg); 
     if (resp.data.failed === true) {
     
       setAddr(val);
       props.showModal(val);
       
     }
     props.refreshData();

   })
   .catch((err) => message.error("Error occured"));
  
   }
   return (
     <>
       {" "}
       <Form initialValues={addr} ref={props.formRef} onFinish={(val) => handleFormSubmit(val)}>
         <Form.Item
           rules={[{ required: true, message: "Please Enter Full name" }]}
           name={"full_name"}
           initialValue={addr.full_name}
         >
           <Input required placeholder="Enter Full Name"></Input>
         </Form.Item>
         <Form.Item
           rules={[{ required: true, message: "Please Enter Email" }]}
           name={"email"}
           initialValue={addr.email}
         >
           <Input required placeholder="Enter Email"></Input>
         </Form.Item>

         <Form.Item
           rules={[{ required: true, message: "Please Enter phone number" }]}
           name={"phone"}
           initialValue={addr.phone}
           maxLength={11}
         >
           <Input
           
           required
             maxLength={11}
             type="tel"
               placeholder="Enter Phone number"
           ></Input>
         </Form.Item>

         <Form.Item
           name={"whatsappNumber"}
           label="whatsapp number?"
           onChange={(e) => setWhatsappNumField(e.target.checked)}
           initialValue={addr.whatsappNumber}
           style={{ margin: "0", padding: "0" }}
         >
           <Input
             type="checkbox"
             style={{ padding: "0", height: "20px", width: "30px" }}
             defaultChecked={addr.whatsappNumber}
           ></Input>
         </Form.Item>

         {props.action === "create-addr" ? (
           <Form.Item
               rules={[{  message: "Please Select address type" }]}
             name={"type"}
             initialValue={addr.type}
           >
             <Select defaultValue={addr.type} placeholder="Address Type" defaultActiveFirstOption>
               <Option value="S">Shipping Address</Option>
               <Option value="B">Billing Address</Option>
             </Select>
           </Form.Item>
         ) : (
           <Form.Item name={"id"} hidden initialValue={addr.id}>
             <Input hidden></Input>
           </Form.Item>
         )}

         <Form.Item name={"action"} hidden initialValue={props.action}>
           <Input hidden required value={props.action}></Input>
         </Form.Item>
         <Form.Item
           name={"default"}
           initialValue={addr.default}
           label={<b>default</b>}
           style={{ margin: "0", padding: "0" }}
           row={15}
         >
           <Input
             type="checkbox"
             onChange={(e) => setDefaultField(e.target.checked)}
             style={{ padding: "0", height: "20px", width: "30px" }}
             defaultChecked={addr.default}
           ></Input>
         </Form.Item>
       </Form>
     </>
   );
   
 };











function Billing() {
  
  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];
 
  const deletebtn = [
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
        fill="#111827"
        className="fill-danger"
      ></path>
    </svg>,
  ];

  const [information, setInformation] = useState([]);
  const [loading, setLoading] = useState(true)
  const addrFormRef = useRef(null);
  
  const getData =()=>{
       setLoading(true);

 client
   .get("/user/api/billing-info")
   .then((resp) => {
     if (resp.data.addresses) { 
       setInformation(resp.data.addresses.reverse());
     }
     setLoading(false);
   })
   .catch((err) => message.error("Error loading data"), setLoading(false));
  
  }
  
  React.useEffect(()=>{
  getData()
  },[])
 
 
  const mins = [
    <svg
      width="10"
      height="10"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10C5 9.44772 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11L6 11C5.44772 11 5 10.5523 5 10Z"
        className="fill-danger"
      ></path>
    </svg>,
  ];
  
  const deleteAddr=(id)=>{
  setLoading(true)
  client.post('/user/api/billing-info', {'action':'delete-addr','id':id}).then(resp=>{
  message.info(resp.data.msg) ; getData();
  
  setLoading(false);

  }).catch(err=> {message.error('something went wrong');  setLoading(false);
})
  
  }


 const editAddr = (addr)=>{
 setLoading(true)
 if (addr){
   Modal.confirm({
     onCancel: () => setLoading(false),
     onOk: () => {
       addrFormRef.current.submit();
       setLoading(false);
     },
     icon: <Edit />,
     title: <h3>Edit address ({addr.full_name})</h3>,

     content: (
       <>
         <AddrForm
           showModal={editAddr}
           refreshData={getData}
           action={"edit-addr"}
           formRef={addrFormRef}
           addr={addr}
         />
       </>
     ),
   });
   

 }

 }
 const createAddr = (addr={})=>{
 setLoading(true)
   Modal.confirm({
     icon: <Home />,
     onCancel: () => setLoading(false),
     onOk: () => {
       addrFormRef.current.submit();
       setLoading(false);
     },
     title: <h3>Create new address</h3>,
     content: (
       <>
         <AddrForm
           showModal={createAddr}
           refreshData={getData}
           action="create-addr"
           formRef={addrFormRef}
           addr={addr}
         />
       </>
     ),
   });
   
   

 
 }
 
 
  return (
    <>
      <Spin spinning={loading}>
      
        <Box style={{ marginTop: "30px", padding: "10px" }}>
          <Row gutter={[24, 0]}>
            <Col span={24} md={16} className="mb-24">
              <Card
                className="header-solid h-full"
                bordered={false}
                title={[
                  <h6 className="font-semibold m-0">Billing Information</h6>,
                ]}
                bodyStyle={{ paddingTop: "0" }}
              >
              
                {" "}
                <IconButton onClick={()=>createAddr()} title="Add Address" style={{float:'right',top:'-60px' }}>
                  <Add></Add>
                </IconButton>
                <Row gutter={[24, 24]}>
                  {information.length >= 1 ? (
                    <>
                      {information.map((i, index) => (
                        <Col span={24} key={index}>
                          <Card className="card-billing-info" bordered="false">
                            <div className="col-info">                              {i.default=== true? <small>Default -- {i.type}</small>:''}

                              <Descriptions title={i.full_name}>
                              
                                <Descriptions.Item label="Name" span={3}>
                                  {i.full_name}
                                </Descriptions.Item>

                                <Descriptions.Item
                                  label="Email Address"
                                  span={3}
                                >
                                  {i.email}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label="Phone Number"
                                  span={3}
                                >
                                  {i.phone}
                                </Descriptions.Item>
                                {/* <Descriptions.Item
                                  label="Whatsapp Number"
                                  span={3}
                                >
                                  {i.whatsappNumber}
                                </Descriptions.Item> */}
                                <Descriptions.Item
                                  label="Address Type"
                                  span={3}
                                >
                                  {i.type}
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                            <div className="col-action">
                              <Button
                                id={i.id}
                                type="link"
                                onClick={(e) => deleteAddr(i.id)}
                                danger
                              >
                                {deletebtn}DELETE
                              </Button>
                              <Button
                                type="link"
                                id={i.id}
                                onClick={(e) => editAddr(i)}
                                className="darkbtn"
                              >
                                {pencil} EDIT
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </>
                  ) : (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <Empty />
                    </div>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </Box>
      </Spin>
    </>
  );
}

export default Billing;
