/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import React from 'react';
function EChart(props) {
  const { Title, Paragraph } = Typography;
  const [catList, setCatList] = React.useState([])
  const [dataList, setDataList] = React.useState([])
  const { user } = props;
  const items = [
    {
      Title: user ? user.today_product_views_sum : 0,
      user: <><small>Today</small> Product Views</>,
    },
    {
      Title: user ? user.today_profile_views_sum : 0,
      user: (<><small>Today</small> Profile Visits</>),
    },
    {
      Title: user ? (<small>{user.total_sales}</small>) : 0,
      user: "Total Sales",
    },
    {
      Title: user ? user.products : 0,
      user: "All Products",
    },
  ];
  const months = {

    1: 'Jan',
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  }
  React.useEffect(() => {
    const datafromLoop = []
    const catfromLoop = []
    if (user.monthly_profile_views) {

      const data = user.monthly_profile_views
      for (let i = 0; i < data.length; ++i) {
        var item = data[i]
        datafromLoop.push(item.sum)
        catfromLoop.push(months[item.month])
      }

    }

    setDataList(datafromLoop)
    setCatList(catfromLoop)
  }, [user])
  const eChart = {
    series: [
      {
        name: "Profile views",
        data: dataList,
        color: "#fff",
      },
    ],

    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",

        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        categories: catList

        ,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return (val + " Views");

          },
        },
      },
    },
  };
  // console.log(catList)
  return (
    <>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={eChart.options}
          series={eChart.series}
          type="bar"
          height={220}
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>Profile Views</Title>
        <Paragraph className="lastweek">
          than last week <span className="bnb2">{user.weekly_profile_diff ? user.weekly_profile_diff.perc : ''}%</span>
        </Paragraph>
        <Paragraph className="lastweek">
          Monitor/compare your Profile Visitors and Products Views using the graphic charts.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;
