import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { TextField, Alert, Stack, Box, Icon } from "@mui/material";
import { Button, message, Spin } from "antd";
import { authClient, client } from "../../app_context";
import { useNavigate, useParams } from "react-router-dom";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/main.css";
import "../../../assets/styles/responsive.css";
import axios from "axios";

import { Layout, Menu, Row, Col, Typography, Input, Switch } from "antd";
import signinbg from "../../../assets/images/img-signin.jpg";
import { Redirect } from "react-router-dom";
import { AlertTitle } from "@mui/material";
import { Done, Error } from "@mui/icons-material";
import { margin, width } from "@mui/system";
// hooks
// import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';

function NewPasswwordForm({ onSent, onGetEmail, uid, token }) {
  // const { resetPassword } = useAuth();
  // const isMountedRef = useIsMountedRef();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const resetPassword = (val) => {
    setloading(true);
    if (val.uid) {
      authClient
        .post("/auth/users/reset_password_confirm/", val)

        .then((resp) => {
          if (resp.data.detail) {
            return message.error(resp.data.detail);
          }
          message.success("Password Successfully Changed");
          setloading(false);
          message.info('Please login to continue.');
          navigate("/sign-in");
        })
        .catch((err) => {
          console.log(err);
          message.error("something went wrong");
          setloading(false);
        });
    } else {
      message.info("Unauthorized Action");
      setloading(false);
    }
  };
  const ResetPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .required("Please Enter New Password"),
    confirm_password: Yup.string()
      .required("Please Confirm Password"),
  });

  const formik = useFormik({
    initialValues: {
     
    "uid": uid,
    "token": token,
    "new_password": "",
    "confirm_password": ""
},
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
      if (values.new_password != values.confirm_password){
        setSubmitting(false)
        setErrors({ afterSubmit:'Passwords do not match'})
      return ;
      }
        await resetPassword(values);
        //   onSent();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setErrors({ afterSubmit: error });
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Spin spinning={loading}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit}</Alert>
            )}

            <TextField
              fullWidth
              {...getFieldProps("new_password")}
              type="password"
              label="New Password"
              error={Boolean(touched.new_password && errors.new_password)}
              helperText={touched.new_password && errors.new_password}
            /> <TextField
              fullWidth
              {...getFieldProps("confirm_password")}
              type="password"
              label="Confirm Password"
              error={Boolean(touched.confirm_password && errors.confirm_password)}
              helperText={touched.confirm_password && errors.confirm_password}
            />

            <Button
              onClick={() => formik.submitForm()}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Reset Password
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Spin>
  );
}

const { Title } = Typography;
const { Header, Footer, Content } = Layout;
const template = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const profile = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signup = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signin = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      className="fill-muted"
      d="M12.25,14H1.75A1.752,1.752,0,0,1,0,12.25V3.5A1.752,1.752,0,0,1,1.75,1.75h.876V.875a.875.875,0,0,1,1.75,0V1.75h5.25V.875a.875.875,0,0,1,1.75,0V1.75h.875A1.752,1.752,0,0,1,14,3.5v8.75A1.752,1.752,0,0,1,12.25,14ZM3.5,4.375a.875.875,0,0,0,0,1.75h7a.875.875,0,0,0,0-1.75Z"
    />
  </svg>,
];
export default function ResetPasswordConfirm() {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = useState("");
  const {uid, token} = useParams()

  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ overflow: "hidden" }}
      >
        <Header>
          <div className="header-col header-brand">
            <h5>AccountGig</h5>
          </div>
          <div className="header-col header-nav">
            <Menu mode="horizontal" defaultSelectedKeys={["4"]}>
              {/* <Menu.Item key="1">
                  <Link to="/dashboard">
                    {template}
                    <span> Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/profile">
                    {profile}
                    <span>Profile</span>
                  </Link>
                </Menu.Item> */}
              <Menu.Item key="3">
                <Link to="/sign-up">
                  {signup}
                  <span> Sign Up</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/sign-in">
                  {signin}
                  <span> Sign In</span>
                </Link>
              </Menu.Item>
            </Menu>
          </div>
          <div
            className="header-col header-btn  left"
            style={{ width: "100px" }}
          >
            <Link to="/">
              <Button type="primary">Continue Shopping</Button>
            </Link>
          </div>
        </Header>
        <div
          id="login-success"
          style={{
            margin: "0 auto",
            width: "100%",
            transition: "linear 0.5s",
            left: 2000,
            position: "absolute",
            margin: "40px",
            zIndex: "2",
          }}
        >
          <Alert
            color="success"
            icon={<Done />}
            style={{
              width: "100%",
              textAlign: "center",
              justifySelf: "center",
              justifyContent: "center",
              position: "fixed",
            }}
          >
            Password Reset Successful
          </Alert>
        </div>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15" level={8}>Password Reset</Title>
              <Title className="font-regular text-muted" level={5}>
                Set New Password
              </Title>
              {errorMessage !== "" ? (
                <Alert
                  title="error"
                  color="error"
                  style={{ width: 500 }}
                  showIcon={true}
                  icon={<Error />}
                >
                  {errorMessage}
                </Alert>
              ) : (
                ""
              )}
                <NewPasswwordForm uid={uid} token={token}  />
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
