import { createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../../app_context"


//Gigs
export const fetchAllAdGigs = createAsyncThunk(
  'adGig/fetchAllGigs',
  async (payload, thunApi) => {
    try {
      const response = await client.get(`/in-hub/api/advertising-gig/`)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
export const fetchSavedAdGigs = createAsyncThunk(
  'adGig/fetchSavedAdGigs',
  async (payload, thunApi) => {
    try {
      const response = await client.get(`/in-hub/api/advertising-gig/?bookmarked=true`)
      console.log(response)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
export const fetchMyAdGigs = createAsyncThunk(
  'adGig/fetchMyAdGigs',
  async (payload, thunApi) => {
    try {
      const response = await client.get(`/in-hub/api/advertising-gig/?creator=${payload.user}`)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
export const loadNewAdGigs = createAsyncThunk(
  'adGig/loadNewAdGigs',
  async (payload, thunApi) => {
    try {
      const response = await client.get(payload.url)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
export const createNewAdGig = createAsyncThunk(
  'adGig/createNewAdGig',
  async (payload, thunApi) => {
    try {
      const response = await client.post(
        '/in-hub/api/detail-advertising-gig/',
        payload,
      )
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)

export const fetchAdGig = createAsyncThunk(
  'adGig/fetchAdGig',
  async (payload, thunApi) => {
    try {
      const response = await client.get(`/in-hub/api/detail-advertising-gig/${payload.id}/`)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
export const updateAdGig = createAsyncThunk(
  'adGig/updateAdGig',
  async (payload, thunApi) => {
    try {
      const response = await client.put(`/in-hub/api/detail-advertising-gig/${payload.id}/`,payload.data)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
export const deleteAdGig = createAsyncThunk(
  'adGig/deleteAdGig',
  async (payload, thunApi) => {
    try {
      const response = await client.delete(
        `/in-hub/api/detail-advertising-gig/${payload.id}/`
      )
      return payload.id
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)



//proposals

export const fetchMyAdGigProposals = createAsyncThunk(
  'adGig/fetchMyAdGigsProposals',
  async (payload, thunApi) => {
    try {
      const response = await client.get(`in-hub/api/ad-gig-proposal/?bidder=${payload.influencer}`)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
export const createNewAdGigProposal = createAsyncThunk(
  'adGig/createNewAdGigProposal',
  async (payload, thunApi) => {
    try {
      const response = await client.post(`in-hub/api/ad-gig-proposal/`, payload)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)

export const fetchAdGigProposal = createAsyncThunk(
  'adGig/fetchAdGigProposal',
  async (payload, thunApi) => {
    try {
      const response = await client.get(
        `in-hub/api/ad-gig-proposal/${payload.id}/`,
      )
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)

export const updateMyAdGigProposal = createAsyncThunk(
  'adGig/updateMyAdGigProposal',
  async (payload, thunApi) => {
    try {
      const response = await client.put(
        `in-hub/api/ad-gig-proposal/${payload.id}/`,
        payload.data,
      )
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)

export const deleteMyAdGigProposal = createAsyncThunk(
  'adGig/deleteAdGigProposal',
  async (payload, thunApi) => {
    try {
      const response = await client.delete(
        `in-hub/api/ad-gig-proposal/${payload.id}/`
      )
      return payload.id
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)
