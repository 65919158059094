import {
  List,
  Avatar,
  message,
  Table,
  Empty,
  Badge,
  Spin,
  Modal,
  Form,
  Select,
  Input,
} from "antd";
import React, { Component, createContext, createRef, useContext } from "react";
import {
  AppContext,
  client,
  authClient,
  VisitHistory,
  getSocialAvatar,
  addToCart,
} from "../app_context";
import DashBoardIndex from "../dashboard";
import {
  ArrowBackIos,
  ArrowDownward,
  ArrowUpward,
  Flag,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Header } from "antd/lib/layout/layout";
import { Divider, y } from "antd";
import { Link } from "react-router-dom";
import { Card } from "antd";
import { Button, Icon, IconButton, Paper, Tab } from "@mui/material";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import jsPDF from "jspdf";
import accgIcon from "../../assets/images/main-logo.png";
import { Option } from "antd/lib/mentions";
import { useAuthTabs } from "../middlewares/authTabs";

const tabListNoTitle = [
  {
    key: "Active",
    tab: "Active",
  },
  //   {
  //     key: "WaitingApproval",
  //     tab: "Waiting Approval",
  //   },
  {
    key: "Closed",
    tab: "Closed",
  },
  //   {
  //     key: "Sold",
  //     tab: "Sold",
  //   },
];

const HookComp =()=>{
  const authTabs = useAuthTabs({ active: 2 })

return <></>
}
export default class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartListData: [],
      itemClicked: false,
      itemPath: "",
      productsRef: props.productFuncs,
      activeTabKey: "Active",
      orders: [],
      loading: true,
      currentlyOpenedList: [],
      unflagRef: createRef(),
      hash: null,
    };
  }

  componentDidMount() {
    if(localStorage){

      localStorage.setItem("currentPage", "orders")
    }
    if (window.location.hash) {
      const hash_c = window.location.hash;
      this.setState({ hash: hash_c });
    }
    client
      .get("/social/api/user-orders")
      .then((resp) => {
        this.setState({ orders: resp.data });
        if (this.state.hash) {
          const hash_val = String(this.state.hash).replace("#", "");
          const el = document.getElementById(hash_val);
          if (el) el.scrollIntoView();
        }
      })
      .catch((err) => message.error(err));
    this.setState({ loading: false });
  }

  currentlyOpened(id) {
    const ordersCont = document.getElementsByClassName("orderCont");
    for (let i = 0; i < ordersCont.length; i++) {
      const element = ordersCont[i];
      if (
        element.id === "o-" + id &&
        element.classList.contains("order-open")
      ) {
        return true;
      }
    }
    return false;
  }

  handleOrderBar(id) {
    const ordersCont = document.getElementsByClassName("orderCont");

    for (let i = 0; i < ordersCont.length; i++) {
      const element = ordersCont[i];
      if (element.id === "o-" + id) {
        element.classList.toggle("order-open");

        if (this.state.currentlyOpenedList.includes(id)) {
          const newListId = this.state.currentlyOpenedList;
          newListId.pop(id);

          this.setState({ currentlyOpenedList: newListId });
        } else {
          this.setState({
            currentlyOpenedList: [...this.state.currentlyOpenedList, id],
          });
        }
      }
    }
  }
  prodDetailSubStyle = {
    backgroundColor: "whitesmoke",
    borderBottom: "0.5px solid dotted",
    padding: "10px",
    margin: "10px",
    width: "100%",
  };

  async downloadProduct(id) {
    this.setState({ loading: true });
    try {
      const response = await client.post("/social/api/download-order", {
        cart_id: id,
      });

      if (response.data.error === true) {
        message.error(response.data.msg);
        this.setState({ loading: false });
      }

      if (response.data.product) {
        setTimeout(async () => {
          const htmlDoc = response.data.file;

          const pdfDoc = new jsPDF("p", "px", [1200, 1210]);
          pdfDoc.setFont(StandardFonts.Courier);
          pdfDoc.setFontSize(40);
          pdfDoc.addImage(accgIcon, "png", 100, 100, 100, 100);

          pdfDoc.html(htmlDoc, {
            callback: function (doc) {
              doc.save();
            },
            x: 500,
          });
          message.success("Done. processing locally...");
          this.setState({ loading: false });
        }, 2000);
      }

      // }).catch((err) => message.error(err));
    } catch (err) {
      message.error("error occured-- contact us please");
      this.setState({ loading: false });
    }
  }

  onTabChange(key) {
    this.setState({ activeTabKey: key });
  }

  unflag_order(id) {
    function handleUnflag(value) {
      client
        .post("user/api/confirm-order", value)
        .then((resp) => {
          message.success("Product Unflagged successfuly");

          Modal.destroyAll();
        })
        .catch((err) => message.error("Something went wrong"));
    }
    const unflagRef = createRef();

    //  console.log(id)
    //  console.log(this.state.orders)
    const _flg = this.state.orders.filter(
      (item) => item.products.filter((prd) => prd.id === id)[0]
    )[0];
    if (!_flg) {
      return message.error("Product could not be found");
    }
    const order_code = _flg.code;
    const flagged_sub_products = _flg.products.filter(
      (item) => item.id === id
    )[0].flagged_sub_products;

    if (flagged_sub_products) {
      Modal.confirm({
        onCancel: () => Modal.destroyAll(),
        okButtonProps: { disabled: true, hidden: true },

        title: "Unflag order products",

        icon: <Flag />,
        content: (
          <>
            <Form ref={unflagRef} onFinish={(value) => handleUnflag(value)}>
              <Form.Item
                required
                rules={[
                  {
                    message: "Please select a or multiple product variants",
                    required: true,
                  },
                ]}
                label="Select Items to unflag"
                name={"sub_products"}
              >
                <Select mode="multiple">
                  {flagged_sub_products.length >= 1
                    ? flagged_sub_products.map((prd) => (
                        <Option value={prd}>{prd}</Option>
                      ))
                    : ""}
                </Select>
              </Form.Item>

              <Form.Item
                label="Action Taken"
                name={"action_taken"}
                required
                rules={[
                  {
                    message: "Please select a or multiple product variants",
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Option value="RM"> Refund Made</Option>
                  <Option value="RP"> Product Replaced</Option>
                  <Option value="N"> None/Other</Option>
                </Select>
              </Form.Item>
              <Form.Item hidden initialValue={id} name={"cart_id"}>
                <Input hidden value={id}></Input>
              </Form.Item>
              <Form.Item hidden initialValue={true} name={"unflag"}>
                <Input hidden value={true}></Input>
              </Form.Item>
              <Form.Item hidden initialValue={order_code} name={"order_code"}>
                <Input hidden value={order_code}></Input>
              </Form.Item>
              <Form.Item>
                <Button id="submit-fnl" type="submit">
                  submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ),
      });
    }
  }
  render() {
    return (
      <Spin spinning={this.state.loading} tip="Loading">
      <HookComp />
        <div>
          <Header
            children={
              <>
                <div style={{ fontSize: "30px", margin: "0" }}>My Orders</div>
              </>
            }
            style={{ backgroundColor: "transparent", fontFamily: "serif" }}
          ></Header>
          <ArrowBackIos
            style={{
              position: "absolute",
              top: "10px",
              fontSize: "20px",
              cursor: "pointer",
              marginLeft: "10px",
              marginTop: "10px",
            }}
            onClick={() => this.setState({ itemPath: "//", itemClicked: true })}
          />

          <VisitHistory
            path={this.state.itemPath}
            clicked={this.state.itemClicked}
          ></VisitHistory>

          <Divider />
          <Box>
            <Card
              style={{
                margin: "auto",
                width: "90%",
              }}
              tabList={tabListNoTitle}
              tabProps={{ color: "red" }}
              // activeTabKey={this.state.activeTabKey}
              // tabBarExtraContent={<Link to="/sell">Upload</Link>}
              onTabChange={(key) => {
                this.onTabChange(key);
              }}
            >
              {this.state.orders.length >= 1 ? (
                <>
                  {this.state.orders.map((item, index) => (
                    <>
                      {item.stateStatus === this.state.activeTabKey ? (
                        <Card
                          className={`orderCont order-${index >3 ?'closed':'open'}`}
                          id={"o-" + item.id}
                          style={{
                            height: "120px",
                            overflow: "hidden",
                            padding: "10px",
                          }}
                        >
                          <div id={item.code}>
                            <div
                              className="OrderHeader"
                              style={{
                                padding: "20px",
                                justifyContent: "center",
                                width: "100%",
                                height: "120px",
                                backkgroundColor: "whitesmoke",
                              }}
                            >
                              <h4>Order code: {item.code}</h4>
                              <b style={{ float: "right", opacity: "0.5" }}>
                                Date ordered : {item.dateDelivered}
                              </b>
                              <div style={{ float: "left" }}>
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    display: "inline-block",
                                  }}
                                >
                                  Status :
                                </p>{" "}
                                <p
                                  style={{
                                    display: "inline-block",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  {" "}
                                  <Badge
                                    status={item.status_tag}
                                    text={
                                      item.status_tag === "success"
                                        ? "Delivered"
                                        : "Awaiting..."
                                    }
                                  ></Badge>
                                </p>
                              </div>

                              <div
                                id="open-btn"
                                style={{
                                  right: "0",
                                  top: "0px",
                                  margin: "20px",
                                  position: "absolute",
                                }}
                              >
                                {this.state.currentlyOpenedList.includes(
                                  item.id
                                ) ? (
                                  <IconButton
                                    onClick={() => {
                                      this.handleOrderBar(item.id);
                                    }}
                                    title={"view more"}
                                  >
                                    <ArrowDownward></ArrowDownward>
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      this.handleOrderBar(item.id);
                                    }}
                                    title={"view less"}
                                  >
                                    <ArrowUpward />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                            <Divider
                              style={{ padding: "0", margin: "5px" }}
                            ></Divider>{" "}
                            {item.products.length >= 1 ? (
                              <>
                                {item.products.map((product) => (
                                  <>
                                    <div className="ind-cart-order">
                                      {product.buyer_validated === false &&
                                      product.rectified === false &&
                                      product.flagged === false ? (
                                        <button
                                          className="attention_needed"
                                          onClick={() =>
                                            this.setState({
                                              itemClicked: true,
                                              itemPath: `/order/status/${item.code}?payment_status=${item.payment_status}`,
                                            })
                                          }
                                          title="validate order"
                                          style={{
                                            color: "white",
                                            backgroundColor: "skyblue",
                                            margin: "10px",
                                            padding: "1px",
                                            borderRadius: "10px",
                                            border: "none",
                                          }}
                                        >
                                          Confirm Order
                                        </button>
                                      ) : product.flagged === true &&
                                        product.rectified == false ? (
                                        <button
                                          title="order flagged"
                                          onClick={() =>
                                            this.unflag_order(product.id)
                                          }
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            margin: "10px",
                                            padding: "1px",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                            border: "none",
                                          }}
                                        >
                                          Unflag Order
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            this.setState({
                                              itemClicked: true,
                                              itemPath: `/order/status/${item.code}?payment_status=${item.payment_status}`,
                                            })
                                          }
                                          title="order validated"
                                          style={{
                                            cursor: "pointer",
                                            color: "white",
                                            backgroundColor: "lightgreen",
                                            margin: "10px",
                                            padding: "1px",
                                            borderRadius: "10px",
                                            border: "none",
                                          }}
                                        >
                                          Order Confirmed
                                        </button>
                                      )}
                                      <b className="order-val">
                                        ID: {"   "}
                                        {"   "}{" "}
                                        <p
                                          style={{
                                            display: "inline-block",
                                          }}
                                        >
                                          {product.id}
                                        </p>
                                      </b>{" "}
                                      <Box
                                        className="orderImgCont"
                                        sx={{
                                          float: "left",
                                          justifyContent: "left",
                                          height: {
                                            md: "200px",
                                            xs: "300px",
                                          },
                                          width: {
                                            xs: "inherit",
                                            md: "300px",
                                            overflow: "hidden",
                                            borderRadius: "10px",
                                            margin: "10px",
                                            boxShadow:
                                              "0.2px 0.2px 3px 0.2px whitesmoke",
                                            padding: "10px",
                                          },
                                        }}
                                      >
                                        <Icon>
                                          {getSocialAvatar(product.socialMedia)}
                                        </Icon>
                                        <b
                                          style={{
                                            float: "right",
                                            opacity: "0.5",
                                            fontFamily: "monospace",
                                          }}
                                          id="prod-category"
                                        >
                                          {product.category}
                                        </b>
                                        <Link
                                          to={`/p/${product.slug}/${product.product_id}`}
                                        >
                                          <img
                                            alt="Product image"
                                            src={product.ordered_image}
                                            style={{
                                              borderRadius: "5px",
                                              objectFit: "cover",
                                            }}
                                          ></img>
                                          <div
                                            className="prod-title"
                                            style={{
                                              padding: "10px",
                                              display: "grid",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <b>{product.title}</b>
                                          </div>{" "}
                                        </Link>
                                      </Box>
                                      <Box className="tab-cont">
                                        <Box
                                          sx={{
                                            textAlign: "center",
                                            margin: "0 auto",
                                            float: "right",
                                            display: "inline-flex",
                                            flexGrow: "2",
                                            floodColor: "red",

                                            // justifyContent: "c",
                                          }}
                                        >
                                          <div
                                            style={this.prodDetailSubStyle}
                                            className="order-product-detail-tab"
                                          >
                                            <p className="order-key">
                                              Download status:
                                            </p>
                                            <p className="order-val">
                                              {product.downloaded === true
                                                ? "Downloaded"
                                                : "Not downloaded"}
                                            </p>
                                          </div>

                                          <div
                                            style={this.prodDetailSubStyle}
                                            className="order-product-detail-tab"
                                          >
                                            <p className="order-key">
                                              Percentage Discount :
                                            </p>
                                            <p className="order-val">
                                              {product.perc_discount}%
                                            </p>
                                          </div>
                                          <div
                                            style={this.prodDetailSubStyle}
                                            className="order-product-detail-tab"
                                          >
                                            <p className="order-key">
                                              Quantity:
                                            </p>
                                            <p className="order-val">
                                              {product.quantity}pcs
                                            </p>
                                          </div>
                                        </Box>
                                        <Box
                                          sx={{
                                            textAlign: "center",
                                            margin: "0 auto",
                                            float: "right",
                                            display: "inline-flex",
                                            flexGrow: "2",
                                            floodColor: "red",

                                            // justifyContent: "c",
                                          }}
                                        >
                                          <div
                                            style={this.prodDetailSubStyle}
                                            className="order-product-detail-tab"
                                          >
                                            <p className="order-key">
                                              Total Price :
                                            </p>
                                            <p className="order-val">
                                              {product.total_cost}
                                            </p>
                                          </div>
                                          <div
                                            style={this.prodDetailSubStyle}
                                            className="order-product-detail-tab"
                                          >
                                            <p className="order-key">
                                              status :
                                            </p>
                                            <p className="order-val">
                                              {product.status}
                                            </p>
                                          </div>
                                          <div
                                            style={this.prodDetailSubStyle}
                                            className="order-product-detail-tab"
                                          >
                                            <Link
                                              to={"/profile/" + product.seller}
                                            >
                                              <p className="order-key">
                                                Seller:
                                              </p>
                                              <p className="order-val">
                                                {product.seller}
                                              </p>
                                              <Avatar
                                                src={product.seller_avatar}
                                              />
                                            </Link>
                                          </div>
                                          <div
                                            style={this.prodDetailSubStyle}
                                            className="order-product-detail-tab"
                                          >
                                            <p className="order-key">
                                              Media Name:
                                            </p>
                                            <p className="order-val">
                                              {product.mediaName}
                                            </p>
                                          </div>
                                        </Box>
                                      </Box>
                                    </div>
                                    <Box
                                      style={{ padding: "30px" }}
                                      className="order-btns"
                                    >
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                        }}
                                      >
                                        <Button
                                          sx={{
                                            width: {
                                              xs: "100%",
                                              md: "50%",
                                            },
                                          }}
                                          style={{
                                            bottom: "0",
                                            padding: "10px",
                                            color: "whitesmoke",
                                            margin: "10px auto",

                                            justifySelf: "right",
                                            fontSize: "15px",
                                            display: "inline-block",
                                            backgroundColor: "#2a452c",
                                            boxShadow: "2px 1px 1px 1px ",
                                          }}
                                          onClick={() =>
                                            this.downloadProduct(product.id)
                                          }
                                        >
                                          Download
                                        </Button>
                                      </div>
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                        }}
                                      >
                                        <Button
                                          sx={{
                                            width: {
                                              xs: "100%",
                                              md: "50%",
                                            },
                                          }}
                                          style={{
                                            bottom: "0",
                                            margin: "10px auto",
                                            fontSize: "16px",
                                            color: "#2a452c",

                                            // float: "right",
                                            // backgroundColor: "whitesmoke",
                                            boxShadow:
                                              "2px 1px 1px 1px #2a452c",
                                          }}
                                          onClick={() => {
                                            addToCart(product.product_id);
                                          }}
                                        >
                                          Buy again
                                        </Button>
                                      </div>
                                    </Box>
                                  </>
                                ))}
                              </>
                            ) : (
                              <Empty />
                            )}
                          </div>
                        </Card>
                      ) : (
                        ""
                      )}
                    </>
                  ))}{" "}
                </>
              ) : (
                <Empty>
                  <Link to={"/"}>Shop</Link>
                </Empty>
              )}

              {/* <Empty /> */}

              {/* {contentListNoTitle[activeTabKey]} */}
            </Card>
          </Box>
        </div>
      </Spin>
    );
  }
}
