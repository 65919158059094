class UserModel {
    username;
    email;
    isAuth;
    constructor(usernameC, emailC, isAuthC) {

        this.email = emailC;
        this.username = usernameC;
        this.isAuth = isAuthC;
    }


}

export default UserModel;