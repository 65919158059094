
import * as React from 'react';
import AccountPosts from './accountsPosts';

function AccountList(props) {
    // const CardStyle = {

    //     backgroundColor: 'rgb(37, 44, 48, .5)',
    //     margin: '5px',
    //     display: 'inline-block'
    // }
    const [accountTypeIndisplay, setaccountTypeIndisplay] = React.useState('all')

    return (
      <>
     
          <AccountPosts
            AcccountType={accountTypeIndisplay}
            addToWish={props.addToWish}
            addToCart={props.addToCart}
          />
          {/* <BodyCont /> */}
       
      </>
    );
}


export default AccountList