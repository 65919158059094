import React from 'react'
import {
  SelectInfluencerStatus,
  SelectPlatformMediums,
} from '../redux/influencer/selectors'
import {
  Box,
  Button,
  Select,
  FormControl,
  Checkbox,
  IconButton,
  InputLabel,
  MenuItem,
  Drawer,
  Container,
  TextField,
  Divider,
  FormLabel,
} from '@mui/material'
import GigCard from './widgets/card'
import {
  SelectAdGigStatus,
  SelectAllAdGigs,
  SelectLoadingNewAdGigs,
  SelectSavedAdGigs,
} from '../redux/gig/selectors'
import {
  UserInfluencerPage,
  UserProfileSelector,
  UserSelector,
} from '../../../store/auth/selectors'
import { fetchMyInfluencerPage } from '../../../store/auth/thunks'
import {
  Col,
  Empty,
  FloatButton,
  Row,
  Pagination,
  Button as AntButton,
  Spin,
  Tabs,
  Typography,
  Badge,
  Input,
  Grid,
} from 'antd'
import { uid } from 'react-uid'
import {
  Bookmarks,
  BookmarksOutlined,
  BookmarksSharp,
  ClearAllOutlined,
} from '@mui/icons-material'

import { useDispatch } from 'react-redux'
import {
  fetchAllAdGigs,
  fetchMyAdGigs,
  fetchSavedAdGigs,
  loadNewAdGigs,
} from '../redux/gig/thunks'

const MineTab = () => {
  const gigs = SelectAllAdGigs()
  const user = UserSelector()
  const userInfluencerPage = UserInfluencerPage()
  const dispatch = useDispatch()
  React.useState(() => {
    dispatch(fetchMyAdGigs({ user: user.id }))
  }, [])
  return (
    <>
      <Row
        gutter={8}
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',

          alignItems: 'center',
        }}
      >
        {gigs?.map((gig, index) => (
          <Col
            style={{ marginBottom: '10px' }}
            key={uid(gig)}
            // span={window.screen.width >= 720 ? 2 : 10}
          >
            <GigCard gig={gig} />
          </Col>
        ))}
      </Row>
      {gigs?.length <= 0 && (
        <Empty
          // image={<People color="inherit" fontSize={'large'} />}
          style={{ marginTop: { md: 50, xs: 100 } }}
          description={'You have no gigs yet'}
        ></Empty>
      )}
    </>
  )
}

const AllTab = () => {
  const platformApprovedMediums = SelectPlatformMediums()

  const [itemsPerPage, setItemsPerPage] = React.useState(21)
  const [filterByBookMarks, setFilterByBookmarks] = React.useState(false)
  const [selectedMediumFilter, setSelectedMediumFilter] = React.useState([])
  const [influencersFilters, setInfluencerFilters] = React.useState(
    `?scope=all&page_size=` + itemsPerPage,
  )
  const userInfluencerPage = UserInfluencerPage()
  const dispatch = useDispatch()
  const [isFinalGig, setIsFinalGig] = React.useState(false)
  const loadingNewGigs = SelectLoadingNewAdGigs()
  const lastGigComponentRef = React.useRef(null)
  const gigs = SelectAllAdGigs()
  const handleBookmarks = () => {}
  React.useState(() => {
    dispatch(fetchAllAdGigs())
  }, [])

  const handleLoadNewGigs = () => {
    console.log('new loading ')
    dispatch(loadingNewGigs(true))

    dispatch(loadNewAdGigs())
  }
  React.useState(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        isFinalGig(!entry.isIntersecting)
      },
      { threshold: 0.5 },
    )

    if (lastGigComponentRef.current) {
      observer.observe()
    }

    if (isFinalGig === true) {
      handleLoadNewGigs()
    }

    return () => {
      if (lastGigComponentRef.current) {
        observer.unobserve()
      }
    }
  }, [isFinalGig])

  return (
    <>
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'start',
          alignContent: 'start',
          position: 'relative',
          marginLeft: { md: '75px', xs: '10px' },
          alignItems: 'start',
        }}
      >
        <form id="fil-for" method="get">
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <input name="mediums" value={selectedMediumFilter} hidden></input>
            <IconButton title="Saved Gigs" onClick={handleBookmarks}>
              {filterByBookMarks ? <Bookmarks /> : <BookmarksOutlined />}{' '}
            </IconButton>

            <IconButton
              title="clear filter"
              onClick={() => {
                setFilterByBookmarks(false)
                setSelectedMediumFilter([])
                setInfluencerFilters('?scope=alls')
              }}
            >
              {' '}
              <ClearAllOutlined />{' '}
            </IconButton>

            <FormControl
              fullWidth
              sx={{ minWidth: 200, maxWidth: { md: 400, xs: 250 } }}
              variant="outlined"
            >
              {' '}
              <InputLabel
                sx={{ marginBottom: '20px!important' }}
                variant="outlined"
                size="small"
              >
                {' '}
                Mediums
              </InputLabel>
              <Select
                style={{ height: 40 }}
                fullWidth
                // onChange={handleSel}
                variant="outlined"
                multiple
                value={selectedMediumFilter}
                renderValue={selected =>
                  [
                    ...new Set(
                      platformApprovedMediums
                        .map(itm => {
                          if (selected.includes(itm.id)) return itm.display_name
                        })
                        .reverse(),
                    ),
                  ].join('|')
                }
              >
                {platformApprovedMediums?.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      checked={selectedMediumFilter.indexOf(option.id) > -1}
                    />
                    {option.display_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: 80 }}>
              <Button type="submit" variant="outlined" size="medium">
                Filter
              </Button>
            </FormControl>
          </div>
        </form>
      </Box>
      <Row
        gutter={8}
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',

          alignItems: 'center',
        }}
      >
        {gigs?.map((gig, index) => (
          <Col
            ref={index === gig.length - 1 ? lastGigComponentRef : null}
            style={{ marginBottom: '10px' }}
            key={uid(gig)}
            // span={window.screen.width >= 720 ? 2 : 10}
          >
            <GigCard gig={gig} />
          </Col>
        ))}
      </Row>
      {gigs?.length <= 0 && (
        <Empty
          // image={<People color="inherit" fontSize={'large'} />}
          style={{ marginTop: { md: 50, xs: 100 } }}
          description={'No Gigs Available Right Now'}
        ></Empty>
      )}
    </>
  )
}
const BookMarksTab = () => {
  const gigs = SelectSavedAdGigs()

  const userInfluencerPage = UserInfluencerPage()
  const dispatch = useDispatch()
  React.useState(() => {
    dispatch(fetchSavedAdGigs())
  }, [])

  return (
    <>
      {' '}
      <Row
        gutter={8}
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',

          alignItems: 'center',
        }}
      >
        {gigs?.map((gig, index) => (
          <Col
            style={{ marginBottom: '10px' }}
            key={uid(gig)}
            // span={window.screen.width >= 720 ? 2 : 10}
          >
            <GigCard gig={gig} />
          </Col>
        ))}
      </Row>
      {gigs?.length <= 0 && (
        <Empty
          // image={<People color="inherit" fontSize={'large'} />}
          style={{ marginTop: { md: 50, xs: 100 } }}
          description={'You have not bookmarked any gig'}
        ></Empty>
      )}
    </>
  )
}
const GigTabs = [
  {
    title: 'All',
    value: 'all',
    component: <AllTab />,
  },
  {
    title: 'Mine',
    value: 'mine',
    component: <MineTab />,
  },
  {
    title: 'Saved',
    value: 'saved',
    component: <BookMarksTab />,
  },
]

export default function GigIndex() {
  const influenceHubStatus = SelectInfluencerStatus()
  const adGigStatus = SelectAdGigStatus()

  const [activeTab, setActiveTab] = React.useState(0)
  const userInfluencerPage = UserInfluencerPage()
  const userProfile = UserProfileSelector()
  const [currentAction, setCurrentAction] = React.useState(null)
  const [activeGig, setActiveGig] = React.useState(null)
  const dispatch = useDispatch()

  React.useState(() => {
    dispatch(fetchMyInfluencerPage())
  })

  const handleCreateNewGig = () => {
    setCurrentAction('new')
  }

  return (
    <Container
      sx={{
        margin: '0 auto',
        width: {
          xs: '100%',
          md: '90%',
          '&.ant-tabs-tab': { minWidth: '250px!important' },
        },
      }}
    >
      <Spin spinning={adGigStatus === 'loading'}>
        {' '}
        <Box sx={{ display: 'flex', float: 'right' }}>
          <Button
            // startIcon={<UploadOutlined  />}
            variant="contained"
            style={{ fontWeight: 'bold' }}
            size="small"
            onClick={handleCreateNewGig}
          >
            <small> POST</small>
          </Button>
        </Box>
        <Box sx={{ width: '100%' }}>
          <FloatButton
            style={{ left: 10 }}
            icon={<h2>+</h2>}
            onClick={handleCreateNewGig}
            type="primary"
          ></FloatButton>
        </Box>
        <header>
          <Tabs
            type="card"
            tabPosition="top"
            animated
            size="small"
            defaultActiveKey={activeTab}
            items={GigTabs.map((tb, index) => ({
              children: tb.component,
              key: uid(tb),

              label: (
                <Badge
                  color="green"
                  count={
                    tb.value === 'mine' ? userProfile.unopened_gig_proposals : 0
                  }
                >
                  <Box
                    sx={{
                      minWidth: '80px',
                      textAlign: 'center',
                      fontSize: { xs: '12px', md: '16px' },
                    }}
                  >
                    {' '}
                    {tb.title}
                  </Box>
                </Badge>
              ),
            }))}
          ></Tabs>
        </header>
      </Spin>

      <Drawer
        anchor="bottom"
        open={currentAction === 'new' || currentAction === 'edit'}
        onClose={() => setCurrentAction(null)}
      >
        <Container
          style={{
            height: '70vh',
            width: '100%',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollBehavior: 'smooth',
          }}
        >
          <Typography.Title level={3}>
            {' '}
            {currentAction?.toUpperCase()} GIG
          </Typography.Title>
          <Divider />
          <br />
          <GigCreateEditForm gig={activeGig} action={currentAction} />
        </Container>
      </Drawer>
    </Container>
  )
}

const GigCreateEditForm = ({ gig = null, action = 'new' }) => {
  const [descrSameAsTContent, setdescrSameAsTContent] = React.useState(true)
  return (
    <>
      <form>
        <Row gutter={4} justify={'center'}>
          <Col flex={'auto'} md={12} sx={24}>
            <FormControl fullWidth>
              <TextField
                multiline
                title="Gig description"
                name="description"
                placeholder="State what you want from an influencer(s)"
                rows={descrSameAsTContent ? 9 : 4}
              />
            </FormControl>
            <FormLabel content="dddddddddddddd" q></FormLabel>
            <small> Contains text content or no text content</small>
            <br />
            <Checkbox
              onChange={e => setdescrSameAsTContent(e.target.checked)}
              checked={descrSameAsTContent}
            ></Checkbox>

            {/* </FormLabel> */}
          </Col>
        </Row>
        <Row gutter={4} justify={'center'}>
          {!descrSameAsTContent && (
            <Col md={12} sx={24}>
              <FormControl fullWidth>
                <TextField
                  multiline
                  title="Gig text content"
                  name="text_content"
                  placeholder="Caption to be used in advert/order"
                  rows={descrSameAsTContent ? 9 : 4}
                />
              </FormControl>
            </Col>
          )}
        </Row>
        <Row gutter={4} justify={'center'}>
        
        
        </Row>
        <Row gutter={4} justify={'center'}>
          <Col flex="auto" md={12} sx={24}>
            <FormControl style={{ marginTop: 10 }}>
              {action === 'edit' ? (
                <Button variant="contained">Update</Button>
              ) : (
                <Button variant="contained">Post</Button>
              )}
            </FormControl>
          </Col>
        </Row>
      </form>
    </>
  )
}
