import { ArrowBackIosNewSharp, Home } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import notFoundImg from '../assets/images/notFound.png'
export default function NotFound() {
    const navigate  = useNavigate()
    
    
  return (
    <>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          display: "grid",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <img width={400} src={notFoundImg} />
      </div>
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          fontFamily: "sans-serif",
        }}
      >
        <h2>Could be you lost your way.</h2>
        <p>
          <Button onClick={() => navigate(-1)} style={{ color: "green" }}>
            <ArrowBackIosNewSharp /> Take me BACK{" "}
          </Button> |
          <Button onClick={() => navigate("/")} style={{ color: "green" }}>
            {" "}
            I want to go Home <Home />
          </Button>
        </p>
      </div>
    </>
  );
}
