import React, { Component, useRef, useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import orderSuccessful from '../../assets/images/order-successful.png';
import orderFailed from '../../assets/images/order-failed.png';
import orderPending from '../../assets/images/order-pending.png';
import bgImage from '../../assets/images/bg-image.jpg';
import { Icon, Box, FormLabel, Divider, IconButton, Container } from '@mui/material';
import { ArrowDownwardRounded, Cancel, ContentCutOutlined, Done, FavoriteOutlined, Pending, } from '@mui/icons-material';
import { Button, Form, Input, Modal, Spin, Rate, Badge, message, Card, Avatar as Antavatar, Tag, Upload, notification, Select } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import axios from 'axios';
import { ArrowBackIos, } from '@mui/icons-material';
import { Option } from 'antd/lib/mentions';
import APP_ENV from '../../env';

const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {

    "Content-Type": "application/json",

};

if (localStorage.getItem('AccgToken') !== null) {

    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}
const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


})
function OrderStatus() {
    const navigate = useNavigate()
    const [orderCode, setOrderCode] = useState(
      useMatch("/order/status/:code").params.code
    );
    const [problemFormModal, setproblemFormModal] = useState(false)
    const [loading, setloading] = useState(true)
    const [confirmed, setconfirmed] = useState(false)
    const [userRating, setUserRating] = useState(2)
    const problemFormRef = useRef(null)
    const techProblemFormRef = useRef(null)
    const [order, setorder] = useState({})
    const [sellers, setsellers] = useState([])
    const [products, setproducts] = useState([])
    const thankYouModalRef = useRef(null)
    const sellerFeedbackFormRef = useRef(null)
    const [refresh, setRefresh] = useState(false)
    const [reportForm] = Form.useForm()
    const [sellerFeedBackForm] = Form.useForm()
    const [techReportForm] = Form.useForm()
    const [fileBArrays, setFileBArrays] = useState([])
    const [technicalReport, setTechnicalReport] = useState(false)
    const [techReportFile, setTechReportFile] = useState([])
    const [sellerRatingModal, setSellerRatingModal] = useState(false)
    const status = new URLSearchParams(window.location.search).get('payment_status')

    React.useEffect(() => {


        client.get('/user/api/order/detail/' + orderCode).then(resp => {
            setorder(resp.data.order);

            setconfirmed(resp.data.order.buyer_confirmed)
            if (resp.data.order.payment_status !== status) {
                message.info('Invalid Page Entry')
                navigate('/')
            }
            const productList = []
            const sellerList = []

            for (let i = 0; i < resp.data.cart.length; i++) {
                const cart = resp.data.cart[i]
                const productData = {}
                productData['product_id'] = cart.product_id
                productData['product_image'] = cart.product_image
                productData['product_slug'] = cart.product_slug
                productData['product_title'] = cart.product_title
                productData['refund_requested'] = cart.refund_requested
                productData['product_price'] = cart.product_price
                productData['cart_id'] = cart.id
                productData['report_message'] = ''
                productData['report_file'] = []
                productData['product_flagged'] = cart.product_flagged
                productData["sub_products"] = cart.sub_products;
                productList.push(productData)
            }

            for (const cart of resp.data.cart) {
                const sellerData = {}

                const cartItem = sellerList.filter(cartItemIn => cartItemIn.seller_id == cart.product_seller)[0]
                if (cartItem) {

                    cartItem['seller_products'].push({ 'product_id': cart.product_id, 'product_title': cart.product_title, 'product_quantity': cart.product_quantity, product_image: cart.product_seller_image })
                }


                else {
                    sellerData['seller_id'] = cart.product_seller
                    sellerData['seller_username'] = cart.product_seller_username
                    sellerData['seller_image'] = cart.product_seller_image
                    sellerData['seller_products'] = [{ 'product_id': cart.product_id, 'product_title': cart.product_title, 'product_quantity': cart.product_quantity, product_image: cart.product_seller_image }]
                    // sellerData['seller_'] = cart.product_seller
                    sellerData['feedback'] = cart.product_seller_user_feedback
                    sellerData['rating'] = cart.product_seller_user_rating
                    sellerList.push(sellerData)
                }
            }



            setsellers(sellerList)
            setproducts(productList)
            setloading(false)
            setRefresh(false);
        }).catch(err => { err.response.status === 404 ? message.error('Order Not Found') : message.error('unable to get order data') })


    }, [refresh])
    // console.log(sellers, products)


    const handleProblemRequest = (val) => {
        setloading(true)
        for (let i = 0; i < products.length; i++) {
            products[i].report_message = val[`report_message-${products[i].product_id}`] ? val[`report_message-${products[i].product_id}`] : null
            products[i].sub_products = val[`sub_products-${products[i].product_id}`] ? val[`sub_products-${products[i].product_id}`]: [];
            let currentProduct = products[i]

            for (let i = 0; i < fileBArrays.length; i++) {

                if (fileBArrays[i].product_id == currentProduct.product_id) {

                    currentProduct.report_file = fileBArrays[i] ? { file: fileBArrays[i].file, file_name: fileBArrays[i].file_name } : null

                }
            }
        }
        const emptyReports = products.filter(item => item.report_message === null || item.report_message === '' || item.report_message === undefined)
        if (emptyReports.length >= products.length) {
            setloading(false)
            setRefresh(true)
            return message.info('No report to send.')
        }
        // console.log(products)

        client.post('/user/api/confirm-order',
            { order_code: orderCode, confirm: false, products_report: products }).then(resp => {
                if (resp.data.status === "Done") {
                    message.success(resp.data.status);

                    Modal.info({
                        title: 'Report received',

                        content: (


                            <> <p style={{ fontFamily: 'sans' }}>
                                A customer support personel will contact you if these issues will need your assistance or information to be rectified.
                                We are sorry for the inconveniences this may have caused you.
                                <b>Thanks for understanding.</b>
                            </p>
                            </>
                        )
                    });

                    setloading(false);
                    setRefresh(true);
                    reportForm.resetFields()
                    setproblemFormModal(false)

                }
                else {

                    setloading(false);
                    setRefresh(true);
                    message.error(resp.data.msg)
                }
                // setloading(false)

            }


            ).catch(err => {
                message.error(err);
                setloading(false);
                setRefresh(true);
            });


    }





    const handleConfirmRecievedOrder = () => {
        Modal.confirm({

            title: 'Proceed with Confirmation?',
            onOk: () => handleSellerFeedBackSubmit(),
            okText: 'Confirm order #' + orderCode,

            content: (

                <>
                    <p>
                        I confirm that my order have been received and working as expected.
                        <br></br>
                        <br></br>

                        <small>Note: After you have confirmed order, you wont be able to report them!.</small>
                    </p>
                </>
            )
        })
        setloading(false);
        setRefresh(true);

    }
    const handleTechFileChange = (e) => {

        if (e.target.files.length >= 1 && e.target.files.length <= 10) {
            setloading(true);

            for (const files of e.target.files) {
                const techFileBArray = []
                var fileReader = new FileReader()
                fileReader.readAsArrayBuffer(files)
                // console.log(files.size)
                if (files.size >= 10024000) {
                    e.target.files = null

                    return message.info('file size should not  exceed 10mb!')

                }

                fileReader.onloadend = function (evt) {
                    if (evt.target.readyState === FileReader.DONE) {

                        var arrayBuffer = evt.target.result,
                            bytesList = new Uint8Array(arrayBuffer)
                        for (var i = 0; i < bytesList.length; i++) {
                            techFileBArray.push(bytesList[i])
                        }
                    }

                }
                const currentTechfile = { file_name: files.name, file: Array(techFileBArray) }
                for (const file of techReportFile) {
                    if (file.file_name == currentTechfile.file_name) {
                        techReportFile.pop(currentTechfile)
                    }


                }
                techReportFile.push(currentTechfile)

                if (techReportFile.length <= 0) {
                    message.error('please Upload file again')

                }

            }


            setloading(false)
        }
        else {

            e.target.files = null
            return message.info('Only 10 files can be uploaded')

        }
    }

    const handleTechProblemRequest = (val) => {
        setloading(true);
        if (val.report_message === null || val.report_message === undefined || val.report_message === '') {

            setloading(false);
            setRefresh(true);
            return message.info('cannot send empty report!')

        }
        val.report_file = techReportFile
        // console.log(techReportFile)

        client.post('/user/api/confirm-order',
            { order_code: orderCode, technical: true, technical_report: val }).then(resp => {
                if (resp.data.status === "Done") {
                    message.success(resp.data.status);

                    Modal.info({
                        title: 'Report received',

                        content: (


                            <> <p style={{ fontFamily: 'sans' }}>
                                Our Technical team will surely look into your report as our service to you and your satisfaction is of great concern to us.
                                We are sorry for the inconveniences this may have caused you.
                                <b>Thanks for taking out your time to report this issue.</b>
                            </p>
                            </>
                        )
                    });

                    setloading(false);
                    setRefresh(true);
                    techReportForm.resetFields()
                    setproblemFormModal(false)

                }
                else {

                    setloading(false);
                    setRefresh(true);
                    message.error(resp.data.msg)
                }
                // setloading(false)

            }


            ).catch(err => {
                message.error(err);
                setloading(false);
                setRefresh(true);
            })


    }



    const handleFileChange = (e, product_id) => {

        if (e.target.files[0].size >= 20024000) {
            e.target.files = null

            return message.info('file size should not  exceed 20mb!')

        }

        var reader = new FileReader();
        var fileByteArray = []
        if (e.target.files.length >= 1) {
            setloading(true)

            reader.readAsArrayBuffer(e.target.files[0])

            reader.onloadend = function (evt) {
                if (evt.target.readyState == FileReader.DONE) {
                    var arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);

                    }

                }
            }
            setTimeout(() => {
                for (const item of products) {
                    if (item.product_id == product_id)
                        item.report_file = fileByteArray
                }
                // const formData = new FormData()
                // formData.append('files', new Blob(e.target.file[0]))
                const currentItem = { 'product_id': product_id, file: Array(fileByteArray), file_name: e.target.files[0].name }

                for (const fileBytes of fileBArrays) {
                    if (fileBytes.product_id == currentItem.product_id) {
                        fileBArrays.pop(currentItem)
                    }
                }
                fileBArrays.push(currentItem)
                // const newList = [...fileBArrays.filter(item => item.product_id != product_id), currentItem]
                // setFileBArrays(newList)
                if (fileBArrays.length <= 0) {
                    message.error('please Upload file again')

                }
                setloading(false)
            }, 300)
        }

    }
    const handleUserRatingRequest = () => {
        setloading(true)

        const val = {}
        val.rating = document.querySelector('#rating').value
        val.feedback = document.querySelector('#feedback').value
        if (val.feedback === null || val.feedback === '' || val.feedback === undefined && val.rating === 2 || val.rating === '') {

            setloading(false);
            setRefresh(true);
            return message.info('empty feedback!')
        }
        if (!val.rating || !val.rating) {
            val.rating = 2
        }
        setloading(true);
        client.post('/user/api/our-rating', val).then(resp => {
            setloading(false)
            Modal.destroyAll()

            message.success('Thank you for rating us.')
        }).catch(err => {
            message.error(err); Modal.destroyAll()
        })

    }
    const ProblemModal = (

        <Modal title={technicalReport ? 'Technical Report' : 'Report Order Products'} visible={problemFormModal} confirmLoading={loading} onCancel={() => {
            setproblemFormModal(false); reportForm.resetFields(); setloading(false)
        }} okText='Submit Report' onOk={() => { technicalReport ? techProblemFormRef.current.submit() : problemFormRef.current.submit() }}>
            {technicalReport ? < Button onClick={() => setTechnicalReport(false)} style={{ marginBottom: '20px', right: '-350px' }}>Report Order </Button> :
                < Button onClick={() => setTechnicalReport(true)} style={{ backgroundColor: 'black', margin: '10px', color: 'whitesmoke' }} title='this is a technical report'>Click here to report a Technical Issue</Button>

            }

            <Box display={!technicalReport ? 'none' : 'block'}>
                <Form form={techReportForm} ref={techProblemFormRef} onFinish={(val) => handleTechProblemRequest(val)} >
                    <Form.Item name={`report_title`} label='Enter Title' rules={[{ max: 200, min: 20, required: true, message: 'Please enter report title!(20 characters atleast)' }]}>
                        <Input title='report title' type='text'
                            placeholder='Report title...' name={`report_title`} style={{ height: '50px', padding: '0' }}></Input>

                    </Form.Item>
                    <Form.Item name={`report_message`} label='Enter Report' rules={[{ max: 5000, min: 50, required: true, message: 'Please enter issue or your encounter!(50 characters atleast)' }]}>
                        <Input.TextArea
                            placeholder='Note system problem(s) or your encounter...' name={`report_message`} type='textarea' style={{ height: '300px', padding: '0' }}></Input.TextArea>

                    </Form.Item>
                    <Form.Item name={`report_file`} label='Attach File (not compulsory):'  >
                        <Input multiple={true} max={10} type='file' name={`report_file`} onChange={(e) => handleTechFileChange(e)} ></Input>
                    </Form.Item>
                </Form>
            </Box>
            <Box display={technicalReport ? 'none' : 'block'} rules={[{ max: 5000, message: 'you can upload max of 10 files' }]}>

                <Form form={reportForm} ref={problemFormRef} onFinish={(val) => handleProblemRequest(val)}>

                    {products.length >= 1 ?
                        products.map((product) => {

                            return (
                              <Card
                                color={product.product_flagged ? "red" : ""}
                                style={{ fontFamily: "sans" }}
                                cover={
                                  <>
                                    <Card.Grid
                                      sx={{
                                        width: "30%",
                                        objectFit: "contain",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: { md: "none", xs: "block" },
                                        }}
                                      >
                                        <Tag
                                          style={{
                                            color: "green",
                                            float: "right",
                                          }}
                                        >
                                          {product.product_price}
                                        </Tag>

                                        <Antavatar
                                          onClick={() =>
                                            navigate(
                                              `/p/${product.product_slug}/${product.product_id}`
                                            )
                                          }
                                          shape="square"
                                          size={50}
                                          src={product.product_image}
                                        />
                                        <p>{product.product_title}</p>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: { md: "block", xs: "none" },
                                        }}
                                      >
                                        <Tag
                                          style={{
                                            color: "green",
                                            float: "right",
                                          }}
                                        >
                                          {product.product_price}
                                        </Tag>
                                        <Antavatar
                                          onClick={() =>
                                            navigate(
                                              `/p/${product.product_slug}/${product.product_id}`
                                            )
                                          }
                                          shape="square"
                                          size={100}
                                          src={product.product_image}
                                        />
                                        <p>{product.product_title}</p>{" "}
                                      </Box>
                                      {!product.product_flagged === true ? (
                                        <>
                                          <Form.Item
                                            name={`report_message-${product.product_id}`}
                                            label="Enter Report"
                                            rules={[
                                              {
                                                max: 5000,
                                                min: 50,
                                                message:
                                                  "Please enter the product problem!(50 characters atleast)",
                                              },
                                            ]}
                                          >
                                            <Input.TextArea
                                              placeholder="Note product problem(s) or your encounter..."
                                              name={`report_message-${product.product_id}`}
                                              type="textarea"
                                              style={{
                                                height: "50px",
                                                padding: "0",
                                              }}
                                            ></Input.TextArea>
                                          </Form.Item>
                                          <Form.Item
                                            name={`report_file-${product.product_id}`}
                                            label="Attach File (not compulsory):"
                                          >
                                            <Input
                                              type="file"
                                              name={`report_file-${product.product_id}`}
                                              onChange={(e) =>
                                                handleFileChange(
                                                  e,
                                                  product.product_id
                                                )
                                              }
                                            ></Input>
                                          </Form.Item>
                                          <Form.Item
                                            rules={[
                                              {
                                                message:
                                                  "Please select a or multiple product variants",
                                                required: false
                                              },
                                            ]}
                                            label="Select Faulty Product ids"
                                            name={`sub_products-${product.product_id}`}
                                          >
                                            <Select  mode="multiple">
                                              {product.sub_products.length >= 1
                                                ? product.sub_products.map(
                                                    (prd) => (
                                                      <Option
                                                        value={prd}
                                                      ></Option>
                                                    )
                                                  )
                                                : ""}
                                            </Select>
                                          </Form.Item>
                                        </>
                                      ) : (
                                        <h5
                                          style={{
                                            backgroundColor: "red",
                                            boxShadow:
                                              "0px 1px 5px 2px darkred",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            color: "white",
                                            right: "30px",
                                            position: "absolute",
                                            top: "50%",
                                          }}
                                        >
                                          Report Sent
                                        </h5>
                                      )}
                                    </Card.Grid>
                                  </>
                                }
                              >
                                <div></div>
                              </Card>
                            );
                        })

                        : 'Empty'
                    }
                </Form >

                <h5>Only products with texts will be flagged so don't worry, all empty reports will be neglected.</h5>
            </Box>
            <h5 style={{ float: 'left' }}>Need help? call: <a href='tel:+2348136095341'>+2348136095341</a></h5>
        </Modal >

    )
    const ThankYouModal = () => {
        setloading(false);
        Modal.success({

            cancelText: 'No, plz',
            title: 'hurrayy!!',
            okText: "Send",
            onCancel: Modal.destroyAll(),
            onOk() {
                handleUserRatingRequest({})
            },
            content: <>
                <Box >
                    <h1>THANKS A BUNCH!</h1>
                    <h3 style={{ color: 'green' }}>We really appreciate your patronage! <FavoriteOutlined /></h3>
                    <h4>Please rate us.</h4>
                    <br />
                    <small>Our team would really be glad to know what you think about our services <i class="fa fa-smile-o" aria-hidden="true"></i></small>
                    <Form ref={thankYouModalRef} >
                        <Form.Item name='feedback' >
                            <FormLabel>Drop us a feedback .</FormLabel>
                            <Input.TextArea id='feedback' placeholder='eg. great services!' name='feedback' style={{ height: '100px', padding: '0' }}></Input.TextArea>

                        </Form.Item>
                        <Form.Item name='rating' >
                            <FormLabel>Rate Us</FormLabel><br />
                            <Input id='rating' hidden />
                            <Rate style={{ fontSize: '50px' }} onChange={(val) => document.querySelector('#rating').value = val} className='rating' defaultValue={userRating} />
                        </Form.Item>

                    </Form>
                </Box>


            </>
        })

    }
    const wishHeaderStyle = {
        fontSize: '10px',
        borderTop: '2px solid grey',
        display: 'inline-block',
        marginRight: '5px',

        color: 'whitesmoke',
        backgroundColor: 'darkolivegreen'

    }
    const btmBtnsStyle = {
        color: 'darkgreen',
        width: '100%',
        fontSize: '10px',
        fontWeight: 'bold'
    }
    const handleSellerFeedBackSubmit = () => {
        setloading(true)
        const reqData = []
        for (let i = 0; i < sellers.length; i++) {
            const seller = sellers[i]

            seller.rating = document.getElementById(`rating-${seller.seller_id}`).innerHTML
            seller.feedback = document.getElementById(`feedback-${seller.seller_id}`).value
            if (seller.feedback) {
                reqData.push(seller)

            }
        }
        client.post('/user/api/confirm-order', { order_code: orderCode, confirm: true, sellers_rating: reqData }).then(resp => {

            message.success('order confirmed')
            setconfirmed(true)
            setloading(false);

        }).catch(err => { message.error(err); setloading(false) })

    }
    const SellerRatingModal = (

        <Modal confirmLoading={loading} visible={sellerRatingModal} onOk={handleConfirmRecievedOrder} onCancel={() => setSellerRatingModal(false)} okText='Confirm Order' >
            <Card title='Confirm Order' cover={
                <>
                    {sellers.length >= 1 ? sellers.map((seller) => {
                        return (
                            <Card style={{ height: '100px', overflow: 'hidden', padding: '0px' }}>
                                <Card.Grid style={{ width: '25%', padding: '10px', }}>
                                    <Box >
                                        <Antavatar src={seller.seller_image}>{String(seller.seller_username)[0]}</Antavatar>
                                        <small>{seller.seller_username}</small>
                                    </Box>
                                </Card.Grid>
                                <Card.Grid style={{ width: '40%', padding: '10px', height: '100px', msOverflowStyle: '-ms-autohiding-scrollbar', scrollBehavior: 'smooth', overflow: '-moz-initial' }}>
                                    <Box >

                                        <Form style={{ padding: '0', margin: '0', }} form={sellerFeedBackForm} ref={sellerFeedbackFormRef} >
                                            <Form.Item style={{ padding: '0', margin: '0', }} name={`feedback-${seller.seller_id}`}>
                                                <Input.TextArea placeholder='Write FeedBack ' style={{ padding: '0', fontSize: '10px', margin: '0', height: '40px' }}>
                                                </Input.TextArea>

                                            </Form.Item>

                                            <Form.Item name={`rating-${seller.seller_id}`} style={{ padding: '0', margin: '0', }}>
                                                <small id={`rating-${seller.seller_id}`} hidden >2</small>
                                                <Rate style={{ padding: '0', margin: '0', fontSize: '10px' }} onChange={(val) => { document.getElementById(`rating-${seller.seller_id}`).innerHTML = val; }} defaultValue={2} ></Rate>
                                                <br />
                                                <small style={{ padding: '0', margin: '0', }}>Rate Seller</small>
                                            </Form.Item>
                                        </Form>

                                    </Box>


                                </Card.Grid>


                                <Card.Grid title='Items You Bought from seller' className='order-seller-items' style={{ width: '35%', scrollBehavior: 'smooth', padding: '4px', height: '100px', overflowWrap: 'anywhere', }}>
                                    <Box style={{ marginRight: '0' }} >
                                        <Box className='order-seller-products' style={{ padding: '0', marginTop: '4px', fontSize: '10px', height: '100px', overflowX: 'hidden', width: '100%', }}>
                                            {seller.seller_products.length >= 1 ? seller.seller_products.map((product) => (
                                                <Tag style={{ width: '100%', marginTop: '4px', textOverflow: 'ellipsis', }}> <Badge size='small' count={product.product_quantity} color='#5a966f'> <div> <small> {product.product_title}</small></div>  </Badge></Tag>

                                            )) : 'Not products to show'}
                                        </Box>
                                    </Box>
                                </Card.Grid >

                            </Card >
                        )
                    })
                        : 'Nothing to show'}
                </>
            }>

                <b>Note:</b>
                <br/>
                <small>Order automatically confirms after 24hrs from when it was placed.</small>
                <br/>
                <small>Your flagged products remains flagged even after this confirmation.</small>

            </Card >

        </Modal >


    )
    const header = (
        <>
            <Header children={<>
                <div style={{ fontSize: '30px', margin: '0', }} >
                    <div style={{ float: 'left', fontSize: '17px', color: 'teal' }} >    code: #{orderCode}</div>
                    <div style={{ float: 'right' }}>
                        <Button style={wishHeaderStyle} onClick={() => { navigate('/cart') }}>Go to cart</Button></div>
                </div>     </>} style={{ backgroundColor: "transparent", fontFamily: 'serif' }}>
            </Header>
            <ArrowBackIos onClick={() => navigate(-1)} style={{ position: 'absolute', top: '10px', fontSize: '20px', cursor: 'pointer', marginLeft: '10px', marginTop: '10px', left: '0' }} />

            <Divider />

        </>
    )

    switch (status) {

        case 'successful':

            return (
              <>
                <Spin tip="loading..." spinning={loading}>
                  {header}
                  <br></br>

                  <Box
                    sx={{ width: { sx: "100%", md: "50%" }, margin: "0 auto" }}
                  >
                    <IconButton
                      onClick={() => ThankYouModal()}
                      style={{ border: "none" }}
                      title="rate us"
                    >
                      {" "}
                      <Rate
                        onChange={(val) => {
                          setUserRating(val);
                          setloading(true);
                          ThankYouModal();
                        }}
                        defaultValue={userRating}
                        style={{ borderRadius: "3px", fontSize: "10px" }}
                      />
                    </IconButton>

                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        height: "700px",
                        boxShadow: "2px 1px 5px 1px",
                        borderRadius: "20px",
                      }}
                    >
                      <br />
                      <div
                        style={{
                          width: "80%",
                          textAlign: "center",
                          margin: "0 auto",
                        }}
                      >
                        <Done
                          sx={{ margin: { xs: "40px", md: "50px" } }}
                          style={{
                            fontSize: 100,
                            backgroundColor: "whitesmoke",
                            boxShadow: "4px 4px 1px 3px forestgreen",
                            color: "graytext",
                            borderRadius: "50%",
                          }}
                        />
                      
                        <h1
                          style={{
                            fontSize: "30px",
                            fontFamily: "sans-serif",
                            color: "green",
                            border: "0.1px solid whitesmoke",
                            padding:'10px',
                            backgroundColor: "fff",
                            borderRadius:'6px',
                            marginTop: "20px",
                            width: "100%",
                          }}
                        >
                          {" "}
                          Order {status}
                          <p>
                            Your order{" "}
                            <b
                              style={{
                                backgroundColor: "lightgrey",
                                color: "black",
                              }}
                            >
                              #{orderCode}
                            </b>{" "}
                            was successful.
                            <br />
                            You can view and download products on the Order
                            Dashboard
                          </p>
                        </h1>
  <Button
                          style={{
                            // backgroundColor: "Background",
                            width: "100%",
                            marginBottom:'20px'
                          }}
                          onClick={() => navigate("/orders#"+orderCode)}
                        >
                          {" "}
                          See Order{" "}
                          <i
                            class="fa fa-dashboard"
                            aria-hidden="true"
                          ></i>{" "}
                        </Button>
                        {!confirmed ? (
                          <>
                            <Box>
                              <h5 style={{ fontFamily: "sans" }}>
                                Please Confirm that Order:
                              </h5>
                              <Button
                                onClick={() => {
                                  setSellerRatingModal(true);
                                }}
                              >
                                {" "}
                                have been received and tested (Works Fine)
                              </Button>
                              <h3>or</h3>

                              <Button
                                onClick={() => setproblemFormModal(true)}
                                style={{ borderColor: "red", color: "red" }}
                              >
                                {" "}
                                have been received, but there's an issue (Report
                                Order)
                              </Button>
                              <br />
                              <br />
                              <br />
                              <hr />
                            </Box>
                            {ProblemModal}
                            {SellerRatingModal}
                          </>
                        ) : (
                          <Box>
                            <b
                              style={{
                                backgroundColor: "lightgreen",
                                width: "30px",
                                padding: "10px",
                                borderRadius: "5px",
                                fontFamily: "ariel",
                                color: "whitesmoke",
                              }}
                            >
                              Order confirmed
                            </b>
                          </Box>
                        )}
                      </div>{" "}
                    </div>
                  </Box>
                </Spin>
              </>
            );

        case 'failed':

            return (
                <Spin tip='loading...' spinning={loading}>

                    <div style={{ backgroundImage: `url(${orderFailed})`, textAlign: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '50px', backgroundPositionX: '50%', backgroundPositionY: '70%' }}>
                        {header}

                        <Box sx={{ width: { sx: '100%', md: '50%' }, margin: '0 auto', }} >
                            <div style={{ backgroundRepeat: 'no-repeat', height: '400px', }}
                            >
                                <br />
                                <div style={{ width: '80%', textAlign: 'center', margin: '0 auto' }}>
                                    <Cancel sx={{ margin: { xs: '60px', md: '100px' } }} style={{ fontSize: 100, backgroundColor: 'whitesmoke', boxShadow: '0.2px 1px 2px darkred', color: 'red', borderRadius: '50%', }} />



                                </div>


                            </div >
                            <Box sx={{ backgroundColor: 'rgb(219, 179, 179)', border: '1px solid black', fontFamily: 'sans', color: 'whitesmoke', borderRadius: '7px', boxShadow: '1px 2px 1px 3px rgb(207, 129, 129)' }}>
                                <b>Something went wrong</b>
                                <h2 >
                                    Sorry,your order couldn't be completed</h2></Box>
                            <br></br>
                            <Button onClick={() => navigate('/')}>Go Home</Button >
                        </Box>
                    </div>
                </Spin>
            )


        case 'pending':

            return (
                <Spin tip='loading...' spinning={loading}>

                    <div style={{ backgroundImage: `url(${orderSuccessful})`, textAlign: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '50px', backgroundPositionX: '50%', backgroundPositionY: '70%' }}>
                        {header}

                        <Box sx={{ width: { sx: '100%', md: '50%' }, margin: '0 auto', }} >
                            <div style={{ backgroundRepeat: 'no-repeat', height: '400px', }}
                            >
                                <br />
                                <div style={{ width: '80%', textAlign: 'center', margin: '0 auto' }}>
                                    <Pending sx={{ margin: { xs: '60px', md: '100px' } }} style={{ fontSize: 100, backgroundColor: 'whitesmoke', boxShadow: '0.2px 1px 2px black', color: 'grey', borderRadius: '50%', }} />



                                </div>


                            </div >
                            <Box sx={{ backgroundColor: 'whitesmoke', border: '1px solid black', fontFamily: 'sans', color: 'graytext', borderRadius: '7px', boxShadow: '1px 2px 1px 3px grey' }}>
                                <b>(We apologize if this is taking longer than expected) </b>
                                <h2 >
                                    Your Order is being processed</h2></Box>
                            <br></br>
                            <Button onClick={() => navigate('/')}>Go Home</Button >
                        </Box>
                    </div>
                </Spin>
            )
        default:
            return (

                <h2>No payment stat yet</h2>
            )
    }
};

export default OrderStatus;