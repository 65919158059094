/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/main.css";
import "../../../assets/styles/responsive.css";
import axios from "axios";

import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  message,
} from "antd";
import { Alert } from "@mui/material";
import signinbg from "../../../assets/images/img-signin.png";
import { Redirect } from "react-router-dom";
import { AlertTitle } from "@mui/material";
import { Done, Error } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { margin, width } from "@mui/system";
import APP_ENV from "../../../env";
// import {
//   DribbbleOutlined,
//   TwitterOutlined,
//   InstagramOutlined,
//   GithubOutlined,
// } from "@ant-design/icons";
const BASE_URL = APP_ENV.SERVER_ADDR;;
const reqHeaders = {
  "Content-Type": "application/json",

  Authorization: `Token ${localStorage.getItem("AccgToken")}`,
};

if (localStorage.getItem("AccgToken") !== null) {
  reqHeaders["Authorization"] = `Token ${localStorage.getItem("AccgToken")}`;
}

const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,
});
const authClient = axios.create({
  baseURL: BASE_URL,
});
function onChange(checked) {
  // console.log(`switch to ${checked}`);
}
const { Title } = Typography;
const { Header, Footer, Content } = Layout;
const template = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const profile = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signup = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signin = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      className="fill-muted"
      d="M12.25,14H1.75A1.752,1.752,0,0,1,0,12.25V3.5A1.752,1.752,0,0,1,1.75,1.75h.876V.875a.875.875,0,0,1,1.75,0V1.75h5.25V.875a.875.875,0,0,1,1.75,0V1.75h.875A1.752,1.752,0,0,1,14,3.5v8.75A1.752,1.752,0,0,1,12.25,14ZM3.5,4.375a.875.875,0,0,0,0,1.75h7a.875.875,0,0,0,0-1.75Z"
    />
  </svg>,
];
export default function SignIn() {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setloading] = useState(false);
  const onFinish = (values) => {
    setloading(true);
    const handleRedirect = () => {
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 3000);
    };
    authClient
      .post("/auth/token/login/", values)
      .then((response) => {
        if (response.data.auth_token) {
          localStorage.setItem("AccgToken", response.data.auth_token);
        }

        const successEl = document.getElementById("login-success");

        successEl.style.left = 0;

        handleRedirect();
        message.success("Logging in...");
        setloading(false);

      })
      .catch((err) => {
        setloading(false);
        if (err.response.data.detail) {
          seterrorMessage(err.response.data.detail);

          setTimeout(() => {
            seterrorMessage("");
          }, 4000);
        }
        if (err.response.data.non_field_errors) {
          seterrorMessage(err.response.data.non_field_errors);

          setTimeout(() => {
            seterrorMessage("");
          }, 4000);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
  setloading(false);

    message.error("Login Failed");
  };
  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ overflow: "hidden", backgroundColor:"inherit" }}
      >
        <Header style={{backgroundColor:"transparent"}}>
          <div className="header-col header-brand">
            <h5>AccountGig</h5>
          </div>
          <div className="header-col header-nav">
            <Menu mode="horizontal" defaultSelectedKeys={["4"]}>
              {/* <Menu.Item key="1">
                  <Link to="/dashboard">
                    {template}
                    <span> Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/profile">
                    {profile}
                    <span>Profile</span>
                  </Link>
                </Menu.Item> */}
              <Menu.Item key="3">
                <Link to="/sign-up">
                  {signup}
                  <span> Sign Up</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/sign-in">
                  {signin}
                  <span> Sign In</span>
                </Link>
              </Menu.Item>
            </Menu>
          </div>
          <div
            className="header-col header-btn  left"
            style={{ width: "100px" }}
          >
            <Link to="/">
              <Button type="primary">Continue Shopping</Button>
            </Link>
          </div>
        </Header>
        <div
          id="login-success"
          style={{
            margin: "0 auto",
            width: "100%",
            transition: "linear 0.5s",
            left: 2000,
            position: "absolute",
            margin: "40px",
            zIndex: "2",
          }}
        >
          <Alert
            color="success"
            icon={<Done />}
            style={{
              width: "100%",
              textAlign: "center",
              justifySelf: "center",
              justifyContent: "center",
              position: "fixed",
            }}
          >
            Login Successful
          </Alert>
        </div>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your Username and password to sign in
              </Title>
              {errorMessage !== "" ? (
                <Alert
                  title="error"
                  color="error"
                  style={{ width: 500 }}
                  showIcon={true}
                  icon={<Error />}
                >
                  {errorMessage}
                </Alert>
              ) : (
                ""
              )}
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="aligin-center"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Switch defaultChecked onChange={onChange} />
                  Remember me
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
                <p className="font-semibold text-muted">
                  Don't have an account?{" "}
                  <Link to="/sign-up" className="text-dark font-bold">
                    Sign Up
                  </Link>
                </p>
                <Link to="/password/reset">Forgot Password?</Link>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img  src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
