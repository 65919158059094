import React, { Component } from "react";
import NavBackWidget from "./back_navigator";
import { Box, Button } from "@mui/material";
import { Divider, Input, Modal, Select, message, Form } from "antd";

export default class JoinourCommunity extends Component {

  render() {
    return (
      <div>
        <Box
          sx={{ width: { md: "60%", xs: "100%" } }}
          style={{ margin: "0 auto" }}
        >
          <NavBackWidget />
          <Divider></Divider>

          <Box className="content-box" style={{ textAlign: "center" }}>
            <h1>Community Links</h1>
            <Divider></Divider>

            <Box
              className="IG"
              style={{
                backgroundColor: "whitesmoke",
                borderRadius: "10px",
                margin: "5px auto",
                padding: "10px",
              }}
            >
              <p
                style={{
                  float: "left",
                  backgroundColor: "pink",
                  color: "whitesmoke",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                Instagram
              </p>
              <div data-mc-src="4260495d-2b12-475e-bf6e-250e984e6a5c#instagram"></div>
            
            </Box>
            <Divider></Divider>

            <Box
              className="Fb"
              style={{
                backgroundColor: "whitesmoke",
                borderRadius: "10px",
                margin: "5px auto",
                padding: "10px",
              }}
            >
              <p
                style={{
                  float: "left",
                  backgroundColor: "blue",
                  color: "whitesmoke",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                Facebook
              </p>
              <div
                class="fb-page"
                data-href="https://www.facebook.com/profile.php?id=100089552083147&amp;mibextid=LQQJ4d"
                data-tabs="timeline"
                data-width="1000"
                data-height=""
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="true"
              >
                <blockquote
                  cite="https://www.facebook.com/profile.php?id=100089552083147&amp;mibextid=LQQJ4d"
                  class="fb-xfbml-parse-ignore"
                >
                  <a href="https://www.facebook.com/profile.php?id=100089552083147&amp;mibextid=LQQJ4d">
                    Accountgig
                  </a>
                </blockquote>
              </div>
            </Box>
            <Divider></Divider>

            <Box
              className="Tl"
              style={{
                backgroundColor: "whitesmoke",
                borderRadius: "10px",
                margin: "5px auto",
                padding: "10px",
              }}
            >
              <p
                style={{
                  float: "left",
                  backgroundColor: "lightblue",
                  color: "whitesmoke",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                Telegram
              </p>
              <div className="col-md-offset-1 col-md-10">
                <div className="widget_container row" id="widget_container">
                  <iframe
                    id="telegram-post-accountgig-3"
                    src="https://t.me/accountgig/3?embed=1&amp;color=29B127&amp;dark_color=72E350"
                    width="100%"
                    height=""
                    frameborder="0"
                    scrolling="no"
                    style={{
                      overflow: "hidden",
                      border: "none",
                      minWidth: "320px",
                      height: "96px",
                    }}
                  ></iframe>
                  <script
                    async=""
                    src="https://telegram.org/js/telegram-widget.js?21"
                    data-telegram-post="accountgig/3"
                    data-width="100%"
                    data-color="29B127"
                    data-dark-color="72E350"
                  ></script>
                </div>
              </div>
            </Box>
            <Divider></Divider>
            {/* <Box
              className="Wh"
              style={{
                backgroundColor: "whitesmoke",
                borderRadius: "10px",
                margin: "5px auto",
                padding: "10px",
              }}
            >
              <p
                style={{
                  float: "left",
                  backgroundColor: "green",
                  color: "whitesmoke",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                Whatsapp
              </p>
              <a
                href="https://chat.whatsapp.com/EBC8lVkmCY1JuC1XlP0wjE"
                style={{
                  display: "table",
                  fontFamily: "sans-serif",
                  textDecoration: "none",
                  margin: "1em auto",
                  color: "#fff",
                  fontSize: "0.9em",
                  padding: "1em 2em 1em 3.5em",
                  borderRadius: "2em",
                  fontWeight: "bold",
                  background:
                    "#25d366 url(https://tochat.be/click-to-chat-directory/css/whatsapp.svg) no-repeat 1.5em center",
                  backgroundSize: "1.6em",
                }}
              >
                Join Our Whatsapp Community
              </a>
            </Box> */}

            <Box
              className="Tw"
              style={{
                backgroundColor: "whitesmoke",
                borderRadius: "10px",
                margin: "5px auto",
                padding: "10px",
              }}
            >
              <p
                style={{
                  float: "left",
                  backgroundColor: "skyblue",
                  color: "whitesmoke",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                Twitter
              </p>
              <a
                class="twitter-timeline"
                href="https://twitter.com/accountgig?ref_src=twsrc%5Etfw"
              >
                Tweets by accountgig
              </a>{" "}
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charset="utf-8"
              ></script>
            </Box>
            <Divider></Divider>
          </Box>
        </Box>
      </div>
    );
  }
}
