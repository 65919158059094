import { ArrowBackIos, FavoriteOutlined } from '@mui/icons-material';
import { Box, Avatar, IconButton, Button, CardContent, Badge, } from '@mui/material';
import { Card, Divider, Image, message, Rate, Tag } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { Component, createRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import APP_ENV from '../../env';
const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json",

    "Authorization": `Token ${localStorage.getItem('AccgToken')}`


};

if (localStorage.getItem('AccgToken') !== null) {
    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


})
const VisitHistory = (props) => {
    const navigate = useNavigate()
    React.useEffect(() => {
        if (props.clicked == true) {
            if (props.path === '//') {
              return  navigate(-1)
            }
            navigate(props.path)
        }

    }, [props.path])
    return (
        <></>
    )
}

export default class Wishlist extends Component {
    constructor(props) {
        super(props)

        this.state = {
            wishlistData: [],
            itemClicked: false,
            itemPath: '',
            productsRef: props.productFuncs,
        }

    }
    updateWishList() {
        setTimeout(() => {
            client.get('/user/api/wishlist').then(resp => {
                this.setState({ wishlistData: resp.data })
            }).catch(err => message.error(err))
        }, 300)
    }

    componentDidMount() {

        client.get('/user/api/wishlist').then(resp => {
            this.setState({ wishlistData: resp.data })
        }).catch(err => message.error(err))
        // this.updateWishList()
    }

    wishHeaderStyle = {
        fontSize: '10px',
        borderTop: '2px solid grey',
        display: 'inline-block',
        marginRight: '5px',
        color: 'whitesmoke',
        backgroundColor: 'darkolivegreen'

    }
    btmBtnsStyle = {
        color: 'darkgreen',
        width: '100%',
        fontSize: '10px',
        fontWeight: 'bold'
    }
    render() {
        const { wishlistData } = this.state
        return (
            <div>
                <Header children={<>
                    <div style={{ fontSize: '30px' }} >Wishlist
                        <div style={{ float: 'right' }}>
                            <Button style={this.wishHeaderStyle} onClick={() => { this.state.productsRef.current.add_allToCart(); this.updateWishList() }}>Add all to cart</Button>
                            <Button style={this.wishHeaderStyle} onClick={() => { this.state.productsRef.current.remove_allFromWish(); this.updateWishList() }}>clear all</Button></div>
                    </div>     </>} style={{backgroundColor: "transparent", fontFamily: 'serif' }}>
                </Header>
                <VisitHistory path={this.state.itemPath} clicked={this.state.itemClicked}>
                </VisitHistory>
                <ArrowBackIos style={{ position: 'absolute', top: '90px', fontSize: '20px', cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.setState({ itemPath: '//', itemClicked: true })} />

                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Divider />

                    {wishlistData.length >= 1 ? wishlistData.map((item, index) => {


                        return (

                            <Card style={{ width: '95%', margin: '3px auto', cursor: 'pointer', borderBottomColor: 'darkgreen' }}
                                actions={[<Button style={this.btmBtnsStyle} onClick={() => { this.state.productsRef.current.addToCartFunc(item.id); this.updateWishList() }} >{item.carted_by_user ? 'Top up cart' : 'Add to cart'}</Button>, <Button onClick={() => { this.state.productsRef.current.addToWishFunc(item.id); this.updateWishList() }} style={this.btmBtnsStyle}>Remove from wishlist</Button>]}
                            >                                    <Tag style={{ left: '0px', position: 'absolute', color: 'whitesmoke', borderRadius: '5px', backgroundColor: 'darkgoldenrod', backgroundColor: 'darkgoldenrod', fontStyle: 'oblique', border: '1px solid #fff' }}>{item.category}</Tag>

                                <Card.Grid style={{ width: '30%', height: '172px', alignContent: 'center', objectFit: 'cover', verticalAlign: 'center', overflow: 'hidden' }} >
                                    <Image alt='' style={{ verticalAlign: 'center', height: '100%', width: '100%' }} src={item.image}></Image>
                                </Card.Grid>
                                <Card.Grid style={{ width: '70%' }} >
                                    {item.sold ?
                                        <Tag style={{ backgroundColor: 'red', color: 'white', font: ' 10px cursive', borderRadius: '5px', position: 'absolute', top: '0.5px', right: '0' }}>
                                            Sold</Tag>
                                        :
                                        <Tag style={{ backgroundColor: 'lightgreen', color: 'white', font: ' 10px cursive', borderRadius: '5px', position: 'absolute', top: '0.5px', right: '0' }}>
                                            on Sale  </Tag>}
                                    <CardContent onClick={() => { this.setState({ itemPath: `/p/${item.slug}/${item.id}` }); this.setState({ itemClicked: true }) }}>
                                        <div>
                                            <h2 style={{ fontFamily: 'Ariel', fontSize: '17px' }}>{item.title}</h2>
                                            <div style={{ float: 'left' }}>

                                                <Tag >{item.qty_left} Qty left </Tag></div>
                                            <Tag style={{ float: 'right', border: 'none', fontFamily: 'serif', color: 'darkolivegreen', fontSize: '20px' }}>{item.price?.currency} {item.price?.amount}</Tag>
                                            <Divider style={{ backgroundColor: 'whitesmoke', margin: '3px' }} />
                                            <div style={{ bottom: '-50px', float: 'left' }}>
                                                <Link to={'/profile/' + item.seller_name} >
                                                    {item.seller_pic ?
                                                        <Avatar style={{ float: 'left', width: 20, height: 20, }} src={item.seller_pic} > </Avatar> :
                                                        <Avatar style={{ float: 'left', width: 20, height: 20, }}  >{String(item.seller_name)[0]} </Avatar>}                                                    <small style={{ color: 'black' }}>   sold by </small>
                                                    <small>{item.seller_name}</small>
                                                    <div>
                                                        <Rate style={{ float: 'right', fontSize: '10px' }} disabled value={item.seller_rating}></Rate>
                                                    </div>
                                                </Link>
                                            </div>

                                        </div>

                                    </CardContent>
                                </Card.Grid>


                            </Card>
                        )

                    }) :
                        <Box color={'GrayText'} style={{ textAlign: 'center', padding: '100px', fontSize: '20px' }} children={
                            <>
                                <FavoriteOutlined /><h1>
                                    No  item
                                </h1>

                                <small><Link to='/'>Keep shopping</Link></small>
                            </>}>

                        </Box>
                    }

                </Box>
                <Box style={{ textAlign: 'center' }} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Divider />

                    {wishlistData.length >= 1 ? wishlistData.map((item, index) => {

                        return (

                            <Card style={{ width: '350px', cursor: 'pointer', margin: '5px', display: 'inline-block' }}
                                actions={[<Button style={this.btmBtnsStyle} onClick={() => { this.state.productsRef.current.addToCartFunc(item.id); this.updateWishList() }
                                } > {item.carted_by_user ? 'Top up cart' : 'Add to cart'}</Button>, <Button style={this.btmBtnsStyle} onClick={() => { this.state.productsRef.current.addToWishFunc(item.id); this.updateWishList() }}>Remove from wishlist</Button>]}
                                cover={<Card.Grid style={{ width: '95%', objectFit: 'contain', textAlign: 'center', height: '20em', overflow: 'hidden', margin: '4px' }}><Image alt='' style={{
                                    width: '100%',
                                    height: '20em'
                                }} src={item.image} ></Image></Card.Grid>}
                            >

                                {item.sold ?
                                    <Tag style={{ backgroundColor: 'red', font: ' 10px cursive', borderRadius: '5px', color: 'white', position: 'absolute', top: '0.5px', right: '0' }}>sold</Tag>
                                    : <Tag style={{ backgroundColor: 'lightgreen', font: ' 10px cursive', color: 'white', borderRadius: '5px', position: 'absolute', top: '0.5px', right: '0' }}>on Sale</Tag>
                                }
                                <CardContent onClick={() => { this.setState({ itemPath: `/p/${item.slug}/${item.id}` }); this.setState({ itemClicked: true }) }}>
                                    <div>
                                        <Tag style={{ right: '0px', position: 'absolute', color: 'whitesmoke', bottom: '190px', borderRadius: '5px', backgroundColor: 'darkgoldenrod', fontStyle: 'oblique', border: '1px solid #fff' }}>{item.category}</Tag>
                                        <h2 style={{ fontFamily: 'Ariel', fontSize: '17px' }}>{item.title}</h2>

                                        <Divider style={{ display: 'none', margin: '0' }} />

                                        <div style={{ float: 'left' }}>

                                            <Tag >{item.qty_left} Qty left </Tag></div>
                                        <div style={{ float: 'right', }}>
                                            <h5 style={{ border: 'none', margin: '0', width: '100%', fontFamily: 'serif', fontSize: '20px', color: 'darkolivegreen' }}>{item.price?.currency + ' ' + item.price?.amount}</h5>

                                        </div>
                                        <Divider style={{ backgroundColor: 'whitesmoke', margin: '3px' }} />
                                        <div style={{ bottom: '-50px', float: 'left' }}>
                                            <Link to={'/profile/' + item.seller_name}>
                                                {item.seller_pic ?
                                                    <Avatar style={{ float: 'left', width: 20, height: 20, }} src={item.seller_pic} > </Avatar> :
                                                    <Avatar style={{ float: 'left', width: 20, height: 20, }}  >{String(item.seller_name)[0]} </Avatar>}
                                                <small style={{ color: 'black' }}>   sold by </small>
                                                <small>{item.seller_name}</small>
                                                <div>
                                                    <Rate style={{ float: 'right', fontSize: '10px' }} disabled value={item.seller_rating}></Rate>
                                                </div></Link>
                                        </div>

                                    </div>
                                </CardContent>

                            </Card>)
                    }) : <Box style={{ fontFamily: 'serif', fontSize: '20px', widht: '100%', textAlign: 'center', margin: '0 auto', padding: '100px' }}>
                        <FavoriteOutlined />
                        <h2>  No item</h2>

                        <small>  <Link to='/'>Continue Shopping.</Link></small>
                    </Box>
                    }
                </Box >
                <br></br>
                <br></br>
            </div >
        )
    }
}
