import React from 'react'
import { useDispatch } from 'react-redux'
import { switchTabs } from '../../../store/core/reducer'
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Typography as MuiTypography,
  CardActions,
  CardContent,
  IconButton,
  Paper,
  alpha,
  Button,
} from '@mui/material'
import { Empty, Modal, Segmented, Spin, Tag, Typography, message, notification } from 'antd'
import InfluencerMediums from './mediums'
import InfluencerPackages from './packages'
import InfluencerFeedBacks from './feedbacks'
import { useNavigate, useParams } from 'react-router-dom'
import CreateInfluencerOrder from '../order/createOrder'
import { SelectInfluencerOnView } from '../redux/influencer/selectors'
import { claimInfluencerPage, fetchinfluencer } from '../redux/influencer/thunks'
import InfluenceHub from '..'
import {
  CardGiftcardOutlined,
  ChatBubbleOutline,
  DeliveryDiningOutlined,
  FeedbackOutlined,
  ListAltOutlined,
  NotAccessibleOutlined,
  Person,
  ShareOutlined,
  ShoppingCartOutlined,
} from '@mui/icons-material'
import './apply.css'
import { shareLink } from '../../../utils/share'
import { AD_CATEGORIES_CHOICES } from '../platformVars'
import { UserSelector } from '../../../store/auth/selectors'
import { InfluencerAvatar } from './widgets/avatar'

export default function Influencer(props) {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const [activeSegment, setActiveSegment] = React.useState(
    params.segment || 'mediums',
  )
  const influencer = SelectInfluencerOnView()
  const User = UserSelector()

  var segments = {
    mediums: {
      title: 'Mediums',
      component: <InfluencerMediums influencer={influencer} />,
      icon: (
        <ListAltOutlined style={{ marginBottom: '-2px' }} fontSize="12px" />
      ),
    },

    packages: {
      title: 'Packages',
      component: <InfluencerPackages influencer={influencer} />,
      icon: (
        <CardGiftcardOutlined
          fontSize="12px"
          style={{ marginBottom: '-2px' }}
        />
      ),
    },
    feedbacks: {
      title: 'Feedbacks',
      component: <InfluencerFeedBacks influencer={influencer} />,
      icon: (
        <FeedbackOutlined fontSize="12px" style={{ marginBottom: '-2px' }} />
      ),
    },
  }
  if (!segments.order && !influencer?.is_my_page) {
    segments = {
      mediums: {
        title: 'Mediums',
        component: <InfluencerMediums influencer={influencer} />,
        icon: (
          <ListAltOutlined style={{ marginBottom: '-2px' }} fontSize="12px" />
        ),
      },
      order: {
        title: 'Create Order',
        component: <CreateInfluencerOrder influencer={influencer} />,
        icon: (
          <ShoppingCartOutlined
            fontSize="12px"
            style={{ marginBottom: '-2px' }}
          />
        )

      },

      packages: {
        title: 'Packages',
        component: <InfluencerPackages influencer={influencer} />,
        icon: (
          <CardGiftcardOutlined
            fontSize="12px"
            style={{ marginBottom: '-2px' }}
          />
        ),
      },
      feedbacks: {
        title: 'Feedbacks',
        component: <InfluencerFeedBacks influencer={influencer} />,
        icon: (
          <FeedbackOutlined fontSize="12px" style={{ marginBottom: '-2px' }} />
        ),
      },
    }
  }
  React.useEffect(() => {
    if (params.display_name || !props.myPage) {
      dispatch(fetchinfluencer({ display_name: params.display_name }))
    }

  }, [])

  const handleChangeSegment = value => {
    setActiveSegment(value)
  }

  const handleClaimPage =()=>{
if(!User.isAuthenticated){
  message.info("You must be logged in before a claim action can be executed")
  return navigate('/sign-in')
}
    dispatch(claimInfluencerPage({data:{influencer:influencer.id}}))
  }
  return (
    <Box>
      {' '}
      {influencer ? (
        <div className="influencer-page ">
          <Card sx={{ borderRadius: '14px', position: 'relative', margin: '0 auto', width: { xs: '100%', md: '80%' } }}>
            <Box sx={{ margin: '0 auto', width: { xs: '100%', md: '70%' } }}>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    margin: '10px',
                    alignItems: 'center', justifyContent: "center",
                    gap: { xs: 1, md: 5 },
                    flexDirection: 'row',
                  }}
                >
                  <div>
                    <InfluencerAvatar
                      className="avtr-infl"
                      sx={{
                        width: { xs: '150px', md: '200px' },
                        height: { xs: '150px', md: '200px' },
                      }}
                      isVerified={influencer.is_verified}
                      src={influencer.display_image}
                    ></InfluencerAvatar>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    <MuiTypography
                      variant="h5"
                      fontWeight={'bolder'}
                      textOverflow={'ellipsis'}
                    >
                      {influencer.display_name}
                    </MuiTypography>
                    <Typography.Text>
                      {influencer.advert_categories.map(cat => (
                        <Tag bordered={false}> {AD_CATEGORIES_CHOICES.find(ct => ct.value === cat)?.label || cat}  </Tag>
                      ))}
                    </Typography.Text>
                    <Typography.Paragraph
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                      }}
                    >
                      <small>Available On</small>
                      <div>
                        {influencer.mediums.map(md => (
                          <Avatar
                            style={{
                              borderRadius: 0,
                              // margin: '2px',
                              width: 20,
                              height: 20,
                              display: 'inline-block',
                              // marginBottom: -5,
                              marginRight: 5,
                            }}
                            variant="overline"
                            alt={md.medium.name}
                            title={md.medium.name}
                            src={md.medium.logo}
                          />
                        ))}
                      </div>
                    </Typography.Paragraph>
                
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                        {influencer.managed_by === 'platform'&&
                    <Button color='secondary' onClick={handleClaimPage} sx={{margin:0, maxHeight:{xs:'20px'},overflow:'hidden', padding:'5px', fontSize:'10px', borderRadius:'15px'}} variant='contained'>
                      Claim 
                    </Button>
}
                    <div style={{ textAlign: 'center', }}>
                      <MuiTypography
                        variant="h2"
                        fontSize={{
                          xs: Math.max(
                            22,
                            40 - String(influencer.total_audience).length * 0.5,
                          ),
                        }}
                      >
                        {influencer.total_audience}
                      </MuiTypography>
                      <MuiTypography
                        variant="overline"
                        fontSize={'12px'}
                        style={{ opacity: '0.6' }}
                      >
                        Audience
                      </MuiTypography>
                    </div>
                    <Paper
                      className="bottomActions"
                      style={{
                        display: 'flex',
                        borderRadius: '20px',
                        flexDirection: 'row',
                        // minWidth:'100px',
                        alignSelf: 'center',
                        padding: '1px',
                        gap: 'auto',
                        alignContent: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {influencer.managed_by === 'self' && (
                        <IconButton
                          title="Chat"
                          style={{ backgroundColor: alpha('#53cf65', 0.5) }}
                          onClick={() =>
                            navigate('/user/chats/?get=' + influencer.manager)
                          }
                        >
                          <ChatBubbleOutline />
                        </IconButton>
                      )}
                      <IconButton
                        title="Share Page"
                        onClick={() =>
                          shareLink({
                            title: influencer.display_name,
                            description: `Hello! check ${influencer.display_name} out on AccountGig Influence Hub, a place to Run Ads and Grow your social media presence!`,
                            url: window.location,
                          })
                        }
                      >
                        <ShareOutlined />
                      </IconButton>
                    </Paper>
                  </div>

                  <div></div>
                </Box>
              </CardContent>
              <CardActions>
                <Segmented
                  size="large"
                  className="custom-segmented-control"
                  playsInline

                  // style={{height:"7vh",alignItems:'center',justifyItems:'center', justifySelf:'center'
                  style={{ backgroundColor: alpha('#4c915e', 0.3) }}
                  // , justifyContent:'center',
                  // display:'flex',alignSelf:'center',

                  //  alignContent:'center', width:"100vw"}}
                  // cellPadding={'20px'}
                  defaultValue={activeSegment}
                  onChange={handleChangeSegment}
                  options={Object.keys(segments).map(itm => ({
                    label: segments[itm].title,
                    value: itm,
                    icon: segments[itm].icon,
                  }))}
                />
              </CardActions>
              <CardContent>
                {activeSegment &&
                  Object.keys(segments).includes(activeSegment) ? (
                  <div
                    title={segments[activeSegment].title}
                    className="influencer-tabs"
                  >
                    {segments[activeSegment].component}
                  </div>
                ) : (
                  <Empty
                    image={<NotAccessibleOutlined />}
                    description="Unknown Segment"
                  />
                )}
              </CardContent>
            </Box>
          </Card>
        </div>
      ) : (
        <>
          <Empty image={<Person />} description="Influencer Not Found" />
        </>
      )}
    </Box>
  )
}
