import { AnalyticsOutlined, FavoriteBorderOutlined, People, PeopleOutline } from '@mui/icons-material'
import { Box, Button, Container } from '@mui/material'
import { Avatar, Card, Divider, Empty, Modal, Tag, Typography, notification } from 'antd'
import React from 'react'
import { ConnectMediumComponent } from '../widgets/connectMedium'
import { SelectInfluencerStatus, SelectPlatformMediums } from '../redux/influencer/selectors'
import { fetchPlatformMediums, updateInfluencerMediums } from '../redux/influencer/thunks'
import { useDispatch } from 'react-redux'
import { isURL } from 'validator'
import { resetInfluencerStatus } from '../redux/influencer/reducer'

export default function InfluencerMediums({ influencer }) {
  const MetricsDesign = {
    audience: { icon: <PeopleOutline fontSize='10px' />, display_name: 'Audience' },
    reach: { icon: <AnalyticsOutlined fontSize='10px' />, display_name: 'Average Reach' },
    average_impressions: {
      icon: <FavoriteBorderOutlined fontSize='10px' />,
      display_name: 'Average Impression',
    },
  }
  const InfluencerStatus = SelectInfluencerStatus()
  const dispatch = useDispatch()
  const PlatformMediums = SelectPlatformMediums()
  const [updateView, setupdateView] = React.useState(false)

  React.useEffect(() => {
    dispatch(fetchPlatformMediums())

  }, [])
  React.useEffect(() => {
    if (InfluencerStatus === 'success') {
      notification.success({ message: 'Mediums Updated, Please wait for approval' })
      dispatch(resetInfluencerStatus())


    }

  }, [InfluencerStatus])
  const handleMediumUpdate = (e) => {
    e.preventDefault()
    const formData = Object.fromEntries(new FormData(e.target));
    var valid = true
    var addedMediumsCount = 0

    for (let key in formData) {


      if (key.includes("url_link") && !formData[key].length <= 0) {
        if (!isURL(formData[key])) {
          valid = false;


          const errorEl = document.createElement("small");
          errorEl.id = "err-msg";
          const inputCont = document.querySelector(
            `#app-field[name="${key}"]`
          );
          errorEl.style.color = "#9F5151";
          errorEl.style.fontSize = "12px";
          errorEl.textContent = 'Invalid Url';
          inputCont.parentNode.appendChild(errorEl);

        } else {
          addedMediumsCount += 1;
        }
      }

    }
    if (addedMediumsCount <= 0 && valid === false) {

      notification.info({ message: "Add atleast one medium" })
    }
    const mediums = [];

    for (let i = 0; i <= PlatformMediums.length; i++) {
      const medium = {};
      medium["url_link"] = formData[i + "_url_link"];
      medium["medium"] = formData[i + "_medium"];

      if (formData.hasOwnProperty(i + "_id")) {
        medium["id"] = formData[i + "_id"];

      }
      if (medium?.url_link?.trim().length >= 1 && isURL(medium?.url_link) && influencer.mediums.filter(md => md.url_link === medium.url_link && md.id === +medium?.id).length <= 0) {
        mediums.push(medium);

      }

    }
    const data = { mediums: mediums }



    if (valid === true && mediums.length >= 1) {
      Modal.confirm({
        onOk: () => {

          dispatch(updateInfluencerMediums({ data, slug: influencer.slug }))

        },
        onCancel: () => { notification.info({ "message": 'Update Cancelled' }); Modal.destroyAll() },
        content: <b>Be aware that this action will cause the updated mediums to be pending until our team has reviewed the changes and approved or deny the alteration.</b>
      })
    }
    setupdateView(false)
  }
  return (
    <>
      <Container style={{ minHeight: '50vh' }}>
        <Typography.Paragraph strong>
        {influencer.is_my_page ?"Your":"Influencer"} mediums ({influencer.mediums.length})
        </Typography.Paragraph>
        {influencer.is_my_page &&
          <div sx={{ float: 'right' }}>
            <Button variant='contained' onClick={() => setupdateView(!updateView)} size='middle'>{updateView ? 'close' : 'Add/Edit'}</Button>
          </div>
        }


        {updateView === false ?
          <div>
            {influencer.mediums?.map(md => (
              <Card
                hoverable
                onClick={() => window.open(md.url_link)}
                size="default"

                cover
                bordered={false}
                style={{ marginTop: 10, position: 'relative' }}
                actions={
                  Object.keys(md.metrics).map(key => {
                    const mdDe = MetricsDesign.hasOwnProperty(key) ? MetricsDesign[key] : null
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Typography.Text strong>{md.metrics[key]}</Typography.Text>
                        <br />
                        <small> {mdDe ? <><b>{mdDe.icon} </b>{mdDe.display_name}</> : key}</small>
                      </div>
                    )
                  })}
              >
                <Card.Meta
                  title={ md.handle?  ('@' + md.handle): 'On Review'}

                  avatar={<Avatar src={md.medium.logo} shape="square" />}
                  description={md.medium.display_name}
                ></Card.Meta>
                {influencer.is_my_page &&
                  <div style={{ position: 'absolute', right: '10px', top: '10px' }}>

                    <Tag title='Medium Status' color={md.status === 'D' ? "error" : md.status == 'A' ? "success" : 'processing'} > {md.status_display}</Tag>
                  </div>
                }
              </Card>
            ))}

            {influencer.mediums?.length <= 0 && <Empty description="No mediums" />}</div>
          :

          <div>
            <form onSubmit={handleMediumUpdate}>
              <ConnectMediumComponent instances={influencer.mediums} platforms={PlatformMediums} />
              <Button fullWidth type='submit' variant='contained'>Update</Button>
            </form>
          </div>

        }
      </Container>
    </>
  )
}
