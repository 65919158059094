import React, { memo } from "react";
import {
  UserProfileSelector,
  UserSelector,
} from "../../../store/auth/selectors";
import { Card, Modal, Spin, Typography, Upload, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Form, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ImgCrop from "antd-img-crop";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Stepper,
  Step,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  alpha,
  StepLabel,
  Alert,
} from "@mui/material";
import { BASE_URL, client } from "../../app_context";
import defaultAvatar from "../../../assets/images/avatar.webp";
import { makeStyles } from "@mui/styles";
import "./apply.css";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Camera,
  DoneAllOutlined,
  DoneOutlineSharp,
  VerifiedSharp,
} from "@mui/icons-material";
import { AD_CATEGORIES_CHOICES } from "../platformVars";
import { uid } from "react-uid";
import {
  SelectInfluencerErrors,
  SelectInfluencerStatus,
  SelectPlatformMediums,
} from "../redux/influencer/selectors";
import {
  fetchPlatformMediums,
  postApplicationForm,
} from "../redux/influencer/thunks";
import { ConnectMediumComponent } from "../widgets/connectMedium";
import InfluencerKYC from "../widgets/kyc";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";

export default function InfluencerApplication() {
  const User = UserSelector();
  const UserProfile = UserProfileSelector();
  const AuthStatus = useSelector((state) => state.auth.status);
  const [selectedImgUrl, setSelectedImgUrl] = React.useState(null);
  const [canNext, setCanNext] = React.useState(false);

  const [step, setStep] = React.useState(0);
  const imgInputRef = React.useRef(null);
  const navigate = useNavigate();
  const { Title } = Typography;
  const [selectedCategories, setselectedCategories] = React.useState([]);
  const connectedMediums = [];
  const dispatch = useDispatch();
  const influencerError = SelectInfluencerErrors();
  const influencerStatus = SelectInfluencerStatus();
  const PlatformMediums = SelectPlatformMediums();
  const [kycActive, setKycActive] = React.useState(false)
  const handleChange = (event) => {
    if (selectedCategories.length < 5) {
      setselectedCategories(event.target.value);
    }
  };

  const [submitted, setSubmitted] = React.useState(false);
  const formRef = React.useRef(null);

  const [kycDone, setKycDone] = React.useState(false);

  React.useEffect(() => {
    if (influencerStatus === "uploaded") {
      setSubmitted(true);
      setStep(2);
    }
    
    console.log(influencerError)
    if (influencerError !== null) {
      // Validate form fields
      const formInputs = formRef.current.querySelectorAll("#app-field");
      formInputs.forEach((input) => {
        const errorMessage = document.createElement("small");
        errorMessage.style.color = "red";

        if (influencerError.hasOwnProperty(input.getAttribute("name"))) {
          // Append error message beneath each field if it's empty
          errorMessage.textContent =
            influencerError[input.getAttribute("name")];

          input.parentNode.appendChild(errorMessage);
        } else {
          // Remove error message if the field is not empty
          const existingErrorMessage = input.parentNode.querySelector("small");
          if (existingErrorMessage) {
            existingErrorMessage.remove();
          }
        }
      });
    }
    if (influencerStatus === "failed" && influencerError === null) {
      notification.error({
        message: "Application failed, please enter all data correctly",
      });
    }
  }, [influencerStatus, influencerError]);

  React.useEffect(() => {
    dispatch(fetchPlatformMediums());
  }, []);
  const LoginModal = () => (
    <Modal
      closable={false}
      closeIcon={false}
      open={!AuthStatus === "loading" || User.isAuthenticated === false}
      title="Authentication"
      onOk={() => navigate("/sign-in")}
      onCancel={() => window.open("mailto:influencehub@accountgig.com")}
      okText="Login"
      cancelText="Contact Us"
      type="confirm"
    >
      <div>
        <b>Looks Like You're Logged Out</b>
        <br />
        Only logged-in users can create an influencer page on our website.
        Please log in or contact us, and we can assist you in creating a
        platform-managed page.
      </div>
    </Modal>
  );
  const handleSetImg = (e) => {
    if (e.target.files.length <= 0) {
      return setSelectedImgUrl(null);
    }
    const img = e.target.files[0];
    const url = URL.createObjectURL(img);
    setSelectedImgUrl(url);
  };

  const handleSubmitApplication = () => {
    if (formRef.current?.checkValidity() && kycDone) {
      const formData = new FormData(formRef.current);

      const formObj = Object.fromEntries(formData);
      const mediums = [];

      for (let i = 0; i <= PlatformMediums.length; i++) {
        const medium = {};
        medium["url_link"] = formObj[i + "_url_link"];
        medium["medium"] = formObj[i + "_medium"];
        mediums.push(medium);
      }

      // console.log(mediums);
      formData.append("mediums", JSON.stringify(mediums));
      formData.set("advert_categories", selectedCategories);

      dispatch(postApplicationForm(formData));
    } else {
      notification.error({
        message: "Please ensure you completed all fields",
      });
    }
  };
  const useStyles = makeStyles((theme) => ({
    hoverableCamera: {
      backgroundColor: alpha("rgb(73, 82, 76)", 0.5),
      textAlign: "center",
      position: "absolute",
      bottom: "23px",

      minWidth: "160px",
      borderBottomRightRadius: 150,
      paddingBottom: "10px",
      borderBottomLeftRadius: 150,
      padding: "10px",
      paddingRight: "-40px",
      fontSize: "20px",
      color: "lightgrey",
    },
    formControl: {
      marginTop: "10px",
    },
    applyButton: {
      marginTop: "10px",
    },
  }));

  const classes = useStyles();

  const stepsList = [
    {
      label: "Your Information",
      index: 0,
      component: (
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",

            maxWidth: "inherit",
            margin: "10px auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "4",
            }}
          >
            <Avatar
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                border: "5px solid grey",
                borderColor: alpha("#5b806d", 0.8),
                cursor: "pointer",
              }}
              onClick={() => imgInputRef.current.click()}
              style={{ width: "200px", height: "200px" }}
              src={selectedImgUrl || defaultAvatar}
            ></Avatar>

            <b>Upload Display Picture</b>

            <input
              name="display_image"
              ref={imgInputRef}
              type="file"
              accept="image/*"
              id="app-field"
              required
              onChange={handleSetImg}
              hidden
            ></input>
          </div>
          <Box
            sx={{
              display: "flex",
              width: { md: "70%", sm: "100%" },
              margin: "0 auto",
              flex: "wrap",
              flexDirection: "column",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <FormControl
              className={classes.formControl}
              fullWidth
              variant="outlined"
              required
            >
              <InputLabel variant="filled"> Display Name</InputLabel>
              <OutlinedInput
                id="app-field"
                name="display_name"
                placeholder="Enter Name"
              />
              <FormHelperText>
                A name you are publicly known with.
              </FormHelperText>
            </FormControl>

            <FormControl
              className={classes.formControl}
              fullWidth
              required
              variant="outlined"
            >
              <InputLabel variant="filled"> Email</InputLabel>
              <OutlinedInput
                name="email"
                id="app-field"
                required
                placeholder="Enter Email"
              />
            </FormControl>
            {/* social medium connections  */}

            <ConnectMediumComponent platforms={PlatformMediums} />

            {/* <Form */}

            <FormControl
              className={classes.formControl}
              fullWidth
              variant="outlined"
              required
            >
              <InputLabel variant="filled"> Select Categories</InputLabel>

              <Select
                multiple
                onChange={handleChange}
                value={selectedCategories}
                renderValue={(selected) =>
                  [
                    ...new Set(
                      AD_CATEGORIES_CHOICES.map((itm) => {
                        if (selected.includes(itm.value)) return itm.label;
                      }).reverse()
                    ),
                  ].join("|")
                }
                placeholder="Advert Categories"
              >
                {AD_CATEGORIES_CHOICES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={selectedCategories.indexOf(option.value) > -1}
                    />

                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <input
                hidden
                name="advert_categories"
                value={selectedCategories}
                id="app-field"
              ></input>
            </FormControl>
          </Box>
        </Box>
      ),
    },
    {
      index: 1,
      label: "Influencer Verification",
      component: (
        <motion.div initial={{ y: -1000 }} animate={{ y: 0 }}>
          <Box
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "inherit",
              margin: "10px auto",
            }}
          >
            <FormControl>
              <InfluencerKYC isActive={kycActive} setDone={setKycDone} />
            </FormControl>
            <div style={{ width: "100%", margin: "10px" }}>
              <Checkbox required />
              <small>
                I agree to AccountGig influenceHub{" "}
                <a target="_blank" href="/terms&C#influenceHub">
                  {" "}
                  Terms and conditions.{" "}
                </a>
              </small>
            </div>

            <FormControl fullWidth>
              <Button
                disabled={kycDone === false}
                variant="contained"
                className={classes.applyButton}
                onClick={handleSubmitApplication}
              >
                Apply
              </Button>
            </FormControl>
          </Box>
        </motion.div>
      ),
    },
    {
      index: 2,
      label: "Done",

      component: (
        <motion.div initial={{ x: 1000 }} animate={{ x: 0 }}>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "50vh",
              gap: 2,
            }}
          >
            <h1>
              <DoneOutlineSharp style={{ fontSize: "70px" }} />
            </h1>
            <Typography.Title style={{ margin: 2 }}>
              Application Received
            </Typography.Title>
            <Typography.Text style={{ margin: 2 }}>
              Thank you for taking your valued time to apply, updates on your
              application would be sent via the mail you provided.{" "}
            </Typography.Text>
            <br />
            <Button
              variant="outlined"
              size="medium"
              onClick={() => navigate("/influence-hub")}
            >
              Influence Hub
            </Button>
          </Box>
        </motion.div>
      ),
    },
  ];

  // const ActiveStep = {stepsList[step].component}

  const handleFormChange = (e) => {
    // / e.currentTarget.checkValidity()
  };

  const handleNext = () => {
    var lastError = [];
    const setLastError = (payload) => {
      if (typeof payload === "function") {
        lastError = payload(lastError);
      } else {
        lastError = payload;
      }
    };

    const formData = Object.fromEntries(new FormData(formRef.current));
    var valid = true;
    const inputsEl = document.querySelectorAll(`#app-field`);

    for (let i = 0; i < inputsEl.length; i++) {
      const element = inputsEl[i];
      element.parentNode.querySelector("small[id='err-msg']")?.remove();
    }

    if (step === 0) {
      const firstStepReqLists = ["display_image", "display_name", "email"];
      var addedMediumsCount = 0;
      for (let key in formData) {
        if (key.includes("url_link") && !formData[key].length <= 0) {
          if (!isURL(formData[key])) {
            valid = false;
            setLastError((prev) => [
              { msg: "Invalid url", field: key },
              ...prev,
            ]);
          } else {
            addedMediumsCount += 1;
          }
        }
      }

      for (let i = 0; i < firstStepReqLists.length; i++) {
        const field = firstStepReqLists[i];

        if (field !== "display_image" && formData[field].length <= 3) {
          valid = false;
          setLastError((prev) => [
            {
              msg: "Required field",
              field: field,
            },
            ...prev,
          ]);
        }
        if (
          field === "email" &&
          formData[field].length > 3 &&
          isEmail(formData[field]) == false
        ) {
          valid = false;
          setLastError((prev) => [
            { msg: "Invalid email", field: "email" },
            ...prev,
          ]);
        }
        if (field === "display_image" && formData[field].size <= 0) {
          valid = false;

          setLastError((prev) => [
            { msg: "Display picture is required", field: "display_image" },
            ...prev,
          ]);
        }
      }
      if (
        formData.hasOwnProperty("advert_categories") &&
        selectedCategories.length <= 0
      ) {
        valid = false;
        setLastError((prev) => [
          {
            msg: "Select your advert categories",
            field: "advert_categories",
          },
          ...prev,
        ]);
      }
      if (addedMediumsCount < 1) {
        valid = false;

          setLastError((prev) => ["Please add your mediums (atleast one)", ...prev]);
      }
    }
    if (step === 1) {
      valid = kycDone;
      if (kycDone === false) {
        setLastError((prev) => ["Please Complete Kyc", ...prev]);
      }
    }

    for (let i = 0; i < lastError.length; i++) {
      const error = lastError[i];
      // console.log(lastError);
      if (error instanceof Object) {
        const errorEl = document.createElement("small");
        errorEl.id = "err-msg";
        const inputCont = document.querySelector(
          `#app-field[name="${error.field}"]`
        );
        errorEl.style.color = "#9F5151";
        errorEl.style.fontSize = "12px";
        errorEl.textContent = error.msg;
        inputCont.parentNode.appendChild(errorEl);
      } else {
        notification.error({ message: error });
      }
    }
    if (valid === true) {
      if (step +1 == 1){
      setKycActive(true)
      }
      setStep((prv) => prv + 1);
    }
  };
  return (
    <>
      <Spin
        spinning={AuthStatus === "loading" || influencerStatus === "loading"}
      >
        {User.isAuthenticated === false ? (
          <LoginModal></LoginModal>
        ) : (
          <Container
            sx={{
              width: { md: "50%", sm: "80%" },
              marginTop: { md: "20px", sm: "10px" },
            }}
          >
            <Card
              className={"applyCard"}
              style={{
                backgroundColor: alpha("#5b806d", 0.2),
                margin: "20px auto",
                width: "100%",
              }}
            >
              <Title className="mb-15" style={{ fontWeight: "bolder" }}>
                Apply for Influencer Page
              </Title>
              <Title className="font-regular text-muted" level={5}>
                You're one step away from creating a distinct social impact!{" "}
              </Title>
              <Box sx={{ margin: "15px" }}>
                <Stepper activeStep={step} alternativeLabel>
                  {stepsList.map((tt) => {
                    const stepProps = {};
                    switch (tt.index) {
                      case 0:
                        stepProps.completed = formRef.current?.checkValidity();
                        break;
                      case 1:
                        stepProps.completed = kycDone;
                        break;
                      case 2:
                        stepProps.completed = submitted;
                        break;
                      default:
                        break;
                    }
                    return (
                      <Step {...stepProps}>
                        <StepLabel style={{ fontSize: "10px" }}>
                          {tt.label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              {influencerError?.detail && (
              
              <Alert content="helloo" style={{margin:'10px auto', width:'80%'}} color='error' icon>{influencerError?.detail}</Alert>
              
              )}
              <form ref={formRef} onChange={handleFormChange}>
                <Box sx={{ overflow: "hidden" }}>
                  {" "}
                  {stepsList.map((stp, index) => (
                    <motion.div
                      hidden={step !== index}
                      initial={{ y: 1000 }}
                      animate={{ y: 0 }}
                    >
                      {" "}
                      {stp.component}{" "}
                    </motion.div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignSelf: "center",
                      marginTop: "20px",
                      marginBottom: "10px",

                      gap: 10,
                    }}
                  >
                    {!step <= 0 && !(step >= stepsList.length - 1) && (
                      <IconButton
                        size="small"
                        onClick={() => setStep((prv) => prv - 1)}
                      >
                        <ArrowBackIosOutlined /> Back
                      </IconButton>
                    )}
                    {step >= 0 && !(step >= stepsList.length - 2) && (
                      <IconButton
                        size="small"
                        onClick={handleNext}
                        hidden={step === 1}
                      >
                        Next <ArrowForwardIosOutlined />
                      </IconButton>
                    )}
                  </div>
                </Box>
              </form>
            </Card>
          </Container>
        )}
      </Spin>
    </>
  );
}
