import React from 'react'
import InfluencerIndex from './influencer'
import { UserProfileSelector } from '../../store/auth/selectors'
import UserWallet from '../authpages/user_wallet.js'
import {
  ListAltOutlined,
  LocalOfferOutlined,
  PeopleAltOutlined,
  ShoppingBasketOutlined,
  AccountBalanceWalletOutlined,
  AccountCircleOutlined,
  ArrowBack,
  ArrowBackOutlined,
  Info,
  InfoOutlined,
} from '@mui/icons-material'
import NavBar from './widgets/navBar'
import GigIndex from './gig'
import OrderIndex from './order/orderList.js'
import ProposalIndex from './proposals'
import MyPage from './influencer/myPage'
import { Layout, Modal, Typography } from 'antd'
import {
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material'
import { gotoTab, switchTabs } from '../../store/core/reducer.js'
import { useDispatch } from 'react-redux'
import { SelectCurrentTabActive } from '../../store/core/selectors.js'
import { useNavigate } from 'react-router-dom'
import Sider from 'antd/es/layout/Sider.js'
import ApplyButton from './widgets/applyButton.js'
import {
  SelectInfluencerErrors,
  SelectInfluencerMessage,
} from './redux/influencer/selectors.js'
import {
  resetInfluencerErrors,
  resetInfluencerMessage,
} from './redux/influencer/reducer.js'
import { fetchUserProfile } from '../../store/auth/thunks.js'

export default function InfluenceHub(props) {
  const userProfile = UserProfileSelector()
  const dispatch = useDispatch()
  const { Title } = Typography
  const influencerError = SelectInfluencerErrors()
  const [isStickyNav, setIsStickyNav] = React.useState(false)
  const activeTab = SelectCurrentTabActive()
  const [children, setChildren] = React.useState(props.children)
  const [showNotification, setShowNotification] = React.useState(false)
  const influencerMessage = SelectInfluencerMessage()
  const [activeMessage, setActiveMessage] = React.useState(null)
  const navigate = useNavigate()

  const NavList = [
    {
      title: 'Influencers',
      orUseChild: true,
      component: <InfluencerIndex />,
      icon: <PeopleAltOutlined />,
      action: () => navigate('/influence-hub'),
    },
    // {
    //   title: 'Gigs',
    //   component: <GigIndex />,
    //   action: () => navigate('/influence-hub/gigs'),
    //   icon: <LocalOfferOutlined />,
    // },
    {
      title: 'Orders',
      component: <OrderIndex />,
      notif_count: userProfile.new_influencer_orders,
      orUseChild: true,
      action: () => navigate('/influence-hub/orders'),

      icon: <ShoppingBasketOutlined />,
    },
  ].concat(
    userProfile.is_influencer === true
      ? [
          // {
          //   title: 'Proposals',
          //   component: <ProposalIndex />,
          //   icon: <ListAltOutlined />,
          // },
          {
            title: 'My Page',
            component: <MyPage userProfile={userProfile} />,
            icon: <AccountCircleOutlined />,
          },
        ]
      : [
          {
            title: 'Wallet',
            icon: <AccountBalanceWalletOutlined />,
            component: <UserWallet />,
          },
        ],
  )

  React.useEffect(() => {
    dispatch(
      switchTabs({
        name: 'inHub',
        active: props.active || 0,
        tabs: NavList.map(nav => ({ title: nav.title, icon: nav.icon })),
      }),
    )
    dispatch(fetchUserProfile())
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStickyNav(!entry.isIntersecting)
      },
      { threshold: 0.5 },
    )
    if (sideNavRef.current) {
      observer.observe(sideNavRef.current)
    }
    return () => {
      if (sideNavRef.current) {
        observer.unobserve(sideNavRef.current)
      }
    }
  }, [])
  const sideNavRef = React.useRef(null)
  React.useEffect(() => {
    if (influencerMessage) {
      setActiveMessage(influencerMessage)
      dispatch(resetInfluencerMessage())
    }
  }, [influencerMessage])
  const SideNav = () => {
    return (
      <Box
        ref={sideNavRef}
        sx={{
          width: '100%',
          position: isStickyNav ? 'fixed' : 'relative',
          maxWidth: 260,
          borderRadius: '15px',
          bgcolor: 'background.paper',
        }}
      >
        <List component={'nav'}>
          {NavList.map((itm, index) => (
            <ListItem>
              <ListItemButton
                onClick={
                  itm?.action
                    ? itm?.action
                    : () => {
                        dispatch(gotoTab(index))
                      }
                }
                selected={index === activeTab}
              >
                <ListItemIcon> {itm.icon}</ListItemIcon>

                <ListItemText
                  primary={<Typography> {itm.title}</Typography>}
                ></ListItemText>
                <Badge color="primary" badgeContent={itm.notif_count || 0}>
                  <></>
                </Badge>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  }
  return (
    <>
      {/* <Container> */}{' '}
      {influencerError && (
        <Dialog>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {influencerError.msg || influencerError.detail}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatch(resetInfluencerErrors())}>
              {' '}
              Ok{' '}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {activeMessage && (
        <>
          {activeMessage.type === 'notification' ? (
            <Modal
              open={activeMessage !== null}
              closable={false}
              title={activeMessage.title}
              type={activeMessage.status || 'info'}
              cancelButtonProps={{ style: { display: 'none' } }}
              okType="default"
              onOk={() => setActiveMessage(null)}
              okText="Close"
            >
              {' '}
              {<p>{activeMessage.message}</p>}{' '}
            </Modal>
          ) : (
            <Alert
              title={activeMessage.title}
              color={activeMessage.status || 'info'}
              variant="outlined"
              icon={<InfoOutlined />}
            >
              {activeMessage.message}
            </Alert>
          )}{' '}
        </>
      )}
      <Box
        sx={{
          marginTop: { md: '15px', xs: '-5px' },
          height: '10vh',
          width: { xs: '100%', md: '50%' },
          margin: '0 auto',
          display: 'flex',

          flexDirection: 'row',
          // padding: '10px',
          // boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '5px',
          }}
        >
          <Title
            style={{ fontWeight: 'bolder', opacity: '0.7', margin: 0 }}
            level={5}
          >
            {NavList[activeTab].orUseChild && children ? '' : 'Influence-Hub'}
          </Title>
          <IconButton
            onClick={() => navigate(-1)}
            style={{ margin: 0 }}
            size="large"
          >
            <ArrowBackOutlined />
          </IconButton>
        </div>
        <Title
          // className="md-5"
          style={{ fontWeight: 'bolder', fontFamily: 'sans' }}
          level={1}
        >
          {NavList[activeTab].orUseChild && children
            ? ''
            : '|' + NavList[activeTab].title}
        </Title>
      </Box>
      <Grid
        container
        component={'div'}
        spacing={1}
        sx={{ paddingTop: 0, padding: { xs: 0, md: 10 } }}
      >
        <Grid sx={{ display: { md: 'block', xs: 'none' } }} item xs={0} md={2}>
          <SideNav />
        </Grid>
        <Grid
          md={10}
          xs={12}
          sx={{
            borderRadius: '14px',
            // overflow: 'hidden',
            scrollbarWidth: 'thin',
            // overflowY: { md: 'scroll', xs: 'auto' },
            // maxHeight: { md: '70vh', xs: 'auto' },
          }}
          item
        >
          <Box>
            {' '}
            {(NavList[activeTab].orUseChild && children) ||
              NavList[activeTab].component}
          </Box>
        </Grid>
      </Grid>
      <Layout.Footer
        style={{ position: 'fixed', right: 0, left: 0, bottom: '0' }}
      >
        {!userProfile?.is_influencer ? activeTab === 0 && <ApplyButton /> : ''}
      </Layout.Footer>
      {/* </Container> */}
    </>
  )
}
