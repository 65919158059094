import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchUser, fetchMyInfluencerPage, fetchUserProfile } from "./thunks.js";
const initialState = {
  status: "idle",
  user: {
    isAuthenticated: false,
  },
  myInfluencerPage:null,
  profile: {},
  error: null,
};
const authReducer = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    changeStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.user.isAuthenticated = true;
        state.status = "loaded";
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.status = "loaded";
      })
      .addCase(fetchMyInfluencerPage.fulfilled, (state, action) => {
        state.myInfluencerPage = action.payload;
        state.status = "loaded";
      })
      .addMatcher(
        isAnyOf(fetchUser.pending, fetchUserProfile.pending, fetchMyInfluencerPage.pending),
        (state, action) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isAnyOf(fetchUser.rejected, fetchUserProfile.rejected,  fetchMyInfluencerPage.rejected),
        (state, action) => {
          state.error = JSON.parse(action.error.message);
          state.status = "failed";
        }
      );
  },
});
export const { changeStatus } = authReducer.actions;
export default authReducer.reducer;
