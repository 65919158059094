import { Box, Button } from '@mui/material';
import {  message } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom';



export default function Signout() {
const navigate = useNavigate()
React.useEffect(()=>{
if(!localStorage.getItem('AccgToken')){
message.info('Signed out')
navigate('/')
}

}, [])
 const handleLogout = () => {

        localStorage.removeItem('AccgToken')
        window.location.reload()
        setTimeout(() => {
            message.success('logged-out')
        }, 2000)
    }
  return (
  <>
  <Box style={{ height:'100vh', textAlign:'center', padding:'20px'}}>
  <h3>Wanna take a break?</h3>
    <div><p>Click the button to continue</p></div>
    
    <Button onClick={()=>{handleLogout()}} style={{color:'whitesmoke', width:'50%', padding:'15px',marginTop:'30px', backgroundColor:'rgb(100,180,120)'}}>Signout </Button>
  </Box>  </>
    
  )
}
