import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FmdGood } from '@mui/icons-material';
import { Button, Form, Input, InputNumber } from 'antd';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { IconButton, Switch } from '@mui/material';
import BgProfile from "../../assets/images/bg-profile.jpg";
import APP_ENV from '../../env';
const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json",



};

if (localStorage.getItem('AccgToken') !== null) {
    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


})

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 20,
        },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
};
const InputStyle = {
    // padding: '10px',
    // borderRadius: '6px',
    // border: '1px solid lightgreen',
    width: '100%'



}
const InputButtonStyle = {
    // padding: '10px',
    // flexWidth: '20px ',
    // borderRadius: '6px',
    // border: '1px solid darkgreen',
    backgroundColor: 'rgb(37, 44, 48)',
    // justifyContent: 'center',
    // margin: '10px',
    color: 'white '


}
const EditUserProfile = React.forwardRef((props, ref) => {
    const [catFields, setCatFields] = React.useState(props.userProfile.seller_category)
    const [coverImage, setCoverImage] = React.useState(props.userProfile.cover_picture !== null ?  props.userProfile.cover_picture : BgProfile)
    const uploadCoverRef = React.useRef(null)


    return (
        <>

            <div style={{ width: 'inherit', textAlign: 'center' }}> <div
                className="profile-nav-bg"
                style={{ paddingTop: '40px', justifyContent: 'center', zIndex: -100, backgroundImage: "url(" + coverImage + ")" }}
            >


                <UploadApp profileID={props.userProfile.id} userUsername={props.userProfile.username} picture={props.userProfile.display_picture} />
                <Upload
                    name="cover"
                onClick={() => { uploadCoverRef.current.click() }}

                    className="avatar-uploader"
                    onChange={(file) => { if (file.file.status === 'done') setCoverImage( file.file.response['cover_picture']) }}
                    // listType='picture-card'
                    action={BASE_URL + 'user/api/profile/' + props.userProfile.username}
                    headers={{ "Authorization": `Token ${localStorage.getItem('AccgToken')}` }}
                    accept='.png,.jpeg,.jgp'
                    onRemove={() => {
                        client.put('/user/api/profile/' + props.userProfile.username, { 'method': 'delete', field: 'cover_picture' }).then(response => { })
                    }}
                    maxCount={1}
                ><ImgCrop rotate >

                        <IconButton ref={uploadCoverRef}
                        ><b style={{ fontSize: '30px', color: 'white' }}>+</b></IconButton>

                    </ImgCrop >
                </Upload>
            </div>
                <br />

                <DetailsForm modalRef={ref} userProfile={props.userProfile} />

            </div ></>
    );
}
)
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 16,
    },
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */

const DetailsForm = (props) => {
    const [notify_on_conversations, setNotify_on_conversations] = useState(props.userProfile.notify_on_conversations)
    const [notify_on_order, setNotify_on_order] = useState(props.userProfile.notify_on_order)
    const [notify_on_update, setNotify_on_update] = useState(props.userProfile.notify_on_update)
    const [subscribed_to_NL, setSubscribed_to_NL] = useState(props.userProfile.subscribed_to_NL)
    const onFinish = (values) => {
        console.log(values)
        values.data.notify_on_order = notify_on_order
        values.data.notify_on_update = notify_on_update
        values.data.notify_on_conversations = notify_on_conversations
        values.data.subscribed_to_NL = subscribed_to_NL
        client.post('user/api/profile/' + props.userProfile.username, values['data']).then(response => {

            if (response.data) {
                message.success('Profile Saved!')
            }
        }).catch(err => { console.log(err) })

    };

    return (

        <Form ref={props.modalRef}  {...layout}

            name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} >
            <Form.Item
                name={['data', 'first_name']}
                label="First Name"
                initialValue={props.userProfile.first_name}

                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'last_name']}
                label="Last Name"
                initialValue={props.userProfile.last_name}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'email']}
                label="Email"
                initialValue={props.userProfile.email}
                rules={[
                    {
                        type: 'email',
                        required: 'true'
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'mobile']}
                label="Phone"
                initialValue={props.userProfile.mobile}
                rules={[
                    {
                        type: 'tel',
                        required: false
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'location']}
                label="Location"
                initialValue={props.userProfile.location}
                rules={[
                    {
                        type: 'text',
                        required: false
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'in_url']}
                label="Instagram-link"
                initialValue={props.userProfile.in_url}
                rules={[
                    {
                        type: 'url',
                        required: false
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'fb_url']}
                label="Facebook-link"
                initialValue={props.userProfile.fb_url}
                rules={[
                    {
                        type: 'url',
                        required: false
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'tw_url']}
                label="Twitter-link"
                initialValue={props.userProfile.tw_url}
                rules={[
                    {
                        type: 'url',
                        required: false
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            {/* <Form.Item
                name={['data', 'age']}
                label="Age"
                initialValue={props.userProfile.age}
                rules={[
                    {
                        type: 'number',
                        min: 16,
                        max: 99,
                    },
                ]}

            >
                <InputNumber style={InputStyle}
                />
            </Form.Item> */}
            <Form.Item name={['data', 'website']} initialValue={props.userProfile.website}
                rules={[
                    {
                        required: false,
                    },

                ]} label="Website">
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item style={{ width: 700 }} name={['data', 'subscribed_to_NL']} initialValue={props.userProfile.subscribed_to_NL}
                rules={[
                    {
                        required: false,
                    },

                ]} label="Subscribe to newsletter">
                <Switch style={{ width: 40 }} onChange={(e) => setSubscribed_to_NL(e.target.checked)} defaultChecked={props.userProfile.subscribed_to_NL}
                />
            </Form.Item>
            <Form.Item style={{ width: 700 }} name={['data', 'notify_on_conversations']} initialValue={props.userProfile.notify_on_conversations}
                rules={[
                    {
                        required: false,
                    },

                ]} label="Notify me when someone answers me">
                <Switch style={{ width: 40 }} onChange={(e) => setNotify_on_conversations(e.target.checked)} defaultChecked={props.userProfile.notify_on_conversations}
                />
            </Form.Item>
            <Form.Item style={{ width: 700 }} name={['data', 'notify_on_update']} initialValue={props.userProfile.notify_on_update}
                rules={[
                    {
                        required: false,
                    },

                ]} label="Email on Monthly updates">
                <Switch style={{ width: 40 }} onChange={(e) => setNotify_on_update(e.target.checked)} defaultChecked={props.userProfile.notify_on_update}
                />
            </Form.Item>
            <Form.Item style={{ width: 700 }} name={['data', 'notify_on_order']} initialValue={props.userProfile.notify_on_order}
                rules={[
                    {
                        required: false,
                    },

                ]} label="Email on order fulfillment" >
                <Switch style={{ width: 40 }} onChange={(e) => setNotify_on_order(e.target.checked)} disabled defaultChecked={props.userProfile.notify_on_order}
                />
            </Form.Item>


            <Form.Item name={['data', 'bio']} initialValue={props.userProfile.bio}
                rules={[
                    {
                        required: false,
                    },
                ]} label="Bio" >
                <Input.TextArea style={InputStyle} placeholder='Write something that can make customers trust your credibility.'
                />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>

            </Form.Item>
        </Form >
    );
};






const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
};

const UploadApp = (props) => {

    const [fileList, setFileList] = useState(props.picture ? [
        {
            uid: 'abc2',
            name: 'profile_picture',
            status: 'done',
            url:  props.picture

        },
    ] : []);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);

    };

    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    // alert(BASE_URL + 'user/api/profile/' + )
    return (
        <>

            <ImgCrop rotate >
                <Upload

                    name="avatar"
                    className="avatar-uploader"
                    listType="picture-card"
                    fileList={fileList}
                    action={BASE_URL + 'user/api/profile/' + props.userUsername}
                    headers={{ "Authorization": `Token ${localStorage.getItem('AccgToken')}` }}
                    accept='.png,.jpeg,.jgp'
                    onChange={onChange}
                    onPreview={onPreview}
                    onRemove={() => {
                        client.put('/user/api/profile/' + props.userUsername, { 'method': 'delete', field: 'display_picture' }).then(response => { })
                    }}
                    maxCount={1}
                >
                    {fileList.length < 1 && '+ Upload'}
                </Upload>
            </ImgCrop >
        </>
    );
};

export default EditUserProfile;
