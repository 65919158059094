import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../../app_context";


export const fetchPlatformMediums =createAsyncThunk('influencer/fetchPlatformMediums', async()=>{
    try{
        const response = await client.get('/in-hub/api/medium/')
        return response.data
    }catch(error)
    {
      
        return Promise.reject(JSON.stringify(error.response.data));
    }

})

export const postApplicationForm = createAsyncThunk(
  "influencer/postApplicationForm",
  async (payload, thunApi) => {
    try {
      const response = await client.post("/in-hub/api/influencers/", payload);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const fetchAllInfluencers = createAsyncThunk(
  "influencer/fetchAllInfluencers",
  async (payload, thunApi) => {
    try {
      const response = await client.get("/in-hub/api/influencers/"+payload);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const fetchBookmarkedInfluencers = createAsyncThunk(
  "influencer/fetchAllInfluencers",
  async (payload, thunApi) => {
    try {
      const response = await client.get("/in-hub/api/influencers/?bookmarked=true&page_size=1000");
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const influencersPageGet = createAsyncThunk(
  "influencer/influencersPageGet",
  async (payload, thunApi) => {
    try {
      const response = await client.get( payload.url?payload.url:"/in-hub/api/influencers/?page="+payload);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const fetchinfluencer = createAsyncThunk(
  "influencer/fetchinfluencer",
  async (payload, thunApi) => {
    try {
      const response = await client.get( `/in-hub/api/influencers/${payload.display_name}/`);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const claimInfluencerPage = createAsyncThunk(
  "influencer/claimInfluencerPage ",
  async (payload, thunApi) => {
    try {
      const response = await client.post('/in-hub/api/influencer/claim/', payload.data);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const interactInfluencerPage = createAsyncThunk(
  "influencer/interactInfluencerPage",
  async (payload, thunApi) => {
    try {
      const response = await client.put( `/in-hub/api/influencers/${payload.slug}/`, payload.data);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const updateInfluencerMediums= createAsyncThunk(
  "influencer/updateInfluencerMediums",
  async (payload, thunApi) => {
    try {
      const response = await client.post( `in-hub/api/influencer-medium/${payload.slug}/`, payload.data);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const fetchInfluencerAdPackages= createAsyncThunk(
  "influencer/fetchInfluencerAdPackages",
  async (payload, thunApi) => {
    try {
      const response = await client.get( `in-hub/api/ad-packages/?influencer=${payload.infSlug}`);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const createInfluencerAdPackages= createAsyncThunk(
  "influencer/createInfluencerAdPackages",
  async (payload, thunApi) => {
    try {
      const response = await client.post( `in-hub/api/ad-packages/?influencer=${payload.infSlug}`, payload.data);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const updateInfluencerAdPackage= createAsyncThunk(
  "influencer/updateInfluencerAdPackage",
  async (payload, thunApi) => {
    try {
      const response = await client.put( `in-hub/api/ad-packages/${payload.id}/?influencer=${payload.infSlug}`, payload.data);
      return response.data;
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
export const deleteInfluencerAddPackage= createAsyncThunk(
  "influencer/deleteInfluencerAddPackage",
  async (payload, thunApi) => {
    try {
      const response = await client.delete( `in-hub/api/ad-packages/${payload.id}/?influencer=${payload.infSlug}`);
      return payload.id
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response.data));
    }
  }
);
