import axios from "axios"
import React from "react";
import APP_ENV from "../env";



const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json",

    "Authorization": `Token ${localStorage.getItem('AccgToken')}`


};


const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


}
)
function SAccounts() {
    const [setSocialAccounts, SocialAccounts] = React.useState(null)

    var sAccounts = null
    client.get('/social/api/accounts').then(response => {
        console.log(response.data)
        setSocialAccounts(response.data)

    })

}

export default SAccounts;