import React from 'react'
import { SelectInfluencerOnViewAdPackages } from '../redux/influencer/selectors'
import {
  Box,
  Radio,
  RadioGroup,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormLabel,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  AvatarGroup,
  Paper,
  alpha,
  TextField,
  Button,
  CardContent,
  CardActionArea,
  Card,
  Chip,
  Checkbox,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { fetchInfluencerAdPackages } from '../redux/influencer/thunks'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { UserSelector } from '../../../store/auth/selectors'
import { Modal, Tag, notification, Divider, List } from 'antd'
import { BASE_URL, authToken } from '../../app_context'
import {
  FILE_POND_TYPES,
  FILE_TYPES,
  USER_NETWORK_FEE_PERC,
} from '../platformVars'
import WalletButton from '../../authpages/pay_with_wallet'
import { DateRangeOutlined } from '@mui/icons-material'
registerPlugin(FilePondPluginFileValidateType)
registerPlugin(FilePondPluginImagePreview)

export default function CreateInfluencerOrder({ influencer }) {
  const influencerPackages = SelectInfluencerOnViewAdPackages()
  const User = UserSelector()
  const [selectedPackage, setSelectedPackage] = React.useState(null)
  const [defaultPkgID, setDefaultPkgID] = React.useState(null)
  const [unavailable, setUavailable] = React.useState(false)
  const [mediaContents, setMediaContents] = React.useState([])
  const [currentFile, setCurrentFile] = React.useState({})
  const filePndRef = React.useRef(null)

  const [mediaFiles, setMediaFiles] = React.useState([])
  const [selectedMediums, setSelectedMediums] = React.useState([])
  const dispatch = useDispatch()
  const urlPkg = new URLSearchParams(window.location.search).get('package')
  const pkgSelectRef = React.useRef(null)
  const [accptableFileTypes, setAccptableFileTypes] = React.useState([])
  const [currentOrderDetails, setCurrentOrderDetails] = React.useState(null)
  const [activeCurrency, setActiveCurrency] = React.useState('')
  // const sessionId = md5(String(new Date().getDate()) + influencer?.display_name + User?.username)
  React.useEffect(() => {
    dispatch(fetchInfluencerAdPackages({ infSlug: influencer.slug }))
  }, [])

  React.useEffect(() => {
    if (urlPkg && influencerPackages?.length >= 1) {
      const findPkg = influencerPackages.find(pkg => pkg.id === +urlPkg)

      if (findPkg && !selectedPackage) {
        handleSelectPackage(findPkg)
        setDefaultPkgID(findPkg.id)
      }
    } else if (
      influencerPackages?.length >= 1 &&
      influencerPackages?.filter(pkg => pkg.default === true).length >= 1 &&
      !selectedPackage
    ) {
      handleSelectPackage(influencerPackages?.find(pkg => pkg.default === true))
      setDefaultPkgID(influencerPackages?.find(pkg => pkg.default === true).id)
    }
    if (!influencer.mediums.filter(md => md.approved === true).length >= 1) {
      setUavailable(true)
    }
    pkgSelectRef?.current?.click()
  }, [influencerPackages])

  const handleCreateOrder = e => {
    e.preventDefault()
    const formData = Object.fromEntries(new FormData(e.target))
    formData.media_contents = mediaContents
      .filter(mc => {
        if (mc.serverId?.id) {
          return true
        }
      })
      .map(mc => mc.serverId.id)
    if (
      selectedPackage.content_type !== 'text' &&
      formData.media_contents.length < selectedPackage.media_min_length
    ) {
      return notification.error({
        message:
          'Please Upload atleast ' +
          selectedPackage.media_min_length +
          ' media contents',
      })
    }

    formData.mediums = selectedMediums
    if (formData.mediums <= 0) {
      return notification.error({
        message: 'Please Select atleast one medium',
      })
    }
    formData.type = 'package'
    // console.log(formData)
    setCurrentOrderDetails(formData)
  }

  const Unavailable = () => {
    return (
      <Box
        sx={{
          backgroundColor: '#2E4838',
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          borderRadius: '14px',
          position: 'absolute',

          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'hidden',
          filter: 'sepia(50%)',
        }}
      >
        <div style={{ width: '80%', margin: '0 auto', marginTop: '20px' }}>
          <Typography variant="h6" color={'GrayText'}>
            Sorry! This influencer Is Currently Unavailable
          </Typography>
          <small>Some Reasons May Include</small>
          <ul>
            <li>Influencer Medium(s) is awaiting approval</li>
            <li>Influencer page is under review over new changes </li>
            {/* <li>Influencer page has been banned</li> */}
          </ul>
        </div>
      </Box>
    )
  }

  const useStyles = makeStyles(theme => ({
    mediumMedia: {
      backgroundColor: alpha('rgb(48, 64, 54)', 0.2),
      backgroundRepeat: 'no-repeat',
      borderRadius: '10px',
      marginBottom: '5px',

      backgroundSize: '100px',
      opacity: 0.3,
      backgroundPositionY: '-10px',
      backdropFilter: 'contrast(-0.2)',
      paddingTop: '20px',
      backgroundBlendMode: 'hard-light',
      minHeight: '50px',
    },
  }))
  const classes = useStyles()

  const handleChangeMedium = val => {
    if (selectedMediums.includes(val)) {
      setSelectedMediums(prv => prv.filter(md => md !== val))
    } else {
      setSelectedMediums(prv => [...prv, val])
    }
  }

  const handleRevert = file => {
    // Send a request to your backend endpoint to revert the file
    fetch(BASE_URL + `/in-hub/api/ad-media-content/${file.id}/`, {
      method: 'DELETE', // Or appropriate HTTP method
    })
      .then(response => {
        if (response.ok) {
          // Optionally, you can refresh the file list or update UI here
          // load(); // Reload FilePond to reflect the changes
        } else {
          console.error('Failed to revert file')
        }
      })
      .catch(error => {
        console.error('Error occurred while reverting file:', error)
      })
  }

  const getTotal = () => {
    var sum = 0
    if (selectedPackage) {
      for (let i = 0; i < selectedPackage.available_mediums.length; i++) {
        const pkg = selectedPackage.available_mediums[i]
        if (selectedMediums.includes(pkg.id)) {
          sum += +pkg.charge
        }
      }

      return sum.toFixed(2)
    }

    return null
  }
  const getNetworkFee = () => {
    var ntFee = 0
    if (getTotal() >= 1) {
      ntFee = (getTotal() * USER_NETWORK_FEE_PERC) / 100
    }

    return ntFee.toFixed(2)
  }

  const handleSelectPackage = e => {
    if (!e.target) {
      var pkg = e
    } else {
      var pkg = influencerPackages.find(pkg => pkg.id === e.target.value)
    }
    const acceptedFileTypes = pkg.acceptable_media_types.map(
      mt => FILE_POND_TYPES.find(([key, val]) => key === mt)[1],
    )
    setAccptableFileTypes(acceptedFileTypes)
    setActiveCurrency(pkg?.available_mediums[0]?.charge_currency)
    setSelectedPackage(pkg)
    setMediaFiles([])
    setSelectedMediums([])
    filePndRef.current?.removeFiles()
  }
  return (
    <>
      <Container style={{ minHeight: '50vh', position: 'relative' }}>
        {unavailable ? (
          <Unavailable />
        ) : (
          <Box>
            <Typography variant="subtitle1"> Create Advert Order </Typography>

            <Box sx={{ paddingTop: '20px' }}>
              <form onSubmit={handleCreateOrder}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel variant="outlined">Select Package </InputLabel>
                  <Select
                    placeholder="Select Package"
                    ref={pkgSelectRef}
                    value={selectedPackage?.id || defaultPkgID}
                    onChange={handleSelectPackage}
                  >
                    {influencerPackages.map(pkg => (
                      <MenuItem key={pkg.id} value={pkg.id}>
                        <ListItem style={{ margin: 0, padding: 0 }}>
                          <ListItemAvatar style={{ marginRight: 2 }}>
                            <AvatarGroup
                              sx={{
                                '& .MuiAvatar-root': { border: 'none' },
                              }}
                              spacing={3}
                              max={4}
                            >
                              {' '}
                              {pkg.available_mediums.map(
                                md =>
                                  md.medium.approved === true && (
                                    <Avatar
                                      sx={{
                                        width: 20,
                                        height: 20,
                                      }}
                                      variant={'square'}
                                      src={md.medium.medium.logo}
                                    />
                                  ),
                              )}
                            </AvatarGroup>
                          </ListItemAvatar>
                          <ListItemText>{pkg.name}</ListItemText>
                        </ListItem>{' '}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedPackage?.id && (
                  <>
                    <input name="package" hidden value={selectedPackage?.id} />
                   <FormControl  style={{display:'inline-flex', textAlign:'center', float:'right', margin:'3px',marginTop:'5px'}} >
                    <div >
                       
                        <Chip icon={<DateRangeOutlined />} variant='filled' color='primary' label={selectedPackage.fulfillment_time_display}>  </Chip>
                   <FormLabel style={{fontSize:'10px', marginTop:"4px", textAlign:'center', display:'block',}} >
                          Fulfillment Time
                        </FormLabel> 
                         </div>  </FormControl>  
                    <div
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    >
                      {' '}
                      <Divider>Description</Divider>
                     
                      <Paper
                        sx={{
                          minHeight: '100px',
                          borderRadius: '14px',
                          border: '0.5px solid ' + alpha('#526653', 0.4),
                          padding: '10px',

                          boxShadow:
                            'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                        }}
                      >
                        <Typography variant="caption">
                          {selectedPackage.description}
                        </Typography>
                      </Paper>
                    </div>
                    <Divider>Advert Contents</Divider>

                    {selectedPackage.content_type === 'text' ||
                      selectedPackage.content_type === 'all' ? (
                      <FormControl required={selectedPackage.content_type === 'text'} style={{ marginBottom: 20 }} fullWidth>
                        <TextField
                          name="text_content"
                          multiline
                          required={selectedPackage.content_type === 'text'}
                          rows={10}

                          placeholder="Enter Text Content"
                        />
                      </FormControl>
                    ) : (
                      ''
                    )}
                    {selectedPackage.content_type === 'file' ||
                      selectedPackage.content_type === 'all' ? (
                      <FilePond
                        ref={filePndRef}
                        maxFiles={selectedPackage.media_max_length}
                        allowImagePreview
                        allowMultiple
                        onupdatefiles={files => {
                          setMediaContents(files)
                        }}
                        acceptedFileTypes={[...accptableFileTypes[0]]}
                        labelIdle={
                          "Drag & Drop your media contents or <span class='filepond--label-action'>Browse</span>" +
                          '<br/>' +
                          '<small style="color:green; font-size:10px">' +
                          (!selectedPackage.acceptable_media_types_display.includes(
                            '*',
                          )
                            ? `Only ${selectedPackage.acceptable_media_types} are accepted` +
                            '</small>'
                            : ' ')
                        }
                        name="media_content"
                        onaddfile={(error, file) => {

                          setCurrentFile({
                            file: file.file,
                            fileName: file.filenameWithoutExtension,
                            fileType: file.fileType,
                          })
                        }}
                        server={{
                          process: {
                            url: BASE_URL + '/in-hub/api/ad-media-content/',
                            method: 'POST',
                            withCredentials: false,

                            headers: { Authorization: authToken },

                            onload: response => {
                              const resp = JSON.parse(response)
                              // console.log(response);
                              notification.success({
                                message: 'Upload Successful',
                              })
                              return resp
                            },

                            ondata: formData => {
                              formData.append('name', currentFile.fileName)
                              formData.append('type', currentFile.fileType)
                              formData.append('uploader', User.id)
                              return formData
                            },
                          },
                          revert: file => handleRevert(file), // Use the serverId for revert
                        }}
                      ></FilePond>
                    ) : (
                      ''
                    )}
                    <Divider>Select Mediums</Divider>
                    <FormControl>
                      <Box
                        style={{
                          display: 'flex',
                          gap: 3,
                          justifyContent: 'start',
                          alignContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        {selectedPackage.available_mediums.map(
                          md =>
                            md.medium.approved && (
                              <div
                                style={{
                                  display: 'inline-block',
                                }}
                              >
                                <Card
                                  sx={{
                                    // padding: 2,
                                    backgroundColor: theme =>
                                      theme.palette.mode === 'dark'
                                        ? '#3C6139'
                                        : '#c5dec3',

                                    borderRadius: '14px',
                                    margin: '2px',
                                    // border:
                                    //   '0.5px solid ' + alpha('#526653', 0.4),
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                  }}
                                >
                                  <CardActionArea
                                    onClick={() =>
                                      window.open(md.medium.url_link)
                                    }
                                  >
                                    <CardContent>
                                      <div style={{ position: 'relative' }}>
                                        <div
                                          style={{
                                            backgroundImage: `url(${md.medium.medium.logo})`,
                                          }}
                                          className={classes.mediumMedia}
                                        ></div>
                                        <Typography
                                          variant="h5"
                                          style={{
                                            top: '40%',
                                            right: 0,
                                            left: 0,
                                            position: 'absolute',
                                          }}
                                          fontSize={14}
                                        >
                                          {md.medium.medium.display_name}{' '}
                                        </Typography>
                                      </div>
                                      <Chip
                                        label={
                                          <Typography
                                            variant="button"
                                            fontWeight="bolder"
                                          >
                                            {md.charge_currency} {md.charge}
                                          </Typography>
                                        }
                                        variant="filled"
                                      ></Chip>
                                      <Typography
                                        color={'HighlightText'}
                                        marginTop={'5px'}
                                        fontSize="10px"
                                      >
                                        @{md.medium.handle}
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                                <Checkbox
                                  value={md.id}
                                  checked={
                                    selectedMediums.includes(md.id) === true
                                  }
                                  sx={{
                                    // position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                  }}
                                  onClick={e => {
                                    handleChangeMedium(+e.target.value)
                                  }}
                                />
                              </div>
                            ),
                        )}
                      </Box>
                    </FormControl>{' '}
                    {getTotal() ? (
                      <div style={{ padding: '10px' }}>
                        <Divider />

                        <Typography style={{ float: 'left' }} variant="button">
                          Total{' '}
                        </Typography>
                        <Typography variant="button" style={{ float: 'right' }}>
                          {activeCurrency} {getTotal()}
                        </Typography>
                      </div>
                    ) : (
                      ''
                    )}
                    <FormControl style={{ marginTop: 15 }} fullWidth>
                      <Button variant="contained" type="submit">
                        Create Order
                      </Button>
                    </FormControl>
                  </>
                )}
              </form>
            </Box>
          </Box>
        )}
      </Container>
      <Modal
        title={'Confirm Order'}
        open={currentOrderDetails !== null}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setCurrentOrderDetails(null)}
      >
        {currentOrderDetails && (
          <>
            <b>Package {selectedPackage?.id} |</b>
            <Typography variant={'caption'}>
              {' '}
              {selectedPackage.name}{' '}
            </Typography>
            <List bordered={false} stye={{ marginBottom: '10px' }}>
              <Divider>Mediums</Divider>
              {selectedMediums.map(mmd => {
                const md = selectedPackage.available_mediums.find(
                  mfd => mfd.id === mmd,
                )

                return (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={md.medium.medium.logo} />}
                      title={md.medium.medium.display_name}
                      description={
                        <>
                          {md.charge_currency} {md.charge}
                        </>
                      }
                    ></List.Item.Meta>
                  </List.Item>
                )
              })}

              <List.Item>
                <List.Item.Meta
                  title={'Network Fee'}
                  description={
                    <>
                      {activeCurrency} {getNetworkFee()}
                    </>
                  }
                ></List.Item.Meta>
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  title={'Sub Total'}
                  description={
                    <>
                      {activeCurrency} {getTotal()}
                    </>
                  }
                ></List.Item.Meta>
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  title={'Total'}
                  description={
                    <>
                      {' '}
                      {activeCurrency} {+getTotal() + +getNetworkFee()}
                    </>
                  }
                ></List.Item.Meta>{' '}
              </List.Item>
            </List>
            <WalletButton
              disable={currentOrderDetails === null}
              mediums={selectedMediums}
              currency={activeCurrency}
              pkgId={selectedPackage.id}
              type={'advertPackage'}
              advertData={currentOrderDetails}
            />
          </>
        )}
      </Modal>
    </>
  )
}
