import React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Avatar, Typography, Box } from "@mui/material";
import { Image, Radio, Spin } from "antd";
import "./chat.css";
import {
  ArrowForwardOutlined,
  Done,
  DoneAll,
  FilePresent,
  Forward5Outlined,
  ForwardRounded,
  ForwardToInbox,
  MarkAsUnread,
  MarkChatRead,
  MarkChatReadOutlined,
  MarkEmailRead,
  PhoneForwarded,
} from "@mui/icons-material";
import ReactPlayer from "react-player";
import FileWidget from "./fileWidget";
import { memo } from "react";

function ChatMessage({
  msg,
  usr,
  className = "",
  child = false,
  selectOn = false,
  ...others
}) {
  const [currentClassName, setcurrentClassName] = useState("unselected");
  const [isSender, setisSender] = useState(
    msg.sender.username === usr.username
  );
  const [selected, setSelected] = useState(false);
  const useStyles = makeStyles((theme) => ({
    selected_chat: {
      backgroundColor: "grey",
    },
    paper: {
      display: "flex",
      // maxWidth: "80%",
      alignSelf: "flex-start",
      borderRadius: "20px",
      float: "left",
      padding: 20,
      margin: 5,
      cursor: selectOn ? "pointer" : "auto",

      width: "50%",
      backgroundColor: "rgb(37, 44, 48)",

      color: "whitesmoke",
      borderTopLeftRadius: child === true ? "20px" : "0px",
    },
    paperSender: {
      cursor: selectOn ? "pointer" : "auto",
      alignSelf: "flex-end",
      display: "flex",
      padding: 20,
      margin: 5,
      width: "50%",
      borderRadius: "20px",
      color: "whitesmoke",
      borderBottomRightRadius: child === true ? "20px" : "0px",
      float: "right",
      backgroundColor: "rgb(57, 104, 48)",

      // color: theme.palette.primary.contrastText,
    },

    avatarContainer: {
      marginRight: "3px",
      marginLeft: "-15px",
      marginTop: "-15px",
      // marginBottom: ,
    },
    date: {
      float: "right",
    },
    body: {
      fontSize: "13px",
    },

    bottomActions: {
      fontSize: "0.8rem",
      color: "lightgrey",
      marginTop: theme.spacing,
    },
    attachment: {},
  }));
  const classes = useStyles();

  React.useEffect(() => {
    if (selectOn === false) {
      setSelected(false);
    }
  }, [selectOn]);
  return (
    <>
      <Box
        sx={{ width: "100%", scrollBehavior: "smooth" }}
        className={className}
      >
        <Paper
          onClick={(e) => {
            if (selectOn) {
              setSelected(!selected);
              others.handleSelect();
            }
          }}
          className={[
            isSender ? classes.paperSender : classes.paper,
            selected === true && selectOn === true ? "selected_chat" : "",
          ]}
        >
          {/* {!isSender && child === false && (
            <div className={classes.avatarContainer}>
              <Avatar sx={{ width: 24, height: 24 }} src={msg.sender.avatar} />
            </div>
          )} */}
          <div style={{ width: "100%" }}>
            <Typography className={classes.attachment}>
              {msg.attachments && msg.attachments.length >= 1
                ? msg.attachments.map((item, index) => (
                    <Box
                      style={{
                        width: "100%",
                        height: msg.attachments.length >= 3 ? "150px" : "100%",
                        overflow: "hidden",
                        padding: "10px",
                        // display: "inline-flex",
                      }}
                    >
                      <Box></Box>{" "}
                      <div>
                        {item?.type.includes("audio") ? (
                          <audio
                            style={{ width: "100%" }}
                            src={item.url}
                            controls
                            title={item.name}
                          ></audio>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {item?.type.includes("video") ? (
                          <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            controls
                            url={item.url}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {item?.type.includes("image") ? (
                          <Image
                            src={item.url}
                            style={{
                              width: "100%",
                              height:
                                msg.attachments.length >= 3 ? "200px" : "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {item?.type.includes("application") ? (
                          <div>
                            <FileWidget
                              url={item.url}
                              type="application"
                              name={item.name}
                              large={true}
                              id={index}
                            ></FileWidget>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Box>
                  ))
                : ""}
            </Typography>
            {msg.forwarded_from ? (
              <div>
                <small
                  style={{
                    top: "-10px",
                    color: "lightgray",
                    fontStyle: "italic",
                    textTransform: "capitalize",
                  }}
                >
                  <>
                    {" "}
                    <ArrowForwardOutlined style={{ fontSize: "12px" }} />{" "}
                    forwarded
                  </>
                </small>
              </div>
            ) : (
              ""
            )}
            <Typography className={classes.body}>{msg.body}</Typography>
            <Typography className={classes.bottomActions}>
              <div className={classes.date}>
                <i>
                  {" "}
                  <time> {msg.time}</time>
                </i>
              </div>
              <div>
                <div style={{ fontSize: "5px", marginTop: "10px" }}>
                  {isSender ? (
                    msg.receipts ? (
                      msg.receipts.filter(
                        (itms) =>
                          itms.chat_member.username !== msg.sender.username
                      )[0]?.seen === true ? (
                        <DoneAll
                          style={{
                            fontSize: "15px",
                            color:
                              msg.receipts.filter(
                                (itms) =>
                                  itms.chat_member.username !==
                                  msg.sender.username
                              )[0]?.read === true
                                ? "lightblue"
                                : "grey",
                          }}
                        />
                      ) : (
                        <Done style={{ fontSize: "15px" }} />
                      )
                    ) : (
                      <Spin spinning={msg.sent}>
                        <p></p>
                      </Spin>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Typography>
          </div>
        </Paper>
        <span className={"msg-id"} id={msg.id} style={{ display: "none" }}>
          {!isSender?msg.receipts
            ? msg.receipts.filter(
                (itms) => itms.chat_member.username !== msg.sender.username
              )[0]?.read === true
              ? "Read"
              : ""
            : "":'Read'}
        </span>
      </Box>
    </>
  );
}

export default ChatMessage;

// <Box
//   sx={{
//     margin: { md: "10px", xs: "5px", display: "flex" },

//     float: message.sender.username === user.username ? "right" : "left",
//   }}
//   className="user-message-litem"
// >
//   <Avatar
//     size={30}
//     style={{
//       marginTop: "3px",

//       float: message.sender.username === user.username ? "left" : "right",
//     }}
//     src={message.sender.avatar}
//   >
//     {user.username[0]}
//   </Avatar>
//   <Box
//     sx={{
//       padding: "20px",
//       borderRadius: "10px",
//       width: { md: "200px", xs: "100px" },
//       backgroundColor:
//         message.sender.username === user.username
//           ? "rgb(37, 44, 48)"
//           : "rgb(57, 104, 48)",
//     }}
//   >
//     {message.body}
//   </Box>
//   <br />
//   <Avatar
//     size={30}
//     style={{
//       marginTop: "3px",

//       float: message.sender.username === user.username ? "left" : "right",
//     }}
//     src={message.sender.avatar}
//   >
//     {user.username[0]}
//   </Avatar>
// </Box>;
