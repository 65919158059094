
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import ProductCard from "./ProductCard";
import { Empty, Row, Col, Spin, message } from "antd";
import { useState } from "react";
import { client } from "./app_context";
import { useNavigate, useParams } from "react-router-dom";
import ProfileCard from "./profileCard";
import { Button, Card, Divider } from "@mui/material";



function SearchPage(props) {
  const [searchResult, setsearchResult] = React.useState([]);

  const [expanded, setExpanded] = React.useState(false);
  const [currentItemId, setCurrentItemId] = React.useState(null);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate()
  const [query, setquery] = useState(
    new URLSearchParams(window.location.search).get("query")
  );

  const getData = () => {
    client
      .get("/user/api/search-platform?query=" + query)
      .then((response) => {
        setsearchResult(response.data.result);
        setloading(false);
      })
      .catch((err) => {
        // alert(err)
        message.error("Error getting products");
        setloading(false);
      });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(function () {
    getData();

    return;
  }, []);

  const addToWish = (id) => {
    // alert(e.target.getAttribute('dataId'))
    client
      .get("/auth/users/me/")
      .then((response) => {
        if (response.data) {
          if (response.data.username) {
            client
              .get(
                `/social/api/add-to-wishlist/${response.data.username}/${id}`
              )
              .then((resp) => {

              });
          }
        }
        props.headerUpdater.current.updateAll();
        message.success("Wishlist Updated");
        getData();
      })
      .catch((err) => {
        message.error("please login!");
      });
  };

  const addToCart = (id) => {
    // alert(e.target.getAttribute('dataId'))
    client
      .get("/auth/users/me/")
      .then((response) => {
        if (response.data) {
          if (response.data.username) {
            client
              .get(`/social/api/add-to-cart/${response.data.username}/${id}`)
              .then((resp) => {
                if (resp.data.msg) {
                  message.info(resp.data.msg);
                }

                message.success("Cart Updated!");
                props.headerUpdater.current.updateAll();
              });
          }
        }
      })
      .catch((err) => {
        message.error("something went wrong");
      });
  };

  return (
    <>
      <Spin spinning={loading} tip='fetching result...'>
        <div style={{ padding: "30px" }}>
          <Row gutter={16}>
            {searchResult.length >= 1 ? (
              <>
                <h3 style={{ textAlign: 'center', width: '100%' }}>Search result for "{query}"</h3>
                {searchResult.map((result, idx) => (
                  <>
                    <Col span={8}>
                      {result.type === 'product' ?
                        <ProductCard
                          refresh={getData}
                          wishFunc={addToWish}
                          cartFunc={addToCart}
                          account={result}
                          loading={loading}
                        /> :
                        result.type === 'profile' ?
                          <>
                            <ProfileCard loading={loading} refresh={getData} {...result}></ProfileCard>

                          </>
                          : result.type == "smm" ?
                            <>
                              <Card sx={{boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px", width:"auto", padding:"10px", minWidth:"100px", display:"inline-block", margin:"10px"}}>
                                <div onClick={()=>navigate(result.url)} style={{width:"100%",cursor:"pointer",fontWeight:"bolder", fontSize:"22px"}}>

                                  <b>{result.title}</b>

                                </div>
                                <br></br>
                                <div style={{opacity:0.6,width:"100%", fontSize:"16px"}}>
                                  {result.category}
                                </div>
                                <div style={{ display: "block", width:"100%" }}>

                                  <div style={{ padding: "20px",borderRadius: "10px", fontWeight:"bold",fontSize:"20px",  display: "inline-block", float: "left" }}>{result.price}</div>
                                  <div style={{ padding: "10px", backgroundColor: alpha("rgb(63, 105, 74)", 0.3), display: "inline-block", borderRadius: "10px", float: "right" }}>Min - {result.min} | Max - {result.max}</div>

                                </div> 
                                <br/>
                                <div style={{width:"100%", float:"left", display:"block",}}>



                                <Button size="large" variant="contained" color="primary" fullWidth onClick={()=>navigate(result.url)}>Boost</Button>
                                </div>
                              </Card>

                            </>
                            : <Empty />
                      }
                    </Col>
                  </>
                ))}

              </>
            ) : (
              <>
                <div style={{ margin: "0 auto" }}>
                  {loading === false ?
                    <>
                      <Empty />
                      <h3>No result for "{query}"</h3></> : ''}
                </div>
              </>
            )}
          </Row>
        </div>
      </Spin>
    </>
  );
}

export default SearchPage;
