import React, { Component } from 'react'
import { Card, Spin } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    Form,
    InputNumber,
    Radio,
    Rate,
    Row,
    Select,
    Slider,
    Switch, Modal, message,
    Upload, Input
} from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState, useRef , useImperativeHandle } from 'react';
import { Card as muiCard } from '@mui/material';
import SubProductForm from "../subProduct";
import { getSocialAvatar, PriceInput, SocialMedia } from '../app_context';
import APP_ENV from '../../env';


const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json",

    "Authorization": `Token ${localStorage.getItem('AccgToken')}`


};
const cookies = new Cookies()
if (localStorage.getItem('AccgToken') !== 'null') {
    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


})
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
        return e;
    }

    return e?.fileList;
};

const ProductEditPage = React.forwardRef((props, ref) => {

    const navigate = useNavigate()
    const onFinish = (values) => {
      setloading(true);

      setloadingtip("updating product..");

      setloading(true);
      if (subProducts.length < 1) {
        message.error("Upload atleast 1 or more product variant!");
        setloading(false);
        setloadingtip("fetching data...");
      } else {
        values["sub_product_count"] = subProducts.length;
        const subProducts_el =
          document.getElementsByClassName("product-subm-btn");
        var productsEl = [];

        for (let i = 0; i < subProducts_el.length; i++) {
          const element = subProducts_el[i];

          element.click();
          const validator_el = document.querySelector(
            `#${element.id}-validator`
          );

          productsEl.push(validator_el);

          // message.error(error);
        }
        setTimeout(() => {
          var positive = productsEl.filter(
            (item) => item.getAttribute("value") === "true"
          );

          if (positive.length >= productsEl.length) {
           client
             .put("social/api/seller/products/" + currentProduct.slug, values)
             .then((resp) => {
               if (resp.data.error === true) {
                 setloadingtip("fetching data");
                 return message.error(resp.data.msg);
               } else {
                 message.success(resp.data.msg);
                 navigate("/seller/products");
               }
               setloading(false);
             })
             .catch((err) => {
               setloading(false);
               message.error("something went wrong");
             });
           
          } else {
            setloadingtip("fetching data...");
            setloading(false);
          }
        }, 5000);
      }
     

      // }
    };
    const [selectedCategory, setSelectedCategory] = React.useState(null)
    const [featureEntry, setfeatureEntry] = React.useState(null)
    const [fileList, setFileList] = React.useState([])

    const [imageList, setImageList] = React.useState([])
    const [features, setfeatures] = React.useState(['Never Banned Before', 'Folllowed By A / Many Celebrities', 'Over 10, 000 followers'])
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [subProducts, setSubProducts] = useState([]);

  const [ProductGroups, setProductGroups] = useState([]);

    const [formInitials, setFormInitials] = useState({})
    const { currentProduct } = props

    const productsRef = useRef();
    const [loading, setloading] = useState(true)
    const [loadingtip, setloadingtip] = useState('Loading...')
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);

            reader.onerror = (error) => reject(error);
        });

    const suffixSelector = (
        <Form.Item required={true} name="age-suffix" noStyle>
            <Select style={{ width: 100 }} defaultValue={currentProduct.age_suffix}>
                <Option value="Y">Year(s)</Option>
                <Option value="M">Month(s)</Option>
                <Option value="D">Day(s)</Option>
            </Select>
        </Form.Item>
    );


    const checkPrice = (_, value) => {
        if (value.number >= 500) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Price must be greater than 500!'));
    };
    React.useEffect(() => {
        setSelectedCategory(currentProduct.category);
        
        for (let i = 0; i < currentProduct.images.length; i++) {
            let image = { url: currentProduct.images[i].url, name: currentProduct.images[i].name };
            setImageList(prevImg => [...prevImg, image]);

        }
        for (let i = 0; i < currentProduct.files.length; i++) {
            let file = { url: currentProduct.files[i].url, name: currentProduct.files[i].name };
            setFileList(prevFile => [...prevFile, file]);

        }
        setSubProducts([])
        if (currentProduct.products){
        for (let i = 0; i < currentProduct.products.length; i++) {
          const product = currentProduct.products[i];
          if(product.index);
          addSubProduct(currentProduct.category, product.index);
        }
        setloading(false)
         client
           .get("/social/api/seller/product-groups/")
           .then((response) => {
             // console.log(response.data);
             setProductGroups(response.data);
             setloading(false);
           })
           .catch((err) => {
             message.error("Error getting groups");
             setloading(false);
           });
    
    }
        // setImageList(imageList)

    }, [])


   const deleteSingleProduct = (index) => {
     setloading(true);
     client
       .delete("/social/api/seller/products/" + currentProduct.id + "/" + index)
       .then((resp) => {
         message.info(resp.data.msg);
        //  refreshPageData();
       })
       .catch((err) => {message.error("An error occured");setloading(false)});
   };
       
   const addSubProduct = (productCategory, index) => {
                   
                   

     setSubProducts((prevSubProducts) => [
       ...prevSubProducts,
       <SubProductForm
         deleteProduct={deleteSingleProduct}
         productId={currentProduct.id}
         type={productCategory}
         index={index}
         ref={productsRef}
         editMode={true}
       />,
     ]);
   };


    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setImageList(newFileList)
        console.log(newFileList)
    };
    const handleFileOnChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const handleFileRemove = (file) => {


        client.delete('/social/api/UserFiles?type=files&name=' + file.name + '&slug=' + file.slug).then(resp => {
            message.info('Deleted')
        }).catch(err => message.error('something went wrong'))


    }
    const handleImageRemove = (file) => {
        client.delete('/social/api/UserFiles?type=images&name=' + file.name + '&slug=' + file.slug).then(resp => message.info('Deleted')).catch(err => message.error('something went wrong'))

    }
    return (
      <Spin
        spinning={loading}
        tip={loadingtip}
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          margin: "0 auto",
        }}
      >

        <Card style={{ marginTop: 16 }} type="inner" title="Edit Product">
          <Form
            ref={ref}
            name="validate_other"
            {...formItemLayout}
            id="editForm"
            onFinish={onFinish}
            initialValues={{
              MediaType: currentProduct.socialMedia,

              features: currentProduct.features,
              age: currentProduct.age,
              "age-suffix": currentProduct.age_suffix,
              title: currentProduct.title,
              description: currentProduct.description,
            }}
          >
            <Form.Item required name="title" label="Post title">
              <Input placeholder="What you want people to see first" />
            </Form.Item>
            <Form.Item
              name="ProductCategory"
              label="Product Category"
              hasFeedback
              initialValue={currentProduct.category}
              rules={[
                {
                  required: true,
                  message: "Please select Product Category",
                },
              ]}
            >
              <Select
                onChange={(val) => {
                  setSelectedCategory(val);
                }}
                placeholder="Please select Product Category"
                defaultValue={currentProduct.category}
              >
                <Option value="sm">Social Media</Option>
                <Option disabled value="gm">
                  Games (Virtual Assets)
                </Option>
              </Select>
            </Form.Item>
            {selectedCategory === "sm" ? (
              <>
                <Form.Item
                  name="MediaType"
                  label="Media Type"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please select your Media Type!",
                    },
                  ]}
                >
                  <Select placeholder="Please select Media Type">
                    {SocialMedia.map((value, index) => (
                      <Option value={value[0]}>{value[1]}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              ""
            )}

            {/* Individual sub product details/product variants  */}
            <div style={{ margin: "10px auto" }}>
              <small>
                <b>Instructions:</b>
                <li style={{ listStyleType: "disc" }}>
                  Upload atleast one Image sample for one in all of the products
                  or one image individually.
                </li>
              </small>
            </div>
            {subProducts.map((product, index) => {
              return <>{product}</>;
            })}
            {/* end product variant  */}
            <Form.Item
              name="features"
              label="Enter features[multiple]"
              rules={[
                {
                  message: "Please Enter features!",
                  type: "array",
                },
              ]}
            >
              <Select
                mode="multiple"
                onKeyUp={(e) => {
                  setfeatures((prevArray) => [...prevArray, e.target.value]);
                }}
                placeholder="What makes this account special?"
              >
                {features.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="age"
              label="Product Age"
              rules={[{ required: true, message: "Please input product age!" }]}
            >
              <InputNumber
                min={1}
                max={31}
                addonAfter={suffixSelector}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              name="price"
              label="Price"
              min={500}
              required={true}
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <PriceInput
                currentProduct={currentProduct}
                value={{
                  number: currentProduct.price_amount,
                  currency: currentProduct.price_currency,
                }}
              />
            </Form.Item>

            {/* <Form.Item
              name="productImages"
              label="Upload Images"
              // valuePropName="fileList"
              getValueFromEvent={normFile}
              required={true}
            >
              <Upload
                onRemove={handleImageRemove}
                maxCount={10}
                headers={{
                  Authorization: `Token ${localStorage.getItem("AccgToken")}`,
                }}
                action={BASE_URL + "social/api/UserFiles"}
                name="productImages"
                onPreview={handlePreview}
                fileList={imageList}
                onChange={handleChange}
                listType="picture"
              >
                <Button aria-required={true} icon={<UploadOutlined />}>
                  Click to upload
                </Button>
              </Upload>
            </Form.Item> */}
            <Form.Item label="Product Files">
              <Form.Item
                name="productFiles"
                // valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  fileList={fileList}
                  maxCount={3}
                  onRemove={handleFileRemove}
                  onChange={handleFileOnChange}
                  method="POST"
                  headers={{
                    Authorization: `Token ${localStorage.getItem("AccgToken")}`,
                  }}
                  accept=".doc,.pdf,.csv,.sql,.html,.xls"
                  action={
                    BASE_URL +
                    "social/api/UserFiles?product_id=" +
                    currentProduct.id
                  }
                  name="productFiles"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Upload any important file needed for this product(not
                    compulsory).
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Input Description (no matter how small)",
                },
              ]}
            >
              <Input.TextArea
                maxLength={400}
                placeholder="Input Addition information,  but not confidential data e.g password,phone,emails etc"
                showCount
              />
            </Form.Item>
            <Form.Item
              name="product_groups"
              label="Select Product group"
              initialValue={currentProduct.groups}
              rules={[
                {
                  message: "Product group empty!",
                  type: "array",
                  required: false,
                },
              ]}
            >
              <Select
                mode="multiple"
                onKeyUp={(e) => {
                  setProductGroups((prevArray) => [
                    ...prevArray,
                    e.target.value,
                  ]);
                }}
                placeholder="Group Product"
              >
                {ProductGroups.map((item) => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
                    wrapperCol={{
                        span: 12,
                        offset: 6,
                    }}
                >

                    <Button type="primary" htmlType="submit">
                        Submit Product
                    </Button>
                </Form.Item> */}
          </Form>

          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </Card>
      </Spin>
    );
})
export default ProductEditPage;