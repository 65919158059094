import {
  AccountBalanceWallet,
  Add,
  ArrowBack,
  InfoOutlined,
  ArrowBackIosNewOutlined,
  ArrowForwardIos,
  Cancel,
  ForwardRounded,
  Money,
  Mood,
  MoodBad,
  MoodBadRounded,
  MoodSharp,
  MoodTwoTone,
  MoreHoriz,
  MoreSharp,
  AccountCircleRounded,
  Clear,
  Remove,
  MarkAsUnread,
  Done,
  Pin,
} from '@mui/icons-material'
import {
  Box,
  Card,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Button as MuiButton,
  Modal as MuiModal,
  Backdrop,
  FormControl,
  Input as MuiInput,
  Checkbox,
  FormLabel,
  ClickAwayListener,
} from '@mui/material'
import {
  Button,
  Dropdown,
  Col,
  Menu,
  Avatar,
  Space,
  Tag,
  message,
  Spin,
  Select,
  Steps,
  Modal,
  Form,
  Input,
  Tooltip,
  AutoComplete,
  Alert,
} from 'antd'

import React, { Component, useRef, useState } from 'react'

import walletBg from '../../assets/images/wallet-bg.jpg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import ChangePin from './wallet/change_pin.js'
import CreatePin from './wallet/create_pin.js'
import Transfer from './wallet/transfer.js'
import PaymentApp from './wallet/recharge.js'
import Withdrawal from './wallet/withdraw.js'
import APP_ENV from '../../env'
import { useAuthTabs } from '../middlewares/authTabs.js'
import { SelectCurrentTabName } from '../../store/core/selectors.js'
import { uid } from 'react-uid'

const BASE_URL = APP_ENV.SERVER_ADDR
const reqHeaders = {
  'Content-Type': 'application/json',
}

if (localStorage.getItem('AccgToken') !== null) {
  reqHeaders['Authorization'] = `Token ${localStorage.getItem('AccgToken')}`
}

const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,
})
const authClient = axios.create({
  baseURL: BASE_URL,
})

// const actionParam = URLSearchParams(window.location.search)

const VisitHistory = props => {
  const currentTabName = SelectCurrentTabName()
  const authTabs = useAuthTabs({
    active: 3,
    terminate: currentTabName !== 'main',
  })

  const navigate = useNavigate()
  React.useEffect(() => {
    if (props.clicked == true) {
      if (props.path === '//') {
        return navigate(-1)
      }

      navigate(props.path)
    }
  }, [props.path])
  return <></>
}

export default class UserWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      UserWalletDetails: {},
      activeTransaction: null,
      loading: true,
      itemClicked: false,
      itemPath: '',
      detailElTop: '-2000px',
      defaultCurrency: '',
      platformUsernames: [],
      changePinModal: false,
      createPinModal: false,
      User: {},
      fund_el: React.createRef(null),
      withdrawal_el: React.createRef(null),
    }
  }
  componentDidMount() {
    this.updateAll()

    // if (window.location.hash) {
    //   window.location.href = window.location.pathname;
    // }
    if (localStorage) {
      localStorage.setItem('currentPage', 'wallet')
    }
  }
  updateAll() {
    this.setState({ loading: true })
    const urlParams = new URLSearchParams(window.location.search)

    this.setState({ nextLocation: window.location.hash })

    client
      .get('/user/api/wallet')
      .then(resp => {
        this.setState({
          UserWalletDetails: resp.data,
          User: resp.data.user,
          loading: false,
          defaultCurrency: resp.data.platformDefaultCurrency,
          platformUsernames: resp.data.platform_users_username,
        })

        const transaction = urlParams.get('trx')
        const ref = urlParams.get('flw_ref')
        if (transaction === true || transaction === 'true') {
          const item = resp.data.transactions.filter(item => item.ref === ref)

          if (item.length >= 1) {
            this.setState({ activeTransaction: item[0], detailElTop: 0 })
          }
        }
        this.setState({ loading: false })
      })
      .catch(err => {
        message.error(err.response.data.msg)
        this.setState({ loading: false })
      })
  }
  handleCreatePin() {
    // Modal.confirm({ title: 'hello' })
    this.setState({
      createPinModal: true,
      itemClicked: true,
      itemPath: '#createPin',
      nextLocation: '#createPin',
    })
    if (document.getElementById('createPin')) {
      document.getElementById('createPin').scrollIntoView()
    }
  }
  handleChangePin() {
    // Modal.confirm({ title: 'hello' })
    this.setState({
      changePinModal: true,
      itemClicked: true,
      itemPath: '#changePin',
      nextLocation: '#changePin',
    })
    if (document.getElementById('changePin')) {
      document.getElementById('changePin').scrollIntoView()
    }
  }
  handleWithdraw() {}
  handleTransferMoney() {}
  handleFundAccount() {}
  handleTransactionDetail(item) {
    this.setState({ activeTransaction: item, loading: true })

    setTimeout(() => {
      // console.log(this.state.activeTransaction)

      // const currentTransaction = item
      // this.state.activeTransaction = []

      // if (detail.style.top >= '2000px') {

      //     detail.style.top = 0

      // }
      // else {
      //     detail.style.top = '2000px'
      // }

      if (this.state.detailElTop >= '-2000px') {
        this.setState({ detailElTop: 0 })
      } else {
        this.setState({ detailElTop: '-2000px' })
      }
      this.setState({ loading: false })
    }, 120)
  }
  handleCancelWithdrawal(id) {
    message.info('cancelling withdrawal..')
    this.setState({
      detailElTop: '2000px',
      itemClicked: true,
      itemPath: '/user/wallet',
    })
    client
      .post(`/user/api/withdrawal/cancel/${this.state.activeTransaction.id}`)
      .then(resp => {
        message.success(resp.data.msg)
        this.updateAll()
      })
  }
  handleCancelDeposit(tx_ref) {
    message.info('Cancelling Deposit')
    this.setState({
      detailElTop: '2000px',
      itemClicked: true,
      itemPath: '/user/wallet',
    })
    client
      .post('/user/api/cancel-payment', {
        tx_ref: this.state.activeTransaction.tx_ref,
      })
      .then(resp => {
        if (resp.data.status === 'successful') {
          return message.success('Payment Cancelled')
        } else {
          message.error('Could not delete payment.')
        }
        this.updateAll()
      })
      .catch(err => {
        message.error('smething went wrong')
      })
  }
  render() {
    // const { handleChangePin, handleCreatePin, handleFundAccount, handleTransferMoney, handleWithdraw } = this
    // const { UserWalletDetails, activeTransaction, loading } = this.state
    const DropdownContent = (
      <Menu
        items={[
          {
            key: '1',
            label: (
              <Button
                style={{ border: 'none', width: '100%' }}
                onClick={() => {
                  this.setState({
                    nextLocation: '#fund-wallet',
                    itemClicked: true,
                    itemPath: '#fund-wallet',
                  })
                  setTimeout(() => {
                    this.state.fund_el.current?.scrollIntoView()
                  }, 300)
                }}
              >
                Recharge
              </Button>
            ),
          },
          this.state.UserWalletDetails.has_pin === true
            ? {
                key: '2',
                label: (
                  <Button
                    title="Pin can only be changed once in 30days"
                    disabled={
                      this.state.UserWalletDetails.can_change_pin === true
                        ? false
                        : true
                    }
                    onClick={() => this.handleChangePin()}
                    style={{ border: 'none', width: '100%' }}
                  >
                    Change Transaction Pin
                  </Button>
                ),
              }
            : {
                key: '3',
                label: (
                  <Button
                    style={{ border: 'none', width: '100%' }}
                    onClick={() => this.handleCreatePin()}
                  >
                    Create Transaction Pin
                  </Button>
                ),
              },
          {
            key: '4',
            label: (
              <Button
                style={{ border: 'none', width: '100%' }}
                onClick={() =>
                  this.setState({
                    nextLocation: '#transfer',
                    itemClicked: true,
                    itemPath: '#transfer',
                  })
                }
                title="Transfer to another platform wallet"
              >
                Transfer
              </Button>
            ),
          },
          // {
          //   key: '5',
          //   label: (
          //     <Button
          //       onClick={() => {
          //         this.setState({
          //           itemClicked: true,
          //           nextLocation: '#withdrawal',
          //           itemPath: '/user/wallet#withdrawal',
          //         })
          //         setTimeout(() => {
          //           this.state.withdrawal_el.current?.scrollIntoView()
          //         }, 300)
          //       }}
          //       style={{ border: 'none', width: '100%' }}
          //     >
          //       Withdraw
          //     </Button>
          //   ),
          // },
        ]}
      />
    )
    const LargeDropdownContent = [
      <Button
        style={{ border: '2px solid grreen' }}
        onClick={() =>
          this.setState({
            nextLocation: '#fund-wallet',
            itemClicked: true,
            itemPath: '#fund-wallet',
          })
        }
      >
        Recharge
      </Button>,
      this.state.UserWalletDetails.has_pin === true ? (
        <Button
          style={{ border: '2px solid grreen' }}
          title="Pin can only be changed once in 30days"
          disabled={
            this.state.UserWalletDetails.can_change_pin === true ? false : true
          }
          onClick={() => this.handleChangePin()}
        >
          Change Transaction Pin
        </Button>
      ) : (
        <Button
          style={{ border: '2px solid grreen' }}
          onClick={() => this.handleCreatePin()}
        >
          Create Transaction Pin
        </Button>
      ),

      <Button
        style={{ border: '2px solid grreen' }}
        title="Transfer to another platform wallet"
        onClick={() =>
          this.setState({
            nextLocation: '#transfer',
            itemClicked: true,
            itemPath: '#transfer',
          })
        }
      >
        Transfer
      </Button>,

      // <Button
      //   style={{
      //     display: 'inline-block',
      //     float: 'right',
      //     border: '2px solid grreen',
      //   }}
      //   onClick={() => {
      //     this.setState({
      //       itemClicked: true,
      //       nextLocation: '#withdrawal',
      //       itemPath: '/user/wallet#withdrawal',
      //     })
      //   }}
      // >
      //   Withdraw
      // </Button>,
    ]
    const TransactionDetail = props => {
      return (
        <div
          id="detailContainer"
          style={{
            position: 'fixed',
            zIndex: 100,
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
            width: '100%',
            bottom: this.state.detailElTop,
            top: this.state.detailElTop,
            transition: 'linear 0.5s',
            backgroundColor: '#407062',
          }}
        >
          <Box sx={{ paddingBottom: { xs: '100px' } }}>
            <Box
              style={{
                backgroundImage: `url(${walletBg})`,
                backgroundRepeat: 'no-repeat',
                overflow: 'none',
                zIndex: '100px',
                backgroundBlendMode: 'hue',
                padding: '30px',
                height: '200px',
                display: 'block',
              }}
            >
              <Box style={{ margin: '0 auto' }}>
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    color: 'white',
                    position: 'absolute',
                  }}
                >
                  <IconButton
                    onClick={() =>
                      this.setState({
                        detailElTop: '2000px',
                        itemClicked: true,
                        itemPath: '/user/wallet',
                      })
                    }
                  >
                    <ArrowBackIosNewOutlined style={{ color: 'white' }} />
                  </IconButton>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <h1
                    style={{
                      color: 'whitesmoke',
                      fontFamily: 'sans',
                      marginLeft: '40px',
                    }}
                  >
                    Transaction Detail
                  </h1>
                </div>
              </Box>
            </Box>
            <Container sx={{ width: { md: '50%', xs: '100%' } }}>
              {this.state.activeTransaction !== null ? (
                <List>
                  {(this.state.activeTransaction.type === 'Withdrawal' ||
                    this.state.activeTransaction.type === 'Deposit') &&
                  this.state.activeTransaction.status == 'pending' ? (
                    <Button
                      onClick={() =>
                        this.state.activeTransaction.type === 'Withdrawal'
                          ? this.handleCancelWithdrawal(
                              this.state.activeTransaction.id,
                            )
                          : this.handleCancelDeposit(
                              this.state.activeTransaction.tx_ref,
                            )
                      }
                      type="dashed"
                      style={{
                        backgroundColor: '#b44b4b',
                        margin: '2px',
                        color: 'whitesmoke',
                        fontSize: '10px',
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    ''
                  )}

                  <ListItem divider={true}>
                    {/* {console.log(activeTransaction !== null ? activeTransaction : '')} */}
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: 'serif' }}
                      primary="Transaction Id"
                      secondaryTypographyProps={{
                        textAlign: 'right',
                        fontSize: '15px',
                        fontFamily: 'verdana',
                      }}
                      secondary={this.state.activeTransaction.id}
                    ></ListItemText>
                  </ListItem>
                  <ListItem divider={true}>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: 'serif' }}
                      primary="Type"
                      secondaryTypographyProps={{
                        textAlign: 'right',
                        fontSize: '15px',
                      }}
                      secondary={this.state.activeTransaction.type}
                    ></ListItemText>
                  </ListItem>
                  <ListItem divider={true}>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: 'serif' }}
                      primary="Date"
                      secondaryTypographyProps={{
                        textAlign: 'right',
                        fontSize: '15px',
                        fontFamily: 'verdana',
                      }}
                      secondary={this.state.activeTransaction.date}
                    ></ListItemText>
                  </ListItem>
                  <ListItem divider={true}>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: 'serif' }}
                      primary="Status"
                      secondaryTypographyProps={{
                        textAlign: 'right',
                        fontFamily: 'Ariel',
                        textTransform: 'uppercase',
                        color:
                          this.state.activeTransaction.status === 'successful'
                            ? 'green'
                            : this.state.activeTransaction.status === 'pending'
                            ? 'grey'
                            : 'red',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                      secondary={this.state.activeTransaction.status}
                    ></ListItemText>
                  </ListItem>
                  <ListItem divider={true}>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: 'serif' }}
                      primary="Amount"
                      secondaryTypographyProps={{
                        textAlign: 'right',
                        fontFamily: 'Ariel',
                        fontSize: '15px',
                        fontWeight: 'bold',
                      }}
                      secondary={this.state.activeTransaction.amount}
                    ></ListItemText>
                  </ListItem>
                  {this.state.activeTransaction.receipient ? (
                    <ListItem divider={true}>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: 'serif' }}
                        primary="Receipient"
                        secondaryTypographyProps={{
                          textAlign: 'right',
                          fontFamily: 'Ariel',
                          fontSize: '15px',
                        }}
                        secondary={this.state.activeTransaction.receipient}
                      ></ListItemText>
                    </ListItem>
                  ) : (
                    ''
                  )}

                  {this.state.activeTransaction.sender ? (
                    <ListItem divider={true}>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: 'serif' }}
                        primary="Sender"
                        secondaryTypographyProps={{
                          textAlign: 'right',
                          fontSize: '15px',
                          fontFamily: 'Ariel',
                        }}
                        secondary={this.state.activeTransaction.sender}
                      ></ListItemText>
                    </ListItem>
                  ) : (
                    ''
                  )}

                  {this.state.activeTransaction.decription !== '' ? (
                    <ListItem divider={true}>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: 'serif' }}
                        primary="Description"
                        secondaryTypographyProps={{
                          textAlign: 'right',
                          fontSize: '13px',
                          fontFamily: 'Ariel',
                        }}
                        secondary={this.state.activeTransaction.description}
                      ></ListItemText>
                    </ListItem>
                  ) : (
                    ''
                  )}
                  <ListItem divider={true}>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: 'serif' }}
                      primary="Ref"
                      secondaryTypographyProps={{
                        textAlign: 'right',
                        fontSize: '15px',
                        fontFamily: 'Ariel',
                      }}
                      secondary={this.state.activeTransaction.ref}
                    ></ListItemText>
                  </ListItem>
                </List>
              ) : (
                'No Data'
              )}
            </Container>
          </Box>
        </div>
      )
    }

    const Wheader = props => (
      <>
        <Spin tip="fetching data.." spinning={this.state.loading}>
          <TransactionDetail />
          <VisitHistory
            path={this.state.itemPath}
            clicked={this.state.itemClicked}
          ></VisitHistory>
          <div
            id="mainContainer"
            style={{
              transition: 'linear 0.5s',
              zIndex: '5',
              overflow: 'hidden',
            }}
          >
            {/* <Spin spinning={loading}> */}
            <Box
              style={{
                backgroundImage: `url(${walletBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: '-600px',
                backgroundBlendMode: 'hue',
                padding: '30px',
                height: '300px',
                display: 'block',
              }}
            >
              <Box style={{ margin: '0 auto' }}>
                <div
                  style={{
                    display: 'inline-block',
                    float: 'left',
                    color: 'white',
                  }}
                >
                  <IconButton
                    onClick={() =>
                      this.setState({ itemClicked: true, itemPath: '//' })
                    }
                  >
                    <ArrowBackIosNewOutlined style={{ color: 'white' }} />
                  </IconButton>
                </div>
                <div style={{ display: 'inline-block', float: 'right' }}>
                  <h3
                    style={{
                      fontSize: '50px',
                      fontFamily: 'cursive',
                      color: 'whitesmoke',
                      userSelect: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Wallet
                  </h3>
                </div>
              </Box>
            </Box>
          </div>
          {props.hideBtns === true ? (
            <>
              <Box style={{ marginTop: '-50px', width: '100%' }}>
                <Card
                  style={{
                    width: '100%',
                    borderTopLeftRadius: '30px',
                    fontFamily: 'sans',
                    borderBottom: '0',
                    borderTopRightRadius: '30px',
                  }}
                >
                  <Container
                    style={{
                      margin: '0 auto',
                      textAlign: 'center',
                      padding: '50px',
                    }}
                  >
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                      <p
                        style={{
                          textTransform: 'uppercase',
                          position: 'absolute',
                          left: '30px',
                          fontSize: '10px',
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Your Account <br />
                        <Tag
                          style={{
                            border: 'none',
                            color: 'white',
                            backgroundColor: '#1ab34dfa',
                            backgroundBlendMode: 'hard-light',
                          }}
                        >
                          {this.state.UserWalletDetails.user_account_status}
                        </Tag>
                      </p>
                      <Dropdown overlay={DropdownContent}>
                        <IconButton
                          style={{ position: 'absolute', right: '30px' }}
                        >
                          <MoreHoriz />
                        </IconButton>
                      </Dropdown>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                      <p
                        style={{
                          textTransform: 'uppercase',
                          position: 'absolute',
                          right: '30px',
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Your Account <br />
                        <Tag
                          style={{
                            border: 'none',
                            color: 'white',
                            backgroundColor: '#1ab34dfa',
                            backgroundBlendMode: 'hard-light',
                          }}
                        >
                          Active
                        </Tag>
                      </p>
                      <Button.Group
                        style={{
                          top: 200,
                          right: '30px',
                          position: 'absolute',
                        }}
                      >
                        {LargeDropdownContent}
                      </Button.Group>
                    </Box>
                    {props.child}
                  </Container>
                </Card>
              </Box>
            </>
          ) : (
            <Box style={{ marginTop: '-50px', width: '100%' }}>
              <Card
                style={{
                  width: '100%',
                  borderTopLeftRadius: '30px',
                  fontFamily: 'sans',
                  borderBottom: '0',
                  borderTopRightRadius: '30px',
                }}
              >
                <Container
                  style={{
                    margin: '0 auto',
                    textAlign: 'center',
                    padding: '50px',
                  }}
                >
                  <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        position: 'absolute',
                        left: '30px',
                        fontSize: '10px',
                        textAlign: 'left',
                      }}
                    >
                      {' '}
                      Your Account <br />
                      <Tag
                        style={{
                          border: 'none',
                          color: 'white',
                          backgroundColor: '#1ab34dfa',
                          backgroundBlendMode: 'hard-light',
                        }}
                      >
                        {this.state.UserWalletDetails.user_account_status}
                      </Tag>
                    </p>
                    <Dropdown overlay={DropdownContent}>
                      <IconButton
                        style={{ position: 'absolute', right: '30px' }}
                      >
                        <MoreHoriz />
                      </IconButton>
                    </Dropdown>
                  </Box>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <p
                      style={{
                        textTransform: 'uppercase',
                        position: 'absolute',
                        right: '30px',
                        textAlign: 'left',
                      }}
                    >
                      {' '}
                      Your Account <br />
                      <Tag
                        style={{
                          border: 'none',
                          color: 'white',
                          backgroundColor: '#1ab34dfa',
                          backgroundBlendMode: 'hard-light',
                        }}
                      >
                        Active
                      </Tag>
                    </p>
                    <Button.Group
                      style={{ top: 200, right: '30px', position: 'absolute' }}
                    >
                      {LargeDropdownContent}
                    </Button.Group>
                  </Box>
                  <h2 style={{ fontSize: '25px', fontFamily: 'Ariel' }}>
                    Balance
                  </h2>
                  <h1 style={{ fontSize: '40px', fontFamily: 'Ariel' }}>
                    {' '}
                    {this.state.UserWalletDetails.user_balance}
                  </h1>
                  <div
                    style={{
                      marginRight: '10px',
                      display: 'inline-block',
                      textAlign: 'center',
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({
                          nextLocation: '#fund-wallet',
                          itemClicked: true,
                          itemPath: '#fund-wallet',
                        })
                        setTimeout(() => {
                          this.state.fund_el.current?.scrollIntoView()
                        }, 300)
                      }}
                      style={{ padding: '10px' }}
                    >
                      <Add style={{ color: 'inherit' }}></Add>
                    </IconButton>{' '}
                    <br></br>
                    Fund account
                  </div>
                  {/* <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline-block',
                      textAlign: 'center',
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({
                          itemClicked: true,
                          nextLocation: '#withdrawal',
                          itemPath: '/user/wallet#withdrawal',
                        })
                        setTimeout(() => {
                          this.state.withdrawal_el.current?.scrollIntoView()
                        }, 300)
                      }}
                      style={{ padding: '10px' }}
                    >
                      <AccountBalanceWallet style={{ color: 'inherit' }} />
                    </IconButton>
                    <br></br>
                    Withdraw
                  </div> */}
                </Container>

                {props.child}
              </Card>
            </Box>
          )}
        </Spin>
      </>
    )
    const home = (
      <div>
        <Spin spinning={this.state.loading}>
          <Box>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                  paddingTop: { xs: '20px' },
                },
              }}
            >
              <Container>
                <Col span={24} md={8} className="mb-24">
                  <Card
                    bordered={false}
                    bodyStyle={{ paddingTop: 0 }}
                    className="header-solid h-full  ant-list-yes"
                    title={
                      <h6 className="font-semibold m-0">Your Transactions</h6>
                    }
                    extra={
                      <p className="card-header-date">
                        {/* {calender} */}
                        <span>{/* 23 - 30 March 2021 */}</span>
                      </p>
                    }
                  >
                    <Box
                      style={{
                        height: '300px',
                        overflowY: 'auto',
                        scrollbarWidth: 'none',
                        scrollbarColor: 'green',
                      }}
                    >
                      {this.state.UserWalletDetails.transactions ? (
                        this.state.UserWalletDetails.transactions.map(item => {
                          return (
                            <div key={uid(item)}>
                              <Card
                                onClick={() =>
                                  this.handleTransactionDetail(item)
                                }
                                style={{
                                  margin: '20px',
                                  cursor: 'pointer',
                                  // backgroundColor: "#fcfefc",
                                  padding: '10px',
                                  paddingTop: '0',
                                }}
                              >
                                {item.status === 'pending' ? (
                                  <div
                                    style={{
                                      display: 'inline-block',
                                    }}
                                  >
                                    <Avatar size={50} shape="square">
                                      {' '}
                                      <MoodTwoTone
                                        style={{
                                          color: 'whitesmoke',
                                        }}
                                      />{' '}
                                    </Avatar>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {item.status === 'successful' ? (
                                  <div
                                    style={{
                                      display: 'inline-block',
                                    }}
                                  >
                                    <Avatar size={50} shape="square">
                                      {' '}
                                      <Mood
                                        style={{
                                          color: '#1ab34dfa',
                                        }}
                                      />{' '}
                                    </Avatar>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {item.status === 'failed' ||
                                item.status === 'cancelled' ? (
                                  <div
                                    style={{
                                      display: 'inline-block',
                                    }}
                                  >
                                    <Avatar size={50} shape="square">
                                      {' '}
                                      <MoodBad style={{ color: 'red' }} />{' '}
                                    </Avatar>
                                  </div>
                                ) : (
                                  ''
                                )}

                                <div
                                  style={{
                                    display: 'inline-block',
                                    marginTop: '0',
                                    marginLeft: '10px',
                                  }}
                                >
                                  {item.type === 'Purchase' ? (
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      You Made a Purchase{' '}
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                  {item.type === 'Deposit' ? (
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Deposit Transaction
                                    </p>
                                  ) : (
                                    ''
                                  )}

                                  {item.type === 'Withdrawal' ? (
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      You Withdrew{' '}
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                  {item.type === 'Transfer' ? (
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {this.state.User.username ==
                                      item.receipient
                                        ? 'You Received ' +
                                          item.amount +
                                          ' from ' +
                                          item.sender
                                        : 'You Transfered' +
                                          item.amount +
                                          ' to ' +
                                          item.receipient}
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                  {item.type === 'Referral' ? (
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Referral Bonus{' '}
                                    </p>
                                  ) : (
                                    ''
                                  )}

                                  <p> {item.date}</p>
                                </div>

                                <div
                                  style={{
                                    display: 'inline-block',
                                    marginTop: '0',
                                    marginLeft: '10px',
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      color:
                                        item.status === 'successful'
                                          ? '#1ab34dfa'
                                          : item.status === 'failed' ||
                                            item.status === 'cancelled'
                                          ? 'red'
                                          : 'graytext',
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {item.status}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    float: 'right',
                                    marginTop: '20px',
                                    marginLeft: '10px',
                                  }}
                                >
                                  {item.type === 'Deposit' ||
                                  item.type === 'Referral' ? (
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        display: 'inline-block',
                                      }}
                                    >
                                      + {item.amount} <Space> </Space>{' '}
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        display: 'inline-block',
                                      }}
                                    >
                                      - {item.amount} <Space> </Space>{' '}
                                    </p>
                                  )}
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      display: 'inline-block',
                                    }}
                                  >
                                    {' '}
                                    <ArrowForwardIos
                                      style={{
                                        fontSize: '15px',
                                        marginTop: '3px',
                                      }}
                                    />{' '}
                                  </p>
                                </div>
                              </Card>
                            </div>
                          )
                        })
                      ) : (
                        <Box
                          style={{
                            textAlign: 'center',
                            padding: '100px',
                            opacity: '0.4',
                          }}
                        >
                          <AccountBalanceWallet
                            style={{
                              color: 'green',
                              opacity: '0.4',
                              fontSize: '150px',
                            }}
                          />
                          <h3>Nothing going on.</h3>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Col>
              </Container>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Container style={{ width: '50%', margin: '0' }}>
                <h3
                  style={{
                    fontSize: '17px',
                    paddingLeft: '20px',
                    color: 'graytext',
                  }}
                >
                  Your transactions
                </h3>
                <Box>
                  {this.state.UserWalletDetails.transactions ? (
                    this.state.UserWalletDetails.transactions.map(item => {
                      return (
                        <>
                          <Card
                            onClick={() => this.handleTransactionDetail(item)}
                            style={{
                              margin: '20px',
                              cursor: 'pointer',
                              // backgroundColor: "#fcfefc",
                              padding: '10px',
                              paddingTop: '0',
                            }}
                          >
                            {item.status === 'pending' ||
                            item.status === 'cancelled' ? (
                              <div style={{ display: 'inline-block' }}>
                                <Avatar size={50} shape="square">
                                  {' '}
                                  <MoodTwoTone
                                    style={{ color: 'whitesmoke' }}
                                  />{' '}
                                </Avatar>
                              </div>
                            ) : (
                              ''
                            )}
                            {item.status === 'successful' ? (
                              <div style={{ display: 'inline-block' }}>
                                <Avatar size={50} shape="square">
                                  {' '}
                                  <Mood style={{ color: '#1ab34dfa' }} />{' '}
                                </Avatar>
                              </div>
                            ) : (
                              ''
                            )}
                            {item.status === 'failed' ? (
                              <div style={{ display: 'inline-block' }}>
                                <Avatar size={50} shape="square">
                                  {' '}
                                  <MoodBad style={{ color: 'red' }} />{' '}
                                </Avatar>
                              </div>
                            ) : (
                              ''
                            )}

                            <div
                              style={{
                                display: 'inline-block',
                                marginTop: '0',
                                marginLeft: '10px',
                              }}
                            >
                              {item.type === 'Purchase' ? (
                                <p style={{ padding: 0, margin: 0 }}>
                                  You Made a Purchase{' '}
                                </p>
                              ) : (
                                ''
                              )}
                              {item.type === 'Deposit' ? (
                                <p style={{ padding: 0, margin: 0 }}>
                                  Deposit Transaction
                                </p>
                              ) : (
                                ''
                              )}

                              {item.type === 'Withdrawal' ? (
                                <p style={{ padding: 0, margin: 0 }}>
                                  You Withdrawed{' '}
                                </p>
                              ) : (
                                ''
                              )}
                              {item.type === 'Transfer' ? (
                                <p style={{ padding: 0, margin: 0 }}>
                                  {' '}
                                  {this.state.User.username == item.receipient
                                    ? 'You Received ' +
                                      item.amount +
                                      ' from ' +
                                      item.sender
                                    : 'You Transfered ' +
                                      item.amount +
                                      ' to ' +
                                      item.receipient}
                                </p>
                              ) : (
                                ''
                              )}
                              {item.type === 'Referral' ? (
                                <p style={{ padding: 0, margin: 0 }}>
                                  Referral Bonus{' '}
                                </p>
                              ) : (
                                ''
                              )}

                              <p> {item.date}</p>
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                marginTop: '0',
                                marginLeft: '10px',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  color:
                                    item.status === 'successful'
                                      ? '#1ab34dfa'
                                      : item.status === 'failed' ||
                                        item.status === 'cancelled'
                                      ? 'red'
                                      : 'graytext',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {item.status}
                              </p>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                float: 'right',
                                marginTop: '20px',
                                marginLeft: '10px',
                              }}
                            >
                              {item.type === 'Deposit' ||
                              item.type === 'Referral' ? (
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    display: 'inline-block',
                                  }}
                                >
                                  + {item.amount} <Space> </Space>{' '}
                                </p>
                              ) : (
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    display: 'inline-block',
                                  }}
                                >
                                  - {item.amount} <Space> </Space>{' '}
                                </p>
                              )}
                              <p
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  display: 'inline-block',
                                }}
                              >
                                {' '}
                                <ArrowForwardIos
                                  style={{
                                    fontSize: '15px',
                                    marginTop: '3px',
                                  }}
                                />{' '}
                              </p>
                            </div>
                          </Card>
                        </>
                      )
                    })
                  ) : (
                    <Box
                      style={{
                        textAlign: 'center',
                        padding: '100px',
                        opacity: '0.4',
                      }}
                    >
                      <AccountBalanceWallet
                        style={{
                          color: 'green',
                          opacity: '0.4',
                          fontSize: '150px',
                        }}
                      />
                      <h3>Nothing going on.</h3>
                    </Box>
                  )}
                </Box>
              </Container>
            </Box>
          </Box>
        </Spin>
      </div>
    )

    switch (this.state.nextLocation) {
      // case '#withdrawal':
      //   return (
      //     <>
      //       <div id="withdrawal">
      //         <Wheader
      //           hideBtns={true}
      //           child={
      //             <Card
      //               style={{
      //                 width: '100%',
      //                 marginTop: '50px',
      //                 height: '100%',
      //                 fontFamily: 'sans',
      //               }}
      //             >
      //               <Box
      //                 ref={this.state.withdrawal_el}
      //                 sx={{
      //                   margin: '0 auto',
      //                   width: { xs: '100%', md: '80%' },
      //                 }}
      //               >
      //                 <Withdrawal
      //                   walletDetails={this.state.UserWalletDetails}
      //                   updateParent={() => this.updateAll()}
      //                   currency={this.state.defaultCurrency}
      //                   user={this.state.UserWalletDetails.user}
      //                 />
      //               </Box>
      //             </Card>
      //           }
      //         />
      //       </div>
      //     </>
      //   )
      case '#changePin':
        return (
          <>
            <div id="changePin">
              <Wheader child={
                                    <ChangePin updateParent={() => this.updateAll()} />

              } />
              {/* <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 1,
                }}
              open={true}
                style={{ zIndex: '20' }}
                transitionDuration={1}
              >
                <ClickAwayListener
                  onClickAway={() => {
                    this.setState({
                      itemClicked: true,
                      itemPath: '/user/wallet',
                      changePinModal: false,
                    })
                  }}
                >
                  <MuiModal
                    sx={{ marginTop: { md: '240px', xs: '330px' } }}
                     open={true}
                  >
                  </MuiModal>
                </ClickAwayListener>
              </Backdrop> */}
            </div>
          </>
        )
      case '#createPin':
        return (
          <>
            <div id="createPin">
              <Wheader child={
                <CreatePin updateParent={() => this.updateAll()} />


              } />
              {/* <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 1,
                }}
                open={this.state.createPinModal}
                style={{ zIndex: '20' }}
                transitionDuration={1}
              >
                <MuiModal */}
                  {/* children={
                  sx={{ marginTop: { md: '240px', xs: '150px' } }}
                  onBackdropClick={() => {
                    this.setState({
                      itemClicked: true,
                      itemPath: '/user/wallet',
                      createPinModal: false,
                    })
                  }}
                  open={this.state.createPinModal} */}
                {/* ></MuiModal>
              </Backdrop> */}
            </div>
          </>
        )
      case '#transfer':
        return (
          <>
            <div id="transfer">
              <Wheader child={home} />
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: theme => theme.zIndex.drawer + 1,
                }}
                open={true}
                style={{ zIndex: '20' }}
              >
                <Transfer
                  user={this.state.User}
                  currency={this.state.defaultCurrency}
                  updateParent={() => this.updateAll()}
                  users={this.state.platformUsernames}
                />
              </Backdrop>
            </div>
          </>
        )
      case '#fund-wallet':
        return (
          <>
            <div id="fund-wallet">
              <Wheader
                child={
                  <Card
                    ref={this.state.fund_el}
                    style={{
                      width: '100%',
                      marginTop: '30px',
                      height: '100%',
                      borderTopLeftRadius: '30px',
                      fontFamily: 'sans',
                      borderTopRightRadius: '30px',
                    }}
                  >
                    <Container
                      id="btnContainer"
                      style={{ margin: '0 auto', textAlign: 'center' }}
                    >
                      <Container
                        sx={{ width: { md: '50%', xs: '100%' } , padding:{xs:'20px', md:'80px'}}}
                        style={{
                          objectFit: 'contain',
                          borderRadius: '20px',
                          textAlign: 'center',
                          boxShadow: '0.5px 1px 4px  0.2px ',
                        }}
                      >
                        <h1>Fund Wallet</h1>
                        <Divider />
                        <Box
                          sx={{
                            margin: '0 auto',
                            marginTop: '30px',
                            width: { xs: '100%', md: '100%' },
                          }}
                        >
                          <PaymentApp
                            trxType="credit"
                            paymentType="Fund Wallet"
                            user={this.state.UserWalletDetails.user}
                          />
                        </Box>
                      </Container>
                    </Container>
                  </Card>
                }
              />
            </div>
          </>
        )
      default:
        return (
          <>
            <Wheader child={home} />
          </>
        )
    }
  }
}
