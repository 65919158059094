import { Box } from "@mui/system";
import Sider from "antd/lib/layout/Sider";
import {
  ButtonGroup,
  Container,
  IconButton,
  Tab,
  Tabs,
  Modal,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";

import {
  Layout,
  Menu,
  Skeleton,
  List,
  Divider,
  Avatar,
  message,
  Input,
  Spin,
  Badge,
  Image,
  Tag,
  AutoComplete,
  Tooltip,
  Space,
  Dropdown,
  notification,
  Upload,
  Empty,
} from "antd";

import { Header, Footer, Content } from "antd/lib/layout/layout";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AddCircleSharp,
  AllInboxOutlined,
  ArrowBackOutlined,
  ChatBubble,
  ChatBubbleOutline,
  Clear,
  ClearOutlined,
  Email,
  FacebookOutlined,
  FaceRounded,
  GroupOutlined,
  Instagram,
  LinkOutlined,
  MoreVertOutlined,
  MyLocationOutlined,
  Person,
  PersonOutlined,
  PersonSearch,
  Phone,
  PhoneOutlined,
  Remove,
  SearchOutlined,
  SupportRounded,
  Twitter,
} from "@mui/icons-material";
import ChatList from "./ChatList";
import useWebSocket, { ReadyState } from "react-use-websocket/";
import { BASE_SOCKET_URL, BASE_URL, client } from "../../app_context";
import ChatContent from "./ChatContent";
import GroupChat from "./GroupChat";
import TicketChat from "./TicketChat";
import PrivateChat from "./PrivateChat";
import abstractBg from "../../../assets/images/chat-bg.jpg";
import Title from "antd/lib/skeleton/Title";
import Search from "antd/lib/input/Search";
import shadows from "@mui/material/styles/shadows";
import FileWidget from "./fileWidget";
import { Link } from "react-router-dom";
import GroupChatMessage from "./GroupChatMessage";
import PrivateChatMessage from "./PrivateChatMessage";
import TicketChatMessage from "./TicketChatMessage";

import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: "50%" },
  bgcolor: "rgb(37, 44, 48)",
  cursor: "pointer",
  // border: "2px solid #000",
  color: "whitesmoke",
  boxShadow: 24,
  p: 4,
};

function AddChatModal({
  addChatModal,
  setAddChatModal,
  handleCloseAddModal,
  autoCompleteOptions,
  getChatFunc,
}) {
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "rgb(37, 44, 48)",
    cursor: "pointer",
    flexDirection: "row-reverse",
    margin: "10px",
    color: "whitesmoke",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  function ChatOptions() {
    const [expanded, setExpanded] = React.useState("panel1");
    const [subAutOp, setSubAutOp] = useState(autoCompleteOptions);

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    const setsubAutOpFunc = (val) => {
      const items_match = subAutOp.filter((item) => item.value.includes(val));
      setSubAutOp(items_match);
      if (items_match.length <= 0) {
        setSubAutOp(autoCompleteOptions);
      }
    };
    return (
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Private Chat</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Box>
                <h2>Select User</h2>
                <Input
                  type="search"
                  placeholder="Search Users.."
                  pattern="none"
                  allowClear
                  onChange={(e) => setsubAutOpFunc(e.target.value)}
                  suffix={<SearchOutlined style={{ color: "whitesmoke" }} />}
                  formAction={false}
                  bordered={true}
                />
                <List
                  style={{
                    height: "30vh",
                    overflowX: "scroll",
                    scrollbarWidth: "thin",
                  }}
                  dataSource={subAutOp}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        padding: "10px",

                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleCloseAddModal();
                        getChatFunc({ username: item.value });
                      }}
                      // title={item.value}
                    >
                      <List.Item.Meta
                        title={item.value}
                        avatar={
                          <Avatar src={item.dp}>
                            {" "}
                            {String(item.value)[0]}
                          </Avatar>
                        }
                      ></List.Item.Meta>
                    </List.Item>
                  )}
                ></List>
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Group Chat</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Ticket</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            
              <FormControl>
                <InputLabel htmlFor="my-input">Name</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">
                  Example is what the issue/problem could be...
                </FormHelperText>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="my-input">Description</InputLabel>
                <Input.TextArea style={{width:'400px', height:'200px', }} typeof="text" id="my-input" aria-describedby="my-helper-text" />
                <FormHelperText id="my-helper-text">
                  Example is the issue/problem detail...
                </FormHelperText>
                
                <Upload>Upload Image</Upload>
              </FormControl>
            </Typography>
          </AccordionDetails>
        </Accordion> */}
      </div>
    );
  }
  return (
    <div>
      <Modal
        aria-labelledby="spring-add-modal-title"
        aria-describedby="spring-add-modal-description"
        open={addChatModal}
        onClose={handleCloseAddModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={addChatModal}>
          <Box sx={modalStyle}>
            <Typography
              id="spring-modal-title"
              variant="h6"
              style={{ color: "whitesmoke" }}
              component="h2"
            >
              New Chat
            </Typography>
            <Typography
              id="spring-modal-description"
              variant="container"
              style={{
                color: "whitesmoke",
              }}
              component="div"
            >
              <ChatOptions />
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
function ChatApp() {
  const [value, setValue] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const { Option } = AutoComplete;
  const [autoComOptions, setautoCoOptions] = useState([]);

  const [chats, setChats] = useState([
    // {
    //   name: "Kanemanuel",
    //   members: { username: "kanemanuel", avatar: abstractBg },
    //   id: 4,
    //   dateCreated: "2 days ago",
    //   latest_message: { body: "Hi" },
    // },
    // {
    //   name: "holykane",
    //   members: { username: "holykane", avatar: abstractBg },
    //   id: 5,
    //   dateCreated: "1 days ago",
    //   latest_message: {
    //     body: "omo how far  i just lorem and i know that you are not lorem and lorem is not lorem  text to text how everything text and how i go run am",
    //   },
    // },
  ]);
  const [sendingActive, setSendingActive] = useState(false);

  const [originalChatList, setOriginalChatList] = useState([]);
  const [socketURL, setSocketURL] = useState(
    BASE_SOCKET_URL + "/ws/api/user-chats/"
  );
  const [createSocketURl, setCreateSocketURL] = useState(
    BASE_SOCKET_URL + `/ws/api/chats/create/`
  );
  const [userToken, setUserToken] = useState(localStorage.getItem("AccgToken"));
  const [loadingChats, setloadingChats] = useState(true);
  const [loadingChat, setloadingChat] = useState(true);
  const [selectedChat, setselectedChat] = useState({});
  const [newMessage, setNewMessage] = useState(null);
  const [User, setUser] = useState({});
  const [searchChatText, setSearchChatText] = useState("");
  const [recentMessage, setRecentMessage] = useState(
    localStorage.getItem("recentMessage")
  );
  const [searchValue, setSearchValue] = useState("");
  const [addChatModal, setAddChatModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(null);
  const [selectedUSer, setSelectedUSer] = useState(null);
  const [sBG, setSBG] = useState("warning");
  const [plUsers, setPlUsers] = useState([]);
  const [selectedMList, setSelectedMList] = useState([]);
  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(
    socketURL,
    { queryParams: { token: userToken } }
  );
  const createChatSocket = useWebSocket(createSocketURl, {
    queryParams: { token: userToken },
    shouldReconnect: (closeEvent) => {
      return true;
    },
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });
  const [loadedChatDetail, setloadedChatDetail] = useState(null);
  const [loadingSide, setLoadingSide] = useState(true);

  const [displayRightScreen, setdisplayRightScreen] = useState(false);
  const [displayMiddleScreen, setdisplayMiddleScreen] = useState(false);
  const [displayLeftScreen, setdisplayLeftScreen] = useState(true);

  const goToMobileHome = () => {
    setdisplayLeftScreen(true);
    setdisplayMiddleScreen(false);
    setdisplayRightScreen(false);
  };
  const goToMobileMessage = () => {
    setdisplayLeftScreen(false);
    setdisplayMiddleScreen(true);
    setdisplayRightScreen(false);
  };
  const goToMobileChatDetails = () => {
    setdisplayLeftScreen(false);
    setdisplayMiddleScreen(false);
    setdisplayRightScreen(true);
  };

  const getUser = useCallback(async function () {
    try {
      const response = await client.get("/auth/users/me/");
      const data = response.data;
      // console.log(data)
      setUser(data);
    } catch (error) {
      setUser({});
      message.error("Unable to load user, please login ");
    }
    client.get("/user/api/platform-users/").then((resp) => {
      setautoCoOptions(resp.data);
      setPlUsers(resp.data);
    });
  }, []);

  React.useEffect(() => {
    // notification.open({ message: "welcome to chats", icon: <FaceRounded /> });
    getUser();
    if (!sendingActive) {
      getChats();
    }
    if(localStorage){

      localStorage.setItem("currentPage", "chat")
    }
  }, [lastJsonMessage, sendingActive, createChatSocket.lastJsonMessage]);

  const getChats = useCallback(() => {
    setSocketURL(BASE_SOCKET_URL + "/ws/api/user-chats/");
    // readyState.

    if (lastJsonMessage) {
      if (lastJsonMessage instanceof Array) {
        setChats(lastJsonMessage);
      } else {
        switch (lastJsonMessage.type) {
          case "Chat_Notification":
            notification.info({ message: lastJsonMessage.msg });
            break;
          default:
            return message.info("New Message");
        }
      }
      // if (recentMessage !== null) {
      //   getChat({ chat_id: recentMessage.chat.id });
      // } else {
      //   if (chats.length >= 1) {
      //     getChat({ chat_id: chats[0].id });
      //   }
      // }
    }
  }, [lastJsonMessage]);

  const handleUserSelect = useCallback(
    ({ username = null, chat = null }) => {
      setLoadingSide(true);
      setSBG("success");
      if (username) {
        client
          .get(`/chat/api/${username}/user-details`)
          .then((resp) => {
            setloadedChatDetail(resp.data);
            setLoadingSide(false);
          })
          .catch((err) => {
            message.error("Unable to fetch chat user");
            setLoadingSide(false);
          });
      } else if (chat) {
        client
          .get(`/chat/api/${chat.uid}/attachments`)
          .then((resp) => {
            const updatedChat = chat;
            updatedChat["attachments"] = resp.data;
            setloadedChatDetail(updatedChat);
            setLoadingSide(false);
          })
          .catch((err) => {
            message.error("Error getting chat details ");
            setLoadingSide(false);
          });
      } else {
        notification.error({ message: "Invalid User" });
      }
    },
    [selectedUSer]
  );

  const getChat = ({ username = null, chat = null }) => {
    if (chat) {
      setselectedChat(chat);

      handleUserSelect({ chat: chat });
    } else if (username) {
      client
        .post(`/chat/api/create`, { username: username })
        .then((resp) => {
          setselectedChat(resp.data);
          sendJsonMessage({ type: "all" });
          handleUserSelect({ chat: resp.data });
        });
        
        goToMobileMessage()
      // console.log(createChatSocket.lastJsonMessage)
      // readyState.
    }
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const filterChatsBy = (val) => {
    var newChats = [];
    switch (val) {
      case null:
        if (originalChatList.length >= 1) {
          newChats = originalChatList;
        } else {
          newChats = chats;
        }
        setSelectedFilter(val);

        break;

      case "chats":
        if (originalChatList.length >= 1) {
          newChats = originalChatList.filter(
            (chat) =>
              (chat.group === null || chat.group === undefined) &&
              (chat.ticket === null || chat.ticket === undefined)
          );
        } else {
          setOriginalChatList(chats);
          newChats = chats.filter(
            (chat) => (chat) =>
              (chat.group === null || chat.group === undefined) &&
              (chat.ticket === null || chat.ticket === undefined)
          );
        }
        setSelectedFilter(val);
        break;

      case "ticket":
        if (originalChatList.length >= 1) {
          newChats = originalChatList.filter(
            (chat) => chat.ticket !== null && chat.ticket !== undefined
          );
        } else {
          setOriginalChatList(chats);
          newChats = chats.filter(
            (chat) => chat.ticket !== null && chat.ticket !== undefined
          );
        }
        setSelectedFilter(val + "s");
        break;
      case "group":
        if (originalChatList.length >= 1) {
          newChats = originalChatList.filter(
            (chat) => chat.group !== null && chat.group !== undefined
          );
        } else {
          setOriginalChatList(chats);
          newChats = chats.filter(
            (chat) => chat.group !== null && chat.group !== undefined
          );
        }
        setSelectedFilter(val + "s");
        break;

      default:
        message.error("invalid filter selected");
    }
    setChats(newChats);
  };

  const getChatBox = (chat) => {
    if (
      (chat.group === null || chat.group === undefined) &&
      (chat.ticket === null || chat.ticket === undefined)
    ) {
      return (
        <PrivateChat
          setCurrentChat={getChat}
          sendingMactive={sendingActive}
          key={chat.id}
          sendJsonMessageFunc={(obj) => sendJsonMessage(obj)}
          sendingMsgIds={selectedMList}
          user={User}
          chat={chat}
          gotoChats={goToMobileHome}
          gotoMsg={goToMobileMessage}
          gotoDetails={goToMobileChatDetails}
        />
      );
    } else if (chat.group !== null && chat.group !== undefined) {
      return (
        <GroupChat
          setCurrentChat={getChat}
          key={chat.id}
          sendingMactive={sendingActive}
          user={User}
          sendJsonMessageFunc={(obj) => sendJsonMessage(obj)}
          sendingMsgIds={selectedMList}
          chat={chat}
          gotoChats={goToMobileHome}
          gotoMsg={goToMobileMessage}
          gotoDetails={goToMobileChatDetails}
        />
      );
    } else if (chat.ticket !== null && chat.ticket !== undefined) {
      return (
        <TicketChat
          setCurrentChat={getChat}
          user={User}
          sendingMactive={sendingActive}
          key={chat.id}
          gotoChats={goToMobileHome}
          gotoMsg={goToMobileMessage}
          gotoDetails={goToMobileChatDetails}
          sendJsonMessageFunc={(obj) => sendJsonMessage(obj)}
          sendingMsgIds={selectedMList}
          chat={chat}
        />
      );
    } else {
      return <p>Invalid Chat</p>;
    }
  };

  const setAutoComplete = (val) => {
    const items_match = autoComOptions.filter((item) =>
      item.value.includes(val)
    );
    setautoCoOptions(items_match);
    if (items_match.length <= 0) {
      setautoCoOptions(plUsers);
    }
    setSelectedUSer(val);
  };

  const handleSearchChat = (val) => {
    setSearchChatText(val);
    if (originalChatList.length >= 1) {
      setChats(originalChatList);
    } else {
      setOriginalChatList(chats);
    }

    setChats(
      originalChatList.filter(
        (cht) =>
          cht.name.toLowerCase().includes(searchChatText.toLowerCase()) ||
          cht.latest_message?.body
            .toLowerCase()
            .includes(searchChatText.toLowerCase())
      )
    );
  };

  const handleAddModal = () => {
    setAddChatModal(true);
  };
  const handleCloseAddModal = () => {
    setAddChatModal(false);
  };

  return (
    <>
      <AddChatModal
        handleCloseAddModal={handleCloseAddModal}
        addChatModal={addChatModal}
        autoCompleteOptions={autoComOptions}
        setAddChatModal={setAddChatModal}
        getChatFunc={getChat}
      />
      <Box
        key={
          'Image by <a href="https://www.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_13223496.htm#query=artistic%20background&position=35&from_view=keyword&track=ais#position=35&query=artistic%20background">Freepik</a>'
        }
        sx={{
          // backgroundImage: `url(${abstractBg})`,
          backgroundRepeat: "no-repeat",
          // overflow: "none",
          top: "0",
          zIndex: "100px",
          // marginTop:'-10px',
          // backgroundPositionY: "-500px",
          // backgroundBlendMode: "hue",
          padding: { md: "20px", xs: "0px" },
          backgroundSize: "100% 100%",
          display: "block",
          // height: { md: "85vh", xs: "100%" },
        }}
        title="Chats"
      >
        {/* {alert(document.body.style.height)} */}
        <Container
          sx={{
            height: { md: "80vh", xs: "100%" },
            width: { md: "100%", xs: "100%" },
            borderRadius: { md: 3, xs: "none" },
            backgroundColor: "#f2fafa ",
            // maxHeight:'100vh',
            padding: "0!important",
          }}
        >
          {" "}
          {/* left side  */}
          {/* </CircularProgress> */}
          <Container
            className="green-chats"
            sx={{
              backgroundColor: "rgb(37, 44, 48)",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollBehavior: "smooth",
              float: { md: "left", xs: "none" },
              display: {
                md: "inline-block",
                xs: displayLeftScreen ? "block" : "none",
              },
              height: { md: "inherit", xs: "100%" },
              position: { xs: "fixed", md: "relative" },

              padding: "0",
              borderRadius: { md: "inherit", xs: "none" },
              borderTopRightRadius: { md: "8px", xs: "none" },
              borderBottomRightRadius: { md: "8px", xs: "none" },
              width: { md: "30%", xs: "100%" },
              color: "white",
            }}
          >
            {/* Header  */}
            <Header title="Chats" style={{ backgroundColor: "inherit" }}>
              <Box className="chat-headerContent">
                <Box
                  sx={{
                    display: "inline-block",
                    marginLeft: { md: "-55px", xs: "-35px" },
                  }}
                >
                  <h1
                    style={{
                      color: "whitesmoke",
                      fontFamily: "verdana",
                      fontSize: "30px",
                      fontWeight: "bold",
                      fontStretch: "expanded",
                    }}
                  >
                    Chats
                  </h1>
                </Box>

                <Box
                  className="action-btn-chats"
                  sx={{
                    display: "inline-block",
                    marginRight: { md: "-65px", xs: "-40px" },
                    float: "right",
                    marginTop: "12px",
                    justifyContent: "end",
                  }}
                >
                  <ButtonGroup>
                    <IconButton onClick={handleAddModal}>
                      <AddCircleSharp
                        style={{
                          fill: "whitesmoke",
                          marginLeft: "5px",
                          fontSize: "30px",
                        }}
                      />{" "}
                    </IconButton>

                    <IconButton>
                      <MoreVertOutlined
                        sx={{ display: { xs: "none", md: "none" } }}
                        style={{ fill: "whitesmoke", fontSize: "30px" }}
                      />
                    </IconButton>
                  </ButtonGroup>
                </Box>
              </Box>
            </Header>
            {/* end header  */}

            {/* Search  */}
            <Box
              sx={{
                padding: "10px",
                width: { xs: "80%", md: "100%" },
                margin: "0 auto",
              }}
            >
              <Input
                type="search"
                placeholder="Search Chats..."
                pattern="none"
                allowClear
                onChange={(e) => handleSearchChat(e.target.value)}
                suffix={<SearchOutlined style={{ color: "whitesmoke" }} />}
                formAction={false}
                bordered={false}
                color="white"
                style={{ border: "none", backgroundColor: "rgb(39, 54, 50)" }}
              />
            </Box>
            {/* end search */}

            {/* Tab List  */}
            <Box
              sx={{
                marginLeft: { md: "-20px" },
              }}
            >
              <Tabs
                TabIndicatorProps={{ hidden: false }}
                value={currentTab}
                scrollButtons
                variant="fullwidth"
                allowScrollButtonsMobile
                centered
                indicatorColor={"primary"}
              >
                <Tab
                  style={{ width: 10 }}
                  tabIndex={0}
                  value={null}
                  onClick={() => {
                    setCurrentTab(null);
                    filterChatsBy(null);
                  }}
                  icon={
                    <AllInboxOutlined
                      style={{ color: "rgba(39, 100, 90,0.7)" }}
                    />
                  }
                  title="All"
                ></Tab>
                <Tab
                  style={{ width: "20px" }}
                  value={0}
                  tabIndex={1}
                  onClick={() => {
                    setCurrentTab(0);
                    filterChatsBy("chats");
                  }}
                  icon={
                    <ChatBubbleOutline
                      style={{ color: "rgba(39, 100, 90,0.7)" }}
                    />
                  }
                  title="Private Chats"
                ></Tab>
                {/* <Tab
                  style={{ width: "20px" }}
                  value={1}
                  onClick={() => {
                    setCurrentTab(1);
                    filterChatsBy("group");
                  }}
                  tabIndex={2}
                  icon={
                    <GroupOutlined style={{ color: "rgba(39, 100, 90,0.7)" }} />
                  }
                  title="Groups"
                ></Tab> */}
                <Tab
                  style={{ width: "20px" }}
                  value={2}
                  tabIndex={3}
                  onClick={() => {
                    setCurrentTab(2);
                    filterChatsBy("ticket");
                  }}
                  icon={
                    <SupportRounded
                      style={{ color: "rgba(39, 100, 90,0.7)" }}
                    />
                  }
                  title="Tickets"
                ></Tab>
              </Tabs>
            </Box>
            
             {/* sending cancel button */}
             <Box onClick={()=>{goToMobileMessage(); setselectedChat(selectedChat)}} sx={{ justifyContent:'left', margin:'10px', display:{md:'none', xs:sendingActive ?'grid':'none'}
}}>
            <Button
            
              style={{
                borderColor: "rgb(210, 90, 79)",
                zIndex: 1999,
                fontSize: "10px",
                backgroundColor: "inherit",
                border: "1px solid rgb(210, 103, 79)",
                borderRadius: "10px",
                padding: "5px",
               
                color: "rgb(210, 103, 79)",
                margin: "5px",
              }}
            >
              Go Back
            </Button>
            </Box>
            
            {/* Chat lists  */}
            <Box sx={{ padding: "10px" }}>
              {" "}
              <InfiniteScroll
                dataLength={chats.length}
                // next={loadMoreData}
                // hasMore={chats.length < 50}

                loader={
                  <Skeleton
                    avatar
                    paragraph={{
                      rows: 1,
                    }}
                    active
                  />
                }
                endMessage={<Divider plain>{chats.length} Chats💬</Divider>}
                scrollableTarget="scrollableDiv"
              >
                <List
                  dataSource={chats}
                  renderItem={(chat) => <>{getChatBox(chat)}</>}
                />
              </InfiniteScroll>
            </Box>

            {/* end chat list  */}
          </Container>
          {/* end left  */}
          {/* center  */}
          {/* </Box> */}
          <Box
            className="center-msg"
            sx={{
              // top:0,
              // float:{md:'right', xs:'none'},
              // padding:'0px !important',
              // margin:'0px !important',

              // height:{md:'100%', xs:},
              height: { md: "100%", xs: "100% !important" },
              display: {
                md: "inline-block",
                xs: displayMiddleScreen ? "block" : "none",
              },
              width: { md: "45%", xs: "100%" },
              color: "white",
            }}
          >
            <ChatContent
              usr={User}
              setSendingButtons={setSendingActive}
              sendingMActive={sendingActive}
              selectedCht={selectedChat}
              setSendingMIdList={setSelectedMList}
              display={displayMiddleScreen ? "block" : "none"}
              gotoChats={goToMobileHome}
              gotoMsg={goToMobileMessage}
              gotoDetails={goToMobileChatDetails}
            />{" "}
          </Box>
          {/* end center  */}
          {/* right end  */}
          <Box
            className="right-msg"
            boxShadow={shadows[3]}
            sx={{
              float: { md: "right", xs: "none" },
              borderTopRightRadius: { md: 10, xs: 0 },
              borderBottomRightRadius: { md: 10, xs: 0 },
              height: "100%",
              // overflow:'auto',
              position: { md: "relative", xs: "fixed" },
              display: {
                md: "inline-block",
                xs: displayRightScreen ? "block" : "none",
              },
              width: { md: "25%", xs: "100%" },
            }}
          >
            <Content
              style={{
                backgroundColor: "#f8fff8",
                overflowY: "hidden",
                borderTopRightRadius: window.screen.width >= 660 ? 10 : 0,
                borderBottomRightRadius: window.screen.width >= 660 ? 10 : 0,
                height: "inherit",
              }}
            >
              {/* Search  */}
              <IconButton
                sx={{
                  display: { md: "none", xs: "block", position: "absolute" },
                }}
                onClick={() => goToMobileMessage()}
              >
                <ArrowBackOutlined />
              </IconButton>

              <Box
                sx={{
                  padding: "10px",
                  width: { xs: "80%", md: "100%" },
                  margin: "0 auto",
                }}
              >
                <AutoComplete
                  className="users-p-search"
                  bordered
                  style={{
                    backgroundColor: "#f1fff5",
                    width: "100%",
                  }}
                  onSelect={(val) => {
                    setSelectedUSer(val);
                    handleUserSelect({ username: val });
                  }}
                  onChange={(val) => {
                    setAutoComplete(val);
                  }}
                  clearIcon={<Clear />}
                  removeIcon={<Remove />}
                  backfill="#f1fff5"
                  suffixIcon={
                    <PersonSearch style={{ color: "ActiveBorder" }} />
                  }
                  placeholder={
                    <>
                      <PersonSearch
                        style={{ display: "flex", float: "left" }}
                      ></PersonSearch>
                      <span style={{ display: "flex" }}>Search User...</span>
                    </>
                  }
                >
                  {autoComOptions.length >= 1
                    ? autoComOptions.map((item) => (
                        <Option key={item.value} value={item.value}>
                          <Avatar src={item.dp}>
                            {" "}
                            {String(item.value)[0]}
                          </Avatar>{" "}
                          <Space />{" "}
                          <small>
                            <i>@</i>
                          </small>
                          {item.value}
                        </Option>
                      ))
                    : "no such user"}
                </AutoComplete>
              </Box>
              {/* end search */}
              {loadedChatDetail === null ? (
                <div style={{ paddingTop: "150px" }}>
                  <Empty />
                </div>
              ) : (
                <Spin spinning={loadingSide}>
                  <Box>
                    <Box
                      sx={{
                        // display: "grid",
                        justifyContent: "center",
                        // paddingTop: "10px",
                        // paddingLeft: "70px",
                        // paddingRight: "70px",
                        textAlign: "center",
                      }}
                    >
                      <Avatar
                        style={{
                          borderRadius: "50%",
                          width: "150px",
                          height: "150px",

                          boxShadow: shadows[2],
                        }}
                        src={
                          loadedChatDetail?.avatar ? (
                            <Image
                              style={{
                                width: "150px",
                                // marginBottom:'-100px',
                                height: "150px",
                                objectFit: "cover",
                              }}
                              src={loadedChatDetail?.avatar}
                            />
                          ) : (
                            <Person style={{width:'inherit', height:'inherit'}} />
                          )
                        }
                      />

                      <h2
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "8px",
                        }}
                      >
                        {loadedChatDetail?.type === "Private"
                          ? loadedChatDetail?.members?.filter(
                              (mm) => mm.username !== User.username
                            )[0].username
                          : loadedChatDetail?.name
                          ? loadedChatDetail.name
                          : loadedChatDetail?.username}
                        <div
                          style={{
                            lineHeight: "1.5",
                            height: "20px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {" "}
                          <p
                            style={{
                              color: "activeborder",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            {loadedChatDetail?.bio
                              ? loadedChatDetail.bio
                              : loadedChatDetail?.description
                              ? loadedChatDetail?.description
                              : loadedChatDetail?.members?.filter(
                                  (mm) => mm.username !== User.username
                                )[0].user.bio}
                          </p>
                        </div>
                      </h2>
                    </Box>
                    <List
                      dataSource={[
                        {
                          type: loadedChatDetail?.type
                            ? loadedChatDetail.type
                            : "Private",

                          website: loadedChatDetail?.username
                            ? loadedChatDetail?.website
                            : loadedChatDetail?.type === "Private"
                            ? loadedChatDetail?.members?.filter(
                                (mm) => mm.username !== User.username
                              )[0].user.website
                            : "",
                          // : null,
                          mobile: loadedChatDetail?.username
                            ? loadedChatDetail?.mobile
                            : loadedChatDetail?.type === "Private"
                            ? loadedChatDetail?.members?.filter(
                                (mm) => mm.username !== User.username
                              )[0].user.mobile
                            : "",
                          location:
                            loadedChatDetail?.type === "Private"
                              ? loadedChatDetail?.members?.filter(
                                  (mm) => mm.username !== User.username
                                )[0].user.location
                              : loadedChatDetail?.location
                              ? loadedChatDetail.location
                              : null,
                          seller:
                            loadedChatDetail?.type === "Private"
                              ? loadedChatDetail?.members?.filter(
                                  (mm) => mm.username !== User.username
                                )[0].user.seller
                              : loadedChatDetail?.seller
                              ? loadedChatDetail.seller
                              : null,
                        },
                      ]}
                      renderItem={(item) => (
                        <Box
                          style={{
                            paddingRight: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <List.Item
                            style={{
                              padding: "0",
                              border: "none",
                              borderBottom: "1px solid rgba(39, 100, 90,0.1)",
                            }}
                          >
                            {loadedChatDetail?.type === "Private" ||
                            loadedChatDetail?.email ? (
                              <List.Item.Meta
                                style={{ marginLeft: "50px" }}
                                avatar={
                                  <a
                                    href={`mailto:${
                                      loadedChatDetail?.email
                                        ? loadedChatDetail.email
                                        : loadedChatDetail?.members?.filter(
                                            (mm) =>
                                              mm.username !== User.username
                                          )[0].user.email
                                    }`}
                                  >
                                    <Email
                                      style={{
                                        fontSize: 30,
                                        color: "rgb(37, 44, 48)",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </a>
                                }
                              ></List.Item.Meta>
                            ) : (
                              ""
                            )}
                            <List.Item.Meta
                              avatar={
                                <IconButton
                                  onClick={() => {
                                    if (loadedChatDetail?.username === null) {
                                      getChat({
                                        chat: loadedChatDetail,
                                      });
                                    } else {
                                      getChat({
                                        username: loadedChatDetail?.username,
                                      });
                                    }

                                    goToMobileMessage();
                                  }}
                                >
                                  <ChatBubble
                                    style={{
                                      fontSize: 30,
                                      color: "rgb(37, 44, 48)",
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              }
                            ></List.Item.Meta>
                            {loadedChatDetail?.type === "Private" ||
                            loadedChatDetail?.username ? (
                              <List.Item.Meta
                                avatar={
                                  <a
                                    href={`tel:${
                                      !loadedChatDetail?.username
                                        ? loadedChatDetail?.type === "Private"
                                          ? loadedChatDetail?.members?.filter(
                                              (mm) =>
                                                mm.username !== User.username
                                            )[0].user.mobile
                                          : ""
                                        : loadedChatDetail?.mobile
                                    }`}
                                  >
                                    <IconButton
                                      disabled={
                                        loadedChatDetail?.mobile ||
                                        loadedChatDetail?.members?.filter(
                                          (mm) => mm.username !== User.username
                                        )[0].user.mobile
                                          ? false
                                          : true
                                      }
                                    >
                                      <Phone
                                        style={{
                                          fontSize: 30,
                                          color: "rgb(37, 44, 48)",
                                          cursor: "pointer",
                                          opacity:
                                            loadedChatDetail?.mobile ||
                                            loadedChatDetail?.members?.filter(
                                              (mm) =>
                                                mm.username !== User.username
                                            )[0].user.mobile
                                              ? 1
                                              : 0.3,
                                        }}
                                      />
                                    </IconButton>
                                  </a>
                                }
                              ></List.Item.Meta>
                            ) : (
                              ""
                            )}
                          </List.Item>
                          {item.website ? (
                            <List.Item
                              style={{
                                padding: "0",
                                border: "none",
                                borderBottom: "1px solid rgba(39, 100, 90,0.1)",
                              }}
                            >
                              <List.Item.Meta
                                title={
                                  <span
                                    style={{
                                      fontSize: 12,
                                      marginTop: "10px",

                                      color: "rgba(39, 160, 90,0.5)",
                                    }}
                                  >
                                    <a href={item.website}> {item.website}</a>
                                  </span>
                                }
                                avatar={
                                  <LinkOutlined
                                    style={{
                                      fontSize: 15,
                                      marginTop: "10px",
                                    }}
                                  />
                                }
                              ></List.Item.Meta>
                            </List.Item>
                          ) : (
                            ""
                          )}
                          {item.location ? (
                            <List.Item
                              style={{
                                padding: "0",
                                border: "none",
                                borderBottom: "1px solid rgba(39, 100, 90,0.1)",
                              }}
                            >
                              <List.Item.Meta
                                title={
                                  <span
                                    style={{
                                      fontSize: 12,
                                      marginTop: "10px",
                                    }}
                                  >
                                    {item.location}
                                  </span>
                                }
                                avatar={
                                  <MyLocationOutlined
                                    style={{
                                      fontSize: 15,
                                      marginTop: "10px",
                                    }}
                                  />
                                }
                              ></List.Item.Meta>
                            </List.Item>
                          ) : (
                            ""
                          )}
                          <List.Item
                            style={{
                              padding: "0",
                              border: "none",
                              borderBottom: "1px solid rgba(39, 100, 90,0.1)",
                            }}
                          >
                            <List.Item.Meta
                              title={
                                <span
                                  style={{ fontSize: 12, marginTop: "10px" }}
                                >
                                  {item.type}
                                </span>
                              }
                              avatar={
                                <ChatBubbleOutline
                                  style={{ fontSize: 15, marginTop: "10px" }}
                                />
                              }
                            ></List.Item.Meta>
                          </List.Item>

                          {item.mobile ? (
                            <List.Item
                              style={{
                                padding: "0",
                                border: "none",
                                borderBottom: "1px solid rgba(39, 100, 90,0.1)",
                              }}
                            >
                              <List.Item.Meta
                                title={
                                  <span
                                    style={{
                                      fontSize: 12,
                                      color: "GrayText",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {item.mobile}
                                  </span>
                                }
                                avatar={
                                  <PhoneOutlined
                                    style={{
                                      fontSize: 15,
                                      marginTop: "10px",
                                    }}
                                  />
                                }
                              ></List.Item.Meta>
                            </List.Item>
                          ) : (
                            " "
                          )}

                          {item.seller !== null ? (
                            <List.Item
                              style={{
                                padding: "0",
                                border: "none",
                                borderBottom: "1px solid rgba(39, 100, 90,0.1)",
                              }}
                            >
                              {item.seller ? (
                                <List.Item.Meta
                                  title={
                                    <Tag
                                      style={{
                                        backgroundColor:
                                          "rgba(39, 100, 90,0.7)",
                                        color: "whitesmoke",
                                        marginTop: "10px",
                                        borderColor: "lightgreen",
                                      }}
                                    >
                                      Seller
                                    </Tag>
                                  }
                                  avatar={
                                    <PersonOutlined
                                      style={{
                                        fontSize: 15,
                                        marginTop: "10px",
                                      }}
                                    />
                                  }
                                ></List.Item.Meta>
                              ) : (
                                ""
                              )}
                            </List.Item>
                          ) : (
                            ""
                          )}
                        </Box>
                      )}
                    ></List>
                    <center>
                      {loadedChatDetail?.username ? (
                        <Button
                          type="primary"
                          style={{
                            color: "lavender",
                            marginTop: "20px",
                            backgroundColor: "rgb(87, 104, 48)",
                          }}
                          onClick={() =>
                            window.open(
                              "/profile/" + loadedChatDetail?.username
                            )
                          }
                        >
                          View Profile
                        </Button>
                      ) : (
                        ""
                      )}
                    </center>
                    {loadedChatDetail?.attachments ? (
                      <Box
                        style={{
                          textAlign: "left",
                          marginTop: "5px",
                          paddingRight: "20px",
                          paddingLeft: "20px",
                          // height: "400px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "15px",
                            opacity: "0.8",
                            padding: "0",
                            margin: 0,
                          }}
                        >
                          Media
                        </p>
                        <List
                          style={{
                            overflowY: "auto",
                            height: "400px",

                            overflowAnchor: "auto",
                            scrollbarWidth: 1,
                            scrollBehavior: "smooth",
                          }}
                          grid
                          size={40}
                          bordered={false}
                          dataSource={loadedChatDetail?.attachments}
                          className="Media-files
                  "
                          renderItem={(file) => (
                            <List.Item
                              title={file.name}
                              key={file.name}
                              style={{
                                margin: "4px",
                              }}
                            >
                              <FileWidget
                                type={file.type}
                                url={file.url}
                                name={file.name}
                                id={file.id}
                              />
                            </List.Item>
                          )}
                        ></List>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Spin>
              )}
            </Content>
          </Box>
          {/* end right end  */}
          {/* end center  */}
          <Footer>
            <Box sx={{ display: { md: "none", sx: "block" } }}>
              {/* <BottomNavBar /> */}
            </Box>
          </Footer>
        </Container>
      </Box>
    </>
  );
}
export default ChatApp;
