import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    status:'idle', 
}

const packageReducer = createSlice({
    name:"package", 
    initialState: initialState,
    reducers:{
        
        changeStatus(state, action){
            state.status = action.payload
        }

    }  
})
export const {changeStatus} = packageReducer.actions
export default packageReducer.reducer