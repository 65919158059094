

import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, {Component} from 'react';
import { useNavigate } from 'react-router-dom';
 
 
 const VisitHistory = (props) =>  {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (props.clicked === true) {
      if (props.path === "//") {
        return navigate(-1);
      }

      navigate(props.path);
    }
  }, [props.path]);
  return <></>;
};

export default class  NavBackWidget extends Component{
  
 state = {
  itemClicked:false,
  itemPath:null
 }
  
 render () {
 return <>
 <div style={{width:'100%', textAlign:'left', padding:'20px'}}>
  <VisitHistory path={this.state.itemPath} clicked={this.state.itemClicked}>
  </VisitHistory>
   <IconButton style={{borderRight:'1px solid green'}} onClick={() => 
   this.setState({ itemClicked: true, itemPath: '//' })}>
   <ArrowBackIosNewOutlined />
   </IconButton>
   </div>
   </>

 } 
}