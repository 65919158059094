import React, { Component, useRef } from "react";
import { Card, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { PriceInput, SocialMedia } from "../app_context";
import {
  Button,
  Form,
  Select,
  Slider,
  InputNumber,
  message,
  Upload,
  Input,
} from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SubProductForm from "../subProduct";
import { Box } from "@mui/system";
import APP_ENV from "../../env";

const BASE_URL = APP_ENV.SERVER_ADDR;;
const reqHeaders = {
  "Content-Type": "application/json",

  Authorization: `Token ${localStorage.getItem("AccgToken")}`,
};
const cookies = new Cookies();
if (localStorage.getItem("AccgToken") !== "null") {
  reqHeaders["Authorization"] = `Token ${localStorage.getItem("AccgToken")}`;
}

const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,
});
const authClient = axios.create({
  baseURL: BASE_URL,
});
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const normFile = (e) => {
  // console.log('Upload event:', e);

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};

const ProductsUploadPage = () => {
  const navigate = useNavigate();

  const [featureEntry, setfeatureEntry] = React.useState(null);
  const [fileList, setFileList] = React.useState([]);

  const [features, setfeatures] = React.useState([
    "Never Banned Before",
    "Folllowed By A / Many Celebrities",
    "Over 10, 000 followers",
  ]);

  const [productDraft, setProductDraft] = useState({});
  const [selectedCategory, setSelectedCategory] = React.useState(
    productDraft.category
  );
  const [subProducts, setSubProducts] = useState([]);
  const [loading, setloading] = useState(false);
  const productFormRef = useRef(null);
  const [form] = Form.useForm();
  const [loadingtip, setloadingtip] = useState("fetching data");
  const productsRef = useRef();
  const [ProductGroups, setProductGroups] = useState([])

  React.useEffect(() => {
    refreshPageData();
  }, [productDraft.id]);
  const refreshPageData = () => {
    setloading(true);
    client
      .post("/social/api/innit-create")
      .then((resp) => {
        const data = resp.data;
        setProductDraft(data);

        setSubProducts([]);
        if (data.products) {
          for (let i = 0; i < data.products.length; i++) {
            const product = data.products[i];
            if (product.index) addSubProduct(data.category, product.index);
          }
        }

        if (data.features) {
          setfeatures(data.features);
        }
        if (data.id !== undefined || data.id !== null) {
          setloading(false);
        }
      })
      .catch((err) => {
        message.error("unable to get draft");
        setloading(false);
      });

    client
      .get("social/api/UserFiles?unused=True")
      .then((resp) => {
        setFileList(resp.data.files);
        // setImageList(resp.data.images);
      })
      .catch((err) => message.error("Unable to Load images/files"));
    if (productDraft.id) {setSelectedCategory(productDraft.category)};
    
        client
          .get("/social/api/seller/product-groups/")
          .then((response) => {
            // console.log(response.data);
            setProductGroups(response.data);
            setloading(false);
          })
          .catch((err) => {
            message.error("Error getting groups");
            setloading(false);
          });
    
  };

  const onFinish = (values) => {
    setloadingtip("uploading product..");

    setloading(true);
    if (subProducts.length < 1) {
      message.error("Upload atleast 1 or more product variant!");
      setloading(false);
      setloadingtip("fetching data...");
    } else {
      values["sub_product_count"] = subProducts.length;
      const subProducts_el =
        document.getElementsByClassName("product-subm-btn");
      var productsEl = [];

      for (let i = 0; i < subProducts_el.length; i++) {
        const element = subProducts_el[i];

        element.click();
        const validator_el = document.querySelector(`#${element.id}-validator`);

        productsEl.push(validator_el);

        // message.error(error);
      }
      setTimeout(() => {
        var positive = productsEl.filter(
          (item) => item.getAttribute("value") === "true"
        );

        if (positive.length >= productsEl.length) {
          client
            .post("social/api/seller/products", values)
            .then((resp) => {
              if (resp.data.error === true) {
                setloadingtip("fetching data");
                return message.error(resp.data.msg);
              } else {
                message.success(resp.data.msg);
                navigate("/seller/products");
              }
              setloading(false);
            })
            .catch((err) => {
              message.error("something went wrong");
              setloadingtip("fetching data...");
              setloading(false);
            });
        } else {
          setloadingtip("fetching data...");
          setloading(false);
        }
      }, 5000);
    }
  };

  const deleteSingleProduct = (index) => {
    setloading(true);
    client
      .delete("/social/api/seller/products/" + productDraft.id + "/" + index)
      .then((resp) => {
        message.info(resp.data.msg);
        refreshPageData();
      })
      .catch((err) => message.error("An error occured"));
  };

  const submitProductUpdate = () => {
    client
      .post("/social/api/innit-create", form.getFieldsValue())
      .then((resp) => {
        //  refreshPageData()
      })
      .catch((err) => {});
  };

  const addSubProduct = (productCategory, index) => {
    setSubProducts((prevSubProducts) => [
      ...prevSubProducts,
      <SubProductForm
        deleteProduct={deleteSingleProduct}
        productId={productDraft.id}
        type={productCategory}
        index={index}
        ref={productsRef}
      />,
    ]);
  };

  const checkPrice = (_, value) => {
    if (value.number >= 500) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Price must be greater than 500!"));
  };

  const handleFileOnChange = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const handleFileRemove = (file) => {
    client
      .delete(
        "/social/api/UserFiles?type=files&name=" +
          file.name +
          "&slug=" +
          file.slug
      )
      .then((resp) => {
        message.info("Deleted");
      })
      .catch((err) => message.error("something went wrong"));
  };

  const suffixSelector = (
    <Form.Item
      required={true}
      initialValue={productDraft.age_suffix}
      name="age-suffix"
      noStyle
    >
      <Select
        style={{ width: 100 }}
        // defaultOpen={true}
        defaultValue={productDraft.age_suffix}
        onChange={() => submitProductUpdate()}
      >
        <Option value="Y">Year(s)</Option>
        <Option value="M">Month(s)</Option>
        <Option value="D">Day(s)</Option>
      </Select>
    </Form.Item>
  );
  return (
    <>
      <Spin
        spinning={loading}
        // style={{ height: "600px" }}
        tip={loadingtip}
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          margin: "0 auto",
        }}
      >
        {productDraft.id ? (
          <Card title="Sell ">
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title="Upload Product"
              extra={<Link to="/seller/products">My Products</Link>}
            >
              <Form
                ref={productFormRef}
                name="validate_other"
                {...formItemLayout}
                form={form}
                onFinish={onFinish}
                id={"productParent"}
                initialValues={{
                  "input-number": 3,
                  // 'checkbox-group': ['A', 'B'],
                  // rate: 3.5,
                }}
              >
                <Form.Item label="Fill Out Product Details">
                  {/* <span className="ant-form-text">China</span> */}
                </Form.Item>
                <Form.Item
                  hidden
                  initialValue={productDraft.id}
                  name="product_id"
                >
                  <Input hidden name="product_id" value={productDraft.id} />
                </Form.Item>

                <Form.Item
                  required
                  initialValue={productDraft.title}
                  name="title"
                  label="Post title"
                >
                  <Input
                    name="title"
                    onKeyUp={() => submitProductUpdate()}
                    placeholder="What you want people to see first"
                  />
                </Form.Item>
                <Form.Item
                  name="ProductCategory"
                  label="Product Category"
                  initialValue={productDraft.category}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please select Product Category",
                    },
                  ]}
                >
                  <Select
                    defaultValue={productDraft.category}
                    onChange={(val) => {
                      setSelectedCategory(val);
                      submitProductUpdate();
                    }}
                    placeholder="Please select Product Category"
                  >
                    <Option value="sm">Social Media</Option>
                    <Option disabled value="gm">
                      Games
                    </Option>
                  </Select>
                </Form.Item>

                {selectedCategory === "sm" ? (
                  <>
                    <Form.Item
                      name="MediaType"
                      label="Media Type"
                      initialValue={productDraft.socialMedia}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please select your Media Type!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue={productDraft.socialMedia}
                        placeholder="Please select Media Type"
                        onChange={() => submitProductUpdate()}
                      >
                        {SocialMedia.map((value, index) => (
                          <Option value={value[0]}>
                            {value[1]} 
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}

                {/* Individual sub product details/product variants  */}
                <div style={{ margin: "10px auto" }}>
                  <small>
                    <b>Instructions:</b>
                    <li style={{ listStyleType: "disc" }}>
                      Upload atleast one Image sample for one in all of the
                      products or one image individually.
                    </li>
                  </small>
                </div>
                {subProducts.map((product, index) => {
                  return <>{product}</>;
                })}
                {/* end product variant  */}
                {selectedCategory ? (
                  <Box
                    style={{
                      margin: "10px auto",
                      width: "60%",
                      display: "grid",
                      justifyContent: "right",
                      textAlign: "right",
                    }}
                  >
                    <Button
                      title="Add Product variant"
                      onClick={() =>
                        addSubProduct(selectedCategory, subProducts.length + 1)
                      }
                      style={{
                        backgroundColor: "rgb(50, 48, 40)",
                        borderColor: "darkgreen",
                        boxShadow: "0.1px 2px 1px 0.3px grey",
                        color: "#c3d7c3",
                        display: "block",
                        fontSize: "14px",
                      }}
                    >
                      Add Product
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
                <Form.Item
                  name="age"
                  label="Product Age"
                  initialValue={productDraft.age}
                  rules={[
                    {
                      required: true,
                      message: "Please input product age!",
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    max={20}
                    name="age"
                    addonAfter={suffixSelector}
                    style={{ width: "100%" }}
                    onKeyUp={() => submitProductUpdate()}
                  />
                </Form.Item>
                <Form.Item
                  name="features"
                  label="Enter product feature(s)"
                  initialValue={productDraft.features}
                  rules={[
                    {
                      message: "Please Enter features!",
                      type: "array",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    onKeyUp={(e) => {
                      setfeatures((prevArray) => [
                        ...prevArray,
                        e.target.value,
                      ]);
                    }}
                    onChange={() => submitProductUpdate()}
                    placeholder="What makes this account special?"
                  >
                    {features.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Box style={{ margin: "0 auto", textAlign: "center" }}>
                  {/* <div
                      style={{
                        width: "200px",
                        display: "inline-block",
                      }}
                    > */}
                  <small>
                    Note: Enter the unform Price of the uploaded product
                    variants.
                  </small>
                  <Form.Item
                    name="price"
                    label="Price"
                    min={500}
                    required={true}
                    rules={[
                      {
                        validator: checkPrice,
                      },
                    ]}
                  >
                    <PriceInput
                      currentProduct={productDraft}
                    
                      // value={{
                      //   number: productDraft.price_amount,
                      //   currency: productDraft.price_currency,
                      // }}
                    />
                  </Form.Item>

                  {/* </div> */}
                </Box>

                <Form.Item label="Product Files">
                  <Form.Item
                    name="productFiles"
                    // valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload.Dragger
                      fileList={fileList}
                      maxCount={3}
                      onRemove={handleFileRemove}
                      method="POST"
                      onChange={handleFileOnChange}
                      headers={{
                        Authorization: `Token ${localStorage.getItem(
                          "AccgToken"
                        )}`,
                      }}
                      accept=".doc,.pdf,.csv,.sql,.html,.xls"
                      action={
                        BASE_URL +
                        "social/api/UserFiles?product_id=" +
                        productDraft.id
                      }
                      name="productFiles"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Upload any important file needed for this product(not
                        compulsory).
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  initialValue={productDraft.description}
                  rules={[
                    {
                      required: true,
                      message: "Input Description (no matter how small)",
                    },
                  ]}
                >
                  <Input.TextArea
                    maxLength={400}
                    defaultValue={productDraft.description}
                    placeholder="Input Addition information,  but not confidential data e.g password,phone,emails etc"
                    showCount
                    onKeyUp={() => submitProductUpdate()}
                  />
                </Form.Item>

                <Form.Item
                  name="product_groups"
                  label="Select Product group"
                  initialValue={productDraft.groups}
                  rules={[
                    {
                      message: "Product group empty!",
                      type: "array",
                      required: false,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    onKeyUp={(e) => {
                      setProductGroups((prevArray) => [
                        ...prevArray,
                        e.target.value,
                      ]);
                    }}
                    onChange={() => submitProductUpdate()}
                    placeholder="Group Product"
                  >
                    {ProductGroups.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    span: 12,
                    offset: 6,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Upload Product
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Card>
        ) : (
          ""
        )}
      </Spin>
    </>
  );
};
export default ProductsUploadPage;
