import { ChatBubble, FileCopyRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Avatar, Badge, List, message, notification } from "antd";
import React, { useState } from "react";

const PrivateChat = ({
  chat,
  user,
  sendingMactive,
  setCurrentChat,
  ...others
}) => {
  const [sentM, setSentM] = useState(false);
  const [prevSent, setprevSent] = useState([]);
  React.useEffect(() => {
    if (prevSent === others.sendingMsgIds) {
      setSentM(true);
    }
  }, [sendingMactive]);
  return (
    <List.Item
      className="chat-box"
      style={{
        borderBottom: "0.1px solid rgb(39, 74, 60)",
        opacity: chat.name === "Deleted Chat" ? 0.5 : 1,
      }}
      onClick={() => {
        if (chat.name === "Deleted Chat") {
          return notification.info({ message: "Chat Deleted By owner" });
        } else {
          if (sendingMactive) {
            if (!sentM) {
              others.sendJsonMessageFunc({
                type: "Forward_Message",
                chat_id: chat.id,
                msg_ids: others.sendingMsgIds,
              });
              setprevSent(others.sendingMsgIds);
            } else {
              message.info("Already Sent");
            }
            setSentM(true);
          } else {
            setCurrentChat({ chat: chat });
            others.gotoMsg();
          }
        }
      }}
    >
      <List.Item.Meta
        avatar={
          <>
            <Avatar size={50} style={{backgroundColor:'whitesmoke'}} src={chat.avatar} />
            {/* <Badge
            status="success"
            style={{ position: "absolute", top: 39, left: 43 }}
          ></Badge> */}
          </>
        }
        title={
          <p style={{ color: "#ccf" }}>
            {chat.type === "Private"
              ? chat.members.filter((mm) => mm.username !== user.username)[0]
                  .username
              : chat.name}
          </p>
        }
        description={
          <p
            style={{
              marginTop: "-10px",
              lineHeight: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "20px",
              color: "whitesmoke",
            }}
          >
            {chat.latest_message ? (
              <>
                <span> {chat.latest_message.body}</span>{" "}
                {chat.latest_message.attachments.length >= 1 ? (
                  <>
                    <b>📎 Attachment</b>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              "Say hi! 👋"
            )}
          </p>
        }
      />
      <Box>
        <p style={{ margin: 0 }}>
          {" "}
          {
            <small style={{ color: "rgba(110, 200, 100, 0.7)" }}>
              {chat.latest_message?.time}
            </small>
          }
        </p>
        <p style={{ float: "right", marginRight: "2px" }}>
          <Badge
            count={chat.unread_count}
            showZero={false}
            style={{
              backgroundColor: "whitesmoke",
              paddingRight: "4px",
              paddingLeft: "4px",
              color: "darkgreen",
            }}
          ></Badge>
        </p>

        {sendingMactive ? (
          <Button
            disabled={sentM === true ? true : false}
            style={{
              borderColor: "rgb(110, 213, 179)",
              zIndex: 1999,
              fontSize: "10px",
              backgroundColor: "inherit",
              border: "1px solid rgb(110, 213, 179)",
              borderRadius: "20px",
              padding: "5px",
              color: "rgb(110, 213, 179)",
              float: "right",
              margin: "5px",
            }}
          >
            {sentM ? "Sent" : "Send"}
          </Button>
        ) : (
          ""
        )}
      </Box>
    </List.Item>
  );
};

export default PrivateChat;
