import {
  ArrowBackIos,
  Cancel,
  Delete,
  DeleteOutline,
  Edit,
  Payment,
  Pin,
  PinOutlined,
} from "@mui/icons-material";
import { Box, Button, Alert, IconButton } from "@mui/material";
import {
  Card as AntCard,
  Form,
  Input,
  List,
  Modal,
  Select,
  Button as AntButton,
  Tag,
  notification,
} from "antd";
import { message, Spin } from "antd";
import React, { Component, createRef, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import md5 from "md5";
import PinInput from "react-pin-input";
import { color } from "@mui/system";
import APP_ENV from "../../env";
const BASE_URL = APP_ENV.SERVER_ADDR;;
const reqHeaders = {
  "Content-Type": "application/json",
};

if (localStorage.getItem("AccgToken") !== null) {
  reqHeaders["Authorization"] = `Token ${localStorage.getItem("AccgToken")}`;
}

const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders,
});
const authClient = axios.create({
  baseURL: BASE_URL,
});
export default function WalletButton(props) {
  const navigate = useNavigate();
  const [pOrder, setpOrder] = useState({});
  const paymentRefButtonRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [msgText, setmsgText] = useState("");

  const [alertColor, setAlertColor] = useState("success");
  React.useEffect(() => {
    setLoading(false);
    if (props.type === 'smm' || props.type === 'advertPackage') {
      return updateStates()
    }
    setpOrder(props.pOrder);
  }, [props.pOrder]);

  const updateStates = () => {
    setLoading(true);

    client
      .get(`/user/api/order/checkout?type=${props.type}&currency=${props.currency}&rate=${props.rate}&quantity=${props.quantity|| 1}&package=${props.pkgId}&mediums=${JSON.stringify(props.mediums)}`)
      .then((resp) => {
        if (resp.data.cart) {
          setpOrder(resp.data.order);
        } else { if (props.type === 'smm' || props.type === 'advertPackage') {
          setLoading(false)

          return setpOrder(resp.data)
        }

          message.error("No item in your cart");

        
        } 
        setLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };

  const showAlert = (color = null, message = null) => {
    var cartEl = document.querySelector("#cartSuccessAlert");
    setAlertColor(color);
    if (color) {
      setAlertColor(color);
      cartEl.children.item(1).innerHTML = message;
    }
    cartEl.style.left = 0;
    setTimeout(() => {
      cartEl.style.left = "-800px";
    }, 1300);
  };

  const handleOrderWalletPayment = () => {
    if (
      pOrder.user.wallet_amount <= 0 ||
      parseFloat(pOrder.user.wallet_amount) < parseFloat(pOrder.total_amount)
    ) {
      Modal.confirm({
        okButtonProps: { loading: loading },
        cancelButtonProps: { loading: loading },
        title: <p style={{ color: "red" }}> Insufficient Balance</p>,
        icon: <Payment style={{ color: "red" }}></Payment>,
        content: (
          <p style={{ fontFamily: "sans" }}>
            You do not have enough amount of money to complete order at the
            moment.Please Recharge wallet.
            <br />
            <br />
            <b>{pOrder.user.wallet}</b>
          </p>
        ),
        okText: "Recharge Wallet",
        onOk: () => {
          navigate("/user/wallet#fund-wallet");
        },
      });
    } else {
      const PaymentConfirmedPinContent = ({ loadingPinC, setloadingPinC }) => {
        const mainInputRef = useRef(null);
        return (
          <>
            <h4>Enter Wallet Pin </h4>

            <PinInput
              ref={mainInputRef}
              autoSelect={true}
              onComplete={(val) => {
                setloadingPinC(true);

                message.info("processing...");

                callbackJSON["pin"] = val;
                client
                  .post("/user/api/create-payment", callbackJSON)
                  .then((resp) => {                  
                    if (resp.data.status === "error") {
                      setloadingPinC(false);

                      notification.error({ message: resp.data.msg, duration:"10" });
                    } else {
                      notification.success({
                        message: "Pin Correct! Payment Processed",
                      });
                      Modal.destroyAll();
                      
                      setloadingPinC(false);
                      if (props.type==="smm"){

                        return  navigate(
                          `/smm/orders?status=successful`
                        );
                      }
                      
                      else if(props.type === 'advertPackage'){
                      return navigate(`/influence-hub/orders?status=successful`)
                      }
                      props?.headerRef.current.updateAll();

                      navigate(
                        `/order/status/${pOrder.code}?payment_status=${resp.data.status}`
                      );

                    }
                  })
                  .catch((err) => {
                    message.error(err);
                    setloadingPinC(false);
                  });
                mainInputRef.current.clear();
              }}
              secret
              style={{
                marginTop: "10px",
                borderRadius: "5px",
                borderColor: "green",
              }}
              type="numeric"
              inputMode="numeric"
              focus
              length={4}
            />
          </>
        );
      };
      const PinContent = () => {
        const [loadingPinC_, setloadingPinC_] = useState(false);
        const [attempts, setAttempts] = useState(0);
        const [lastEntered, setLastEntered] = useState(null);
        const [mTitle, setMTitle] = useState("Create 4 digit Pin");

        const createInputRef = useRef(null);
        const [hasPin, setHasPin] = useState(
          pOrder ? (pOrder.user ? pOrder.user.wallet_has_pin : false) : false
        );
        return (
          <Spin spinning={loadingPinC_} tip="processing...">
            {pOrder.user.wallet_has_pin === true || hasPin === true ? (
              <PaymentConfirmedPinContent
                loadingPinC={loadingPinC_}
                setloadingPinC={setloadingPinC_}
              />
            ) : (
              <>
                <h4>{attempts === 1 ? "Confirm Pin" : mTitle}</h4>

                <PinInput
                  autoSelect={true}
                  type="numeric"
                  inputMode="numeric"
                  onComplete={(val) => {
                    setloadingPinC_(true);

                    // pinModal.destroy();
                    if (attempts === 0) {
                      setLastEntered(val);

                      setAttempts(attempts + 1);

                      // setTimeout(() => {
                      // paymentRefButtonRef.current.click();
                      // }, 700);
                      createInputRef.current.clear();

                      return setloadingPinC_(false);
                    }

                    if (attempts === 1) {
                      if (lastEntered === val) {
                        client
                          .put("/user/api/wallet", {
                            method: "create_pin",
                            pin: val,
                          })
                          .then((resp) => {
                            // console.log(resp)
                            if (resp.data.status === "error") {
                              setAttempts(0);
                              setloadingPinC_(false);
                              createInputRef.current.clear();

                              return notification.error({
                                message: resp.data.msg,
                              });
                            }
                            if (resp.data.status === "successful") {
                              createInputRef.current.clear();

                              setHasPin(true);
                              // pinConfirmModal.destroy()

                              setMTitle(
                                "Pin created. Enter Pin to proceed with payment"
                              );
                              setloadingPinC_(false);

                              notification.success({ message: resp.data.msg });

                              return true;
                            }
                          })
                          .catch((err) => {
                            message.error(err);
                            setAttempts(0);
                          });
                      } else {
                        setLastEntered("");

                        setTimeout(() => {
                          // paymentRefButtonRef.current.click();
                          setloadingPinC_(false);
                        }, 1000);
                        message.error("Pin do not match! try again");
                        createInputRef.current.clear();

                        return setAttempts(0);
                      }
                    }
                    // setTimeout(() => {
                    // paymentRefButtonRef.current.click();
                    // }, 1000);
                    createInputRef.current.clear();

                    return setAttempts(attempts);
                  }}
                  focus
                  length={4}
                  ref={createInputRef}
                  style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    borderColor: "green",
                  }}
                />
              </>
            )}
          </Spin>
        );
      };
      const nonce = Math.floor(Math.random() * 9999) + 1;
      const timestamp = Date.now().toString().replace(".", "");
      const user_id = pOrder.user.id;
      const user_username = pOrder.user.username;
      const comb = String(
        String(nonce) +
          String(timestamp) +
          String(user_username) +
          String(user_id)
      ).toLowerCase();
      const sign = md5(comb).toString();
      const order_id = pOrder.id;
      const callbackJSON = {};
      callbackJSON["nonce"] = nonce;
      callbackJSON["timestamp"] = timestamp;
      callbackJSON["trx_type"] = "debit";

      callbackJSON["sign"] = sign;
      callbackJSON["user_id"] = user_id;
      callbackJSON["user_username"] = user_username;
      callbackJSON["order_id"] = order_id;
      callbackJSON["api_key"] =APP_ENV.PLATFORM_WALLET_API_KEY;
      callbackJSON["payment_type"] =props?.advertData ? "AD": "P" ;
      callbackJSON["smmData"] = props.smmOrderData
      callbackJSON['advertData'] = props.advertData
      callbackJSON['total_amount'] = pOrder.total_amount
      const pinModalConfig = {
        title: (
          <p style={{ fontFamily: "sans" }}>
            {" "}
            {pOrder.user.wallet_has_pin === true
              ? "Authorize Payment"
              : "Create Pin and Authorize Payment"}
            ({pOrder.total})
          </p>
        ),
        icon: <Pin />,
        confirmLoading: loading,
        content: (
          <>
            <Box
              style={{
                padding: "20px",
                fontFamily: "sans",
                textAlign: "center",
              }}
            >
              <PinContent />
            </Box>
          </>
        ),
        okButtonProps: { style: { display: "none" }, danger: true },
        onCancel: () => {
          message.info("Payment cancelled");
        },
      };
      const pinModal = Modal.confirm({ ...pinModalConfig });
    }
  };

  const couponServices = (val) => {
    client
      .post("/social/api/coupon/" + val.code, val)
      .then((resp) => {
        if (resp.data.error === true) {
          return message.error(resp.data.msg);
        }
        props?.updateParentOrder();
        updateStates();
        message.success(resp.data.msg);
      })
      .catch((err) => message.error("Something went wrong"));
  };

  return (
    <Spin tip="loading.." style={{ zIndex: "10px" }} spinning={loading}>
      <div
        style={{
          textAlign: "center",
          margin: "0 auto",
          objectFit: "contain",
          // marginLeft: window.screen.width >= 600 ? "0" : "90px",
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "grid",
            justifyContent: "center",
            float: "left",
          }}
        >
          <Form
            onFinish={(val) => {
              val["action"] = "add_to_order";
              val["order_code"] = pOrder.code;

              couponServices(val);
            }}
          >
            <Form.Item
              style={{
                width: "60%",
                marginBottom: "10px",
                display: "inline-block",
                margin: "0",

                padding: "0",
              }}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Please Enter Coupon Code",
                },
              ]}
              requiredMark={""}
              name={"code"}
            >
              <Input placeholder="Coupon Code" bordered={false}></Input>
            </Form.Item>
            <Form.Item
              style={{
                width: "40%",
                display: "inline-block",
                margin: "0",
                
                marginBottom: "10px",
                padding: "0",
              }}
            >
              <Input
                typeof="submit"
                bordered={false}
                type="submit"
                
                value={"Apply Coupon"}
              ></Input>
            </Form.Item>
          </Form>

          {pOrder ? (
            pOrder.coupon ? (
              <Box className="coupons">
                <div
                  style={{
                    // padding: "10px",
                    margin: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    // height: "100px",
                    paddingRight: "10px",
                    backgroundColor: "transparent",
                    boxShadow: "1px 0.2px 4px 0.3px",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      width: "50%",
                      fontSize: "16px",
                      color: "orange",
                    }}
                    className="coupon-name"
                  >
                    <b>{pOrder.coupon.code}</b>
                    {pOrder.coupon.expired == true ? (
                      <Tag style={{ color: "red", borderColor: "red" }}>
                        Expired
                      </Tag>
                    ) : (
                      " "
                    )}
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "40%",
                      fontFamily: "fantasy",
                      color: "green",
                    }}
                    className="coupon-price"
                  >
                    <b>
                      {pOrder.coupon.amount
                        ? pOrder.coupon.amount_currency +
                          "" +
                          pOrder.coupon.amount
                        : pOrder.coupon.percentage + "%"}
                    </b>
                  </div>
                  <div
                    className="coupon-actions"
                    style={{
                      display: "inline-block",
                      width: "10%",
                      fontSize: "15px",
                      cursor: "pointer",

                      ".couponCancel:hover": {
                        backgroundColor: "red",
                        color: "white",
                      },
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        couponServices({
                          action: "delete_from_order",
                          code: pOrder.coupon.code,
                          order_code: pOrder.code,
                        })
                      }
                      className="couponCancel"
                    >
                      <Cancel />
                    </IconButton>
                  </div>
                </div>
              </Box>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Box>
        <Button
          ref={paymentRefButtonRef}
          onClick={() => handleOrderWalletPayment()}
          style={{
            backgroundColor: "rgba(122,200,120,1)",
            // left: "75px",
            width: "inherit",
            color: "whitesmoke",
            padding: "15px",
          }}
          disabled={props?.disable}
        >
          <Payment /> Pay With Wallet{" "}
          {pOrder.user ? pOrder.user.wallet : "loading..."}
        </Button>
      </div>
    </Spin>
  );
}
