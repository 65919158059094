import React, { useRef } from "react";
import { Avatar, Carousel, Empty, Image,List as antList, message, Modal, Select, Rate,  Spin, Tag, Form } from "antd";

import {Container,Box,Card,Button ,Slider,FormGroup, IconButton, Badge, Divider, List } from '@mui/material';
import { client } from "./app_context";
import { Check, Clear, Error, Favorite, FavoriteBorder, LinkRounded, Remove, StarRate } from "@mui/icons-material";
import { useState } from "react";
import { styled } from "@mui/system";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSocialAvatar } from "./app_context";


const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

function ProductDetail (props) {
  
  const [loading, setloading] = useState(true)
  const [product, setproduct] = useState({})
  const carouselRef = useRef()
  const [quantity, setquantity] = useState(product.qty_in_userCart?product.qty_in_userCart : 1)
  const [selectedCartProducts, setSelectedCartProducts] = useState([])
  const [subProducts, setSubProducts] = useState([])
  const {Option} = Select
  const [selectForm] = Form.useForm()
  const params = useParams()
  const navigate = useNavigate()
  
  const fetchData=()=>{
  if (params.id){
  const product_slug = params.slug;
  const product_id = params.id
      client
        .get(`/user/api/p/${product_slug}/${product_id}`)
        .then((resp) => {
          setproduct(resp.data);
          setloading(false);
          setSelectedCartProducts(resp.data.cartedSubProducts)
  if (resp.data.sub_products) {
    setSubProducts(resp.data.sub_products);
  };

        })
        .catch((err) => message.error("Unable to complete fetch"));
        
  
  }
  }
  React.useEffect(() => {
      fetchData()
  
  }, [])
   const handleSelectProducts =(val)=>{
   setloading(true);
    client
      .get("/auth/users/me/")
      .then((response) => {
        if (response.data) {
          if (response.data.username) {
            client
              .post(`/user/api/select-cart-product`, {
                sub_product_ids: val,
                product_id:product.id
                
              }).then((resp) => {
                setSelectedCartProducts(resp.data.sub_product_ids); 
                props.headerUpdater.current.updateAll();
                message.success("Products Updated");
                fetchData();
                setloading(false);
              });
          }
        }
      })
      .catch((err) => {
        message.error("please login!");
        setloading(false);

      });
   };
    const addToWish = (id) => {
       // alert(e.target.getAttribute('dataId'))
       client
         .get("/auth/users/me/")
         .then((response) => {
           if (response.data) {
             if (response.data.username) {
               client
                 .get(
                   `/social/api/add-to-wishlist/${response.data.username}/${id}`
                 )
                 .then((resp) => {
                   
                 });
             }
           }
           props.headerUpdater.current.updateAll();
          message.success("Wishlist Updated");
           fetchData();
         })
         .catch((err) => {
           message.error("please login!");
         });
     };
  
   const addToCart = (id) => {
    // alert(e.target.getAttribute('dataId'))
    setloading(true)
    client
      .get("/auth/users/me/")
      .then((response) => {
        if (response.data) {
          if (response.data.username) {
            client
              .get(
                `/social/api/add-to-cart/${response.data.username}/${id}?quantity=${quantity}`
              )
              .then((resp) => {
                if (resp.data.msg) {
                  message.info(resp.data.msg);
                }
                setSelectedCartProducts(resp.data.sub_products);
                selectForm.setFields([
                  { name: "sub_products", value: resp.data.sub_products },
                ]);
                message.success("Cart Updated!");
                props.headerUpdater.current.updateAll();
                fetchData();
              })
              .catch((err) => setloading(false));
          }
        }
      })
      .catch((err) => {
        message.error("something went wrong");
                         setloading(false);

      });
  };
   const handleCartSlider = (id) => {
     // alert(e.target.getAttribute('dataId'))
     setloading(true)
     client
       .get("/auth/users/me/")
       .then((response) => {
         if (response.data) {
           if (response.data.username) {
             client
               .get(
                 `/social/api/add-to-cart/${response.data.username}/${id}?quantity=${quantity}`
               )
               .then((resp) => {
                 if (resp.data.msg) {
                   // message.info(resp.data.msg);
                 }
                 setSelectedCartProducts(resp.data.sub_products);
                 selectForm.setFields([
                   { name: "sub_products", value: resp.data.sub_products },
                 ]);
                 props.headerUpdater.current.updateAll();
                 fetchData();
                 setloading(false);
                 //  message.success("Cart Updated!");
               })
               .catch((err) => setloading(false));
           }
         }
       })
       .catch((err) => {
                        setloading(false);

        //  message.error("something went wrong");
       });
   };
   
  
  const PrettoSlider = styled(Slider)({
    color: "#52af77",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  return (
    <Spin spinning={loading}>
      {product.id ? (
        <Container>
          <Box
            className={"image-cont"}
            sx={{
              float: {
                md: "left",
              },
              height: { md: "130vh" },
              width: { md: "56%", margin: "10px", xs: "100%" },
            }}
          >
            <Box
              sx={{
                padding: "20px",
                textAlign: "center",
                objectFit: "contain",
                width: "100%",
                height: { md: "600px", xs: "450px" },
                maxHeight: { md: "550px", xs: "450px" },
                maxWidth: "100%",
                backgroundColor: "whitesmoke",
              }}
            >
              {product.images.length >= 1 ? (
                <>
                  <Carousel
                    dots={false}
                    style={{ height: "400px", overflow: "hidden" }}
                    ref={carouselRef}
                    autoplay
                  >
                    {product.images.map((img, index) => (
                      <div key={index}>
                        <Image
                          height={400}
                          alt={"product " + product.id}
                          src={img.url}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <Box
                    sx={{
                      position: "relative",
                      marginTop: { md: "20px", xs: "-50px" },
                      backgroundColor: "whitesmoke",
                      overflow: "hidden",
                      display: "block",
                    }}
                  >
                    {product.images.map((img, index) => (
                      <div
                        onClick={() => carouselRef.current.goTo(index)}
                        key={index}
                        style={{
                          margin: "10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          border: "0.3px solid grey",
                          padding: "10px",
                          height: 50,
                          width: 50,
                          overflow: "hidden",
                          display: "inline-block",
                        }}
                      >
                        <img
                          width={50}
                          style={{ objectFit: "cover" }}
                          alt={"product " + product.id}
                          src={img.url}
                        />
                      </div>
                    ))}
                  </Box>
                </>
              ) : (
                <>
                  <center>
                    <Empty> 0 Images</Empty>
                  </center>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "left",
              width: { md: "38%", xs: "100%" },
              float: { md: "right" },
            }}
            className="text-contents"
          >
            <div style={{ paddingTop: "20px", textTransform: "capitalize" }}>
              <h1>{product.title}</h1>
              <Tag
                style={{
                  float: "right",
                  fontSize: "10px",
                  color: "white",
                  top: "0",
                  padding: "5px",
                  margin: "10px",
                  right: "0",
                  backgroundColor: "rgba(180,194,70,0.9)",
                }}
              >
                <span>
                  {" "}
                  {product.category} / {product.socialMedia_name}{" "}
                  {getSocialAvatar(product.socialMedia)}{" "}
                </span>
              </Tag>
              <div>
                <h2 style={{ fontWeight: "bolder", fontSize: "30px" }}>
                  {product.price}{" "}
                </h2>

                {product.perc_discount ? (
                  <h4 style={{ opacity: "0.3" }}>
                    {" "}
                    <strike> {product.actual_price}</strike>
                  </h4>
                ) : (
                  ""
                )}
                {product.perc_discount ? (
                  <Tag
                    style={{
                      position: "absolute",
                      fontWeight: "bolder",
                      fontSize: "15px",
                      backgroundColor: "rgba(160,190,200,0.8)",
                      color: "whitesmoke",
                      borderRadius: "10%",
                      top: "0",
                      right: "0",
                      margin: "10px",
                      padding: "10px",
                    }}
                  >
                    {product.perc_discount}% off
                  </Tag>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Divider />
            <div
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
              className="descr-Box"
            >
              <Button
                style={{
                  color: "orange",
                  margin: "5px",
                  float: "right",
                  fontSize: "14px",
                }}
                onClick={() => {
                  Modal.info({
                    title: "Preview Products",
                    icon: <LinkRounded />,
                    content: (
                      <>
                        {" "}
                        <antList>
                          {product.preview_links
                            ? product.preview_links.map((item) => (
                                <antList.Item style={{ padding: "10px" }}>
                                  <Divider />
                                  <p style={{ float: "right" }}>
                                    Product ID : <b>{item.product_id}</b>
                                  </p>{" "}
                                  <a target="_blank" href={item.url}>
                                    <LinkRounded />
                                    {item.url}
                                  </a>{" "}
                                  <Divider />
                                </antList.Item>
                              ))
                            : "No item to show"}{" "}
                        </antList>
                      </>
                    ),
                  });
                }}
              >
                Preview
              </Button>
              <p>{product.description}</p>
            </div>
            <Divider />
            <div
              className="features"
              style={{
                marginBottom: "20px",
                padding: "10px",
                marginTop: "20px",
                backgroundColor: "whitesmoke",
              }}
            >
              <b>Features</b>
              <span
                style={{
                  backgroundColor: "white",
                  float: "right",
                  top: "0",
                  border: "2px solid whitesmoke",
                  borderRadius: "5px",
                  padding: "5px",
                  fontSize: "13px",
                  color: "gainsboro",
                }}
              >
                {product.age}
              </span>
              {product.features.length >= 1 ? (
                <>
                  {product.features.map((item) => (
                    <List>
                      {" "}
                      <p>
                        <Check /> {item}
                      </p>
                    </List>
                  ))}
                </>
              ) : (
                <center>No features</center>
              )}
            </div>
            <PrettoSlider
              aria-label="Quantity To buy"
              max={product.available_quantity}
              valueLabelDisplay="on"
              // valueLabelFormat={'qty'}
              min={1}
              onChange={(e) => {
                setquantity(e.target.value);
                handleCartSlider(product.id);
              }}
              defaultValue={product.userCartItem ? product.qty_in_userCart : 0}
            />
            <small>
              {product.available_quantity}left of {product.total_quantity} Qty
            </small>
            <Box>
              <Form form={selectForm}>
                <Form.Item
                  name={"sub_products"}
                  initialValue={product.cartedSubProducts}
                >
                  <Select
                    multiple
                    // defaultValue={1}
                    mode="multiple"
                    placeholder="Select products for cart"
                    style={{
                      width: "100%",
                      padding: 10,
                      boxShadow: "0px 2px 1px rgba(170,259,92,0.5)",
                    }}
                    allowClear={false}
                    loading={loading}
                    onChange={(val) => {
                      setSelectedCartProducts(val);
                      handleSelectProducts(val);
                    }}
                  >
                    {subProducts.map((item) => (
                      <Option
                        style={{ color: "green" }}
                        value={item.product_id}
                      >
                        @<b>{item.username}</b>
                        <small style={{ float: "right" }}>
                          ID : {item.product_id}
                        </small>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Box>
            <div className="btns" style={{ padding: "20px" }}>
              {product.userCartItem ? (
                <>
                  <Badge
                    badgeContent={product.qty_in_userCart}
                    color="success"
                  ></Badge>
                  <Button
                    className="add-tocartBtn"
                    style={{
                      display: "inline-block",
                      backgroundColor: "rgba(170,259,92,0.5)",
                      color: "green",
                      width: "80%",
                    }}
                    onClick={() => addToCart(product.id)}
                  >
                    Add to Cart
                  </Button>
                </>
              ) : (
                <Button
                  className="add-tocartBtn"
                  style={{
                    display: "inline-block",
                    backgroundColor: "rgba(170,259,92,0.5)",
                    color: "green",
                    width: "80%",
                  }}
                  onClick={() => addToCart(product.id)}
                >
                  Add to Cart
                </Button>
              )}
              {product.userFavourite ? (
                <IconButton
                  style={{ display: "inline-block", width: "20%" }}
                  onClick={() => {
                    addToWish(product.id);
                  }}
                >
                  <Favorite />
                </IconButton>
              ) : (
                <IconButton
                  style={{ display: "inline-block", width: "20%" }}
                  onClick={() => {
                    addToWish(product.id);
                  }}
                >
                  <FavoriteBorder />
                </IconButton>
              )}
            </div>
            <Divider>Product Seller</Divider>
            <div id="seller-details">
              <small style={{ position: "absolute", marginTop: "-10px" }}>
                overall rating
              </small>
              <Rate
                style={{ fontSize: "12px" }}
                disabled
                value={product.seller_rating}
              />

              <Link to={"/profile/" + product.seller}>
                <div style={{ float: "right" }}>
                  <p>
                    {" "}
                    {product.seller}
                    <Avatar
                      src={product.seller_pic}
                      style={{ marginLeft: "10px", top: "-10px" }}
                    >
                      {" "}
                      {product.seller}
                    </Avatar>
                  </p>
                </div>
              </Link>
              <Divider />
            </div>
          </Box>
        </Container>
      ) : (
        <>
          <Empty />
        </>
      )}
    </Spin>
  );
}
export default ProductDetail;
