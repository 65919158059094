import React, { Component } from 'react'
import {
  IconButton,
  FormControl,
  Button,
  FormLabel,
  TextField,
  Input,
  Box,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { createRef, createContext } from 'react';
import contactBg from "../../assets/images/contact-bg.png";
import msgSentIcon from "../../assets/images/message-sent.png";
import AppContext from "../app_context";
import {client} from '../app_context'
import { message,Spin } from 'antd';




export default class ContactUs extends Component {
  constructor(props){
  super(props)
  this.state = {
  contactFormRef  : createRef() ,
  messageSent: false,
  loading:true,

  }
  }
  
  componentDidMount(){

  this.state.contactFormRef.current.addEventListener('submit', (e)=>{
  e.preventDefault();
  this.setState({ loading: true });

  const contactFormData = new FormData(this.state.contactFormRef.current);
  client.post(this.state.contactFormRef.current.getAttribute('action'),
  contactFormData ).then(resp => {

  if (resp.data.saved === true) {
    this.setState({messageSent:true});

  }
  else{
  message.error('something went wrong')
  }
    this.setState({ loading: false });

  
  }).catch(err => {message.error('something went wrong');  this.setState({ loading: false });
  });
  
  });
  
  
  this.setState({loading:false})
  }

  
  InputStyle ={
  display:'block',
  margin:'5px',
  width:'100%',
  }
    render() {
        return (
        <Spin spinning={this.state.loading}>
          <div style={{ margin: "10%" }}>
            <Box
              sx={{ width: { md: "30%", xs: "100%" },height: {xs:'200px',md:"400px"} , backgroundSize:{xs:'50%', md:'100%'} }}
              style={{
                display: "inline-block",
                backgroundRepeat: "no-repeat",
                
                
                backgroundImage: `url(${contactBg})`,
              }}
            ></Box>

            <Box
              sx={{ width: { md: "60%", xs: "100%" } }}
              style={{ display: "inline-block", float: "right" }}
            >
            
            {this.state.messageSent === false?
            
           <>
              <h1 style={{ color: "#39483a" }}>We'd love to hear from you</h1>
              <h3>Contact us</h3>

              <Box>
                <form
                  method="post"
                  action="/user/api/contact-us"
                  ref={this.state.contactFormRef}
                >
                  <FormControl style={this.InputStyle} component="fieldset">
                    <FormLabel component="legend">Full Name</FormLabel>
                    <FormGroup title="full name">
                      <Input
                        name="full_name"
                        required
                        type="text"
                        placeholder="Full name (e.g chris james)"
                      ></Input>
                    </FormGroup>
                    <FormHelperText>Enter full Name</FormHelperText>
                  </FormControl>

                  <FormControl style={this.InputStyle} component="fieldset">
                    <FormLabel component="legend">Email</FormLabel>
                    <FormGroup title="email">
                      <Input
                        name="email"
                        required
                        type="email"
                        placeholder="Email (e.g chris100@gmail.com)"
                      ></Input>
                    </FormGroup>
                    <FormHelperText>Please Enter Email</FormHelperText>
                  </FormControl>

                  <FormControl style={this.InputStyle} component="fieldset">
                    <FormLabel component="legend">Your message</FormLabel>
                    <FormGroup>
                      <TextField
                        size="1000"
                        name="message"
                        multiline
                        required
                        placeholder="message"
                      ></TextField>
                    </FormGroup>
                    <FormHelperText>Please Enter A Message </FormHelperText>
                  </FormControl>

                  <FormControl component="button">
                    <FormGroup>
                      <Button style={{ width: "300px" }}>Submit</Button>
                    </FormGroup>
                  </FormControl>
                </form>
              </Box></>
              :
              <>
              <Box style={{textAlign:'center'}}>
              <img width='100' src={msgSentIcon}></img>
           <b>   <h3>Message delivered</h3></b>
              <p>Your message have been sent to us, we will respond if needed... Thank you for your time!</p>
            </Box>
            </>
              }
            </Box>
          </div>
          </Spin>
        );
    }
}
