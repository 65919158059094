import { Bookmark, BookmarkBorderOutlined } from '@mui/icons-material'
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Paper,
  Typography,
  alpha,
} from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { interactInfluencerPage } from '../../redux/influencer/thunks'
import { InfluencerAvatar } from './avatar'

export default function InfluencerCard({ influencer }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleBookmarkInfluencer =()=>{

    dispatch(interactInfluencerPage({slug:influencer.slug, data:{action:'bookmark'}}))
  }

  return (
    <>
      <Card
        sx={{
          maxWidth: { md: '300px', xs: 'auto' },
          // height: { md: '47vh', xs: '39vh' },
          margin: { md: '10px', xs: '2px' },
        }}
      >
        <CardActionArea  onClick={() =>
                navigate('/influencers/' + influencer.slug)
              }>
          
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignContent: 'center',
              gap: 5,
              alignItems: 'center',
              marginTop: '5px',
              alignSelf: 'center',
            }}
          >
          <InfluencerAvatar src={influencer.display_image} sx={{
            width: { md: '150px', xs: '100px' },
            height: { md: '150px', xs: '100px' },
          }} isVerified={influencer.is_verified} />

            <Typography
              variant="button"
              sx={{ fontSize: { xs: '12px', md: '' } }}
              height={'20px'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
            >
              {influencer.display_name}
            </Typography>
          </div>
          <CardContent sx={{ marginTop: '-14px' }}>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h6" component={'div'}>
                {influencer.total_audience}
              </Typography>{' '}
              <Typography style={{ opacity: 0.5 }} variant="caption">
                Audience
              </Typography>
            </div>
            {/* <Divider /> */}
            <Paper
             
              sx={{
                backgroundColor: alpha('#3c4f47', 0.2),
                minHeight: '50px',
                maxHeight: { xs: '55px', md: '70px' },
                overflowY: 'hidden',

                padding: '5px',
              }}
            >
              <Typography
                variant="overline"
                sx={{ display: 'block', fontSize: { md: '12px', xs: '8px' } }}
              >
                {' '}
                Available on :{' '}
              </Typography>
              {influencer.mediums.map(md => (
                <Avatar
                  style={{
                    borderRadius: 0,
                    // margin: '2px',
                    width: 20,
                    height: 20,
                    display: 'inline-block',
                    // marginBottom: -5,
                    marginRight: 5,
                  }}
                  variant="overline"
                  alt={md.medium.name}
                  title={md.medium.name}
                  src={md.medium.logo}
                />
              ))}{' '}
            </Paper>
          </CardContent>

        </CardActionArea>
        <CardActions sx={{ marginTop: '-14px', objectFit:'contain' }}>
          <IconButton sx={{padding:{xs:'5px', md:'initial'}}} onClick={handleBookmarkInfluencer}>
            {influencer.viewer_bookmarked? <Bookmark/>: <BookmarkBorderOutlined/>}
          </IconButton>
          <Button
            onClick={() => navigate('/influencers/' + influencer.slug + '/order')}
            variant="contained"
            fullWidth
            sx={{fontSize:{xs:'10px', md:'initial'}}}
          >
            Advertise
          </Button>
        </CardActions>
      </Card>
    </>
  )
}
