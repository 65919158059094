import axios from 'axios'

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import EditProfile from './sellerEdit';
import SellerWelcome from './sellerWelcome';
import { useRef, forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductsUploadPage from './productsUpload';
import { Divider, message, Spin } from 'antd';
import UserModel from '../models';
import { useState } from 'react';
import { List,ListItem } from '@mui/material';
import APP_ENV from '../../env';
const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json",

    "Authorization": `Token ${localStorage.getItem('AccgToken')}`


};

if (localStorage.getItem('AccgToken') !== null) {
    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


})
const userInst = new UserModel(null, null)

const steps = ['Legal Agreement', 'Edit Account', 'Start Selling'];
export default forwardRef(function HorizontalLinearStepper(props, ref) {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [seller, setSeller] = React.useState(false)
    const [UserProfile, setUSerProfile] = React.useState({});
    const [User, setUser] = React.useState(userInst);
    const [loading, setloading] = useState(true)


    useImperativeHandle(ref, () => ({


        updateUserProfile() {
            client.get('/user/api').then(response => {
                if (response.data) {
                    // setUSerProfile(response.data)
                    setSeller(response.data.seller)
                }
            }).catch(err => { console.log(err) })
        }

    }),


    )

    function updateUserProfile() {
        setloading(true);
        client.get('/auth/users/me/').then(response => {
            if (response.data) {

                client.get('/user/api/profile/' + response.data.username).then(response => {
                    if (response.data) {
                        setUSerProfile(response.data)
                        setSeller(response.data.seller)
                    }
                }).catch(err => { message.error(err) })

                const reqUser = new UserModel()
                reqUser.username = response.data.username;
                reqUser.email = response.data.email;
                reqUser.isAuth = true;


                setUser(reqUser)
                setloading(false);

            }

        }).catch(error => {
            message.error('Please signup/login')
            setloading(false);

            // alert(error)
        })
        props.headerUpdater.current.updateAll()

    }
    React.useEffect(function () {

        updateUserProfile()

    }, [])

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
    setloading(true)
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        client.get('/user/api/profile/' + User.username).then(response => {
            if (response.data) {
                setUSerProfile(response.data)
                setSeller(response.data.seller)
            }
            setloading(false)

        }).catch(err => { message.error(err);                setloading(false);
 })
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
                    setloading(true);

        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
                        setloading(false);

    };

    const handleReset = () => {
                    setloading(true);

        setActiveStep(0);

        client.post('/user/api/profile/' + User.username, { seller: false }).then(response => {
                setloading(false);

        }).catch(err => { console.log(err);                setloading(false);
 })
    };
    const handleFinish = () => {
                    setloading(true);

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        client.post('/user/api/profile/' + User.username, { seller: true }).then(response => {
                setloading(false);

        }).catch(err => { console.log(err);                setloading(false);
})

    }

    const AgreementText =()=>  {
    return (
           <Box className="textContent" style={{ padding: "20px" }}>
           <h3>By Nexting, You Agree to these Terms :</h3>

            <b>What does the store undertake?</b>
            <List>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Automatic sale of your accounts at your price.
                  <br />
                  <b>* 10% is for us , and 90% is for you the seller</b>
                </li>
              </ListItem>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Comprehensive advertising of the store and your products in
                  search engines, contextual advertising, forums, etc. * We
                  invest millions of Naira in advertising monthly.{" "}
                </li>
              </ListItem>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Communication with customers, problem-solving. * There is no
                  payment for refunded account , i.e a faulty account
                </li>
              </ListItem>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Acceptance of payments. * We accept payment from and payment
                  to all Nigerian banks.
                </li>
              </ListItem>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Withdrawals/payouts. * We charge 5% for withdrawal
                </li>
              </ListItem>
            </List>
            <b>What is required of you?</b>
            <List>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Periodically upload accounts to the store.{" "}
                </li>
              </ListItem>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Monitoring the performance of uploaded accounts.
                </li>
              </ListItem>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Click the "withdraw" button.
                </li>
              </ListItem>
            </List>
            <b>How to start working with us?</b>
            <h3>Very Eassyy!</h3>
            <List>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Register as a user, switch on seller mode on the side menu,
                  Fill out the application form and start uploading accounts.
                </li>
              </ListItem>
              <ListItem>
                <li style={{ listStyleType: "disc" }}>
                  Upload accounts to the store and wait for approval of your
                  request. If the request is rejected, you will see the reason.
                  If your request is approved, the account will be uploaded on
                  the site{" "}
                </li>
              </ListItem>
            </List>
            <h3>Note:</h3>
            <p>
              Account gig immediately blocks the identified accounts and access
              to them if its support detects that the Users, selling the
              accounts on the website, have more than one account. And all the
              funds of the User balance are transferred to Account gig as
              penalties and are not refundable.
            </p>{" "}
            <br />
            <p>
              Login details of the accounts should be secured until it’s bought
              , incorrect login details or charged logins details can lead to a
              request of a change or no payment if there is no identical account
              .{" "}
            </p>
            <br />
            <p>
              If complain keeps coming from buyers about accounts sold by you ,
              we may be forced to stop you from selling .
            </p>
            <Divider style={{ width: "70%" }}>
              <p>Last update: January 30th, 2023.</p>
            </Divider>
          </Box>)
    
                }
        
    
    const readyPage = () => {

    }
    return (
    <Spin spinning={loading}>
        <>
        
            {!seller === true ? (
                <React.Fragment>
                    <CssBaseline />
                    <Container maxWidth="sm">
                        <Box sx={{ width: '100%', height: 'auto', marginBottom: '30px', marginTop: '20px', padding: '20px', borderRadius: '4px' }
                        } >
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepOptional(index)) {
                                        labelProps.optional = (
                                            <Typography variant="caption">Optional</Typography>
                                        );
                                    }
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {
                                activeStep === steps.length ? (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            Activation Complete -You are now a Seller!.

                                            <Box sx={{ width: '100%', height: 'auto', marginBottom: '30px', marginTop: '20px', padding: '20px', borderRadius: '4px' }
                                            } >
                                                <Button variant="contained" color="success" onClick={updateUserProfile}>
                                                    Upload Product
                                                </Button>
                                            </Box>
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            <Button onClick={handleReset}>Reset</Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>



                                            {activeStep === 0 ? <AgreementText /> : ""}
                                            {activeStep === 1 ? <EditProfile userProfile={UserProfile} /> : ""}
                                            {activeStep === 2 ? <SellerWelcome full_name={UserProfile.full_name} display_picture={UserProfile.display_picture} bio={UserProfile.bio} seller_category={UserProfile.seller_category} /> : ""}


                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            {isStepOptional(activeStep) && (
                                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                    Skip
                                                </Button>
                                            )}

                                            <Button onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        </Box>
                                    </React.Fragment>
                                )
                            }
                        </Box ></Container></React.Fragment>
            ) :

                <ProductsUploadPage />
            }
        </>
        </Spin>
    );
}
)