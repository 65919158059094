

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import axios from "axios";
import { Box } from '@mui/system';
import ProductCard from './ProductCard';
import { Empty, Row, Col, Spin, message, Divider, Pagination } from 'antd';
import { useState } from 'react';
import ProductFilter from './productFilter';
import SearchHighlights from './layouts/sarchInput';
import { Button } from 'antd';
import { FilterAlt } from '@mui/icons-material';
import { Drawer, Toolbar } from '@mui/material';
import APP_ENV from '../env';

const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
  "Content-Type": "application/json",

};
if (localStorage.getItem('AccgToken') !== null) {
  reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}
const client = axios.create({
  baseURL: BASE_URL,
  headers: reqHeaders


})
const authClient = axios.create({
  baseURL: BASE_URL,


}
)
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));





const AccountPosts = (props, ref) => {

  const [SocialAccounts, setSocialAccounts] = React.useState([])

  const [expanded, setExpanded] = React.useState(false);
  const [currentItemId, setCurrentItemId] = React.useState(null);
  const [loading, setloading] = useState(true)
  const [mobileFilter, setmobileFilter] = useState(false)
  const [currentFilters, setcurrentFilters] = useState([])

  const [page, setPage] = React.useState(1)
  const [itemsCount, setItemsCount] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(24)

  const [itemsURL, setItemsURL] = useState("/social/api/accountsCustom?page=" + page + "&pageSize=" + pageSize)
  const getData = () => {

    client
      .get(itemsURL)
      .then((response) => {
        // console.log(response)
        setSocialAccounts(response.data.results);
        setItemsCount(response.data.count)
        setloading(false);
      })
      .catch((err) => {
        // alert(err)
        message.error("Error getting products");
        setloading(false);
      });

  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ApplyFilter = (parameters) => {
    var newList = currentFilters
    if (parameters.key !== 'price') {
      if (currentFilters.length >= 1) {

        newList = currentFilters.filter(
          (item) => !(item.value === parameters.value && item.key === parameters.key)
        );
        newList.push(parameters)

      } else {
        newList.push(parameters);
      }
    }
    else {

      newList = newList.filter(item => !(item.key === parameters.key));
      newList.push(parameters)

    }
    setcurrentFilters(newList)
    setloading(true)
    client.post("user/api/product-filter", { params: newList }).then((resp) => {
      setSocialAccounts(resp.data)
      setloading(false);

    }).catch(err => {
      setloading(false);
      message.error('invalid effect')
    });
  }

  React.useEffect(function () {
    getData()

    return;



  }, []


  )
  const capture = async () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const video = document.createElement("video");

    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia();
      video.srcObject = captureStream;
      context.drawImage(video, 0, 0, window.width, window.height);
      const frame = canvas.toDataURL("image/png");
      captureStream.getTracks().forEach(track => track.stop());
      window.location.href = frame;
    } catch (err) {
      console.error("Error: " + err);
    }
  };


  const handlePage = (val) => {

    setloading(true)
    setItemsURL(`/social/api/accountsCustom?page=${val}&pageSize=${pageSize}`)
    setPage(val)
    client
    .get(`/social/api/accountsCustom?page=${val}&pageSize=${pageSize}`)
    .then((response) => {
      // console.log(response)
      setSocialAccounts(response.data.results);
      setItemsCount(response.data.count)
      setloading(false);
    })
    .catch((err) => {
      // alert(err)
      message.error("Error getting products");
      setloading(false);
    });

  }
  const handlePageSize = (val) => {
    setloading(true)

    // setItemsURL(`/social/api/accountsCustom?page=${page}&pageSize=${val}`)
    setPageSize(val);
    client
    .get(`/social/api/accountsCustom?page=${page}&pageSize=${val}`)
    .then((response) => {
      setSocialAccounts(response.data.results);
      setItemsCount(response.data.count)
      setloading(false);
      document?.getElementById("product-frame").scrollIntoView()

    })
    .catch((err) => {
      // alert(err)
      message.error("Error getting products");
      setloading(false);
    });

  }

  const handleFilterReset = () => {

    getData()
  }

  return (
    <>
      <Spin spinning={loading} tip="Loading Products...">
        <Box sx={{ display: { md: "none", xs: "block" } }}>
          <IconButton onClick={() => setmobileFilter(true)}>
            <FilterAlt /> <small style={{ fontSize: "12px" }}>Filter</small>
          </IconButton>

          <Drawer
            anchor="right"
            open={mobileFilter}
            sx={{ display: { md: "none", xs: "block" } }}
            type="primary"
            title="Filter Products"
            onClose={() => setmobileFilter(false)}
          >
            <Toolbar
              style={{
                backgroundColor: "rgb(37, 44, 48)",
                boxShadow: "0.2px 4px  2px 1px",
                padding: "10px",
                margin: "0 auto",
              }}
            >
              <SearchHighlights xs={true} />
            </Toolbar>
            <ProductFilter
              ApplyFilter={ApplyFilter}
              reset={handleFilterReset}
              sx={{
                float: { md: "left" },
                width: { md: "100%" },
                display: { md: "none", xs: "block" },
              }}
            />
          </Drawer>

          {/* <Divider></Divider> */}
        </Box>
        <ProductFilter
          ApplyFilter={ApplyFilter}
          reset={handleFilterReset}
          sx={{
            float: { md: "left" },
            width: { md: "20%" },
            display: { md: "block", xs: "none" },
          }}
        />

        <Box
          sx={{
            padding: "30px",
            width: { md: "80%" },
            float: { md: "right" },
          }}
        >
          <div id='product-frame'>
          <Row gutter={16}>
            {SocialAccounts.length >= 1 ? (
              SocialAccounts.map((accounts, idx) => (
                <>
                  <Col span={window.screen.width >= 720 ? 7 : 12}>
                    <ProductCard
                      refresh={getData}
                      wishFunc={props.addToWish}
                      cartFunc={props.addToCart}
                      account={accounts}
                      loading={loading}
                    />
                  </Col>
                </>
              ))
            ) : (
              <>
                <div style={{ margin: "0 auto" }}>
                  <Empty />
                </div>
              </>
            )}
          </Row>
          </div>
          {SocialAccounts.length >= 1 &&
            <div style={{ textAlign: "center", marginTop: "20px", width: "50%", margin: "0 auto" }}>
              <Pagination size="large" showQuickJumper showSizeChanger onShowSizeChange={(val, size) => handlePageSize(size)} onChange={handlePage} responsive pageSize={pageSize} current={page} total={itemsCount} />
            </div>}
        </Box>
      </Spin>
    </>
  );
}

export default AccountPosts;
