import React, { Component, useRef } from 'react'
import { Link } from 'react-router-dom';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Card,Button, Avatar, message, Popover, Dropdown, Popconfirm, Alert, Input, Empty, Spin, Form, List, Checkbox, Skeleton } from 'antd';
import {  Menu, Space, Modal, Tag,Select } from 'antd';
import ProductEditPage from './productEditPage';
import { useState } from 'react';
import axios from 'axios';
import { IconButton, Button as MuiButton } from '@mui/material';
import { ArrowBackIos, Delete, Folder, FolderDelete, RadioButtonUnchecked} from '@mui/icons-material';
import { Header } from 'antd/lib/layout/layout';
import { Box } from '@mui/system';
import APP_ENV from '../../env';
const SellerProductsPage = () => {
    const [activeTabKey, setActiveTabKey] = useState('Active');
    const [sellerProducts, setSellerProduct] = useState([]);
    const [currenEditModal, setCurrentEditModal] = useState(false);
    const [popOverMessage, setPopOverMessage] = useState('');
    const [popOverOpen, setPopOverOpen] = useState(false);
    const [currentEditProducts, setCurrentEditProduct] = useState({});
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [warningMessage, setWarningMessage] = useState('');
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [SelectedForEdit, setSelectedForEdit] = useState([])
    const [unFilteredProducts, setunFilteredProducts] = useState([])
    const [Edit, setEdit] = useState(false)
    const [Action, setAction] = useState(null)
    const [loading, setloading] = useState(true)
    const [groupForm] =Form.useForm() 
    const [selectedForGrouping, setselectedForGrouping] = useState([]);
    const [selectedGroup, setselectedGroup] = useState(null)
    const [selectedGroups, setselectedGroups] = useState([])
    const formGroupRef = useRef(null)
    const {Option} = Select
    const [ProductGroups, setProductGroups] = useState([])
    const groupSelectRef = useRef(null)
    const { Meta } = Card;
    const navigate = useNavigate()
    const submitEdit = useRef(null)
    const updateProducts = () => {
                setloading(true);

        client.get('/social/api/seller/products').then(response => {
            // console.log(response.data);
            setSellerProduct(response.data);
                        setloading(false);

        }).catch(err => {message.error('Error getting products ready');             setloading(false);
});
        client.get('/social/api/seller/product-groups/').then(response => {
            // console.log(response.data);
            setProductGroups(response.data);
            setloading(false)
        }).catch(err => {message.error('Error getting groups');             setloading(false);
});
    }
    
    
    React.useEffect(() => {
        updateProducts();
    }, [])

    const BASE_URL = APP_ENV.SERVER_ADDR;
    const reqHeaders = {
        "Content-Type": "application/json",

        "Authorization": `Token ${localStorage.getItem('AccgToken')}`


    };

    if (localStorage.getItem('AccgToken') !== 'null') {
        reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

    }

    const client = axios.create({
        baseURL: BASE_URL,
        headers: reqHeaders,


    })
    const authClient = axios.create({
        baseURL: BASE_URL,


    })
    const handleAddToSelected=(product_id)=>{
    if ( SelectedForEdit.filter(item=>item===product_id).length >= 1){
      SelectedForEdit.pop(product_id)
    }
    else{
        SelectedForEdit.push(product_id)

    }      

      
    }
    
    const handleDisplayGroup =(id=null)=>{
            
    setloading(true)
    if (id!==null){
    if (unFilteredProducts.length <= 0){
    setunFilteredProducts(sellerProducts);
    
    }
        if (unFilteredProducts.length <= 0){

    var newProductsList = sellerProducts.filter(item=> item.groups.includes(id))
    
        }
        else{
            var newProductsList = unFilteredProducts.filter((item) =>
              item.groups.includes(id)
            );

        }
    setSellerProduct(newProductsList);
    const curntGroup = ProductGroups.filter(item=> item.id === id);
    if (curntGroup){
        setselectedGroup(curntGroup[0]);
    };}
    else{
        setselectedGroup(null);
        updateProducts();
    }
        setloading(false);

    };
    
    
  const  handleDeleteSelected = ()=>{
    setloading(true)
        client
          .put("/social/api/seller/products", {action:'delete_selected', product_list:SelectedForEdit})
          .then((response) => {
            // console.log(response.data);
            updateProducts();
            setloading(false);
                              message.success("Products Deleted");

          })
          .catch((err) => {message.error("Error getting products ready");             setloading(false);
});
    };
    const handleGroupSelected =()=>{
                Modal.confirm({
                  title: "Add Products to Group",
                  okText: "Group Products",
                  onCancel: () => {
                    Modal.destroyAll();
                    setloading(false);
                  },
                  onOk: () => {
                    client
                      .put("/social/api/seller/products", {
                        action: "group_selected",
                        product_list: SelectedForEdit,
                        selected_groups: selectedGroups,
                      })
                      .then((response) => {
                        // console.log(response.data);

                        updateProducts();
                        setloading(false);
                        message.success("Products Grouped");
                      })
                      .catch((err) => {
                        message.error("Error getting products ready");
                        setloading(false);
                      });
                  },
                  content: (
                    <>
                      <List
                        header={"Product Groups"}
                        dataSource={ProductGroups}
                        pagination={{ pageSize: 50 }}
                        itemLayout="horizontal"
                        renderItem={(item, index) => (
                          <List.Item
                            key={item.name + String(index)}
                            actions={[
                              <>
                                <Checkbox
                                  defaultChecked={
                                    selectedGroups.includes(item.id)
                                      ? true
                                      : false
                                  }
                                  style={{ position:'absolute', top:'-50px'}}
                                  onChange={() => {
                                    selectedGroups.includes(item.id)
                                      ? selectedGroups.pop(item.id)
                                      : selectedGroups.push(item.id);
                                  }}
                                />
                              </>,
                            ]}
                          >
                            {" "}
                            <Skeleton
                              avatar
                              title={false}
                              loading={loading}
                              active
                            >
                              <List.Item.Meta
                                avatar={<Avatar>{String(item.name)[0]}</Avatar>}
                                description={item.description}
                                title={item.name}
                              ></List.Item.Meta>
                            </Skeleton>
                          </List.Item>
                        )}
                      ></List>
                    </>
                  ),
                });
                setloading(true);
  
    };
    const handleUnGroupSelected =()=>{
    setloading(true);
        client
          .put("/social/api/seller/products", {
            action: "ungroup_selected",
            product_list: SelectedForEdit,
          })
          .then((response) => {
            // console.log(response.data);

            updateProducts();
            setloading(false);    
                              message.success("Products Ungrouped");

            })
          .catch((err) => {message.error("Error getting products ready");setloading(false);});
    };
    
    const ProductsCard = (props) =>


        < div style={{ width: '100%', textAlign: 'center' }
        }>
        
            { sellerProducts.length >=1?
                sellerProducts.map((product, index) => {
                
                    product.Delete = () => {
                        Modal.confirm({
                            title: 'Sure You want to delete this Product?',
                            content: `By Deleting "${product.title}", You would no longer be able to reactivate or perform any action on it!`,
                            onOk() {
                                client.delete('/social/api/seller/products/' + product.slug).then(resp => message.success('Post Deleted')); setTimeout(() => { updateProducts() },


                                    2000)

                            }

                        })
                    }

                    const settingsDropDownMenu = (<Menu
                        items={[{
                            key: '1',
                            disabled: true,
                            label: (
                                <Button disabled style={{ border: 'none', width: '100%' }}>
                                    Boost
                                </Button>
                            ),
                        }, product.closed === false && product.approved == true ? {
                            key: '2',
                            label: (
                                <Button onClick={() => {
                                    client.put('/social/api/seller/products/' + product.slug, { 'single-edit': true, 'close': true }).then(resp => message.success('Post Closed')); setTimeout(() => { updateProducts() },


                                        2000)
                                }} style={{ border: 'none', width: '100%' }}>
                                    Close
                                </Button>
                            ),
                        } : '',
                        product.sold === false && product.approved === true ?
                            {
                                key: '3',
                                label: (
                                    <Button style={{ border: 'none', width: '100%' }} onClick={() => {
                                        client.put('/social/api/seller/products/' + product.slug, { 'single-edit': true, 'sold': true }).then(resp => message.success('Marked as sold')); setTimeout(() => { updateProducts() },


                                            2000)
                                    }} >
                                        Mark as Sold
                                    </Button>
                                ),
                            } : ''

                            ,]} />
                    )
                    const ellipsisDropDownMenu = (<Menu
                        items={[{
                            key: '1',
                            label: (
                                <Button style={{ border: 'none', width: '100%', color: 'red' }} onClick={() => {
                                    product.Delete()
                                }}>
                                    Delete
                                </Button>
                            ),
                        }, product.closed === true || product.sold === true ? {
                            key: '2',
                            label: (
                                <Button style={{ border: 'none', width: '100%' }} onClick={() => {
                                    if (product.available_quantity >= 1) {
                                        client.put('/social/api/seller/products/' + product.slug, { 'single-edit': true, 'open': true }).then(resp => message.success('Post Submitted For Approval')); setTimeout(() => { updateProducts() },


                                            2000)
                                    } else { setPopOverMessage('Please increase Product Quantity before Republishing'); setPopOverOpen(true) }
                                }} >
                                    Republish
                                </Button>
                            ),
                        } : '',]} />
                    )

                    return props.closed === product.closed &&
                      props.approved === product.approved ? (
                      props.sold === product.sold ||
                      props.soldAndClosed === true ? (
                      
                        <>
                          {" "}
                          <Card key={product.title + product.id}
                            style={
                              product.disapproved === true
                                ? {
                                    width: 300,
                                    display: "inline-block",
                                    margin: "5px auto",
                                    boxShadow: "1px 2px 3px 0px red",
                                    textTransform: "capitalize",
                                  }
                                : {
                                    width: 300,
                                    display: "inline-block",
                                    margin: "5px auto",
                                    textTransform: "capitalize",
                                  }
                            }
                            cover={
                              <>
                                {Edit == true ? (
                                  <Input
                                    style={{
                                      width: "20px",
                                      borderColor: "green",
                                      margin: "10px",
                                      float: "left",
                                    }}
                                    type="checkbox"
                                    defaultChecked={
                                      SelectedForEdit.includes(product.id) ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      handleAddToSelected(product.id)
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                <img
                                  alt="product image"
                                  style={{
                                    objectFit: "cover",
                                    maxWidth: "auto",
                                    height: 300,
                                  }}
                                  src={
                                    product.images.length >= 1
                                      ? product.images[0].url
                                      : ""
                                  }
                                />
                              </>
                            }
                            actions={[
                              <Dropdown
                                overlay={settingsDropDownMenu}
                                placement="topLeft"
                              >
                                <Button>
                                  <SettingOutlined key="setting" />
                                </Button>
                              </Dropdown>,

                              <EditOutlined
                                onClick={() => {
                                  setCurrentEditProduct(product);
                                  setCurrentEditModal(true);
                                }}
                                key="edit"
                              />,

                              <Dropdown
                                overlay={ellipsisDropDownMenu}
                                placement="topLeft"
                              >
                                <Button>
                                  <EllipsisOutlined key="ellipsis" />
                                </Button>
                              </Dropdown>,
                            ]}
                          >
                            <Tag
                              style={{
                                float: "left",
                                position: "absolute",
                                top: "0",
                              }}
                            >
                              {product.available_quantity}x Quantity Remaining
                            </Tag>
                            {product.disapproved === true ? (
                              <Tag
                                style={{
                                  width: "200px",
                                  padding: "1px",
                                  backgroundColor: "darkred",
                                  color: "white",
                                }}
                                title="consult our customer support agent to know why or re-upload product following the right way."
                              >
                                PRODUCT REJECTED
                              </Tag>
                            ) : (
                              <></>
                            )}

                            <Meta
                              style={{
                                height: 50,
                                overflowY: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "left",
                                wordBreak: "normal",
                              }}
                              // avatar={<Avatar src={product.socialIcon} />}
                              title={product.title}
                              description={product.description}
                            />
                            {product.disapproved === true ? (
                              <>
                                {product.disapproval_reason ? (
                                  <>
                                    <b>Reason :</b>
                                    <Tag>
                                      <small
                                        style={{
                                          color: "red",
                                          backgroundColoe: "grey",
                                        }}
                                      >
                                        {product.disapproval_reason}
                                      </small>
                                    </Tag>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <br></br>
                            <Tag
                              title="Price"
                              style={{ float: "right" }}
                              color={"lime"}
                            >
                              {product.price}
                            </Tag>
                            <Tag
                              title="Views"
                              style={{ float: "left", borderRadius: "4px" }}
                              color={"black"}
                            >
                              {product.views} views
                            </Tag>
                          </Card>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    );
                }) :
''                
            }
           
        </div >

    const ActiveTab = (

        <>
            <h2 style={{ fontFamily: 'sans-serrif', textAlign: 'right', color: 'green', border: '1px dotted green', padding: '10px', borderRadius: '4px' }}>Active Products</h2>
            
            <ProductsCard closed={false} approved={true} sold={false} />
        </>
    )


    const AwaitingApprovalTab = (

        <>
            <h2 style={{ fontFamily: 'sans-serrif', textAlign: 'right', color: 'grey', border: '1px dotted grey', padding: '10px', borderRadius: '4px' }}>Products Waiting For Approval</h2>

            <ProductsCard closed={false} approved={false} sold={false} />
        </>
    )
    const Closed = (

        <>
            <h2 style={{ fontFamily: 'sans-serrif', textAlign: 'right', color: 'red', border: '1px dotted red', padding: '10px', borderRadius: '4px' }}>Closed Products</h2>

            <ProductsCard closed={true} approved={true} sold={false} soldAndClosed={true} />
        </>
    )
    const Sold = (

        <>
            <h2 style={{ fontFamily: 'sans-serrif', textAlign: 'right', color: 'black', border: '1px dotted black', padding: '10px', borderRadius: '4px' }}>Sold Products</h2>

            <ProductsCard closed={false} approved={true} sold={true} />
        </>
    )


    const tabListNoTitle = [
        {
            key: 'Active',
            tab: 'Active',
        },
        {
            key: 'WaitingApproval',
            tab: 'Waiting Approval',
        },
        {
            key: 'Closed',
            tab: 'Closed',
        },
        {
            key: 'Sold',
            tab: 'Sold',
        },
    ];
    const contentListNoTitle = {
        Active: <>{ActiveTab}</>,
        WaitingApproval: <>{AwaitingApprovalTab}</>,
        Closed: <>{Closed}</>,
        Sold: <>{Sold}</>,
    };



    const onTabChange = (key) => {
        setActiveTabKey(key);
    };
const wishHeaderStyle = {
  fontSize: "10px",
  borderTop: "2px solid grey",
  display: "inline-block",
  marginRight: "5px",
  color: "whitesmoke",
  backgroundColor: "darkolivegreen",
};
  
  const GroupForm =({group=null, edit=false, create=false})=>{
  return (
    <Form form={groupForm}>
      {group !== null ? (
        <Form.Item
          initialValue={group ? group.id : ""}
          hidden
          label="id"
          rules={[{ required: true }]}
          name={"id"}
        >
          <Input name="id"></Input>
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        label="Name"
        initialValue={group ? group.name : ""}
        rules={[{ required: true }]}
        name={"name"}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Description"
        rules={[{ required: true }]}
        initialValue={group ? group.description : ""}
        name={"description"}
      >
        <Input.TextArea></Input.TextArea>
      </Form.Item>
      {create === true ? (
        <>
          <Form.Item label="Select Products" hidden name={"group_products"}>
            <Select id="group_products"></Select>
          </Form.Item>
          <List
            pagination={{ pageSize: 50}}
            itemLayout="horizontal"
            header={<h3>Add Products</h3>}
            dataSource={sellerProducts.length >= unFilteredProducts.length?sellerProducts:unFilteredProducts }
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Checkbox
                    defaultChecked={
                      selectedForGrouping.includes(item.id) === true
                        ? true
                        : false
                    }
                    onChange={() => {
                      selectedForGrouping.includes(item.id)
                        ? selectedForGrouping.pop(item.id)
                        : selectedForGrouping.push(item.id);
                      groupForm.setFields([
                        { name: "group_products", value: selectedForGrouping },
                      ]);
                    }}
                    key={item.title + String(index)}
                  />,
                ]}
              >
                <Skeleton avatar title={false} loading={loading} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={
                          item.images.length >= 1
                            ? item.images[0].url
                            : item.title
                        }
                      ></Avatar>
                    }
                    description={item.description}
                    title={item.title}
                  ></List.Item.Meta>
                </Skeleton>
              </List.Item>
            )}
          >
            <></>
          </List>
        </>
      ) : (
        ""
      )}
    </Form>
  );
  }

  function handlePostGroup ( {edit=false, del=false, create=false, group_id=null}){
      const grpFormValues = groupForm.getFieldsValue()
      if (edit){
      
              client
                .put(`/social/api/seller/product-groups/${grpFormValues.id}/`,grpFormValues)
                .then((response) => {
                  // console.log(response.data);

                  updateProducts();
                  message.success('Edit Applied');
                })
                .catch((err) => {
                  message.error("Error getting products ready");
                  setloading(false);
                });
         
      };
      if (del){
       client
         .delete(`/social/api/seller/product-groups/${group_id}/` )
         .then((response) => {
           // console.log(response.data);

           updateProducts();
           message.success("Group Deleted");
           setselectedGroup(null);
           groupSelectRef.current.value =null;

         })
         .catch((err) => {
           message.error("Error Deleting Group");

           setloading(false);
         });
      };
      if(create){
              client
                .post("/social/api/seller/product-groups/", grpFormValues)
                .then((response) => {
                  // console.log(response.data);

                  updateProducts();
                  message.success("Group Created");
                  setselectedGroup(response.data);

                })
                .catch((err) => {
                  message.error('Error Creating Group');
                  setloading(false);
                });
         
      };
      groupForm.resetFields();
      setselectedForGrouping([]);
}
  const handleCreateGroup =()=>{
  Modal.confirm({
    title: "Create New Group",
    okText:'Create',
  
    onCancel: () => {
      Modal.destroyAll();
              setloading(false);

    },
    onOk: () => {
        handlePostGroup({ create: true });
    return;
    },
    icon: <Folder />,

    content: 
      <>
        <GroupForm edit={false} create={true}/>
      </>
    
  });
  }
  const handleDeleteGroup =(group_id)=>{
      const group = ProductGroups.filter((item) => item.id === group_id);
  if (group) {
    Modal.confirm({
      title: `Delete ${group[0].name}`,
      
      okButtonProps:{style:{color:'white', backgroundColor:'red'}},
      okText: `Delete  ${group[0].name}`,
      centered:true,
      icon: <Delete  style={{fill:'red'}}/>,
      onCancel: () => {
        Modal.destroyAll();
        setloading(false);
        
      },
      onOk: () => {
        handlePostGroup({ del: true, group_id:group[0].id });
        groupSelectRef.current.value = null;
      },
      content: (
        <>
        <center>
          <h1>Are you Sure</h1><h3>You want to delete this group?</h3>
          <small>this action is not reversible.</small></center>
        </>
      ),
    });
  } else {
    message.info("Group Not Found");
  }
  }
  
  const handleEditGroup =(group_id)=>{
    
      groupForm.resetFields()
      const group = ProductGroups.filter((item)=> item.id === group_id)
      if (group){

      Modal.confirm({
        title: `Edit ${group[0].name}`,
        okText:'Apply Edit',
        icon: <EditOutlined/>,
        onCancel:()=>{Modal.destroyAll();        setloading(false);
},
        onOk:()=>{
            handlePostGroup({ edit:true });

                      return;
        },
        content: 
          <>
           <GroupForm edit={true} group={group[0]} />
          </>
      });
      
      } else{
      message.info('Group Not Found');
      }
  }
    return (
      <>
        {/* <IconButton title="Create Product Group">
          <Folder></Folder>
        </IconButton> */}
        <Header
          children={
            <>
              <div style={{ fontSize: "28px", margin: "0" }}>
                My Products
                <div style={{ float: "right" }}>
                  <MuiButton
                    style={wishHeaderStyle}
                    onClick={() => {
                      navigate("/sell");
                    }}
                  >
                    Upload
                  </MuiButton>
                  <MuiButton
                    style={wishHeaderStyle}
                    onClick={() => {
                      handleCreateGroup();
                    }}
                  >
                    Create Group
                  </MuiButton>
                </div>
              </div>{" "}
            </>
          }
          style={{backgroundColor: "transparent", fontFamily: "serif" }}
        ></Header>
        <ArrowBackIos
          style={{
            position: "absolute",
            top: "90px",
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "10px",
            // marginTop: "100px",
          }}
          onClick={() => navigate(-1)}
        />
        <Spin spinning={loading}>
          <Card
            title={selectedGroup !== null ? "" : "All Products"}
            tabList={[]}
            extra={
              <>
                <Box>
                  <p>Product Groups</p>
                  <Select
                  ref={groupSelectRef}
                    style={{ width: "100%" }}
                    onChange={handleDisplayGroup}
                  >
                    <Option value={null}>None</Option>

                    {ProductGroups.map((item, index) => (
                      <>
                        <Option key={item.title} value={item.id}>{item.name}</Option>
                      </>
                    ))}
                  </Select>
                  {selectedGroup !== null ? (
                    <>
                      <IconButton
                        onClick={() => handleDeleteGroup(selectedGroup.id)}
                        style={{
                          margin: "10px",
                          fontSize: "12px",
                          color: "lightred",
                        }}
                      >
                        <Delete />
                        Delete Group
                      </IconButton>
                      <IconButton
                        onClick={() => handleEditGroup(selectedGroup.id)}
                        style={{
                          color: "navy",
                          margin: "10px",
                          fontSize: "12px",
                        }}
                      >
                        <EditOutlined />
                        Edit Group
                      </IconButton>
                    </>
                  ) : (
                    <small style={{ color: "green" }}>No Selected Group</small>
                  )}
                </Box>
              </>
            }
          >
            <Card
              style={{
                width: "100%",
              }}
              tabList={tabListNoTitle}
              tabProps={{ color: "red" }}
              activeTabKey={activeTabKey}
              tabBarExtraContent={
                <MuiButton
                  style={{ border: "none", fontSize: "13px" }}
                  onClick={() => {
                    setEdit(!Edit);
                  }}
                >
                  {Edit === true ? "Cancel" : "Edit"}
                </MuiButton>
              }
              onTabChange={(key) => {
                onTabChange(key);
              }}
              actions={
                Edit === true
                  ? [
                      <MuiButton
                        onClick={handleDeleteSelected}
                        startIcon={<Delete />}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        Delete{" "}
                      </MuiButton>,
                      <MuiButton
                        onClick={handleGroupSelected}
                        style={wishHeaderStyle}
                      >
                        {" "}
                        Group
                      </MuiButton>,
                      <MuiButton
                        onClick={handleUnGroupSelected}
                        style={wishHeaderStyle}
                      >
                        UnGroup{" "}
                      </MuiButton>,
                    ]
                  : []
              }
            >
              {contentListNoTitle[activeTabKey]}
            </Card>
          </Card>
        </Spin>
        <Modal
          destroyOnClose
          onCancel={() => setCurrentEditModal(false)}
          visible={currenEditModal}
          cancelText="Close"
          okText="Save"
          okType="submit"
          onOk={() => {
            submitEdit.current.submit();
            setTimeout(() => {
              updateProducts();
            }, 2000);
          }}
        >
          <ProductEditPage
            ref={submitEdit}
            currentProduct={currentEditProducts}
          />
        </Modal>
        <Modal
          okType="info"
          cancelButtonProps={{ hidden: true }}
          closable
          centered
          title="info"
          onCancel={() => setPopOverOpen(false)}
          onOk={() => setPopOverOpen(false)}
          visible={popOverOpen}
        >
          {popOverMessage}
        </Modal>
      </>
    );
};

export default SellerProductsPage