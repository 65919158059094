import { Cancel, ForwardRounded, } from '@mui/icons-material'
import { Box, ButtonGroup, Button as MuiButton, } from '@mui/material'
import { message, Alert } from 'antd'

import React, { useRef, useContext, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PinInput from 'react-pin-input';
import AppContext from '../../app_context';


export default function CreatePin(props) {

    const [pin, setPin] = useState(['', '', '', ''])
    const [stage, setStage] = useState(0)
    const inputRef = useRef(null)
    const [messageD, setMessageD] = useState([])
    const [length, setLength] = useState(0)
    const [newPin, setNewPin] = useState('')
    const [confirmPin, setConfirmPin] = useState('')
    const navigate = useNavigate()
    const { client, BASE_URL, authClient } = React.useContext(AppContext)


    // stage 1 ---- enter old pin for auth
    // stage 2 --- enter new pin 
    // stage 3 --- confirm new pin 

    React.useEffect(() => {
        if (inputRef.current !== null) {
            for (let i = 0; i < inputRef.current.elements.length; i++) {
                inputRef.current.elements[i].props.onChange = (val) => { pin[i] = val;  };
                inputRef.current.elements[i].onKeyDown = (e) => { pin[i] = ''; setLength(length - 1); };
            }
        }

    })



    const resetFields = () => {


        for (let i = 0; i < inputRef.current.elements.length; i++) {

            inputRef.current.elements[i].state.value = '';
            setLength(0);
            setPin(['', '', '', '']);
        }

        inputRef.current.values = pin;


    }
    const handlePinCreate = () => {
        if (stage === 0) {
            const newPin = pin.join('')

            if (newPin.length === 4) {

                setNewPin(newPin);
                resetFields();
                setStage(stage + 1)
                setMessageD([])

            }
            else {
                message.error('Pin must be 4 digits!')


            }
        }
        if (stage === 1) {
            const confmPin = pin.join('')

            if (confmPin.length === 4) {
                resetFields();
                if (newPin != confmPin) {
                    setMessageD(['error', 'Pin Do Not Match!'])
                    setStage(0)


                }
                else {
                    setMessageD([])
                    message.loading('checking pin..', 1)


                    client.put('/user/api/wallet', { method: 'create_pin', pin: newPin }).then(resp => {
                        if (resp.data.status === 'error') {
                            resetFields();

                            return setMessageD(['error', resp.data.msg])
                        }
                        else {
                            setMessageD(['success', resp.data.msg]);




                            navigate('/user/wallet')
                            message.success(resp.data.msg, 3)
                            return props.updateParent();
                        }
                    }
                    )

                }
            }
            else {

                message.error('Pin must be 4 digits!')
            }
        }
    }

    const handleNumberSet = (e) => {
        const val = String(e.target.value)
        if (val === '>') {
            if (pin.length != 4) {
                return message.error('Enter 4 digits pin!',)
            }
            else {
                return handlePinCreate();
            }
        }
        if (length !== 4 && e.target.value !== undefined && isNaN(e.target.value) === false) {



            if (val === 'undefined') {

                return
            }


            else

                for (let i = 0; i < inputRef.current.elements.length; i++) {
                    if (inputRef.current.elements[i].state.value === '') {
                        inputRef.current.elements[i].state.value = val;
                        pin[i] = val;

                        setLength(length + 1);
                        // inputRef.current.elements[i].focus = false;

                        // inputRef.current.elements[i + 1]?.focus

                        break;

                    }



                }
            inputRef.current.values = pin
            if (length == 4) {
                handlePinCreate()

            }
            // console.log(inputRef.current);


        }

        if (val === 'X') {
            for (let i = 0; i < inputRef.current.elements.length; i++) {

                inputRef.current.elements[i].state.value = '';
                setPin(['', '', '', ''])
                setLength(0)
            }
            inputRef.current.values = pin

            // inputRef.current.elements[0]?.focus

            // setPin(0)
        }
    }
    const numpadStyle = {
        borderColor: '#51442e',
        color: 'white',
        padding: '10px',
        width: '80px',
        marginTop: '4px',
        backgroundColor: '#51864e',


    }
    const buttons1 = [
        <MuiButton style={numpadStyle} value='1' onClick={(e) => handleNumberSet(e)} key="1">1</MuiButton>,
        <MuiButton style={numpadStyle} value='4' onClick={(e) => handleNumberSet(e)} key="4">4</MuiButton>,
        <MuiButton style={numpadStyle} value='7' onClick={(e) => handleNumberSet(e)} key="7">7</MuiButton>,
        <MuiButton style={numpadStyle} value='X' onClick={(e) => handleNumberSet(e)} title='clear' key="X"><Cancel /></MuiButton>,

    ];

    const buttons2 = [
        <MuiButton style={numpadStyle} value='2' onClick={(e) => handleNumberSet(e)} key="2">2</MuiButton>,

        <MuiButton style={numpadStyle} value='5' onClick={(e) => handleNumberSet(e)} key="5">5</MuiButton>,
        <MuiButton style={numpadStyle} value='8' onClick={(e) => handleNumberSet(e)} key="8">8</MuiButton>,
        <MuiButton style={numpadStyle} value='0' onClick={(e) => handleNumberSet(e)} key="0">0</MuiButton>,

    ];

    const buttons3 = [
        <MuiButton style={numpadStyle} value='3' onClick={(e) => handleNumberSet(e)} key="3">3</MuiButton>,
        <MuiButton style={numpadStyle} value='6' onClick={(e) => handleNumberSet(e)} key="6">6</MuiButton>,

        <MuiButton style={numpadStyle} value='9' onClick={(e) => handleNumberSet(e)} key="9">9</MuiButton>,
        <MuiButton style={numpadStyle} value='>' disabled={pin.length !== 4 ? true : false} onClick={(e) => handleNumberSet(e)} title='Proceed' key='>' ><ForwardRounded /></MuiButton>,
    ];
    return (
        <div className="App" style={{ textAlign: 'center', fontFamily: 'sans', objectFit: 'contain' }}>
            {messageD.length >= 1 ?
                <Alert showIcon={true} style={{ width: '300px', margin: '0 auto' }} type={messageD[0]} message={messageD[1]}></Alert> : ''}
            <Box sx={{ fontSize: { md: '30px', xs: '20px', color: 'silver' } }}>
                <h2 style={{ color: 'whitesmoke' }}>Create Pin</h2>
                {stage === 0 ?
                    <p>Enter New Pin.</p>

                    :
                    <p>Re-Enter Pin</p>
                }

            </Box>
            <PinInput inputFocusStyle={{ color: 'chartreuse' }} autoSelect={true} ref={inputRef} inputStyle={{ borderColor: 'darkgreen', color: 'whitesmoke', margin: '10px', borderRadius: '4px' }} type='numeric'
                focus length={4} initialValue={pin} inputMode='numeric' />
            <Box
                sx={{
                    display: 'flex',
                    '& > *': {
                        m: 1,
                    },
                }}
                style={{ textAlign: 'center', width: '100%', justifyContent: 'center', margin: '0 auto' }}
            >

                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical outlined button group"
                >
                    {buttons1}
                </ButtonGroup>
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                // variant="contained"
                >
                    {buttons2}
                </ButtonGroup>
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                // variant="text"
                >
                    {buttons3}
                </ButtonGroup>
            </Box>
        </div >
    );

}