import React, { Component } from 'react'
import DashBoardIndex from '../dashboard'
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Header } from 'antd/lib/layout/layout';
import { Divider } from 'antd';

const VisitHistory = (props) => {
    const navigate = useNavigate()
    React.useEffect(() => {
        if (props.clicked == true) {
            if (props.path === '//') {
              return  navigate(-1)
            }

            navigate(props.path)
        }

    }, [props.path])
    return (
        <></>
    )
}
export default class DashBoard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cartListData: [],
            itemClicked: false,
            itemPath: '',
            productsRef: props.productFuncs,
        }
    }

    render() {
        return (
            <div>
                <Header children={<><div style={{ fontSize: '30px', margin: '0' }}  >Dashboard</div></>} style={{ backgroundColor: "transparent", fontFamily: 'serif' }}>
                </Header>
                <ArrowBackIos style={{ position: 'absolute', top: '80px', fontSize: '20px', cursor: 'pointer', marginLeft: '10px', marginTop: '10px' }} onClick={() => this.setState({ itemPath: '//', itemClicked: true })} />
                <VisitHistory path={this.state.itemPath} clicked={this.state.itemClicked}>
                </VisitHistory>
                <Divider />                <DashBoardIndex />

            </div >
        )
    }
}
