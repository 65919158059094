import React from 'react'
import PropTypes from 'prop-types'

import { Badge, Button, Col, Image, Rate, Space, Tag } from "antd";
import { addToCart, getSocialAvatar } from "./app_context";
import { Carousel,Card,mx  } from "antd";
import { Icon, IconButton,LinearProgress } from '@mui/material';
import {  ShoppingBag,FavoriteBorder,Favorite } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';



function ProfileCard({
  type,
  username,
  is_seller,
  positive_feedbacks,
  rating,
  primary_img,
  date_created,
  product_count,
  category,
  refresh,
  loading,
}) {
  const navigate = useNavigate();
  const get_Profile = (username) => {
    navigate(`/profile/` + username);
  };
  return (
    <Card
      hoverable={true}
      title={
        <Box
          sx={{ fontSize: { md: "16px", xs: "10px" }, padding: { xs: "0" } }}
        >
          <p
            onClick={() => {
              get_Profile(username);
            }}
          >
            {" "}
            @{username}
          </p>
          {is_seller === true ? (
            <>
              <Tag
                style={{
                  position: "absolute",
                  top: "0",
                  border: "none",
                  right: "0",
                  paddingBottom: "0",

                  color: "green",
                  borderColor: "lightgreen",
                }}
              >
                {/* <Badge status="success"></Badge> */}
                <Badge title="currently selling" status="success" /> Seller
              </Tag>
              {positive_feedbacks?
              <div
                style={{
                  justifyContent: "center",
                  color: "greenyellow",
                  display: "grid",
                }}
              >
                {positive_feedbacks}+ feedback(s)
              </div>:""}
            </>
          ) : (
            ""
          )}
        </Box>
      }
      loading={loading}
      bordered={false}
    >
      <>
        {primary_img ? (
          <img
            onClick={() => {
              get_Profile(username);
            }}
            // width={260}
            style={{ objectFit: "cover" }}
            src={primary_img}
          ></img>
        ) : (
          ""
        )}
      </>
      {is_seller === true ? (
        <>
          <Rate disabled style={{ fontSize: "10px" }} value={rating} />

          <div
            style={{ display: "inline-flex", margin: "5px", float: "right" }}
          >
          
            {category.length > 15 ? <b>{category}</b> : ""}
          </div>
        </>
      ) : (
        ""
      )}
      <div style={{}}>
        <Box xs={{ margin: { md: "200px" } }}>
          <Button
            onClick={() => {
              get_Profile(username);
            }}
            style={{ width: "100%" }}
            loading={loading}
          >
            View Profile
          </Button>
        </Box>
      </div>
    </Card>
  );
}

// ProfileCard.propTypes = {}

export default ProfileCard
