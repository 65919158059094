import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  fetchAllAdGigs,
  fetchMyAdGigs,
  fetchAdGig,
  updateAdGig,
  deleteAdGig,
  fetchAdGigProposal,
  fetchMyAdGigProposals,
  updateMyAdGigProposal,
  createNewAdGigProposal,
  createNewAdGig,
  deleteMyAdGigProposal,
  fetchSavedAdGigs,
  loadNewAdGigs,
} from './thunks'

const initialState = {
  status: 'idle',
  gigs: [],
  savedGigs: [],
  formErrors: {},
  myGigs: [],
  myProposals: [],
  loadingNewAdGigs: false,
  activeGig: null,
  activeProposal: null,
  gigsPagination: { nextURL: null, prevURL: null,count:0 },
}

const adGigReducer = createSlice({
  name: 'adGig',
  initialState: initialState,
  reducers: {
    changeAdGigStatus(state, action) {
      state.status = action.payload
    },
    setLoadingNewGigs(state, action) {
      state.loadingNewAdGigs = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllAdGigs.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.gigs = action.payload.results
        state.gigsPagination.nextURL = action.payload.next
        state.gigsPagination.prevURL = action.payload.previous
        state.gigsPagination.count = action.payload.count
      })
      .addCase(fetchMyAdGigs.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.myGigs = action.payload.results
      })
      .addCase(loadNewAdGigs.fulfilled, (state, action) => {
        state.loadingNewAdGigs = false
        state.gigs = state.gigs.map(gig =>
          action.payload.results.find(g => g === gig)
            ? gig
            : action.payload.results.find(g => g === gig),
        )
        state.gigsPagination.nextURL = action.payload.next
        state.gigsPagination.prevURL = action.payload.previous
        state.gigsPagination.count = action.payload.count
      })
      .addCase(fetchAdGig.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.activeGig = action.payload
      })
      .addCase(createNewAdGig.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.myGigs.unshift(action.payload)
      })
      .addCase(fetchSavedAdGigs.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.savedGigs = action.payload.results
      })
      .addCase(deleteAdGig.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.gigs = state.gigs.filter(gig => gig.id !== action.payload)
        state.myGigs = state.myGigs.filter(gig => gig.id !== action.payload)
      })
      .addCase(fetchMyAdGigProposals.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.myProposals = action.payload
      })
      .addCase(fetchAdGigProposal.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.activeProposal = action.payload
      })
      .addCase(updateAdGig.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.gigs = state.gigs.map(gig =>
          gig.id === action.payload.id ? action.payload : gig,
        )
      })
      .addCase(updateMyAdGigProposal.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.myProposals = state.myProposals.map(prp =>
          prp.id === action.payload.id ? action.payload : prp,
        )
      })
      .addCase(createNewAdGigProposal.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.myProposals = state.myProposals.map(prp =>
          prp.id === action.payload.id ? action.payload : prp,
        )
      })
      .addCase(deleteMyAdGigProposal.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.myProposals = state.gigs.filter(prp => prp.id !== action.payload)
        state.activeGig.proposals = state.myGigs.filter(
          prp => prp.id !== action.payload,
        )
      })
      .addCase(loadNewAdGigs.rejected, (state, action) => {
        state.loadingNewAdGigs = false
      })
      //pendings
      .addMatcher(
        isAnyOf(
          fetchAllAdGigs.pending,
          fetchMyAdGigs.pending,
          fetchAdGig.pending,
          updateAdGig.pending,
          deleteAdGig.pending,
          fetchMyAdGigProposals.pending,
          updateMyAdGigProposal.pending,
          fetchSavedAdGigs.pending,
          deleteMyAdGigProposal.pending,
          createNewAdGig.pending,
          createNewAdGigProposal.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )

      //rejections

      .addMatcher(
        isAnyOf(
          fetchAllAdGigs.rejected,
          fetchMyAdGigs.rejected,
          fetchAdGig.rejected,
          updateAdGig.rejected,
          deleteAdGig.rejected,
          fetchMyAdGigProposals.rejected,
          updateMyAdGigProposal.rejected,
          fetchSavedAdGigs.rejected,
          deleteMyAdGigProposal.rejected,
          createNewAdGig.rejected,
          createNewAdGigProposal.rejected,
        ),
        (state, action) => {
          state.status = 'failed'
        },
      )
  },
})
export const { changeAdGigStatus, setLoadingNewGigs } = adGigReducer.actions
export default adGigReducer.reducer
