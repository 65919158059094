export const AD_CATEGORIES_CHOICES = [
  { "label": "Fashion", "value": "fashion" },
  { "label": "Beauty", "value": "beauty" },
  { "label": "Fitness", "value": "fitness" },
  { "label": "Travel", "value": "travel" },
  { "label": "Food", "value": "food" },
  { "label": "Lifestyle", "value": "lifestyle" },
  { "label": "Tech", "value": "tech" },
  { "label": "Gaming", "value": "gaming" },
  { "label": "Health", "value": "health" },
  { "label": "Business", "value": "business" },
  { "label": "Parenting", "value": "parenting" },
  { "label": "Entertainment", "value": "entertainment" },
  { "label": "Home Decor", "value": "home_decor" },
  { "label": "Pets", "value": "pets" },
  { "label": "Art", "value": "art" },
  { "label": "Music", "value": "music" },
  { "label": "Books", "value": "books" },
  { "label": "Movies", "value": "movies" },
  { "label": "Sports", "value": "sports" },
  { "label": "Education", "value": "education" },
  { "label": "Finance", "value": "finance" },
  { "label": "Automotive", "value": "automotive" },
  { "label": "Outdoors", "value": "outdoors" },
  { "label": "DIY", "value": "DIY" },
  { "label": "Photography", "value": "photography" },
  { "label": "Technology", "value": "technology" },
  { "label": "Science", "value": "science" },
  { "label": "Social Justice", "value": "social_justice" },
  { "label": "Charity", "value": "charity" },
  { "label": "Events", "value": "events" },
  { "label": "Comedy", "value": "comedy" },
  // {"label": "Any", "value": "any"}
  // Add more categories as needed
]

export const AD_PACKAGE_CHOICES = [["text", "Text"], ["file", "Files"], ["all", "All"]]

const fulfilmentSuffix = ' From Order Date'
export const FULFILLMENT_TIMES_CHOICES = [[1, "A Day" + fulfilmentSuffix], [2, "Two Days" + fulfilmentSuffix], [3, "Three Days" + fulfilmentSuffix], [4, "Four Days" + fulfilmentSuffix], [5, 'Five Days' + fulfilmentSuffix], [6, 'Six Days ' + fulfilmentSuffix], [7, 'Seven Days ' + fulfilmentSuffix], [14, 'Fourtheen Days ' + fulfilmentSuffix], [30, 'Thirty Days ' + fulfilmentSuffix], [60, 'Sixty Days ' + fulfilmentSuffix], [90, 'Ninethy Days ' + fulfilmentSuffix], [180, 'One Hundred And Eighty ' + fulfilmentSuffix]]


export const FILE_TYPES = [
  ['Images', ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']], 
  ['Documents', ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']], 
  ['Videos', ['video/mp4', 'video/avi', 'video/x-matroska']], 
  ['Audio', ['audio/mpeg', 'audio/wav', 'audio/ogg']], 
  ['All Files', ['*']]
];

export const FILE_POND_TYPES = [
  ['Images', ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']], 
  ['Documents', ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']], 
  ['Videos', ['video/mp4', 'video/avi', 'video/x-matroska']], 
  ['Audio', ['audio/mpeg', 'audio/wav', 'audio/ogg']], 
  ['All Files', ['*']]
];
export const USER_NETWORK_FEE_PERC = 4.98
