import React from 'react';
import { SelectInfluencerErrors, SelectInfluencerOnViewActiveAdPAckage, SelectInfluencerOnViewAdPackages, SelectInfluencerStatus } from '../redux/influencer/selectors';
import { createInfluencerAdPackages, deleteInfluencerAddPackage, fetchInfluencerAdPackages, updateInfluencerAdPackage } from '../redux/influencer/thunks';
import { useDispatch } from 'react-redux';

import { AnalyticsOutlined, FavoriteBorderOutlined, NoteAltOutlined, LockOutlined, PhonelinkRingRounded, Info, Delete, Edit, ShoppingBasketOutlined } from '@mui/icons-material'
import { Alert, Box, Button, Container, FilledInput, FormControl, IconButton, Input, InputLabel, OutlinedInput, Typography as MuiTypography, Switch, TextField, alpha } from '@mui/material'
import { Avatar, Card, Divider, Empty, Modal, Select, Tag, Typography, notification, InputNumber, Button as AntButton } from 'antd'
import { resetInfluencerStatus, setActiveInfluencerAdPAckage } from '../redux/influencer/reducer'
import { useNavigate } from 'react-router-dom';
import { AD_PACKAGE_CHOICES, FILE_TYPES, FULFILLMENT_TIMES_CHOICES } from '../platformVars';
import { PriceInput } from '../../app_context';

export default function InfluencerPackages({ influencer }) {
  const dispatch = useDispatch()
  const adPackages = SelectInfluencerOnViewAdPackages()

  React.useEffect(() => {

    dispatch(fetchInfluencerAdPackages({ infSlug: influencer.slug }))
  }, [])


  const InfluencerStatus = SelectInfluencerStatus()
  const [updateView, setupdateView] = React.useState(false)
  const [currentAction, setCurrentAction] = React.useState('')
  const [recentAction, setRecentAction] = React.useState(null)
  const activePkg = SelectInfluencerOnViewActiveAdPAckage()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (InfluencerStatus === 'success') {
      switch (recentAction) {

        case 'delete':
          notification.success({ message: 'Package Deleted' })
          dispatch(resetInfluencerStatus())
          setRecentAction(null)
          setupdateView(false)

          break
        case 'update':
          notification.success({ message: 'Package Updated' })
          dispatch(resetInfluencerStatus())
          setupdateView(false)

          setRecentAction(null)
          break
        case 'add':
          notification.success({ message: 'Package Successfully Added' })
          dispatch(resetInfluencerStatus())
          setupdateView(false)
          setRecentAction(null)
          break
        default:
          break
      }


    }

  }, [InfluencerStatus, recentAction])

  const handleEditPackage = (pkg) => {
    setCurrentAction('Update Package')
    dispatch(setActiveInfluencerAdPAckage(pkg)); setupdateView(true);
  }

  const handleDeletePkg = (pkg) => {
    setRecentAction('delete')

    dispatch(deleteInfluencerAddPackage({ infSlug: influencer.slug, id: pkg.id }))
  }
  const handleCreatePkg = () => {
    if (!updateView ) {
      dispatch(setActiveInfluencerAdPAckage(null))
      setCurrentAction("Create Package")
      setupdateView(true)
    }

    else {
      setCurrentAction(null);
      
      setupdateView(!updateView);
    }
  }

  return (
    <>
      <Container style={{ minHeight: '50vh' }}>
        <Typography.Paragraph strong>
          {influencer.is_my_page ? 'Your' : 'Influencer'} Packages (
          {adPackages.length})
        </Typography.Paragraph>
        {influencer.is_my_page && (
          <div sx={{ float: 'right' }}>
            <Button variant="contained" onClick={handleCreatePkg} size="middle">
              {!updateView ? 'Create Package' : 'Cancel'}
            </Button>
          </div>
        )}

        {updateView === false ? (
          <div>
            {adPackages?.map(pkg => (
              <Card
                hoverable
                size="default"
                // cover
                // bordered={false}
                style={{ marginTop: 10, position: 'relative' }}
                actions={[
                  <Tag
                    bordered={false}
                    style={{
                      fontSize: '14px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'inherit',
                      height: 'inherit',
                      padding: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      marginLeft: '4px',
                      gap: 0.2,
                      justifySelf: 'center',
                      alignSelf: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography.Text>
                      <MuiTypography
                        sx={{ display: { md: 'inline', xs: 'none' } }}
                        fontSize={'13px'}
                      >
                        {' '}
                        Available On
                      </MuiTypography>{' '}
                      {pkg.available_mediums.map(md => (
                        <Avatar
                          shape="square"
                          src={md.medium?.medium.logo}
                          alt={md.medium?.medium.name}
                          size="small"
                        />
                      ))}{' '}
                    </Typography.Text>
                    <small style={{ color: 'seagreen' }}>
                      Starting from {pkg.least_charge || 0.0}
                    </small>
                  </Tag>,
                ].concat(
                  !influencer.is_my_page
                    ? [
                        <AntButton
                          style={{
                            width: 'inherit',
                            height: 'inherit',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            border: 'none ',
                          }}
                          size="large"
                          color="primary"
                          onClick={() =>
                            navigate(
                              `/influencers/${influencer.slug}/order?package=${pkg.id}`,
                            )
                          }
                          icon={<ShoppingBasketOutlined fontSize="12px" />}
                        >
                          Order{' '}
                        </AntButton>,
                      ]
                    : [
                        <AntButton
                          style={{
                            width: '100%',
                            height: 'inherit',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            border: 'none ',
                          }}
                          size="large"
                          color="primary"
                          onClick={() => handleEditPackage(pkg)}
                          icon={<Edit fontSize="12px" />}
                        >
                          Edit
                        </AntButton>,
                        <AntButton
                          color="error"
                          style={{
                            width: '100%',
                            height: 'inherit',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            border: 'none ',
                          }}
                          size="large"
                          onClick={() => handleDeletePkg(pkg)}
                          icon={<Delete fontSize="12px" />}
                        >
                          Delete
                        </AntButton>,
                      ],
                )}
              >
                <Card.Meta
                  title={pkg.name}
                  description={pkg.description}
                  avatar={<Tag>ID {pkg.id}</Tag>}
                ></Card.Meta>
                {influencer.is_my_page && (
                  <div
                    style={{ position: 'absolute', right: '10px', top: '10px' }}
                  >
                    {pkg.private && <LockOutlined fontSize="14px" />}
                  </div>
                )}
                 <div
                    style={{ position: 'absolute', right: '10px', top: '10px' }}
                  >
                <Tag bordered={false}>

                  {pkg.acceptable_media_types}
                </Tag>
                </div>
              </Card>
            ))}

            {adPackages?.length <= 0 && <Empty description="No Packages" />}
          </div>
        ) : influencer.is_my_page ? (
          <div>
            <PackageAddEditForm
              influencer={influencer}
              setCompletedAction={setRecentAction}
              action={currentAction}
            />
          </div>
        ) : (
          <div>
            <PreviewPackage influencer={influencer} />
          </div>
        )}
      </Container>
    </>
  )
}


function PackageAddEditForm({ influencer, setCompletedAction, action = null }) {
  const { Option } = Select
  const pkg = SelectInfluencerOnViewActiveAdPAckage()
  const [activeMedium, setActiveMedium] = React.useState(null)
  const [addedMediums, setAddedMediums] = React.useState([])
  const [selectedContentType, setSelectedContentType] = React.useState(pkg?.content_type)
  const [selectedMediaTypes, setSelectedMediaTypes] = React.useState(pkg?.acceptable_media_types || [])
  const [selectedFulfillmentTime, setSelectedFulfilmentTime] = React.useState(pkg?.fulfillment_time)
  const [maxVal, setMaxVal] = React.useState(pkg?.media_max_length)
  const influencerError = SelectInfluencerErrors()
  const dispatch = useDispatch()
  const [isDefault, setIsDefault] = React.useState(pkg?.default)
  const [isPrivate, setIsPrivate] = React.useState(pkg?.private)


  React.useEffect(() => {
    if (pkg?.available_mediums.length >= 1) {
      const alreadyEM = []
      for (let i = 0; i < pkg.available_mediums.length; i++) {
        const svrM = pkg.available_mediums[i];
        const addedAcMedium = { medium: svrM.medium.id, charge: svrM.charge, charge_currency: svrM.charge_currency }

        if (i === 0) {
          setActiveMedium(svrM.medium)
        }
        alreadyEM.push(addedAcMedium)
      }
      setAddedMediums(alreadyEM)

    }
    if (influencerError instanceof Object) {

      const inputConts = document.querySelectorAll("#err-msg");
      for (let i = 0; i < inputConts.length; i++) {
        const element = inputConts[i];
        element.remove()
      }
      for (let key in influencerError) {
        const errorEl = document.createElement("small");
        errorEl.id = "err-msg";
        const inputCont = document.querySelector(
          `#pkg-field[name="${key}"]`
        );
        errorEl.style.color = "#9F5151";
        errorEl.style.fontSize = "12px";
        errorEl.textContent = influencerError[key]
        inputCont?.parentNode?.appendChild(errorEl);

      }
    }

  }, [influencerError])
  const handleCurrentAction = (e) => {
    e.preventDefault()
    const formData = Object.fromEntries(new FormData(e.target));
    formData.available_mediums = addedMediums
    formData.private = isPrivate
    formData.default = isDefault
    if (!selectedFulfillmentTime || selectedFulfillmentTime.length <= 0) {
      return notification.error({ message: "Please select fulfillment time" })
    }
    if (['all', 'file'].includes(selectedContentType) && (!selectedMediaTypes || selectedMediaTypes.length <= 0)) {
      return notification.error({ message: "Please select media types" })
    }
    if (!selectedContentType || selectedContentType.length <= 0) {
      return notification.error({ message: "Please select content type" })
    }
    if (addedMediums.filter(md => md.charge >= 1).length <= 0) {
      return notification.error({ message: "Please add atleast one medium" })
    }

    if (action === 'Create Package') {

      setCompletedAction('add')
      dispatch(createInfluencerAdPackages({ infSlug: influencer.slug, data: formData }))

    }
    else if (action === 'Update Package') {
      setCompletedAction('update')
      dispatch(updateInfluencerAdPackage({ infSlug: influencer.slug, data: formData, id: pkg.id }))


    }
  }
  return (
    <form onSubmit={handleCurrentAction}>

      <div style={{ display: "flex", flexDirection: 'column', marginTop: '10px', gap: 20 }}>

        <Typography.Title level={3}>{action && action}</Typography.Title>
        <FormControl required >
          <OutlinedInput inputProps={{ required: true, id: 'pkg-field', maxLength: 125 }} placeholder='Name' name={'name'} defaultValue={pkg?.name} />
        </FormControl>
        <FormControl required variant='outlined'>
          <TextField InputProps={{ id: 'pkg-field', required: true, maxLength: 1000 }} labe="Description" variant='outlined' rows={5} multiline placeholder='Description' name='description' defaultValue={pkg?.description} />
        </FormControl>

        <div style={{ display: 'flex', alignContent: 'end', justifyContent: "space-around", flexDirection: 'row', gap: 5 }}>

          <Select style={{ minWidth: '50px' }} size='middle' id='pkg-field' placeholder="Content Type" onChange={setSelectedContentType} defaultValue={selectedContentType}>

            {AD_PACKAGE_CHOICES.map(([key, value]) => (

              <Option value={key}>  {value}</Option>
            ))}
          </Select>
          <input hidden name='content_type' value={selectedContentType} />
          {['all', 'file'].includes(selectedContentType) && (
            <div style={{ display: "flex", flexDirection: 'column', gap: 4 }}>
              <Select style={{ minWidth: '50px' }} id='pkg-field' placeholder="Media Types" multiple onChange={setSelectedMediaTypes} defaultValue={selectedMediaTypes}>

                {FILE_TYPES.map(([key, value]) => (

                  <Option value={key}> {key} </Option>
                ))}
              </Select>
              <input hidden name='acceptable_media_types' value={selectedMediaTypes} />

              <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <InputNumber id='pkg-field' min={0} max={+maxVal} prefix={"MIN"} width={"50px"} size='small' defaultValue={pkg?.media_min_length || 1} name="media_min_length" />
                <InputNumber id='pkg-field' min={1} onChange={setMaxVal} max={5} prefix={"MAX"} width={"50px"} size='small' defaultValue={pkg?.media_max_length || 1} name="media_max_length" />
              </div>
            </div>
          )}
          <Select style={{ minWidth: '50px' }} onChange={setSelectedFulfilmentTime} placeholder="Fulfillment Time" id='pkg-field' defaultValue={selectedFulfillmentTime}>

            {FULFILLMENT_TIMES_CHOICES.map(([key, value]) => (

              <Option value={key}> {value}</Option>
            ))}
          </Select>
          <input hidden name='fulfillment_time' value={selectedFulfillmentTime} />


        </div>

        <Box sx={{ background: 'paper.background', padding: '10px', display: 'block', borderRadius: '10px', borderStyle: "solid", borderWidth: '0.1px', borderColor: alpha('#284134', 0.2) }} >
          {/* available mediums  */}
          <div style={{ display: 'flex', flexDirection: "column" }}>
          <div style={{ display: 'flex', flexDirection: "row" }}>
            {influencer.mediums?.map(md => (

              <Box onClick={() => setActiveMedium(md)} sx={{backgroundColor: activeMedium?.id === md.id ? alpha("#305d3a", 0.4) : alpha("#374434", 0.4), textAlign: 'center', "&:hover": { backgroundColor: alpha("#305d3a", 0.4) }, borderTopLeftRadius: '14px', width: '90px', borderTopRightRadius: '14px' }}>
                <IconButton style={{ display: 'flex',  margin: '0 auto',overflow:'hidden', width:'inherit', textOverflow:'ellipsis', lineHeight:1.5, alignContent: 'center', justifyContent: 'center' }}>
                  <small style={{ fontSize: '12px' }}>  {md.medium.display_name}</small>
                  <Avatar size={'small'} shape='square' src={md.medium.logo}></Avatar>
                </IconButton>
                {addedMediums.find(mdd => mdd.medium === md.id) && (
                  <Typography.Text  strong>
                    {addedMediums.find(mdd => mdd.medium === md.id).charge_currency} {""}{addedMediums.find(mdd => mdd.medium === md.id)?.charge}
                  </Typography.Text>)
                }
              </Box>
            ))}
            </div>
            <div id="media-tab-on-edit" style={{ padding: '10px', borderRadius: '10px', borderTopLeftRadius: '0px', width: '100%', minHeight: '10vh', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', backgroundColor: alpha("#305d3a", 0.4), display: activeMedium ? "flex" : 'none' }}>
              {activeMedium && (

                <div >
                  <InputLabel >{activeMedium.medium.display_name} Charge</InputLabel>
                  
                  <div >
                    <PriceInput inputProps={{ id: 'pkg-field' }} onChange={(val) => { setAddedMediums(prev => [...prev.filter(md => md.medium !== activeMedium.id), { medium: activeMedium.id, charge: val.number, charge_currency: val.currency }]) }} value={{
                      number: '',
                      currency: '',
                    }} currentProduct={{ price_amount:  addedMediums.filter(adMd=>adMd.medium === activeMedium.id)[0]?.charge || 0, price_currency: addedMediums.filter(adMd=>adMd.medium === activeMedium.id)[0]?.charge_currency || 'NGN' }} />
                  </div>
                  <Alert variant='outlined' sx={{ marginTop: 1 }} icon={<Info />} color='info'>
                    <small>Leave charge at 0 NGN if you do not want this medium available to package</small></Alert>
                </div>
              )}

            </div>
          </div>
          {influencer.mediums?.length <= 0 && (

            <Empty description="Please add some Mediums or wait for approval if you already got some pending" />
          )}
        </Box>


        <div style={{ display: 'flex', padding: '10px', alignContent: 'end', justifyContent: "space-between", flexDirection: 'row', gap: 10 }}>

          <FormControl variant='outlined'>
            <InputLabel size='small' variant='standard' style={{ marginTop: '10px' }}>Private</InputLabel>
            <Switch type='checkbox' id='pkg-field' onChange={(e) => setIsPrivate(e.target.checked)} defaultChecked={pkg?.private}></Switch>
            {isPrivate}
          </FormControl>
          <FormControl variant='outlined'>
            <InputLabel size='small' variant='standard' style={{ marginTop: '10px' }}>Default</InputLabel>
            <Switch id='pkg-field' onChange={(e) => setIsDefault(e.target.checked)} defaultChecked={pkg?.default}></Switch>

          </FormControl>

          <FormControl variant='outlined'>                   <InputLabel size='small' variant='outlined'>

            Priority</InputLabel>

            <OutlinedInput size='small' placeholder='Priority' type='number' id='pkg-field' name='priority' defaultValue={pkg?.priority || 1} />

          </FormControl>
        </div>
        <FormControl>
          <Button sx={{ marginTop: '10px' }} fullWidth type='submit' variant='contained'>{action}</Button>
        </FormControl>

      </div>              </form>

  )
}

function PreviewPackage({ pkg }) {

  return (

    <>

    </>
  )
}
