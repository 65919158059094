const environments = {
  development: {
    BANKTRANSFER_API_KEY: process.env.REACT_APP_BANKTRANSFER_API_DEV_KEY,
    PAYSTACK_API_KEY: process.env.REACT_APP_PAYSTACK_API_DEV_KEY,
    PAYSTACK_PUB_KEY: process.env.REACT_APP_PAYSTACK_PUB_DEV_KEY,
    FLUTTERWAVE_API_KEY: process.env.REACT_APP_FLUTTERWAVE_API_DEV_KEY,
    FLUTTERWAVE_PUB_KEY: process.env.REACT_APP_FLUTTERWAVE_PUB_DEV_KEY,
    PLATFORM_WALLET_API_KEY: process.env.REACT_APP_PLATFORM_WALLET_API_DEV_KEY,
    SERVER_ADDR: process.env.REACT_APP_SERVER_DEV_ADDR,
    SOCKET_URL: process.env.REACT_APP_SOCKET_DEV_URL,
  },
  test: {
    PAYSTACK_PUB_KEY: process.env.REACT_APP_PAYSTACK_PUB_TEST_KEY,
    BANKTRANSFER_API_KEY: process.env.REACT_APP_BANKTRANSFER_API_TEST_KEY,

    PAYSTACK_API_KEY: process.env.REACT_APP_PAYSTACK_API_TEST_KEY,
    FLUTTERWAVE_API_KEY: process.env.REACT_APP_FLUTTERWAVE_API_TEST_KEY,
    FLUTTERWAVE_PUB_KEY: process.env.REACT_APP_FLUTTERWAVE_PUB_TEST_KEY,
    PLATFORM_WALLET_API_KEY: process.env.REACT_APP_PLATFORM_WALLET_API_TEST_KEY,
    SERVER_ADDR: process.env.REACT_APP_SERVER_TEST_ADDR,
    SOCKET_URL: process.env.REACT_APP_SOCKET_TEST_URL,
  },
  production: {
    BANKTRANSFER_API_KEY: process.env.REACT_APP_BANKTRANSFER_API_PROD_KEY,

    PAYSTACK_PUB_KEY: process.env.REACT_APP_PAYSTACK_PUB_PROD_KEY,

    PAYSTACK_API_KEY: process.env.REACT_APP_PAYSTACK_API_PROD_KEY,
    FLUTTERWAVE_API_KEY: process.env.REACT_APP_FLUTTERWAVE_API_PROD_KEY,
    FLUTTERWAVE_PUB_KEY: process.env.REACT_APP_FLUTTERWAVE_PUB_PROD_KEY,
    PLATFORM_WALLET_API_KEY: process.env.REACT_APP_PLATFORM_WALLET_API_PROD_KEY,
    SERVER_ADDR: process.env.REACT_APP_SERVER_PROD_ADDR,
    SOCKET_URL: process.env.REACT_APP_SOCKET_PROD_URL,
  },
};

const APP_ENV =  environments[process.env.REACT_APP_ENV] 
export default APP_ENV
