export default {
    "0": [{
        "field": "text",
        "name": "link",
        "label": "Link",
        "required": true,
        "type": "string"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity",
        "required": true,
        "type": "integer"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "1": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "textarea",
        "name": "keywords",
        "label": "Keywords (1 per line)",
        "required": true
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "2": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity",
        "disabled": true,
        "related": "comment"
    }, {
        "field": "textarea",
        "name": "comment",
        "label": "Comments (1 per line)"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "9": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "textarea",
        "name": "mentionUsernames",
        "label": "Usernames (1 per line)"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "3": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "textarea",
        "name": "usernames",
        "label": "Usernames (1 per line)"
    }, {
        "field": "textarea",
        "name": "hashtags",
        "label": "Hashtags (1 per line)"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "4": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity",
        "disabled": true,
        "related": "usernames_custom"
    }, {
        "field": "textarea",
        "name": "usernames_custom",
        "label": "Usernames (1 per line)"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "6": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "text",
        "name": "hashtag",
        "label": "Hashtag"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "7": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "text",
        "name": "username",
        "label": "Username"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "8": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "text",
        "name": "mediaUrl",
        "label": "Media URL"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "10": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "12": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "checkbox",
        "name": "check",
        "label": "Drip-feed"
    }, {
        "field": "text",
        "name": "runs",
        "label": "neworder.runs"
    }, {
        "field": "text",
        "name": "interval",
        "label": "neworder.interval"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "14": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "textarea",
        "name": "comment",
        "label": "Comments (1 per line)"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "100": [{
        "field": "text",
        "name": "username",
        "label": "Username"
    }, {
        "field": "text",
        "name": "posts",
        "label": "New posts"
    }, {
        "field": "text",
        "name": "old_posts",
        "label": "Old posts"
    }, {
        "field": "text",
        "name": "min",
        "label": "Quantity"
    }, {
        "field": "text",
        "name": "max",
        "label": "Quantity"
    }, {
        "field": "text",
        "name": "delay",
        "label": "Delay"
    }, {
        "field": "text",
        "name": "expiry",
        "label": "Expiry"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "15": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "text",
        "name": "comment_username",
        "label": "Username of the comment owner"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "16": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "textarea",
        "name": "keywords",
        "label": "Keywords (1 per line)",
        "required": true
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "17": [{
        "field": "text",
        "name": "link",
        "label": "Link",
        "required": true,
        "type": "string"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity",
        "required": true,
        "type": "integer"
    }, {
        "field": "text",
        "name": "answer_number",
        "label": "Answer number"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "18": [{
        "field": "text",
        "name": "username",
        "label": "Username"
    }, {
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity",
        "disabled": true,
        "related": "comment"
    }, {
        "field": "textarea",
        "name": "comment",
        "label": "Comments (1 per line)"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "19": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "textarea",
        "name": "keywords",
        "label": "Keywords (1 per line)",
        "required": true
    }, {
        "field": "text",
        "name": "email",
        "label": "Email"
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }],
    "20": [{
        "field": "text",
        "name": "link",
        "label": "Link"
    }, {
        "field": "text",
        "name": "quantity",
        "label": "Quantity"
    }, {
        "field": "textarea",
        "name": "groups",
        "label": "Groups",
        "required": true
    }, {
        "field": "text",
        "name": "average_time",
        "label": "Average time",
        "disabled": true,
        "type": "string"
    }]
}