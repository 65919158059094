import { createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../../app_context"

export const fetchOrders = createAsyncThunk(
  'adGig/fetchOrders',
  async (payload, thunApi) => {
    try {
      const response = await client.get(`/in-hub/api/orders/`)
      console.log(response.data);
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)


export const fetchActiveInHubOrder = createAsyncThunk(
  'adGig/fetchOrder',
  async (payload, thunApi) => {
    try {
      const response = await client.get(`/in-hub/api/orders/${payload.code}/`)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)

export const updateOrder = createAsyncThunk(
  'adGig/updateOrder',
  async (payload, thunApi) => {
    try {
      const response = await client.put(`/in-hub/api/orders/${payload.id}/`, payload)
      return response.data
    } catch (error) {
      return Promise.reject(JSON.stringify(error.response?.data || error))
    }
  },
)