import {
  ArrowBackOutlined,
  ArrowForwardIos,
  ArrowForwardIosRounded,
  ArrowForwardOutlined,
  BlockOutlined,
  Cancel,
  CancelOutlined,
  CancelSharp,
  CancelTwoTone,
  Close,
  DeleteForever,
  Face2Rounded,
  Forward,
  Forward10Outlined,
  ForwardToInbox,
  ForwardToInboxTwoTone,
  ForwardTwoTone,
  MessageOutlined,
  MoreVertOutlined,
  Report,
} from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import {
  Badge,
  Avatar,
  Modal,
  Input,
  Form,
  Select,
  notification,
  message,
  Button,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React from "react";
import { useCallback } from "react";
import { memo } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL, client } from "../../app_context";
import ChatNotificaton from "./ChatNotificaton";
import GroupChatMessage from "./GroupChatMessage";
import PrivateChatMessage from "./PrivateChatMessage";
import TicketChatMessage from "./TicketChatMessage";

const GetChatMessage = memo(
  ({ chat_obj, user, selectMessagesOn, ...others }) => {
    // var chat_obj = chat.chat;
    if (chat_obj.id !== null && chat_obj.id !== undefined) {
      if (
        (chat_obj.group === null || chat_obj.group === undefined) &&
        (chat_obj.ticket === null || chat_obj.ticket === undefined)
      ) {
        return (
          <>
            <PrivateChatMessage
              key={chat_obj.uid}
              // setCurrentChat={}
              user={user}
              root_chat={chat_obj}
              selectedMessages={others.selectedMsgs}
              setselectedMessages={others.setSelectedMsgs}
              selectMsg={selectMessagesOn}
            />
          </>
        );
      } else if (chat_obj.group !== null && chat_obj.group !== undefined) {
        return (
          <GroupChatMessage
            // setCurrentChat={}
            key={chat_obj.uid}
            user={user}
            chat={chat_obj}
            selectMsg={selectMessagesOn}
          />
        );
      } else if (chat_obj.ticket !== null && chat_obj.ticket !== undefined) {
        return (
          <TicketChatMessage
            // setCurrentChat={}
            key={chat_obj.uid}
            user={user}
            chat={chat_obj}
            selectMsg={selectMessagesOn}
          />
        );
      } else {
        return <p>Invalid Chat</p>;
      }
    } else {
      return (
        <Box
          sx={{
            padding: "20px",
            margin: "0 auto",
            height:{md:'inherit', xs:'600px'},
            width: "70%",
            color:"grey",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "60px", color: "rgb(39, 54, 50)" }}>
            <MessageOutlined color="#f2fafa" fontSize="60px" />
          </div>
          <h2>
            Start a conversation with product sellers and potential buyers
          </h2>
          <h4>
            Talk with fellow sellers and grow your community with chats{" "}
            <Face2Rounded />
          </h4>
<Box sx={{display:{md:'none', xs:'block'}}}>
          <Button onClick={()=>others.gotoChats()} type="primary">See Chat List</ Button></Box>
          <br/>
          <Link to={'/'}>Go Back Shopping</Link>
        </Box>
        
        
      );
    }
  }
);

function ChatContent({ selectedCht, usr, ...others }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const moreOpen = Boolean(anchorEl);
  const [selectMessages, setselectMessages] = useState(false);
  const { Option } = Select;
  const [chatBlocked, setChatBlocked] = useState(null);
  const [deleted, setdeleted] = useState(false);
  const [reportForm] = Form.useForm();
  const [reportAttachement, setReportAttachement] = useState();
  const [selectedMsgList, setSelectedMsgList] = useState([]);
  // alert(selectedCht.members.filter((mm) => mm.username !== usr.username)[0].blocked)
  const handleFinalChatSelectForWard = (MsgLi) => {
    if (selectedMsgList.length <= 0) {
      return message.info("Please select atleast one message!");
    }
    if (selectedMsgList.length > 10) {
      return message.info("Selected Message too much (maximum is 10)!");
    }
    others.setSendingButtons(true);

    others.setSendingMIdList(selectedMsgList);

    others.gotoChats();
  };
  const handleFinalChatSelectClose = () => {
    others.setSendingButtons(false);
    setSelectedMsgList([]);
    setselectMessages(false);
    others.gotoMsg();
  };

  React.useEffect(() => {
    setdeleted(false);
    setChatBlocked(false);

    setReportAttachement(null);
    reportForm.resetFields();
    handleFinalChatSelectClose();
  }, [selectedCht]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  const handleBlockUser = () => {
    Modal.confirm({
      title:
        selectedCht.members.filter((mm) => mm.username !== usr.username)[0]
          .blocked === true || chatBlocked === true
          ? "Unblock User"
          : "Block User",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        client
          .post(`/chat/api/${selectedCht.uid}/actions`, {
            action:
              selectedCht.members.filter(
                (mm) => mm.username !== usr.username
              )[0].blocked === true || chatBlocked === true
                ? "Unblock_Member"
                : "Block_Member",
            private: true,
          })
          .then((resp) => {
            notification.success({ message: resp.data.msg });
            if (chatBlocked === null) {
              setChatBlocked(
                !selectedCht.members.filter(
                  (mm) => mm.username !== usr.username
                )[0].blocked
              );
            } else {
              setChatBlocked(!chatBlocked);
            }
          })
          .catch((err) => message.error("error deleting chat"));
      },
      icon: <BlockOutlined />,
      content: (
        <Box style={{ textAlign: "center" }}>
          {selectedCht.members.filter((mm) => mm.username !== usr.username)[0]
            .blocked === true || chatBlocked === true ? (
            <h1>
              Unblock{" "}
              {
                selectedCht.members.filter(
                  (mm) => mm.username !== usr.username
                )[0].user.username
              }
              ?
            </h1>
          ) : (
            <h1>
              Confirm you want to block{" "}
              {
                selectedCht.members.filter(
                  (mm) => mm.username !== usr.username
                )[0].user.username
              }
              ?
            </h1>
          )}
        </Box>
      ),
    });

    handleClose();
  };
  const handleDeleteChat = () => {
    {
      deleted
        ? notification.info("Already deleted")
        : Modal.confirm({
            title: "Delete Chat",
            okButtonProps: { style: { backgroundColor: "red" } },
            onOk: () => {
              client
                .post(`/chat/api/${selectedCht.uid}/actions`, {
                  action: "Delete_Chat",
                  private: true,
                })
                .then((resp) => {
                  notification.success({ message: resp.data.msg });
                  setdeleted(true);
                })
                .catch((err) => message.error("error deleting chat"));
            },
            okText: "Delete",
            icon: <DeleteForever />,
            content: (
              <Box style={{ textAlign: "center" }}>
                <h1>Are you Sure You Want To Delete This Chat?</h1>
                <small style={{ color: "red" }}>
                  This action is irreversible
                </small>
              </Box>
            ),
          });
    }
    handleClose();
  };

  const handleUserReportUpload = () => {
    const formData = new FormData();
    formData.append(
      "report_message",
      reportForm.getFieldValue("report_message")
    );
    formData.append("report_type", reportForm.getFieldValue("report_type"));
    formData.append("action", reportForm.getFieldValue("action"));
    formData.append("private", reportForm.getFieldValue("private"));

    const fileInput = document.getElementById("rp_attachment").files;
    if (fileInput[0]) {
      formData.append("report_attachment", fileInput[0], fileInput[0]?.name);
    }

    client
      .post(`/chat/api/${selectedCht.uid}/actions`,
        // reportForm.getFieldsValue()
        formData
      )
      .then((resp) => {
        notification.success({ message: resp.data.msg });
        reportForm.resetFields();
        setReportAttachement(null);
      })
      .catch((err) => message.error(String(err)));
  };
  const handleReportUser = () => {
    Modal.confirm({
      title: "Report User",
      icon: <Report />,
      onOk: () => {
        handleUserReportUpload();
      },
      okText: <span>Report</span>,
      okButtonProps: { style: { backgroundColor: "red" } },
      content: (
        <Box>
          <Form form={reportForm}>
            <Form.Item
              name={"report_type"}
              initialValue={"S"}
              rules={[{ required: true }]}
              label={"Type"}
            >
              <Select defaultValue={"S"}>
                <Option value={"S"}>Spam Messages</Option>
                <Option value={"B"}>Bot</Option>
                <Option value={"T"}>Threatening Messages</Option>
                <Option value={"I"}>Impersonator</Option>
              </Select>
            </Form.Item>

            <Form.Item name={"report_message"} label="Enter Report Message">
              <Input.TextArea placeholder="Additional Information" />
            </Form.Item>

            <Form.Item hidden name={"action"} initialValue={"Report_Member"}>
              <Input type="text" value={"Report_Member"} />
            </Form.Item>

            <Form.Item hidden initialValue={true} name={"private"}>
              <Input value={true} />
            </Form.Item>
            <Form.Item
              name={"report_attachment"}
              label="Upload an attachment (optional)"
            >
              <Input
                type="file"
                id="rp_attachment"
                onChange={(e) => {
                  setReportAttachement(e.target.files[0]);
                }}
              />
            </Form.Item>
          </Form>
        </Box>
      ),
    });
    handleClose();
  };
  const handleCloseTicket = () => {
    Modal.confirm({
      title: "Close Ticket",
      icon: <Report />,
      onOk: () => {
        notification.success({ message: "Ticket Closed" });
      },
      okText: <span>Report</span>,
      okButtonProps: { style: { backgroundColor: "red" } },
      content: (
        <Box style={{ textAlign: "center" }}>
          <h1>Are you Sure You Want To Close this Ticket?</h1>
        </Box>
      ),
    });
    handleClose();
  };
  return (
    <>
      <Header
        title={
          selectedCht.members?.filter((mm) => mm.username !== usr.username)[0]
            .user.username
        }
        className="chat-header"
        style={{
          backgroundColor: deleted ? "rgb(169, 96, 96)" : "#f2fafa ",

          marginBottom: "10px",
          margin: "0px !important",
          width: "100%",
          boxShadow: shadows[5],
        }}
      >
        <IconButton
          sx={{
            display: {
              md: "none",
              xs: "block",
              position: "absolute",
              zIndex: 1000,
              left: 0,
              marginTop: "-5px",
            },
          }}
          onClick={() => others.gotoChats()}
        >
          <ArrowBackOutlined />
        </IconButton>

        {selectedCht.id ? (
          <Box>
            <div>
              <Avatar
                src={
                  selectedCht.avatar
                    ? selectedCht.avatar
                    : selectedCht.members.filter(
                        (item) => item.username !== usr.username
                      )[0]?.username
                }
                onClick={() => others.gotoDetails()}
                style={{
                  float: "left",
                  top: "10px",
                  margin: "5px", backgroundColor: "whitesmoke",
                  cursor: "pointer",
                }}
              />
              <h2
                style={{
                  float: "left",
                  padding: "10px",
                  fontSize: "20px",
                  marginTop: "10px",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  lineHeight: 1,
                  height: "50px",
                  overflow: "hidden",
                  width: "70%",
                }}
                onClick={() => others.gotoDetails()}
              >
                {selectedCht.type === "Private" ? (
                  <>
                    {" "}
                    {
                      selectedCht.members.filter(
                        (item) => item.username !== usr.username
                      )[0]?.username
                    }{" "}
                    {/* <Badge style={{ top: "-3px" }} status="success"></Badge> */}
                  </>
                ) : (
                  selectedCht.name
                )}{" "}
              </h2>
            </div>

            <Box
              sx={{
                float: { md: "right" },
                right: { xs: 0 },
                marginRight: "10px",
                position: { md: "relative", xs: "absolute" },
              }}
            >
              <div style={{ display: "inline-block" }}>
                {selectMessages ? (
                  others.sendingMActive === true ? (
                    <IconButton
                      title="cancel"
                      onClick={() => handleFinalChatSelectClose()}
                    >
                      <Close />
                    </IconButton>
                  ) : (
                    <IconButton
                      title="forward"
                      onClick={() =>
                        handleFinalChatSelectForWard(selectedMsgList)
                      }
                    >
                      <i class="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                    </IconButton>
                  )
                ) : (
                  ""
                )}
              </div>
              <div style={{ display: "inline-block" }}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={moreOpen ? "long-menu" : undefined}
                  aria-expanded={moreOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertOutlined />
                </IconButton>

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  open={moreOpen}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      // width: "20ch",
                    },
                  }}
                >
                  <MenuItem
                    key={"Select"}
                    onClick={() => {
                      handleClose();
                      setselectMessages(!selectMessages);
                      if (selectMessages === true) {
                        handleFinalChatSelectClose();
                      }
                    }}
                  >
                    {selectMessages === false ? "Select" : "Cancel select"}
                  </MenuItem>
                  {!selectedCht.ticket ? (
                    <>
                      <MenuItem
                        key={"View profile"}
                        onClick={() => {
                          handleClose();
                          window.open(
                            `/profile/${
                              selectedCht.members.filter(
                                (mm) => mm.username !== usr.username
                              )[0].user.username
                            }`
                          );
                        }}
                      >
                        {"View Profile"}
                      </MenuItem>

                      <MenuItem key={"Delete Chat"} onClick={handleDeleteChat}>
                        {"Delete Chat"}
                      </MenuItem>

                      <MenuItem key={"Report User"} onClick={handleReportUser}>
                        {"Report User"}
                      </MenuItem>
                      <MenuItem key={"Block User"} onClick={handleBlockUser}>
                        {selectedCht.members.filter(
                          (mm) => mm.username !== usr.username
                        )[0].blocked === true || chatBlocked === true
                          ? "Unblock"
                          : "Block "}
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem
                        key={"Close Ticket"}
                        onClick={handleCloseTicket}
                      >
                        {"Close Ticket"}
                      </MenuItem>
                      <MenuItem key={"Report User"} onClick={handleReportUser}>
                        {"Report Agent"}
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </div>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Header>

      <Box
        style={{
          opacity: deleted ? 0.3 : 1,
          height: "100%",
          zIndex: "0 !important",
        }}
      >
        <Content>
          <Box>
            {deleted ? (
              <Box
                sx={{
                  fontSize: { md: "32px", xs: "20" },
                  margin: { xs: "100px", md: "120px" },
                }}
                style={{
                  color: "red",
                  display: "grid",
                  justifyContent: "center",
                  position: "absolute",
                  width: "inherit",
                  height: "inherit",
                  zIndex: 999,
                }}
              >
                {" "}
                <h1>Chat Deleted</h1>{" "}
              </Box>
            ) : (
              ""
            )}
            <GetChatMessage
              chat_obj={selectedCht}
              selectMessagesOn={selectMessages}
              user={usr}
              selectedMsgs={selectedMsgList}
              setSelectedMsgs={setSelectedMsgList}
              gotoChats={others.gotoChats}
            />
          </Box>{" "}
        </Content>
      </Box>
    </>
  );
}
export default memo(ChatContent);
