import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import { Opacity } from '@mui/icons-material';
import APP_ENV from '../../env';
const BASE_URL = APP_ENV.SERVER_ADDR;

export default function SellerWelcome(props) {

    return (
        <>

            <Box sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper', borderRadius: '30px', margin: 'auto', padding: '50px' }}>

                <Box sx={{ my: 3, mx: 2 }}>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <small style={{ fontFamily: 'courier' }}>


                                Congrats!, your account is now ready for sales.
                            </small>
                            <Typography gutterBottom variant="h4" component="div">
                                {props.full_name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" component="div">
                                <small style={{ 'backgroundColor': 'darkgreen', color: 'white', padding: '5px', borderRadius: '4px', boxShadow: '1px 2px,2px 3px green' }}>seller</small>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography color="text.secondary" variant="body2">
                        {props.bio}
                        <Avatar style={{ float: 'right' }} sx={{ width: 56, height: 56 }}
                            src={ props.display_picture}></Avatar>
                    </Typography>
                </Box>
                <Divider variant="middle" />
                <Box sx={{ m: 2 }}>
                    <Typography gutterBottom variant="body1">
                        Product Categories
                    </Typography>
                    <Stack spacing={1} >
                        {props.seller_category.map((val, index) => {
                            return (
                                <>
                                    {index === 1 ?
                                        <Chip color='primary' label={val} />
                                        :
                                        <Chip label={val} />

                                    }


                                </>)


                        })}
                    </Stack>
                    <small id='clickME' style={{ fontFamily: 'Ariel', fontSize: '12px', float: 'center', opacity: '0', transition: '0.5s ease-in' }}>Click Finish To Complete Activation.</small>

                </Box>
            </Box></>
    );
}