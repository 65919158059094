import React, { forwardRef,useState, useImperativeHandle } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Divider, FormLabel, IconButton } from '@mui/material';
import { CheckBox, FilterList, RestartAlt } from '@mui/icons-material';
import { Card,Checkbox, List, message, Slider, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { client } from './app_context';





const ProductFilter = forwardRef((props, ref) => {
    useImperativeHandle(
      ref,
      ()=>{
      }
     ,
      [])
const [age, setAge] = React.useState("");
const [selectedCategory, setSelectedCategory] = useState('Social Media')
const [priceValue, setpriceValue] = useState([10, 100]);
const [filterValues, setfilterValues] = useState({fields:[]})
const [maxPrice, setMaxPrice] = useState(10000)
const handleChange = (event) => {
  setAge(event.target.value);
};

React.useEffect(()=>{
    
    client.get('/user/api/product-filter').then(resp=>{
    if (resp.data.maxPrice){setpriceValue([resp.data.minPrice, resp.data.maxPrice]);} 
    setfilterValues(resp.data);
    setMaxPrice(resp.data.maxPrice);
    }).catch(err=> message.error('unable to load filters'))
}, [])

const handleReset=()=>{

// clear changes 
setfilterValues({ fields: [] });
    client
      .get("/user/api/product-filter")
      .then((resp) => {
        if (resp.data.maxPrice) {
          setpriceValue([resp.data.minPrice, resp.data.maxPrice]);
        }
        setfilterValues(resp.data);
        setMaxPrice(resp.data.maxPrice);
      })
      .catch((err) => message.error("unable to load filters"));
}
  return (
    <>
      <Box
        sx={props.sx}
        style={{
          // backgroundColor: "whitesmoke",
          marginTop: "10px",
          boxShadow: "0.1px 0.1px 0.1px 0.1px",
          height: "130vh",
        }}
      >
        <IconButton style={{ float: "right" }} onClick={() => {props.reset();handleReset()}}>
          <RestartAlt></RestartAlt>
          <small style={{ fontSize: "12px" }}>Reset</small>
        </IconButton>

        <Box
          className="contentNames"
          sx={{
            margin: "5px",
            marginTop: "30px",
            padding: "5px",
            width: { xs: 300, md: "100%" },
          }}
        >
          <Card title="Price range">
            <Slider
              max={maxPrice}
              onChange={(val) => {setpriceValue(val);
              }}
              onAfterChange={(val)=>{
                      props.ApplyFilter({ value: val, key: 'price' })
                    }
              
              
              }
              range
              marks={{ label: <></> }}
              defaultValue={priceValue}
            />
            <b
              style={{
                justifyContent: "center",
                display: "grid",
                opacity: "0.6",
              }}
            >
              {priceValue[0]} {filterValues.currency} - {priceValue[1]} {filterValues.currency}
            </b>
          </Card>
          <Card
            title="Categories"
            children={
              <Card><Card.Grid  title="Social Media">
                <Link
                  to="?categories=sm"
                  style={{ color: "darkgreen" }}
                >
                  Social Media
                </Link>
                </Card.Grid>
                <Card.Grid hoverable={false} aria-disabled title="Phone Numbers" disable>

                <Link
                
                  to="?categories=pn"
                  style={{ color: "darkgreen" }}
                >
                    Phone numbers
                  
                </Link></Card.Grid>                  <Card.Grid hoverable={false}  title="Financial assets" aria-disabled>

                <Link
                  to="?categories=fa"
                  style={{ color: "darkgreen" }}
                >
                    Financial Assets
                </Link>                        </Card.Grid>
            <Card.Grid hoverable={false}  title="Game assets" aria-disabled>

                <Link
                  to="?categories=ga"
                  style={{ color: "darkgreen" }}
                >
                    Game Assets
                </Link>                  </Card.Grid>

              </Card>
            }
          ></Card>
          {filterValues.fields.length>=1? 
          filterValues.fields.map(field=>(
          <>
          {field.values.length >= 1 ? (
            <Card
              title={<span style = {
                {textTransform:'capitalize'}
              }
              >{ field.key === 'socialMedia'?'Media Type': field.key.toString().replace('_', ' ')}</span>}
              style={{
                marginTop: "10px",
                height: "350px",
                overflowY: "scroll",
              }}
            >
              {field.values.map((value) => (
                <Card.Grid
                  style={{
                    display: "inline-block",
                    padding: "10px",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Switch
                    onChange={(status) =>
                      { props.ApplyFilter({
                        value: value,
                        key: field.key,
                        show:status,
                      });}
                    }
                    style={{
                      width: "40px",
                      borderColor: "green",
                      float: "right",
                    }}
                  />
                  <span> {value} </span>
                </Card.Grid>
              ))}
            </Card>
          ) : (
            ""
          )}
        </>
        ))
        
            : ''}
        </Box>
      </Box>
      {/* <Divider /> */}
    </>
  );
})
export default ProductFilter;