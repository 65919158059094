import {
  AttachFile,
  AttachFileOutlined,
  AttachFileRounded,
  Clear,
  DocumentScanner,
  EmojiEmotionsOutlined,
  FilePresent,
  Radio,
  RadioButtonCheckedOutlined,
  RadioButtonCheckedSharp,
  Send,
  SendOutlined,
  Telegram,
} from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Container,
  IconButton,
  Modal,
} from "@mui/material";
import { Avatar, Divider, Form, Image, Input, Upload } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useContext, useRef } from "react";
import AppContext, { BASE_SOCKET_URL } from "../../app_context";
import ChatMessage from "./ChatMessage";
import ChatNotificaton from "./ChatNotificaton";
import InputEmoji from "react-input-emoji";

import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import FileWidget from "./fileWidget";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "rgb(37, 44, 48)",
  // border: "2px solid #000",
  color: "whitesmoke",
  boxShadow: 24,
  p: 4,
};

export default function TicketChatMessage({ setCurrentChat, user, chat, selectMsg }) {
  const [messages, setMessages] = useState([]);
  const [selectedMessages, setselectedMessages] = useState([]);
  const [text, setText] = useState("");
  const [attachFile, setAttachFile] = useState(false);
    const [userToken, setUserToken] = useState(
      localStorage.getItem("AccgToken")
    );

  const [socketUrl, setSocketUrl] = useState(BASE_SOCKET_URL + `/ws/api/${chat.uid}/messages/`)
  const {lastJsonMessage, sendJsonMessage, readyState} = useWebSocket(socketUrl, {queryParams:{token: userToken}})
  const [fileList, setFileList] = useState([]);
  const attachmentRef = useRef(null);

  React.useEffect(() => {
    getMessages();

    // const el = document.getElementById("messages-body-box-cont");
    // el.scrollTo(0, el.scrollHeight);
  }, [lastJsonMessage, selectMsg]);

  const getMessages = () => {
  
  if(lastJsonMessage){
  console.log(lastJsonMessage)
  setMessages(lastJsonMessage)
  }
  
    // setMessages([
    //   {
    //     id: 1,
    //     body: "Hi",
    //     dateCreated: "3 min ago",
    //     date: "2nd march, 2022",
    //     time: "3:18am",

    //     sender: {
    //       username: "orian",
    //       avatar: "http://127.0.0.1:8000/media/images/userimages/IMG_4986.PNG",
    //     },
    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: false },
    //       { chat_member: { username: "orian" }, read: true },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     body: "How far",
    //     dateCreated: "7 min ago",

    //     date: "2nd march, 2022",
    //     time: "3:19am",
    //     sender: {
    //       username: "kanemanuel",
    //       avatar:
    //         "http://127.0.0.1:8000/media/images/userimages/image_9_l0CGk8y.jpg",
    //     },
    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: true },
    //       { chat_member: { username: "orian" }, read: true },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     body: "i  dey ",
    //     dateCreated: "9 min ago",

    //     date: "2nd march, 2022",
    //     time: "3:19am",
    //     sender: {
    //       username: "orian",
    //       avatar: "http://127.0.0.1:8000/media/images/userimages/IMG_4986.PNG",
    //     },
    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: true },
    //       { chat_member: { username: "orian" }, read: true },
    //     ],
    //   },
    //   {
    //     id: 4,
    //     body: "i have something to send you ",
    //     dateCreated: "9 min ago",
    //     date: "3nd march, 2022",
    //     time: "7:19am",
    //     sender: {
    //       username: "orian",
    //       avatar: "http://127.0.0.1:8000/media/images/userimages/IMG_4986.PNG",
    //     },

    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: true },
    //       { chat_member: { username: "orian" }, read: true },
    //     ],
    //   },
    //   {
    //     id: 5,
    //     dateCreated: "20 min ago",

    //     date: "9nd march, 2022",
    //     time: "8:19am",
    //     body: "okay send... but serilously i dont know how you got to chat me up asking to send me something for me if not for you lorema ll sekd ",
    //     sender: {
    //       username: "kanemanuel",
    //       avatar:
    //         "http://127.0.0.1:8000/media/images/userimages/image_9_l0CGk8y.jpg",
    //     },
    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: true },
    //       { chat_member: { username: "orian" }, read: false },
    //     ],
    //   },
    //   {
    //     id: 6,
    //     body: "i have something to send you some docs ",
    //     dateCreated: "12 min ago",

    //     date: "9nd march, 2022",
    //     time: "3:19am",
    //     sender: {
    //       username: "orian",
    //       avatar: "http://127.0.0.1:8000/media/images/userimages/IMG_4986.PNG",
    //     },

    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: false },
    //       { chat_member: { username: "orian" }, read: true },
    //     ],
    //   },
    //   {
    //     id: 8,
    //     body: "check this out tooo ",
    //     dateCreated: "18 min ago",

    //     date: "10nd march, 2022",
    //     time: "3:19am",
    //     sender: {
    //       username: "orian",
    //       avatar: "http://127.0.0.1:8000/media/images/userimages/IMG_4986.PNG",
    //     },
    //     attachments: [
    //       {
    //         name: "first audio",
    //         type: "audio/mp3",
    //         url: "http://127.0.0.1:8000/media/images/productsFiles/Anidave_our_God_is_good.mp3",
    //       },
    //       {
    //         name: "first pdf",
    //         type: "application/pdf",
    //         url: "http://127.0.0.1:8000/media/images/productsFiles/batch_C_3KYkJA8.pdf",
    //       },
    //     ],
    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: false },
    //       { chat_member: { username: "orian" }, read: true },
    //     ],
    //   },
    //   {
    //     id: 9,
    //     body: "some songs for you girl ",
    //     dateCreated: "1 min ago",
    //     date: "1nd april, 2022",
    //     time: "3:19am",
    //     attachments: [
    //       {
    //         name: "lasttime many",
    //         type: "video/mp3",
    //         url: "http://127.0.0.1:8000/media/images/productsFiles/game_of_trouble.mp4",
    //       },
    //       {
    //         name: "mj uina",
    //         type: "image/jpeg",
    //         url: "http://127.0.0.1:8000/media/reports/user/files/0C0F9680-21CF-40D6-A26C-189D0418DB2D.png_JiknYst.jpg",
    //       },
    //       {
    //         name: "mj uina778",
    //         type: "image/jpeg",
    //         url: "http://127.0.0.1:8000/media/reports/user/files/0C0F9680-21CF-40D6-A26C-189D0418DB2D.png_JiknYst.jpg",
    //       },
    //     ],

    //     sender: {
    //       username: "kanemanuel",
    //       avatar: "http://127.0.0.1:8000/media/images/userimages/IMG_4986.PNG",
    //     },

    //     receipts: [
    //       { chat_member: { username: "kanemanuel" }, read: false },
    //       { chat_member: { username: "orian" }, read: true },
    //     ],
    //   },
    // ]);
    setTimeout(() => {
      
    const el = document.getElementsByClassName("msg-box-date-read");
    const el_m = document.getElementsByClassName("msg-b");
  
    
    if (el[el.length - 1]) {  
      el[el.length - 1].scrollIntoView();
        el[el.length - 1].style.opacity = 1;

    
    }
    for (let i = 0; i < el_m.length; i++) {
      const element = el_m[i];
      if (element) {
        element.style.opacity = 1
      
      }
    }
    }, 50);
  };
  const handleSelectMsg = (msg) => {
    if (selectMsg) {
      // if (msg.sender.username === user.username) {
      if (selectedMessages.filter((item) => item.id === msg.id).length >= 1) {
        var newL = selectedMessages;
        newL.pop(msg);
        setselectedMessages(newL);
        // console.log("exists");
        // console.log(selectedMessages);
      } else {
        var newL = selectedMessages;
        newL.push(msg);
        setselectedMessages(newL);

        // console.log("not exist");
        // console.log(selectedMessages);
      }
      // }
    }
  };

  function AttatchFileModal({ open = false, setOpen, receiver }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [fileText, setFileText] = useState(text);
    const [urlFileList, setUrlFileList] = useState([]);

    React.useEffect(() => {
      const trFiles = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        var obj = {};
        obj["type"] = file.type;
        obj["name"] = file.name;
        obj["url"] = URL.createObjectURL(file);
        trFiles.push(obj);
      }
      setUrlFileList(trFiles);
    }, []);
    return (
      <div>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                id="spring-modal-title"
                variant="h6"
                style={{ color: "whitesmoke" }}
                component="h2"
              >
                Send Files to {receiver}
              </Typography>
              <Typography
                id="spring-modal-description"
                sx={{
                  mt: 2,
                  overflow: "auto",
                  height: fileList.length >= 3 ? "50vh" : "100%",
                  textAlign: "center",
                }}
              >
                {urlFileList.length >= 1
                  ? urlFileList.map((item, index) => (
                      <Box
                        style={{
                          width: fileList.length >= 3 ? "150px" : "100%",
                          height: fileList.length >= 3 ? "150px" : "100%",
                          overflow: "hidden",
                          padding: "10px",
                          display: "inline-block",
                        }}
                      >
                        <Box>
                          <IconButton
                            title="remove"
                            style={{
                              color: "grey",
                              position: "static",
                              zIndex: "999",
                            }}
                            onClick={() => {
                              const prevList = [];
                              for (let i = 0; i < fileList.length; i++) {
                                const fl = fileList[i];
                                prevList.push(fl);
                              }

                              const newList = prevList.filter(
                                (fItem) => fItem.name !== item.name
                              );

                              setFileList(newList);
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </Box>
                        <a target="_blank" href={item.url}>
                          {" "}
                          <div>
                            {item.type.includes("audio") ? (
                              <audio
                                src={item.url}
                                style={{ width: "100%" }}
                                controls
                                title={item.name}
                              ></audio>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {item.type.includes("video") ? (
                              <ReactPlayer
                                width={"100%"}
                                height={"100%"}
                                controls
                                url={item.url}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {item.type.includes("image") ? (
                              <img
                                src={item.url}
                                style={{
                                  width: "100%",
                                  height:
                                    fileList.length >= 3 ? "200px" : "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {item.type.includes("application") ? (
                              <div>
                                <FilePresent />
                                {item.name}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </a>
                      </Box>
                    ))
                  : ""}
              </Typography>

              <Container className="bottom-input" style={{ marginTop: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    float: "left",
                    maxHeight: "50px",
                    width: { xs: "350px", md: "390px" },
                  }}
                >
                  <InputEmoji
                    value={fileText}
                    onChange={(val) => setFileText(val)}
                    placeholder="Type a message"
                    cleanOnEnter
                  />
                </Box>
                <Box sx={{ display: "flex", margin: "0" }}>
                  <IconButton
                    title="send"
                    style={{ color: "whitesmoke", margin: "0" }}
                  >
                    <Send />
                  </IconButton>
                </Box>
              </Container>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
  const handleAttachment = () => {
    setAttachFile(true);
    attachmentRef.current.click();
  };

  if (user.username !== null && user.username !== undefined) {
    return (
      <Box className="messages-container">
        <Container className="messages-inner-cont">
          <Box
            sx={{
              height: { md: "460px", sx: "500px" },
              overflow: "hidden",
              overflowY: "auto",
              width: "100%",
              scrollbarWidth: "thin",
            }}
            className="messages-body-box"
            id="messages-body-box-cont"
          >
            <ChatNotificaton type={"first"} />

            {messages.map((message, index) => (
              <>
                <span
                  style={{
                    display:
                      messages[index - 1] &&
                      messages[index - 1].date == message.date
                        ? "none"
                        : "block",
                  }}
                >
                  <Divider>
                    <ChatNotificaton type={"date"} value={message.date} />
                  </Divider>
                </span>
                <ChatMessage
                  className={`msg-b msg-box-date-${
                    message.receipts.filter(
                      (itm) => itm.chat_member.username !== user.username
                    )[0]?.read
                      ? "read"
                      : "unread"
                  }`}
                  msg={message}
                  usr={user}
                  selectOn={selectMsg}
                  child={
                    messages[index - 1]
                      ? messages[index - 1].sender.username ==
                        message.sender.username
                      : false
                  }
                  key={index}
                  handleSelect={() => handleSelectMsg(message)}
                ></ChatMessage>
              </>
            ))}
          </Box>

          <input
            ref={attachmentRef}
            accept="image/*, audio/*, video/*, application/pdf, application/docs,application/xls"
            onChange={(e) => setFileList(e.target.files)}
            id="raised-button-file"
            hidden
            multiple
            type="file"
          />
          <Container className="bottom-input" style={{ marginTop: "10px" }}>
            <IconButton
              style={{ display: "flex", float: "left" }}
              onClick={() => handleAttachment()}
            >
              <AttachFile />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                float: "left",
                maxHeight: "50px",
                width: { xs: "350px", md: "390px" },
              }}
            >
              <InputEmoji
                value={text}
                onChange={(val) => setText(val)}
                placeholder="Type a message"
                cleanOnEnter
              />
            </Box>
            <Box sx={{ display: "flex", margin: "0" }}>
              <IconButton
                title="send"
                style={{ color: "rgb(37, 44, 48)", margin: "0" }}
              >
                <Send />
              </IconButton>
            </Box>
          </Container>
        </Container>

        {/* upload attachment */}
        <AttatchFileModal
          fileList={fileList}
          receiver={"Kanemanuel"}
          setOpen={setAttachFile}
          open={attachFile}
          chat={chat}
        />
      </Box>
    );
  } else {
    return "Login Plz";
  }
}
