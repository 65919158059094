import React from 'react'
import { Col, Empty, Row, Pagination, Spin } from 'antd'
import { Box, Button, Select, FormControl, Checkbox, MenuItem, IconButton,  InputLabel } from '@mui/material'
import { uid } from 'react-uid'
import InfluencerCard from './widgets/card'
import {
  SelectInfluencerStatus,
  SelectInfluencers,
  SelectInfluencersNext,
  SelectInfluencersPrevious,
  SelectInfluencersTotalCount,
} from '../redux/influencer/selectors'
import { fetchAllInfluencers, fetchBookmarkedInfluencers, fetchPlatformMediums, influencersPageGet } from '../redux/influencer/thunks'
import { useDispatch } from 'react-redux'
import { Bookmarks, BookmarksOutlined, ClearAllOutlined, People } from '@mui/icons-material'
import { AD_CATEGORIES_CHOICES } from '../platformVars'

export default function InfluencerIndex() {
  const influencers = SelectInfluencers()
  const influencerStatus = SelectInfluencerStatus()
  const influencerLoading = influencerStatus === 'loading'
  const [influencersCurrentPage, setInfluencersCurrentPage] = React.useState(1)
  const influencersTotalPage = SelectInfluencersTotalCount()
  const influencersNext = SelectInfluencersPrevious()
  const influencersPrevious = SelectInfluencersNext()
  const [selectedCategoriesFilter, setSelectedCategoriesFilter] = React.useState([])
  const [itemsPerPage, setItemsPerPage] = React.useState(21)
  const [filterByBookMarks, setFilterByBookmarks] = React.useState(false)
  const [influencersFilters, setInfluencerFilters] = React.useState(`?scope=all&page_size=` + itemsPerPage)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchAllInfluencers(influencersFilters))
    dispatch(fetchPlatformMediums())

  }, [influencersFilters, itemsPerPage])

  const handleSetCategories = (e) => {
    setSelectedCategoriesFilter(e.target.value)
  }

  const handleFilter = (e) => {
    e.preventDefault()
    setFilterByBookmarks(false)
    const formData = new FormData(e.target)
    var urlParams = new URLSearchParams(formData).toString();

    setInfluencerFilters(`?scope=all&page_size=` + itemsPerPage + '&' + urlParams)
    setInfluencersCurrentPage(1)
  }

  const handleChangePage = (val) => {
    setInfluencerFilters(influencersFilters+"&page="+val)
    // dispatch(influencersPageGet({url:influencersFilters+"&page="+val}))
    setInfluencersCurrentPage(+val)
    document.getElementById('fil-for').scrollIntoView()


    // dispatch(/(e.target.textContent)
  }
  const handePageNav = ({ url, next = true }) => {
    dispatch(influencersPageGet({ url: url }))
    if (next === true) {
      setInfluencersCurrentPage(influencersCurrentPage + 1)

    } if (next === false) {
      setInfluencersCurrentPage(influencersCurrentPage - 1)

    }
    document.getElementById('fil-for').scrollIntoView()

  }

  const handleBookmarks = () => {
    if (!filterByBookMarks) {
      dispatch(fetchBookmarkedInfluencers())
      setFilterByBookmarks(true)
    }
    else {
      setFilterByBookmarks(false)
      setInfluencerFilters(influencersFilters + "&page="+ influencersCurrentPage+"&bookmarks=false")
    }
  }
  const handleChangePageSize = (size) => { setItemsPerPage(size) }

  return (
    <>
      <Spin spinning={influencerStatus === 'loading'}>
        <Box>
          <Box
            sx={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'start',
              alignContent: 'start',
              position: 'relative',
              marginLeft: { md: '75px', xs: '10px' },
              alignItems: 'start',
            }}
          >
            <form id="fil-for" onSubmit={handleFilter}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <input
                  name="advert_categories"
                  value={selectedCategoriesFilter}
                  hidden
                ></input>
                <IconButton title='My bookmarks' onClick={handleBookmarks}>{filterByBookMarks ? <Bookmarks /> : <BookmarksOutlined />}  </IconButton>

                <IconButton
                  title="clear filter"
                  onClick={() => {
                    setFilterByBookmarks(false)
                    setSelectedCategoriesFilter([])
                    setInfluencerFilters('?scope=alls')
                  }}
                >
                  {' '}
                  <ClearAllOutlined />{' '}
                </IconButton>

                <FormControl
                  fullWidth
                  sx={{ minWidth: 200 , maxWidth:{md:400,xs:250}}}
                  variant="outlined"
                >
                  {' '}
                  <InputLabel sx={{ marginBottom: '20px!important' }} variant='outlined' size='small' > Industry Categories</InputLabel>
                  <Select
                    style={{ height: 40 }}
                    fullWidth
                    onChange={handleSetCategories}
                    variant="outlined"
                    multiple
                    value={selectedCategoriesFilter}
                    renderValue={selected =>
                      [
                        ...new Set(
                          AD_CATEGORIES_CHOICES.map(itm => {
                            if (selected.includes(itm.value)) return itm.label
                          }).reverse(),
                        ),
                      ].join('|')
                    }
                  >
                    {AD_CATEGORIES_CHOICES.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                          checked={
                            selectedCategoriesFilter.indexOf(option.value) > -1
                          }
                        />
                        {option.label}
                      </MenuItem>



                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: 80 }}>
                  <Button type="submit" variant="outlined" size="medium">
                    Filter
                  </Button>
                </FormControl>
              </div>

            </form>

          </Box>
          <Row
            gutter={8}
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',

              alignItems: 'center',
            }}
          >
            {influencers?.map((influencer, index) => (
              <Col
                style={{ marginBottom: '10px' }}
                key={uid(influencer)}
                span={window.screen.width >= 720 ? 7 : 10}
              >
                <InfluencerCard influencer={influencer}></InfluencerCard>
              </Col>
            ))}
          </Row>
          {influencers?.length <= 0 && (
            <Empty
              image={<People color="inherit" fontSize={'large'} />}
              style={{ marginTop: { md: 50, xs: 100 } }}
              description={'No influencer(s) found'}
            ></Empty>
          )}
        </Box>
        {influencers?.length >= 1 &&
          <div
            style={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px',
              width: '50%',
              padding: '10px', borderRadius: '30px',
              margin: '0 auto',

            }}
          >
            <Pagination
              size="large"
              showQuickJumper
              showSizeChanger

              onShowSizeChange={(val, size) => handleChangePageSize(size)}
              onChange={handleChangePage}

              responsive
              pageSize={itemsPerPage}
              current={influencersCurrentPage}
              total={influencersTotalPage}
            />
          </div>}
      </Spin>
    </>
  )
}
