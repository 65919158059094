import React from "react";
import Webcam from "react-webcam";
import { Button, notification } from "antd";
import RecordRTC from "recordrtc";
import ReactPlayer from "react-player";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Box, Paper } from "@mui/material";
import {
  StopSharp,
  UploadFileOutlined,
  VideoCameraFront,
  VideoCameraFrontOutlined,
  VideoCameraFrontSharp,
} from "@mui/icons-material";
import { uid } from "react-uid";
import { UserSelector } from "../../../store/auth/selectors";
import { BASE_URL, authToken } from "../../app_context";
import md5 from "md5";
import { useAnimation, motion } from "framer-motion";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const WebcamRecorder = ({ sessionId, onFinish = (file) => {}, active }) => {
  const webcamRef = React.useRef(null);
  const user = UserSelector();
  const [recording, setRecording] = React.useState(false);
  const [timer, setTimer] = React.useState(10);
  const [videoURL, setVideoURL] = React.useState(null);
  const [videoFile, setVideoFile] = React.useState(null);
  const [uploaded, setUploaded] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const pondRef = React.useRef(null);
  const [showCountDown, setShowCountDown] = React.useState(false);
  const [webCamActive, setWebCamActive] = React.useState(active);
  let recordRTC;

  const Countdown = ({ onComplete = () => {} }) => {
    const controls = useAnimation();
    const [count, setCount] = React.useState(1);

    React.useEffect(() => {
      async function counter() {
        setCount(1);
        for (let i = 6; i >= 1; i--) {
          await controls.start({ opacity: 1, scale: 1.5 });

          setCount((prev) => prev + 1);
          await controls.start({ opacity: 0, scale: 1 });
        }
      }
      counter();
      // onComplete();
    }, [controls]);

    return (
      <motion.div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "4em",
        }}
        initial={{ opacity: 0, scale: 1 }}
        animate={controls}
      >
        {count}
      </motion.div>
    );
  };

  const startRecording = async () => {
    setVideoFile(null);
    setVideoURL(null);
    setRecording(true);
    setShowCountDown(true);

    setTimeout(async () => {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const videoStream = webcamRef.current.video.srcObject;

      const combinedStream = new MediaStream([
        ...audioStream.getTracks(),
        ...videoStream.getTracks(),
      ]);

      recordRTC = RecordRTC(combinedStream, {
        type: "video",
        mimeType: "video/webm",
      });

      recordRTC.startRecording();
      setShowCountDown(false);

      const countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(countdownInterval);
        stopRecording();
      }, 10000);
    }, 3000);
  };

  const stopRecording = () => {
    setRecording(false);
    setShowCountDown(false);
    setTimer(10);

    if (recordRTC) {
      recordRTC.stopRecording(() => {
        const blob = recordRTC.getBlob();
        const videoURL = URL.createObjectURL(blob);
        setVideoURL(videoURL);
        const file = new File([blob], sessionId + "-kycvideo.webm", {
          type: blob.type,
        });
        setVideoFile(file);

        // Handle the recorded blob as needed, e.g., save it or upload it
        // console.log("Recorded Blob:", blob);
      });
    }
  };

  const handleUpload = () => {
    setUploading(true);

    // Get the FilePond instance
    const pond = pondRef.current;
    if (pond) {
      // Process the files (trigger upload)
      pond.processFiles().then((files) => {
        // console.log("Files processed:", files);
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4",
        position: "relative",
      }}
    >
      {showCountDown && <Countdown />}
      {videoURL === null ? (
        <Webcam
          allo
          style={{
            borderRadius: "14px",
            maxWidth: "70vw",
            height: "100%",

            margin: "10px",
          }}
          size={"100%"}
          ref={webcamRef}
        />
      ) : (
        ""
      )}
      {videoURL ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {uploading || uploaded ? (
            <>
              {" "}
              <FilePond
                ref={pondRef}
                files={[videoFile !== null && videoFile]}
                name="video"
                allowRevert={false}
                allowBrowse={false}
                allowPaste={false}
                allowDrop={false}
                server={{
                  process: {
                    url:
                      BASE_URL +
                      `/in-hub/api/kyc/${sessionId}/upload/?type=video`,
                    method: "POST",
                    withCredentials: false,

                    headers: { Authorization: authToken },
                    onerror: (response) => {
                      // console.log(response);
                      setUploading(false);
                      notification.error({ message: "Upload Failed" });
                    },
                    oncancel: () => {
                      alert("canceled");
                      setUploading(false);
                      setUploaded(false);
                      setVideoURL(null);
                      setVideoFile(null);
                    },

                    onload: (response) => {
                      const resp = JSON.parse(response);
                      // console.log(response);
                      setUploaded(true);
                      setUploading(false);
                      onFinish(resp);
                      notification.success({ message: "Upload Successful" });
                    },
                  },
                }}
              />
            </>
          ) : (
            <Box
              sx={{
                borderRadius: "14px",
                marginTop: "10px",
                overflow: "hidden",
              }}
            >
              <ReactPlayer
                width="100%"
                height="100%"
                url={videoURL}
                controls={true}
              />
            </Box>
          )}
          {!uploaded && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <Button
                type="default"
                style={{ paddingTop: "-10px" }}
                size="middle"
                onClick={startRecording}
              >
                <VideoCameraFrontOutlined /> Re-record
              </Button>
              <Button
                type="default"
                style={{ paddingTop: "-10px" }}
                size="middle"
                onClick={handleUpload}
              >
                <UploadFileOutlined /> Upload Video
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Button
          type="default"
          style={{ width: "200px", paddingTop: "-10px" }}
          size="middle"
          onClick={recording ? stopRecording : startRecording}
        >
          {recording ? <StopSharp /> : <VideoCameraFrontSharp />}{" "}
          {recording ? "Stop Recording" : "Start Recording"}
        </Button>
      )}
      {recording && (
        <div
          style={{
            float: "right",
            position: "absolute",
            top: 15,
            right: 25,
            borderRadius: "14px",
            padding: "5px",
            paddingRight: "10px",
            paddingLeft: "10px",
            backgroundColor: "rgba(79, 97, 87, 0.6)",
            color: "red",
          }}
        >
          {" "}
          {!showCountDown ? <> Recording... {timer}s</> : "Get Ready..."}
        </div>
      )}
    </div>
  );
};

export default class InfluencerKYC extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      faceRecord: null,
      componentActive: props.isActive,
      document: null,
      sessionId: md5(String(new Date().getDate()) + "InApplication")
        .toLowerCase()
        .toString(),
    };
  }

  handleInit() {
    // console.log("FilePond instance has initialised", this.pond);
  }
  componentDidUpdate() {
    if (this.state.document !== null && this.state.faceRecord !== null) {
      this.props.setDone(true);
    }
  }

  FileUpload = () => {
    return (
      <FilePond
        ref={(ref) => (this.pond = ref)}
        maxFiles={1}
        acceptedFileTypes={[
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "image/*",
        ]}
        labelIdle="Drag & Drop your documents or <span class='filepond--label-action'>Browse</span>"
        oninit={() => this.handleInit()}
        name="doc"
        maxFileSize={"10MB"}
        server={{
          process: {
            url:
              BASE_URL +
              `/in-hub/api/kyc/${this.state.sessionId}/upload/?type=doc`,
            method: "POST",
            withCredentials: false,
            headers: { Authorization: authToken },
            onerror: (response) => {
              notification.error({ message: "Upload Failed" });
              // console.log(response);
            },
            onload: (response) => {
              // console.log(response);
              const resp = JSON.parse(response);
              notification.success({ message: "Upload Successful" });
              this.setState({ files: [resp] });
              this.setState({ document: resp });
            },
          },
        }}
        onupdatefiles={(fileItems) => {
          // Set current file objects to this.state
          this.setState({
            files: fileItems.map((fileItem) => fileItem.file),
          });
        }}
      ></FilePond>
    );
  };
  render() {
    return (
      <Paper
        sx={{
          marginTop: "10px",
          padding: "15px",
          // margin: "20px auto",
        }}
      >
        {/* document section */}
        <p>
          <>
            Upload An Official ID (Either Passport, National ID or License) or
            Brand Registration Document (if you are a brand/business)
          </>
        </p>
        <this.FileUpload />
        <input id="app-field" name="document" hidden></input>

        <br />

        {/* face liveness section  */}
        <p>
          {" "}
          <>
            Record a 10 seconds Introduction for verification purposes e.g Hello
            I am Beone pablo, a tourist and an influencer.
          </>
          <br />
          <i>
            • Ensure you are in a well-lit and quiet environment to facilitate a
            clear and effective verification process.
          </i>
          <br/>
          <i>• Speak audibly and clearly during the verification process.</i>
          <br/>
          <i>
            • Aim for a passport-like fit in the camera frame. Your camera
            should capture your entire face and half of your shoulders.
          </i>
        </p>
        <b>Note:</b>
        <br />
        {/* <small>
          {" "}
          - We delete this video after verification has been processed.
        </small> */}
        <small>
          {" "}
          <br />- We would not share your video/files with thirdparty without
          your consent.
        </small>

        <input
          name="kyc"
          hidden
          id="app-field"
          value={this.state.faceRecord?.id || this.state.document?.id}
        ></input>

        <WebcamRecorder
          sessionId={this.state.sessionId}
          active={this.state.componentActive}
          onFinish={(file) => this.setState({ faceRecord: file })}
        />
        <br />
      </Paper>
    );
  }
}
