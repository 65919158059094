import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FmdGood } from '@mui/icons-material';
import { Button, Form, Input, InputNumber } from 'antd';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import APP_ENV from '../../env';

const BASE_URL = APP_ENV.SERVER_ADDR;
const reqHeaders = {
    "Content-Type": "application/json",

    "Authorization": `Token ${localStorage.getItem('AccgToken')}`


};

if (localStorage.getItem('AccgToken') !== null) {
    reqHeaders["Authorization"] = `Token ${localStorage.getItem('AccgToken')}`

}

const client = axios.create({
    baseURL: BASE_URL,
    headers: reqHeaders,


})
const authClient = axios.create({
    baseURL: BASE_URL,


})

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 20,
        },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
};
const InputStyle = {
    // padding: '10px',
    // borderRadius: '6px',
    // border: '1px solid lightgreen',
    width: '100%'



}
const InputButtonStyle = {
    // padding: '10px',
    // flexWidth: '20px ',
    // borderRadius: '6px',
    // border: '1px solid darkgreen',
    // backgroundColor: 'rgb(37, 44, 48)',
    // justifyContent: 'center',
    // margin: '10px',
    // color: 'white '


}
const EditProfile = (props) => {
    const [catFields, setCatFields] = React.useState(props.userProfile.seller_category)
    const onFinish = (values) => {
        client.post('user/api/profile/' + props.userProfile.username, values).then(response => {

            if (response.data) {

                document.querySelector('#categorySubmitB').innerHTML = 'Category Updated'
                document.querySelector('#categorySubmitB').style.backgroundColor = 'lightgreen'
                document.querySelector('#categorySubmitB').style.color = 'black'
            }
        }).catch(err => { console.log(err) })

        // console.log('Received values of form:', values);

    };


    return (
        <div style={{ width: 'inherit', textAlign: 'center' }}>
            <UploadApp profileID={props.userProfile.id} picture={props.userProfile.display_picture} userProfile={props.userProfile} />
            <DetailsForm userProfile={props.userProfile} />
            <Form
                name="dynamic_form_item" {...formItemLayoutWithOutLabel} onFinish={onFinish}>
                <Form.List

                    name="categories"
                    rules={[
                        {
                            validator: async (_, names) => {
                                if (!names || names.length < 3 && catFields.length + names.length < 3) {
                                    return Promise.reject(new Error('At least 3 Categories'));
                                }
                            },
                        },
                    ]}
                >


                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields?.map((field, index) => (
                                <Form.Item
                                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                    label={index === 0 ? 'Categories' : ''}
                                    required={false}
                                    key={field.key}

                                >
                                    <Form.Item
                                        {...field}

                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input category name or delete this field.",
                                            },
                                        ]}
                                    >
                                        <Input

                                            placeholder="Category name eg. Facebook Accounts.."
                                        />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}

                            <>
                                {catFields !== 0 ? catFields?.map((catName) => (

                                    <Form.Item

                                        validateTrigger={['onChange', 'onBlur']}
                                        initialValue={catName}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input category name or delete this field.",
                                            },
                                        ]}
                                    >
                                        <Input
                                            id='dynamic_form_item_categories_0'
                                            value={catName}
                                            placeholder="Category name eg. Facebook Accounts.."
                                            style={InputStyle}
                                        />
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            id={catName}
                                            onClick={() => { client.put('/user/api/profile/' + props.userProfile.username, { method: 'delete', field: 'category', name: catName }).then(resp => { return setCatFields(catFields.filter(item => item !== catName)) }); }}
                                        />
                                    </Form.Item>
                                )) : ''}
                            </>
                            <Form.Item>


                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={InputButtonStyle}

                                    icon={<PlusOutlined />}
                                >
                                    Add Your Product Categories
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button style={InputButtonStyle} id='categorySubmitB'
                        type="primary" htmlType="submit">
                        Submit Categories
                    </Button>
                </Form.Item>
            </Form></div >
    );
};

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */

const DetailsForm = (props) => {
    const onFinish = (values) => {
        client.post('user/api/profile/' + props.userProfile.username, values['data']).then(response => {

            if (response.data) {
                document.querySelector('#detailForm-submitButton').innerHTML = 'Profile Updated'
                document.querySelector('#detailForm-submitButton').style.backgroundColor = 'lightgreen'
                document.querySelector('#detailForm-submitButton').style.color = 'black'
            }
        }).catch(err => { console.log(err) })

    };

    return (
        <Form {...layout}

            name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} >
            <Form.Item
                name={['data', 'first_name']}
                label="First Name"
                initialValue={props.userProfile.first_name}

                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'last_name']}
                label="Last Name"
                initialValue={props.userProfile.last_name}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'email']}
                label="Email"
                initialValue={props.userProfile.email}
                rules={[
                    {
                        type: 'email',
                        required: 'true'
                    },
                ]}
            >
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item
                name={['data', 'age']}
                label="Age"
                initialValue={props.userProfile.age}
                rules={[
                    {
                        type: 'number',
                        min: 16,
                        max: 99,
                        required: true
                    },
                ]}

            >
                <InputNumber style={InputStyle}
                />
            </Form.Item>
            <Form.Item name={['data', 'website']} initialValue={props.userProfile.website}
                rules={[
                    {
                        required: true,
                    },

                ]} label="Website">
                <Input style={InputStyle}
                />
            </Form.Item>
            <Form.Item name={['data', 'bio']} initialValue={props.userProfile.bio}
                rules={[
                    {
                        required: true,
                    },
                ]} label="Bio" >
                <Input.TextArea style={InputStyle} placeholder='Write something that can make customers trust your credibility.'
                />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
                <Button style={InputButtonStyle} id='detailForm-submitButton'

                    type="secondary" htmlType="submit">
                    Submit Details
                </Button>
            </Form.Item>
        </Form >
    );
};






const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
};

const UploadApp = (props) => {

    const [fileList, setFileList] = useState(props.picture ? [
        {
            uid: 'abc2',
            name: 'profile_picture',
            status: 'done',
            url:  props.picture

        },
    ] : []);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);

    };

    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    // alert(BASE_URL + 'user/api/profile/' +url_username)
    return (
        <ImgCrop rotate>
            <Upload

                name="avatar"
                className="avatar-uploader"
                listType="picture-card"
                fileList={fileList}
                action={BASE_URL + 'user/api/profile/' + props.userProfile.username}
                headers={{ "Authorization": `Token ${localStorage.getItem('AccgToken')}` }}
                accept='.png,.jpeg,.jgp'
                onChange={onChange}
                onPreview={onPreview}
                onRemove={() => {
                    client.put('/user/api/profile/' + props.userProfile.username, { 'method': 'delete', field: 'display_picture' }).then(response => { })
                }}
                maxCount={1}
            >
                {fileList.length < 1 && '+ Upload'}
            </Upload>
        </ImgCrop >
    );
};

export default EditProfile;

