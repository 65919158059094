
export const shareLink = ({title, description, url}) => {
  const handleShareClick = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: title,
          text: description,
          url: url, // replace with your actual URL
        });
      } else {
        // Fallback for browsers that do not support Web Share API
        alert('Web Share API is not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error.message);
    }
  }
  handleShareClick()
}