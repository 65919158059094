import { Box } from "@mui/material";
import { Divider, Radio  } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";


export default function SMMHeader({ current }) {
    const navigate = useNavigate()

    return (
        <Box sx={{width:"100%" }}>
            <Box sx={{float:{md:"left", xs:"right"}}} >
            <Radio.Group  style={{margin:"10px",}}value={current} onChange={(e) => navigate(`/smm/${e.target.value}`)}>
                <Radio.Button value="boosting"   style={{width:"150px",fontSize:"16px" }}>Boost</Radio.Button>
                <Radio.Button value="orders" style={{width:"150px",fontSize:"16px" }}>SMM Orders</Radio.Button>
            </Radio.Group></Box>
            <Divider  style={{margin:"2px"}} />
        </Box>
            )
}