import { VerifiedSharp } from "@mui/icons-material"
import { Avatar, Badge } from "@mui/material"


export const InfluencerAvatar =(props)=>{

    return(

        <Badge color='default'  overlap='circular' anchorOrigin={{vertical:"bottom", horizontal:'right'}}  badgeContent={props.isVerified && <VerifiedSharp sx={{fontSize:{xs:'15px', md:'25px'}}}/>} >


        <Avatar
          sx={props.sx}
className={props.className}
          src={props.src}
        >
        </Avatar>{' '}            </Badge>
    )
}