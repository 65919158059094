import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Paper,
  alpha,
} from "@mui/material";
import React from "react";
import SMMHeader from "./header";
import {
  Empty,
  List,
  Skeleton,
  Spin,
  Tag,
  Typography,
  message,
  notification,
} from "antd";
import { client } from "../app_context";
import { ArrowDownward, ArrowDownwardOutlined, ArrowUpward, ArrowUpwardOutlined, MoreOutlined } from "@mui/icons-material";
import { motion } from "framer-motion";

export default function SMMOrders(props) {
  const [orderList, setOrderList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    client
      .get("/smm/api/order/")
      .then((resp) => {
        setOrderList(resp.data);
        // console.log(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Error Loading Orders");
        setLoading(false);
      });
  }, []);
  
  
  return (
    <>
      <Spin spinning={loading}>
        <Box sx={{ padding: "10px", borderRadius: "50px" }}>
          <Paper
            sx={{
              width: { sm: "100%", md: "50%" },
              overflow: "hidden",
              marginTop: { sx: "10px", md: "30px" },
              margin: "0 auto",
              height: "100%",
              minHeight: "50vh",
            }}
          >
            <SMMHeader current="orders" />
            <Box sx={{ display: "block", margin:{xs:"0px"},marginTop:"40px"}}>
              {orderList.map((order, index) => (
                <Box sx={{marginRight:"20px"}} key={index + "-order"}>
                  {order.items?.map((item) => (
                    <OrderItem
                      key={index + "-orderItem"}
                      loadingState={loading}
                      orderV={item}
                    />
                  ))}
                </Box>

              ))}
              <Box sx={{ marginTop: { xs: "50%", md: "30%" } }}>
                {orderList.length <= 0 && (
                  <Empty description={"You currently have no order"}></Empty>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Spin>
    </>
  );
}

const OrderItem = ({ orderV, loadingState }) => {
  const [order, setOrder] = React.useState(orderV);

  const [status, setStatus] = React.useState(order?.status);
  const [loading, setLoading] = React.useState(loadingState);
  const [fields, setFields] = React.useState([]);
  const [detailsOn, setDetailsOn] = React.useState(false);
  const handleRefill = () => {
    setLoading(true);
    client
      .post("/smm/api/order/", { action: "refill", order: order.tx_id })
      .then((resp) => {
        if (resp.data.error && resp.data.error !== "") {
          setLoading(false)

          return notification.error({message:resp.data.msg});
        }
        notification.success({message:"Refill Processed"});
        setLoading(false);        updateStatus();

      })
      .catch((err) => {
        notification.error({message:"Something went wrong"});
        setLoading(false);
      });
  };

  const updateStatus = () => {
    client
      .post("/smm/api/order/", { action: "status", order: order.tx_id })
      .then((resp) => setOrder(resp.data));
  };
  const handleCancel = () => {
    setLoading(true)
    client
      .post("/smm/api/order/", { action: "cancel", order: order.tx_id })
      .then((resp) => {
        if (resp.data.error) {          setLoading(false)

          return notification.error({message:resp.data.msg});
        }
        updateStatus();
        notification.success({message:"Order Cancelled"});
        setLoading(false);
      })
      .catch((err) => {
        notification.error({message:"Something went wrong"});
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setLoading(true)
    client
      .post("/smm/api/order/", { action: "status", order: order.tx_id })
      .then((resp) => {
        if (resp.data.error && resp.data.error !== "") {
          setLoading(false)
          return notification.error({message:resp.data.error});
        }
        setOrder(resp.data)
        notification.success({message:"Order Refreshed"});
        setLoading(false);
      })
      .catch((err) => {
        notification.error({message:"Something went wrong"});
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const orderFields = [
      { field: "Service ID", value: order.service?.service || order.origin_service_id },
      { field: "Price Rate", value: order.service.price_rate },
      { field: "Quantity", value: order.quantity },
      { field: "Time", value: order.time },
      { field: "Total Amount", value: order.total },
      { field: "Refills", value: order.refill.length },
      {
        field: "Latest Refill",
        value: order.refill[order.refill.length - 1]?.status,
      },
      { field: "Remains", value: order.remains },
    ];

    for (let key in order) {
      if (order[key] !== null || order[key] !== "") {
        if (
          [
            "quantity", "id",
            "charge",
            "error",
            "refill",
           
            "tx_id",
            "dateCreated",
            "dateUpdated",
            "totalPlatformCharge",
            "remains","time",
            "service", "currency"
          ].find((fld) => fld === key)
        ){

          continue;
        
        } else {

        orderFields.push({ field: String(key).replace("_", " ").toUpperCase(), value: order[key] });
      }
      }
      
    }
    setFields(orderFields)
    setLoading(false)
  }, [orderV]);
  return (
    <Box
      sx={{
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        width: "100%",
        padding: "15px",
        margin: "10px",
        borderRadius: "14px",
         border: "0.1px solid grey",
        display: "inline-block",
      }}
    >
      {loading && <CircularProgress content="loading order" />}
      <motion.div
        style={{
          overflowX: "hidden",
          backgroundColor: alpha("rgb(60, 102, 88)", 0.3),
          borderRadius: "10px",
          transition: "all 100ms linear ",
          marginBottom: "10px",
          height: detailsOn ? "200px" : "40px",
                    overflowY: detailsOn? "scroll":"hidden"

          // scale: detailsOn ? 1.5 : 0.3,
        }}
      >
        <IconButton
          sx={{ float: "left" }} size="medium"
          onClick={() => setDetailsOn(!detailsOn)}
        >
          {" "}
          {detailsOn ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}

        </IconButton>
        <br />

        <Box             sx={{width:"90%", display:"block", marginTop:"10px", scrollbarWidth:"thin", overflowX:"scroll", margin:"10px"}}
>

          <List
            dataSource={fields}
            renderItem={(itm, index) => (
              <List.Item key={index + "-" + itm.field} >
                <List.Item.Meta title={itm.field} description={itm.value}>
                
                </List.Item.Meta>
              </List.Item>
            )}
          ></List>
        </Box>

      </motion.div>
      <Divider style={{ margin: "2px", opacity: "0.4" }}></Divider>

      <Box>
        <Box className="firstRow" sx={{ width: "100%", margin: "5px", display: "block" }}>
          <div style={{ float: "left", fontSize: "18px", display: "inline-block" }}>
            <b>{order.service.name || order.origin_service_name}</b>
          </div>
          {/* right */}
          <div style={{ float: "right", fontWeight: "bolder", display: "inline-block" }}>
            <Tag color={status === "In progress" || status === "Partial" ? "green" : "gold"} >
              {status}</Tag>
          </div>
        </Box>
        <br />
        <Box className="secondRow" sx={{ width: "100%", margin: "10px", display: "block", }}>
          <div style={{ float: "left", display: "inline-block" }}>
            <p>ID: <b>{order.tx_id} </b></p>
            <p>{order.quantity && <b>{order.quantity} Qty</b>}</p>
          </div>

          {/* right actions */}
          <div style={{ float: "right", textAlign: "right", display: "inline-block", marginRight: "10px", }}>
            <ButtonGroup variant="contained">
              {/* {order.service.cancel && ( */}
              <Button
                variant="contained"
                color="primary"
                disabled={order.status === "Completed"}
                onClick={handleRefresh}
                size="medium"
              >
                Refresh 
              </Button>
              {/* )}
              {order.service.refill && ( */}
              <Button
                variant="contained"
                color="primary"
                disabled={!order.service.refill}
                onClick={handleRefill}
                size="medium"
              >
                Refill
              </Button>
              {/* )} */}
            </ButtonGroup>
          </div>
        </Box>

      </Box>

    </Box>
  );
};
