
import { ContactPageOutlined, HomeOutlined, PointOfSaleOutlined, Telegram } from '@mui/icons-material';
import { Avatar, AvatarGroup, Box, Container, Divider, Paper, Typography } from '@mui/material';
import React from 'react';
import slide1 from "../assets/images/slide_1.jpg"
import slide2 from "../assets/images/slide_2.jpg"
import slide3 from "../assets/images/slide_3.jpg"

import slide1_mobile from "../assets/images/slide_1_mobile.jpg"
import slide2_mobile from "../assets/images/slide_2_mobile.jpg"
import slide3_mobile from "../assets/images/slide_3_mobile.jpg"

import whatsappHome from "../assets/images/whatsapp-home.png"

import phoneHome from "../assets/images/phone-home.png"
import telegramHome from "../assets/images/telegram-home.png"
import obedHome from "../assets/images/obed-home.jpg"
import peterHome from "../assets/images/peter-home.jpg"
import jamesHome from "../assets/images/james-home.jpg"

import fbHome from "../assets/images/facebook-home.png"
import iGHome from "../assets/images/instagram-home.png"
import tiktokHome from "../assets/images/tiktok-home.png"
import XHome from "../assets/images/x-home.png"
import linkedinHome from "../assets/images/linkedin-home.png"
import snapHome from "../assets/images/snapchat-home.png"


import hotDeal from "../assets/images/hot-sale-home.png"


import { addToCart, addToWish, client } from "./app_context"
import { Button, Carousel, Form, Input, message, notification, Tag } from 'antd';
import { alpha } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAuthTabs } from './middlewares/authTabs';

export default function LandingPage(props) {
    const { Search } = Input;
    const authTab = useAuthTabs()
    const defaulDealsURL = "/user/api/best-deals/"
    const [dealsURL, setDealsURL] = React.useState(defaulDealsURL)
    const [bestDeals, setBestDeals] = React.useState([])
    const [nextDealURL, setNextDealURL] = React.useState(null)
    const [prevDealURL, setPrevDealURL] = React.useState(null)

    const [dealsLoading, setDealsLoading] = React.useState(true)
    const categories = [
        {
            title: "FaceBook", image: fbHome, url: "/products/category/fb"
        },
        {
            title: "Instagram", image: iGHome, url: "/products/category/in"
        },
        {
            title: "X", image: XHome, url: "/products/category/tw"
        },
        {
            title: "Linkedin", image: linkedinHome, url: "/products/category/ln"
        },
        {
            title: "Tiktok", image: tiktokHome, url: "/products/category/tt"
        },
        {
            title: "Snap chat", image: snapHome, url: "/products/category/sn"
        }

    ]
    const navigate = useNavigate()
    const mainSlider = [
        {
            title: "All products", md: true, onClick: () => navigate("/products"),
            Commponent: <Box sx={{
                margin: "0px", overflow: "hidden",

                width: "inherit", objectFit: "cover"
            }}> <img style={{ borderRadius: "10px" }} src={slide1} /></Box>,
        },

        {
            title: "All Products", xs: true, onClick: () => navigate("/products"),
            Commponent: <Box sx={{
                margin: "0px", overflow: "hidden",

                width: "inherit", objectFit: "cover"
            }}> <img style={{ borderRadius: "10px" }} src={slide1_mobile} /></Box>,
        },

        {
            title: "Sell products", md: true, onClick: () => navigate("/sell"),
            Commponent: <Box sx={{
                margin: "0px", overflow: "hidden",

                width: "inherit", objectFit: "cover"
            }}> <img style={{ borderRadius: "10px" }} src={slide2} /></Box>,
        },
        {
            title: "Sell Products", xs: true, onClick: () => navigate("/sell"),
            Commponent: <Box sx={{
                margin: "0px", overflow: "hidden",

                width: "inherit", objectFit: "cover"
            }}> <img style={{ borderRadius: "10px" }} src={slide2_mobile} /></Box>,
        },

        {
            title: "Chats", md: true, onClick: () => navigate("/user/chats"),
            Commponent: <Box sx={{
                margin: "0px", overflow: "hidden",

                width: "inherit", objectFit: "cover"
            }}> <img style={{ borderRadius: "10px" }} src={slide3} /></Box>,
        },
        {
            title: "Chats", xs: true, onClick: () => navigate("/user/chats"),
            Commponent: <Box sx={{
                margin: "0px", overflow: "hidden",

                width: "inherit", objectFit: "cover"
            }}> <img style={{ borderRadius: "10px" }} src={slide3_mobile} /></Box>,
        },


    ]

    const getData = () => {
        client.get(dealsURL).then((resp) => {

            setNextDealURL(resp.data.next)
            setPrevDealURL(resp.data.previous)
            setBestDeals((prev) => [...prev, ...resp.data.results])
            setDealsLoading(false)
            // document?.getElementById("product-frame").scrollIntoView()

        }).catch(err => { })


        setDealsLoading(false)


    }
    React.useEffect(() => {
        getData()
        if(localStorage){

            localStorage.setItem("currentPage", "home")
          }

    }, [dealsURL])
    const handleNextDeal = () => {
        if (nextDealURL) {
            setDealsLoading(true)
            setDealsURL(nextDealURL.replace("http", "https"))
        }

    }
    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const handleNewsLetter = (data) => {

        client.post("/user/api/NL-subscribe", data).then(resp =>
            message.success("Thank you for Subscribing to  our NewsLetter")).catch((err) => message.error("Something went wrong"))
    }
    return (

        <Box sx={{ overflow: "hidden" }}>

            <Container sx={{
                margin: "5px auto", display: { xs: "none", md: "block" }, paddingTop: "auto", paddingRight: "auto",
                paddingLeft: "auto", paddingBottom: "auto", borderRadius: "20px", padding: "0px", marginTop: "20px",
                height: "auto", width: "100%"
            }}>
                <Carousel dotPosition="top" autoplay >

                    {mainSlider.map((sl) => (sl.md &&
                        <div onClick={sl.onClick} style={{
                            width: "inherit", margin: 0,
                            boxShadow: "rgba(0, 0, 0, 0.2) 0px 20px 30px",
                            backgroundColor: props.displayMode === "dark" ? alpha("#284034", 0.05) : alpha("#abebcc", 0.05),
                            overflow: "hidden", padding: 0
                        }} title={sl.title}>
                            {sl.Commponent}
                        </div>

                    ))
                    }
                </Carousel>


            </Container>
            <Container sx={{
                margin: "5px auto", display: { xs: "block", md: "none" }, paddingTop: "auto", paddingRight: "auto",
                paddingLeft: "auto", paddingBottom: "auto", boxShadow: "rgba(0, 0, 0, 0.2) 0px 20px 30px", borderRadius: "20px"
                , padding: "0px", marginTop: "20px",
                backgroundColor: props.displayMode === "dark" ? alpha("#284034", 0.05) : alpha("#abebcc", 0.05), overflow: "hidden",
                height: "auto", width: "90%"
            }}>
                <Carousel dotPosition="top" autoplay >

                    {mainSlider.map((sl) => (sl.xs &&
                        <div style={{ width: "inherit", margin: 0, padding: 0 }} title={sl.title}>

                            {sl.Commponent}
                        </div>

                    ))}
                </Carousel>


            </Container>
            {/* <Divider style={{ marginTop: "5px" }} textAlign='left'><Tag style={{ padding: "10px", }}>Product Categories </Tag></Divider> */}

            <Container className='product-categories' sx={{ paddingLeft: { md: "20px", xs: "2px" }, minHeight: "20vh", paddingTop: { xs: "25px", md: "12px" }, }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>

                    {categories.map((cat, index) => (

                        <Box title={cat.title} key={index + 222} sx={{
                            "&:hover": { backgroundColor: alpha("rgb(54, 143, 104)", 0.21) },
                            cursor: "pointer", display: "inline-block",
                            padding: { md: "20px", xs: "0px" }, height: "70%", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            width: { md: "20vh", xs: "10vh" }, margin: { xs: "5px", md: "10px" }, height: { md: "15vh", xs: "8vh" }
                        }} >
                            <div onClick={() => navigate(cat.url)} style={{ overflow: "hidden", objectFit: "contain", width: "70%", margin: "0 auto" }}>
                                <img loading='lazy' src={cat.image}></img>

                            </div>
                        </Box>
                    ))}
                </Box>


            </Container>





            <Divider textAlign='left'><Tag style={{
                padding: "10px", position: "relative", width: "35vh", paddingTop: "5px",
                fontWeight: "bolder", fontSize: "30px", color: "orange"
            }}><div><img src={hotDeal} loading='lazy' width={40} height={40} />
                    <span style={{ position: "absolute", top: "20px", textShadow: "2px ", letterSpacing: 2, fontFamily: "fantasy " }}>

                        Best Deals </span> </div> </Tag></Divider>

            <Box className='best-delals' sx={{
                minHeight: { md: "50vh", xs: "40vh" },
                position: "relative", marginTop: "5px", width:"100%"
            }}>
                <Box id='product-frame' sx={{ width: {md:"80%", xs:"100%"}, margin: "0 auto" }}>
                    {dealsLoading &&
                        <Box sx={{ position: "absolute", top: 0, right: 0, textAlign: "center", zIndex: 100, left: 0 }}>
                            <CircularProgress title='Loading Products..' color="inherit" /></Box>
                    }
                    {bestDeals.map((prd, index) =>
                        <Box key={index * 333} sx={{ width: "30%", margin: { md: "10px", xs: "3px" }, borderRadius: "10px", display: "inline-block", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", "&:hover": { boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" } }}>
                            <ProductCard
                                refresh={getData}
                                wishFunc={addToWish}
                                cartFunc={addToCart}
                                account={prd}
                                loading={dealsLoading}
                            />
                        </Box>)
                    }{nextDealURL && bestDeals.length >= 1 &&
                        <div style={{ textAlign: "center", marginTop: "20px", width: "50%" }}>

                            <Button type='primary' style={{ textTransform: "capitalize", width: "inherit", }} onClick={()=>navigate("/products")}>All Products</Button>


                        </div>}
                </Box>
            </Box>






            <Divider textAlign='left' style={{ marginTop: "5px" }}><Tag style={{ padding: "10px" }}>2023 Top Sellers</Tag></Divider>

            <Container className='top-sellers' sx={{
                background: alpha("#79b898", 0.1),padding:"0",
                borderRadius: { xs: "0", md: "20px" }, width: "100%", marginTop: "20px", marginBottom: "20px"
            }}>
                        <Box sx={{width:{md:"100%", xs:"100%"}, padding:{xs:"20px", md:"20px"}, margin:"0 auto"}}>
                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px", display: { md: "inline-block", xs: "block" }, overflow: "hidden", width: { md: "31%", xs: "100%" }, paddingRight: "10px", margin: {md:"3px", xs:"5px"} }} className="seller-info">
                    <div style={{ fontFamily: "cursive", color: "inherit", textAlign: "center", width: "100%" }}>Peter Holmes</div>
                    <div className='seller-details' style={{ float: "left", width: "50%", color: "inherit" }}>
                        <ul style={{ listStyleImage: "disc", color: "inherit" }}>
                            <li>  Over NGN 200,000+ Monthly Sales </li>
                            <li>Sold 100+ Products </li>
                            <li>Positive Ratings and FeedBacks</li>
                            <li>Member Since 2021</li>
                        </ul>

                    </div>
                    <div className='seller-img' style={{ float: "right", width: "40%", textAlign: "center" }}>
                        <Avatar onClick={() => navigate("/profile/Peter")} sx={{ width: 130, height: 130 }} src={peterHome}></Avatar>

                        <Tag style={{ cursor: "pointer", margin: "5px", borderRadius: "5px" }} onClick={() => navigate("/profile/Peter")}>View Profile</Tag>
                    </div>
                </Paper>

                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px", display: { md: "inline-block", xs: "block" }, overflow: "hidden", width: { md: "31%", xs: "100%" }, paddingRight: "10px", margin: {md:"3px", xs:"5px"}}} className="seller-info">
                    <div style={{ fontFamily: "cursive", color: "inherit", textAlign: "center", width: "100%" }}>James Ayo</div>
                    <div className='seller-details' style={{ float: "left", width: "50%", color: "inherit" }}>
                        <ul style={{ listStyleImage: "disc", color: "inherit" }}>
                            <li>  Over NGN 1,000,000+  Monthly Sales </li>
                            <li>Sold 300+ Products </li>
                            <li>Positive Ratings and FeedBacks</li>
                            <li>Member Since 2020</li>
                        </ul>

                    </div>
                    <div className='seller-img' style={{ float: "right", width: "40%", textAlign: "center" }}>
                        <Avatar sx={{ width: 130, height: 130 }} onClick={() => navigate("/profile/James")} src={jamesHome} ></Avatar>

                        <Tag style={{ cursor: "pointer", margin: "5px", borderRadius: "5px" }} onClick={() => navigate("/profile/James")}>View Profile</Tag>

                    </div>
                </Paper>

                <Paper sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px", display: { md: "inline-block", xs: "block" }, overflow: "hidden", width: { md: "31%", xs: "100%" }, paddingRight: "10px", margin: {md:"3px", xs:"5px"} }} className="seller-info">
                    <div style={{ fontFamily: "cursive", color: "inherit", textAlign: "center", width: "100%" }}>Obed Maggi</div>
                    <div className='seller-details' style={{ float: "left", width: "50%", color: "inherit" }}>
                        <ul style={{ listStyleImage: "disc", color: "inherit" }}>
                            <li> Platform Re-Seller </li>
                            <li>  Over NGN 50,000+ Monthly Sales </li>

                            <li>Sold 50+ Products </li>
                            <li>Member Since 2023</li>
                        </ul>

                    </div>
                    <div className='seller-img' style={{ float: "right", width: "40%", textAlign: "center" }}>

                        <Avatar onClick={() => navigate("/profile/obed")} sx={{ width: 130, height: 130 }} src={obedHome}></Avatar>
                        <Tag style={{ cursor: "pointer", margin: "5px", borderRadius: "5px" }} onClick={() => navigate("/profile/obed")}>View Profile</Tag>

                    </div>
                </Paper>
                </Box>

            </Container>
            <Divider textAlign='left'><Tag style={{ padding: "10px" }}>We are available 24/7 <Telegram fontSize={"10px"} /></Tag></Divider>
            <Container className='contact-banner' style={{ textAlign: "center", alignItems: "center", justifyItems: "center" }}>
                <div title='Contact Us' style={{ width: "100%", display: "block" }}>
                    <Box onClick={() => window.open("https://wa.me/+2349039981921")} sx={{
                        display: "inline-block", cursor: "pointer",
                        boxShadow: "rgba(77, 97, 84, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(59, 110, 77, 0.35) 0px -2px 6px 0px inset",
                        margin: { xs: "2px", md: "10px" }, backgroundColor: alpha("#6e9187", 0.2),
                        padding: "30px", width: { md: "10vw", xs: "30%" }, "&:hover": { opacity: 0.5 }, borderRadius: "10px", objectFit: "cover"
                    }}       >

                        <img src={whatsappHome} ></img>
                    </Box>
                    <Box onClick={() => window.open("tel:+2348136095341")} border={"#a0b8a6"} sx={{
                        display: "inline-block", cursor: "pointer",
                        margin: { xs: "2px", md: "10px" }, backgroundColor: alpha("#6e9187", 0.2),
                        boxShadow: "rgba(77, 97, 84, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(59, 110, 77, 0.35) 0px -2px 6px 0px inset",
                        padding: "30px", width: { md: "10vw", xs: "30%" }, "&:hover": { opacity: 0.5 }, borderRadius: "10px", objectFit: "cover"
                    }}       >

                        <img src={phoneHome} ></img>
                    </Box>   <Box border={"#a0b8a6"} onClick={() => window.open("https://t.me/accountgig")} sx={{
                        display: "inline-block", cursor: "pointer",
                        margin: { xs: "2px", md: "10px" }, backgroundColor: alpha("#6e9187", 0.2),
                        boxShadow: "rgba(77, 97, 84, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(59, 110, 77, 0.35) 0px -2px 6px 0px inset",
                        padding: "30px", width: { md: "10vw", xs: "30%" }, "&:hover": { opacity: 0.5 }, borderRadius: "10px", objectFit: "cover"
                    }}       >

                        <img src={telegramHome} ></img>
                    </Box>
                </div>

            </Container>
            <Container className='news-letter' sx={{ marginBottom: 10, width: { xs: "100%", md: "80%" }, margin: "10 auto", marginTop: "40px", textAlign: "left" }}>
                <Form onFinish={(val) => handleNewsLetter(val)}>
                    <Box sx={{ width: { md: "60%", xs: "100%" }, }}>
                        <Form.Item name={"email"}>
                            <Input placeholder='Get Latest updates' name='email' type='email' width={"100%"} />
                        </Form.Item>
                        <Button htmlType="submit" style={{ width: "50%", marginTop: "10px" }} type="primary" >Subscribe</Button>
                    </Box>
                </Form>
            </Container>
        </Box >
    )

}