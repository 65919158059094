import React, { Component } from "react";
import NavBackWidget from "./back_navigator";
import { Box } from "@mui/material";
import { Divider } from "antd";

export default class CookiesUsage extends Component {
  render() {
    return (
      <div>
        <Box
          sx={{ width: { md: "60%", xs: "100%" } }}
          style={{ margin: "0 auto" }}
        >
          <NavBackWidget />
          <Box className="textContent" style={{ padding: "20px" }}>
            <h1>Cookies Usage</h1>

            <b>Cookie and analytical data</b>
            <br />
            <p>
              Cookies are very small text files that are stored on your computer
              when you visit some sites. We use cookies to identify your
              computer and tailor the User interface to your needs. You can
              disable any already stored cookies on your computer, but they may
              interfere with the proper operation of our Site and service. For a
              proper Account gig Site to work, you need to identify yourself as
              a User of the system and ensure that your session is safe.
            </p>
            <Divider style={{ width: "70%" }}>
              <p>Last update: January 30th, 2023.</p>
            </Divider>
          </Box>
        </Box>
      </div>
    );
  }
}
