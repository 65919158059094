import { combineReducers } from 'redux';
import inHubReducer from '../components/influence-hub/redux/reducers';
import influencerReducer from '../components/influence-hub/redux/influencer/reducer';
import influenceHubOrderReducer from '../components/influence-hub/redux/order/reducer';
import authReducer from './auth/reducer'
import coreReducer from './core/reducer'
import adGigReducer from '../components/influence-hub/redux/gig/reducer'

const rootReducer = combineReducers({
  auth : authReducer,
  core :coreReducer,
  influencer:influencerReducer,
  influenceHubOrder: influenceHubOrderReducer,
  adGig:adGigReducer
  // inHubReducer


  // Add reducers for other slices as needed...
});

export default rootReducer;
