import React, { Component } from "react";
import NavBackWidget from "../basic/back_navigator";
import { Box, Button,Icon, IconButton, Paper,FormGroup, Typography, Input as MuiInput, FormControl, Button as MuiButton } from "@mui/material";
import { Divider, Input, Modal, Select, message, Form, Table, Dropdown, Spin, Badge } from "antd";
import { ArrowCircleDown, ArrowDownward,ExpandLess, ArrowDropDown, ArrowDropDownOutlined, ArrowDropDownRounded, Twitter, Facebook, WhatsApp } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import referralImg from "../../assets/images/referral-gif.png";
import inviteImg from "../../assets/images/send-invite.png";
import refeSignupImg from "../../assets/images/ref-signup.png";
import rewardImg from "../../assets/images/reward.png";
import {client, copyFunc} from '../app_context.js';

export default class JoinourCommunity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refShow: false,
      refListHeight: "70px",
      loading: true,
      referralData: [],
      userRefUrl: '',
      userTotalEarnings:0,
      NumberofUsersInvitedbyUser:0,
      
    };
  }
fetchData(){
const refData = [];

 client.get("/user/api/referrals-handle").then((resp) => {
   this.setState({
     userRefUrl: window.origin + "/?ref=" + resp.data.userRefCode,
     userTotalEarnings: resp.data.total_earnings,
     NumberofUsersInvitedbyUser: resp.data.total_referrals,
   });
   if (resp.data.referrals){
     for (let i = 0; i < resp.data.referrals.length; i++) {
       const ref = resp.data.referrals[i];
       refData.push({
         name: ref.name,
         date: ref.date,
         status: (
           <Badge
             status={ref.status === true ? "success" : "warning"}
             text={ref.status === true ? "Rewarded" : "Pending"}
           />
         ),
         reward: ref.reward,
       });
     }}
  this.setState({ loading: false, referralData: refData });

 }).catch(err=> message.error('Problem fetching data'));
}
  componentDidMount() {
    
   this.fetchData()
    
  }

  handleRefShow() {
    this.setState({ loading: true, refShow: !this.state.refShow });
   this.fetchData();

    setTimeout(() => {
      if (this.state.refShow === true) {
        this.setState({ refListHeight: "100%" });
      } else {
        this.setState({ refListHeight: "70px" });
      }
      this.setState({ loading: false });
    }, 100);
  }

  refStepStyle = {
    wordBreak: "break-word",
    margin: "20px",
    display: "inline-block",
    padding: "20px",
    width: "200px",
    textAlign: "center",
    height: "300px",
    overflow: "hidden",
  };
  refSocStyle = {
    display: "inline-block",
  };
  socialBtnStyle = {
    backgroundColor: "#c7d3c0",
    padding:'10px',
    margin:'5px'
  };
  render() {
    return (
      <Spin spinning={this.state.loading} tip="fetching data">
        <div>
          <Box
            sx={{ width: { md: "60%", xs: "100%" } }}
            style={{ margin: "0 auto" }}
          >
            <NavBackWidget />
            <div>
              <Box
                className="referralList"
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  overflow: "hidden",
                  transition: "all 0.5s ease-in",
                  height: this.state.refListHeight,
                }}
              >
                <div
                  className="outerText"
                  style={{ color: "grey", fontSize: "20px" }}
                >
                  <img
                    width="50"
                    height="50"
                    alt="referral gifts"
                    src={referralImg}
                  ></img>{" "}
                  <b style={{ color: "teal" }}>{this.state.NumberofUsersInvitedbyUser} Users invited</b>
                </div>
                <div
                  className="mainTitle"
                  style={{ marginTop: "30px", textAlign: "center" }}
                >
                  <h3>
                    Total Earnings :
                    <span style={{ color: "green" }}> {this.state.userTotalEarnings}</span>{" "}
                  </h3>
                </div>
                <div
                  style={{
                    width: "30%",
                    padding: "0",
                    float: "right",
                    marginTop: "-100px",
                  }}
                  className="sideBtn"
                >
                  {this.state.refShow === false ? (
                    <IconButton
                      aria-label="more"
                      style={{
                        float: "right",
                        // backgroundColor: "rgba(233,323,233,2)",
                        fontSize: "30px",
                      }}
                      onClick={() => this.handleRefShow()}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="less"
                      style={{
                        float: "right",
                        // backgroundColor: "rgba(233,323,233,2)",
                        fontSize: "30px",
                      }}
                      onClick={() => this.handleRefShow()}
                    >
                      <ExpandLess />
                    </IconButton>
                  )}
                </div>

                <Table
                  dataSource={this.state.referralData}
                  columns={[
                    { title: "Name", dataIndex: "name" },
                    { title: "Date", dataIndex: "date" },
                    { title: "Status", dataIndex: "status" },
                    { title: "Reward", dataIndex: "reward" },
                  ]}
                ></Table>
              </Box>

              <Paper
                style={{ margin: "10px", marginTop: "20px", padding: "40px" }}
              >
                <h1 style={{ fontWeight: "bold" }}>Referral</h1>
                <p>
                  Invite your friends to AccountGig and get a{" "}
                  <b style={{ color: "yellowgreen" }}>NGN500</b> wallet top-up
                  per individual signup!{" "}
                </p>
                <Box className="ref-step-cont" style={{ textAlign: "center" }}>
                  <Box style={this.refStepStyle} className="ref-step">
                    <Icon
                      style={{
                        margin: "10px",
                        width: "100px",
                        height: "100px",
                        borderRadius: "100%",
                        padding: "10px",
                        backgroundColor: "#c3d7c3",
                      }}
                    >
                      <img
                        width="100%"
                        alt="send referral link"
                        src={inviteImg}
                      ></img>
                    </Icon>
                    <h4 style={{ margin: "4px" }}>Send invitation</h4>
                    <p
                      style={{
                        margin: "5px",
                        textAlign: "center",
                        width: "150px",
                      }}
                    >
                      Share your referral link with your friends or send them
                      your referral code
                    </p>
                  </Box>
                  <Box style={this.refStepStyle} className="ref-step">
                    <Icon
                      style={{
                        margin: "10px",
                        width: "100px",
                        height: "100px",
                        borderRadius: "100%",
                        padding: "10px",
                        backgroundColor: "#c3d7c3",
                      }}
                    >
                      <img
                        width="100%"
                        alt="friend signup"
                        src={refeSignupImg}
                      ></img>
                    </Icon>
                    <h4 style={{ margin: "4px" }}>Registration</h4>
                    <p
                      style={{
                        margin: "5px",
                        textAlign: "center",
                        width: "150px",
                      }}
                    >
                      They register and fund account wallet with or above 3000
                      Naira.
                    </p>
                  </Box>
                  <Box style={this.refStepStyle} className="ref-step">
                    <Icon
                      style={{
                        margin: "10px",
                        width: "100px",
                        height: "100px",
                        borderRadius: "100%",
                        padding: "10px",
                        backgroundColor: "#c3d7c3",
                      }}
                    >
                      <img width="100%" alt="Get reward" src={rewardImg}></img>
                    </Icon>
                    <h4 style={{ margin: "4px" }}>Get 500NGN Top-up</h4>
                    <p
                      style={{
                        margin: "5px",
                        textAlign: "center",
                        width: "150px",
                      }}
                    >
                      {" "}
                      You receive the sum of <b>500 Naira</b> in your account
                      wallet! &#128513;
                    </p>
                  </Box>
                </Box>
                <Box>
                  <h2>Share the referral link</h2>
                  <p>
                    {" "}
                    You can share your referral link by copying and sending it
                    or sharing it on your social media
                  </p>
                  <div style={this.refSocStyle} className="input-list">
                    <div
                      style={{
                        borderRadius: "30px",
                        backgroundColor: "#c3d7c3",
                        padding: "8px",
                        width: "100%",
                      }}
                    >
                      <input
                        id="refLink"
                        type="text"
                        disabled
                        value={this.state.userRefUrl}
                        style={{
                          width: "300px",
                          backgroundColor: "inherit",
                          padding: "10px",
                          borderRadius: "30px",
                          border: "none",
                          userSelect: "none",
                          MozUserFocus: "none",
                          WebkitTapHighlightColor: "Background",
                          height: "inherit",
                        }}
                      ></input>
                      <button
                        id="ref-copyBtn"
                        onClick={(e) => {
                          copyFunc(e, "refLink");
                        }}
                        style={{
                          height: "inherit",
                          borderRadius: "30px",
                          border: "none",
                          padding: "10px",

                          backgroundColor: "inherit",
                          fontSize: "13px",
                          color: "navyblue",
                        }}
                      >
                        Copy Link
                      </button>
                    </div>
                  </div>
                  <div style={this.refSocStyle} className="input-list">
                    <IconButton
                      onClick={() =>
                        window.open(
                          `whatsapp://send?text=Have you heard of this reliable and easy site where you can get different social media accounts? Try out AccountGig here with my link! ${this.state.userRefUrl}`
                        )
                      }
                      style={this.socialBtnStyle}
                    >
                      <WhatsApp />
                    </IconButton>
                  </div>
                  <div style={this.refSocStyle} className="input-list">
                    <IconButton
                      onClick={() =>
                        window.open(
                          `http://twitter.com/share?text=Have you heard of this reliable and easy site where you can get different social media accounts? Try out AccountGig here with my link!&url=${this.state.userRefUrl}&hashtags=accountGig,socialmediaaccounts,reliable&easy`
                        )
                      }
                      style={this.socialBtnStyle}
                    >
                      <Twitter />
                    </IconButton>
                  </div>
                  <div style={this.refSocStyle} className="input-list">
                    <IconButton
                      style={this.socialBtnStyle}
                      onClick={() =>
                        window.open(
                          `https://www.facebook.com/share.php?u=${this.state.userRefUrl}`
                        )
                      }
                    >
                      <Facebook />
                    </IconButton>
                  </div>
                </Box>
              </Paper>
            </div>
          </Box>
        </div>
      </Spin>
    );
  }
}
