import React from 'react'
import PropTypes from 'prop-types'
import DashBoardHome from './pages/Home'

// const propTypes = {

// }

function DashBoardIndex(props) {
    return (
        <div >
            <div></div>
            <DashBoardHome /></div>
    )
}

//  DashBoardIndex.propTypes = propTypes

export default DashBoardIndex
