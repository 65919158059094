import {  PeopleOutlined, Person, StarOutline } from "@mui/icons-material";
import { Avatar, List, Space,Tooltip } from "antd";
import React from "react";

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);


const GroupChat = ({ chat, user, setCurrentChat }) => (
  <List.Item
    actions={[
      <IconText
        icon={StarOutline}
        text={chat.group.starred_count}
        key="list-vertical-star-o"
      />,
      <IconText
        icon={PeopleOutlined}
        text={chat.group.active_members_count}
        key="list-vertical-like-o"
      />,
      <IconText
        text={`${chat.group.pending_member_approval_count} waiting approval`}
        key="list-vertical-message"
      />,
    ]}
    extra={<img width={272} alt="logo" src={chat.image} />}
  >
    <List.Item.Meta
      avatar={
        <Avatar.Group
          maxCount={2}
          maxPopoverTrigger="click"
          size="large"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {chat.members.map((member) => (
            <Tooltip title={member.username} placement="top">
              <Avatar
                style={{
                  backgroundColor: "#87d068",
                }}
                src={member.avatar}
                icon={<Person />}
              >
                {String(member.username)[0]}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      }
      title={chat.group.name}
      description={chat.latest_message.body}
    />
    <p> {chat.dateCreated}</p>
  </List.Item>
);
export default GroupChat;
